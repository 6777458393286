import styled from 'styled-components';

export const MessageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 32px;

  p {
    font-size: 16px;
  }
`;

export const LogoWrapper = styled.div`
  color: var(--gray-5);
  animation: spin 10s linear infinite;

  svg {
    width: 200px;
    height: 200px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
