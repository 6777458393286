import { ShippingPriceDTO } from '@invenco/common-interface/shipping';
import { AccountDTO } from '@invenco/common-interface/accounts';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Filter, FilterSelections, useDataTable, useRowNavigation } from 'components/data-table';
import { useQueryWithInput } from 'shared/hooks/queries';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { ComponentData, Page, Pagination } from '../../../../shared/types';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';

export type ShippingPriceRow = ShippingPriceDTO;

export type FilterKey = 'accountId';

type Models = {
  accountsById?: Record<string, AccountDTO>;
  isLoading: boolean;
  isLoadingAccounts: boolean;
  hasError: boolean;
  rows?: ShippingPriceRow[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  filters: Filter<FilterKey>[];
  filterSelections?: FilterSelections<FilterKey>;
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  updateFilters: (filters: FilterSelections<FilterKey>) => void;
  refresh: () => void;
  onClickRow: (row: ShippingPriceRow, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export function useShippingPriceListPage(): ComponentData<Models, Operations> {
  const { accountsGateway, shippingGateway } = useGateways();
  const { onClickRow } = useRowNavigation<ShippingPriceRow>({
    baseUrl: '/shipping/prices',
  });
  const [tableState, setTableState, { getFilterValue }] = useDataTable({
    filterKeys: ['accountId'],
  });

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'shippingPrices',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
      accountId: getFilterValue('accountId'),
    },
    query: (input, { signal }) => shippingGateway.getShippingPrices(input, { signal }),
  });

  const { data: accountsData, isLoading: isLoadingAccounts } = useQuery({
    queryKey: ['accounts'],
    queryFn: ({ signal }) => accountsGateway.getAccounts(undefined, { signal }),
  });

  const accountsById = useMemo(
    () =>
      accountsData?.items.reduce(
        (accumulated, account) => ({ ...accumulated, [account.id!]: account }),
        {},
      ) as Record<string, AccountDTO>,
    [accountsData],
  );

  const accountFilter = useMemo<Filter<FilterKey>>(
    () => ({
      key: 'accountId',
      title: 'Account',
      options: accountsData?.items.map((acc) => ({ value: acc.id, title: acc.name })),
      loading: isLoadingAccounts,
    }),
    [accountsData, isLoadingAccounts],
  );

  return {
    models: {
      accountsById,
      isLoading,
      isLoadingAccounts,
      hasError: isError,
      rows: data?.items as ShippingPriceRow[] | undefined,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      filters: [accountFilter],
      filterSelections: tableState.filters,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      updateFilters: (updated: FilterSelections<FilterKey>) =>
        setTableState({ filters: updated, view: undefined, page: undefined }),
      refresh: refetch,
      onClickRow,
    },
  };
}
