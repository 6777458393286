import { QueryFunctionContext, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { AxiosError } from 'axios';
import { useMessages } from '../../providers/MessagesProvider';
import { extractErrorMessage } from '../../helpers';
import { useQueryWithInput } from './useQueryWithInput';

type BaseT = { id?: string };
type QueryKey = [string, string | undefined];

type Props<T extends BaseT> = {
  parentKey: string;
  id?: string;
  query: (fetchId: string, context: QueryFunctionContext<QueryKey>) => Promise<T>;
  isNew?: boolean;
} & Omit<Partial<UseQueryOptions<T, Error, T, QueryKey>>, 'queryFn' | 'queryKey'>;

export function useEntityDetailsQuery<T extends BaseT>({
  parentKey,
  id,
  query,
  isNew,
  enabled = true,
  ...rest
}: Props<T>) {
  const queryClient = useQueryClient();
  const messages = useMessages();

  // check whether we can fetch the DTO from parent lists in cache
  const placeholderData = useMemo(
    () =>
      id
        ? queryClient
            .getQueriesData<{ items: T[] }>({ queryKey: [parentKey] })
            ?.flatMap(([, data]) => data?.items || [])
            .find((o) => o.id === id)
        : undefined,
    [parentKey, id, queryClient],
  );

  const result = useQueryWithInput({
    parentKey,
    input: id,
    query: (fetchId, context) => query(fetchId!, context),
    enabled: enabled && !isNew && !!id,
    placeholderData: placeholderData as Props<T>['placeholderData'], // react query is weird about it not being a function
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => (error as AxiosError).status !== 404 && failureCount < 3,
    ...rest,
  });

  useEffect(() => {
    if (result.error) {
      messages.error(extractErrorMessage(result.error), { key: `${parentKey}-${id}-error` });
    }
  }, [result.error, messages, parentKey, id]);

  return result;
}
