import { CarrierServiceCategory, CarrierServiceDTO } from '@invenco/common-interface/shipping';

import { Button } from 'components/button';
import {
  Container,
  DetailContainer,
  MainContainer,
  SideContainer,
} from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { formatDateTime } from '../../../../shared/helpers';
import { DEFAULT_DATETIME_FORMAT, DEFAULT_TIME_ZONE } from '../../../../constants';
import { Card } from '../../../../components/card';
import { DetailsCard } from '../../../../components/details-card';
import { DetailsBlock } from '../../../../components/details-block';
import { CarrierDetailsModal } from './CarrierDetailsModal';
import { useCarrierDetailsPage } from './useCarrierDetailsPage';
import { carrierServiceCategoryTitle } from '../../../../shared/title-maps';
import { DataTable, Column } from '../../../../components/data-table';

const serviceColumns: Column<CarrierServiceDTO>[] = [
  { title: 'Name', key: 'name' },
  { title: 'Code', key: 'code' },
  {
    title: 'Category',
    key: 'category',
    render: (category: CarrierServiceCategory) => carrierServiceCategoryTitle[category],
  },
  {
    title: 'External Codes',
    key: 'externalCodes',
    render: (codes: string[]) => codes.sort().join(', '),
  },
];

export function CarrierDetails() {
  const {
    models: { isNew, isLoading, isSaving, isModalOpen, canSave, breadcrumbs, carrier },
    operations: { openModal, closeModal, refresh, save, updateCarrier, onClickService },
  } = useCarrierDetailsPage();

  return (
    <Container>
      <Header
        links={breadcrumbs}
        title={isNew ? 'New Carrier' : carrier?.name}
        description={
          carrier?.createdAt &&
          formatDateTime(carrier.createdAt, DEFAULT_TIME_ZONE, DEFAULT_DATETIME_FORMAT)
        }
        loading={isLoading}
        loadDescription
        backURL="/shipping/carriers"
        onRefresh={!isNew ? refresh : undefined}
        actionContent={
          isNew && (
            <Button
              type="primary"
              disabled={!canSave}
              loading={isSaving}
              onClick={() => void save()}
            >
              Save
            </Button>
          )
        }
      />

      <DetailContainer>
        <MainContainer>
          <Card title="Services" count={carrier?.carrierServices?.length ?? 0}>
            <DataTable
              simple
              columns={serviceColumns}
              rows={carrier.carrierServices ?? []}
              loading={isLoading}
              onClickRow={onClickService}
            />
          </Card>
        </MainContainer>
        <SideContainer>
          <DetailsCard hasEditButton={isNew} onEdit={openModal}>
            <DetailsBlock contentTitle={carrier.name} loadingRows={3}>
              <div>{carrier.code}</div>
              <div>{carrier.baseTrackingUrl}</div>
            </DetailsBlock>
          </DetailsCard>
        </SideContainer>
      </DetailContainer>

      <CarrierDetailsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        updateCarrier={updateCarrier}
        isSaving={isSaving}
        carrier={carrier}
      />
    </Container>
  );
}
