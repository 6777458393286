import { EnumMap } from '../types';

export enum KeyCaseType {
  CAMEL = 'camel',
  SNAKE = 'snake',
}

const keyCaseTypeMap: EnumMap<KeyCaseType, (s: string) => string> = {
  camel: (s) => {
    if (!s) return s;
    return s.replace(/_[^_]/g, (m) => m.substr(m.length - 1, m.length).toUpperCase());
  },
  snake: (s) => {
    if (!s) return s;
    return s.replace(
      /[a-z][A-Z0-9]+/g,
      (m) => `${m.substr(0, 1)}_${m.substr(1, m.length).toLowerCase()}`,
    );
  },
};

export function transformCaseKeys(
  obj: Record<string, any> | Record<string, any>[],
  keyCaseType = KeyCaseType.CAMEL,
  seen = new Map(),
): any {
  if (seen.has(obj)) {
    return seen.get(obj);
  }
  const transform = keyCaseTypeMap[keyCaseType];
  if (Array.isArray(obj)) {
    const newArray: any[] = [];
    seen.set(obj, newArray);
    obj.forEach((value, key) => {
      newArray[key] = transformCaseKeys(value, keyCaseType, seen);
    });
    return newArray;
  }
  if (Object.prototype.toString.call(obj) === '[object Object]') {
    const newObj = {};
    seen.set(obj, newObj);
    Object.entries(obj).forEach(([key, value]) => {
      newObj[transform(key)] = transformCaseKeys(value, keyCaseType, seen);
    });
    return newObj;
  }
  return obj;
}

export const extractErrorMessage = (error: any) =>
  typeof error === 'string'
    ? error
    : (error.response && error.response.data && error.response.data.message) || error.message;

export const extractErrorDetails = (error?: Record<string, any>) => {
  if (error && Array.isArray(error?.response?.data?.errors)) {
    return [...error.response.data.errors];
  }
  return [];
};
