import { useMemo, useState } from 'react';
import {
  BaseRow,
  TableColumn,
  TableSelection,
  TableUpdateCallback,
  TableUpdateOptions,
} from '../list-table/types';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { ComponentData, Pagination } from '../../shared/types';

type Props<T extends BaseRow> = {
  pageSize?: number;
  searchColumns?: string[];
  searchValues?: (record: T, index: number) => string[];
  columns: TableColumn<T>[];
  rows: T[];
  customOnUpdate?: TableUpdateCallback;
};

type Models<T extends BaseRow> = {
  pageRows: T[];
  total: number;
  pagination: Pagination;
};

type Operations = {
  update: TableUpdateCallback;
};

export function useMemoryTableComponent<T extends BaseRow>({
  pageSize = DEFAULT_PAGE_SIZE,
  searchColumns,
  searchValues,
  columns,
  rows,
  customOnUpdate,
}: Props<T>): ComponentData<Models<T>, Operations> {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<string>();

  const searchKeys = useMemo(
    // If searchColumns is defined, use it, otherwise use all columns
    () => searchColumns || columns.map(({ key }) => key),
    [searchColumns, columns],
  );

  // This is the list of rows that match the search query
  const filteredRows = useMemo(
    () =>
      query
        ? // If there is a query, filter the rows
          rows.filter((row, index) =>
            (searchValues
              ? // If a searchValues function is defined, use it to get the array of values to search
                searchValues(row, index)
              : // Otherwise, build an array of search values by looking up the row value for each search key
                searchKeys.map((key) => (key === 'id' ? '' : row[key]))
            ).some(
              // Find a value in the search values array that matches the query
              (value) =>
                value && value.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()),
            ),
          )
        : rows,
    [query, searchValues, searchKeys, rows],
  );

  // Get the rows for the current page
  const pageRows = useMemo(
    () => filteredRows.slice((page - 1) * pageSize, page * pageSize),
    [filteredRows, page, pageSize],
  );

  // Build the pagination object
  const maxPages = Math.ceil(filteredRows.length / pageSize);
  const pagination = useMemo(
    () => ({
      prev: page > 1 ? { cursor: (page - 1).toString(), take: -pageSize } : null,
      next: page < maxPages ? { cursor: (page + 1).toString(), take: pageSize } : null,
    }),
    [page, maxPages, pageSize],
  );

  const update = (selection: TableSelection, options: TableUpdateOptions) => {
    const newQuery = selection.query?.trim() || undefined;
    if (newQuery !== query) {
      setPage(1);
      setQuery(newQuery || undefined);
    } else if (selection.page?.cursor) {
      setPage(+selection.page.cursor);
    }
    customOnUpdate?.(selection, options);
  };

  return {
    models: { pageRows, total: filteredRows.length, pagination },
    operations: { update },
  };
}
