import { Input, Select } from 'antd';
import { Card } from 'components/card';
import { Button } from 'components/button';
import { useTenantDetailsPage } from './useTenantDetailsPage';
import {
  Container,
  DetailContainer,
  MainContainer,
  SideContainer,
} from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { DetailsBlock } from '../../../../components/details-block';
import { DetailList } from '../../../../components/detail-list/DetailList';
import { StatusLabel } from '../../../../components/label';
import { tenantModeType } from '../../../../shared/status-maps';
import { Form } from '../../../../components/form';

export function TenantDetails() {
  const {
    models: { isLoading, isSaving, hasChanges, warehouseTenant, breadcrumbs },
    operations: { updateOptions, save, refresh },
  } = useTenantDetailsPage();
  return (
    <Container>
      <Header
        title={warehouseTenant.tenant?.name}
        backURL="/tenants"
        links={breadcrumbs}
        loading={isLoading}
        loadDescription
        description={warehouseTenant.warehouse?.name}
        onRefresh={refresh}
        actionContent={
          <Button
            type="primary"
            loading={isSaving}
            disabled={!hasChanges}
            onClick={() => void save()}
          >
            Save
          </Button>
        }
      />
      <DetailContainer>
        <MainContainer>
          <Card title="Options">
            <Form layout="vertical">
              <Form.Item label="Work Stream Name">
                <Input
                  aria-label="Work Stream Name"
                  value={warehouseTenant.warehouseTenantOptions?.workStreamName}
                  onChange={(e) => updateOptions({ workStreamName: e.target.value })}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item label="Pack Method">
                <Input
                  aria-label="Pack Method"
                  value={warehouseTenant.warehouseTenantOptions?.packMethod}
                  onChange={(e) => updateOptions({ packMethod: e.target.value })}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item label="Tariff Code">
                <Input
                  aria-label="Tariff Code"
                  value={warehouseTenant.warehouseTenantOptions?.tariffCode}
                  onChange={(e) => updateOptions({ tariffCode: e.target.value })}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item label="Allowable Pick Strategies">
                <Select
                  aria-label="Allowable Pick Strategies"
                  mode="tags"
                  value={warehouseTenant.warehouseTenantOptions?.allowablePickStrategies}
                  onChange={(value: string[]) => updateOptions({ allowablePickStrategies: value })}
                  disabled={isLoading}
                />
              </Form.Item>
            </Form>
          </Card>
        </MainContainer>
        <SideContainer>
          <Card title="Tenant">
            <DetailsBlock loading={isLoading} loadingRows={2}>
              <DetailList
                items={[
                  { label: 'Code', value: warehouseTenant.tenant?.code },
                  {
                    label: 'Mode',
                    value: (
                      <StatusLabel status={warehouseTenant.tenant?.mode} type={tenantModeType} />
                    ),
                  },
                ]}
              />
            </DetailsBlock>
          </Card>
        </SideContainer>
      </DetailContainer>
    </Container>
  );
}
