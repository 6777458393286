import OneSchemaImporter from '@oneschema/react';
import { formatShortInt } from 'shared/helpers';
import { DEPLOYMENT_STAGE, ONESCHEMA_CLIENT_ID } from 'env';
import { Button } from 'components/button/Button';
import { useDataImporterComponent } from './useDataImporterComponent';
import { TemplateKey } from './types';
import { DataImporterRunnerModal } from './DataImporterRunnerModal';

// TODO: LYNK-1600: generate JWTs on server - these are temporary hardcoded JWTs per environment
const DEV_USER_JWT =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjNzBhZTA5Mi1mOTdkLTQ0OWYtOGYzZC1lNzA2ZTU5YjNmYzciLCJ1c2VyX2lkIjoiPFVTRVJfSUQ-In0.Zyn_FA-MwYlPxXmPm-eZQ5Ww7Gv0j3YkWbfYFFdvT_s';
const PROD_USER_JWT =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiPFVTRVJfSUQ-IiwiaXNzIjoiMTZkNjQ5ZGEtMzlmZi00ZmFhLTk3MTMtNWEzYmJiYTZmY2E3In0.DMgWrrPqNb6e2c5ryLjOkuZUCVV_rpO4EBC-vBXINgs';

type Props = {
  template: TemplateKey;
  buttonText?: string;
  title?: string;
  onComplete?: () => void;
};

export function DataImporter({
  template,
  buttonText = 'Import',
  title = 'Import',
  onComplete,
}: Props) {
  const {
    models: { isImporterOpen, isRunnerModalOpen, isRunning, total, created, completed, failures },
    operations: { processImportData, handleError, open, closeRunnerModal, closeImporter },
  } = useDataImporterComponent({ template, onComplete });
  const completedLabel = `${formatShortInt(completed ?? 0)}/${formatShortInt(total ?? 0)}`;
  return (
    <>
      <Button onClick={open} loading={isImporterOpen}>
        {isRunning ? `Importing ${completedLabel}` : buttonText}
      </Button>

      <OneSchemaImporter
        isOpen={isImporterOpen}
        onRequestClose={closeImporter}
        clientId={ONESCHEMA_CLIENT_ID}
        devMode={DEPLOYMENT_STAGE !== 'PROD'}
        // TODO: LYNK-1600: generate JWT with account_id / user_id
        userJwt={DEPLOYMENT_STAGE === 'PROD' ? PROD_USER_JWT : DEV_USER_JWT}
        templateKey={template}
        importConfig={{ type: 'local' }}
        onSuccess={(data) => void processImportData(data)}
        onCancel={closeImporter}
        onError={(err) => handleError(err.message)}
        className="oneschema-importer"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 1000,
        }}
      />

      <DataImporterRunnerModal
        template={template}
        title={title}
        open={isRunnerModalOpen}
        running={isRunning}
        totalCount={total}
        completedCount={completed}
        createdRecords={created}
        errorRecords={failures}
        onCancel={closeRunnerModal}
      />
    </>
  );
}
