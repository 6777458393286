import styled, { css } from 'styled-components';
import { autoScroll } from '../../styles/mixins';

const simpleBleedAmount = '16px';
const normalBleedAmount = '32px';

const tableBleed = (amount: string) => `
  & {
    margin-left: -${amount};
    margin-right: -${amount};
  }
  td, th {
    &:first-child {
      padding-left: ${amount};
    }
    &:last-child {
      padding-right: ${amount};
    }
  }
`;

export const StyledDataTable = styled.div<{ $simple?: boolean }>`
  ${({ $simple }) => tableBleed($simple ? simpleBleedAmount : normalBleedAmount)}
  & {
    max-width: unset;
    ${autoScroll('var(--gray-1)')}
  }

  table {
    width: 100%;
  }
`;

type CellProps = {
  $hasCheckbox?: boolean;
  $align?: 'left' | 'center' | 'right';
  $simple?: boolean;
};

const regularCellStyle = css`
  border-top: 1px solid var(--gray-4);
  height: 40px;
`;

const simpleCellStyle = css`
  height: 48px;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const checkboxCellStyle = css`
  width: 64px;
  cursor: pointer;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    background: var(--blue-4);
    opacity: 0.02;
    transition: all 0.15s;
  }

  &:not(:has(.ant-checkbox-disabled)) {
    &:hover::after {
      width: 100%;
      opacity: 0.1;
    }
  }
`;

const cellStyles = css<CellProps>`
  padding: 8px;
  ${({ $simple }) => ($simple ? simpleCellStyle : regularCellStyle)}
  ${({ $align = 'left' }) => `text-align: ${$align};`}
  ${({ $hasCheckbox }) => $hasCheckbox && checkboxCellStyle}
`;

const simpleHeaderStyle = css`
  &:not(:first-child):not(:last-child) {
    border-bottom: 1px solid var(--gray-4);
  }

  &:first-child,
  &:last-child {
    position: relative;
    border-bottom: none;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      background-color: var(--gray-4);
    }
  }

  &:first-child::after {
    left: ${simpleBleedAmount};
    right: 0;
  }
  &:last-child::after {
    left: 0;
    right: ${simpleBleedAmount};
  }
`;

export const HeaderCell = styled.th<CellProps>`
  ${cellStyles}
  ${({ $simple }) => $simple && simpleHeaderStyle}
  font-weight: 600;
  ${({ onClick }) => onClick && 'cursor: pointer;'}
`;

export const SortedHeaderInner = styled.div<{ $active: boolean }>`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  .sort-icon {
    color: var(--gray-8);
  }
  ${({ $active }) =>
    !$active &&
    `
    .sort-icon {
      visibility: hidden;
    }
    &:hover .sort-icon {
      visibility: visible;
      color: var(--gray-6);
    }
  `}
`;

export const BodyCell = styled.td<CellProps>`
  ${cellStyles}
`;

export const TableRow = styled.tr<{ $clickable?: boolean }>`
  &:hover td {
    background: var(--gray-2);
  }
  ${({ $clickable }) => $clickable && 'cursor: pointer;'}
`;

export const MessageRow = styled.tr`
  td {
    text-align: center;
    color: var(--gray-5);
  }
`;
