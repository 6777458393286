import { Input } from 'antd';
import { Form } from 'components/form';

export function CustomerForm() {
  return (
    <>
      <Form.Item
        name="customerName"
        label="Customer Name"
        rules={[{ required: true, message: 'Please enter customer name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, type: 'email', message: 'Please enter email' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone"
        rules={[{ required: true, message: 'Please enter contact phone number' }]}
      >
        <Input />
      </Form.Item>
    </>
  );
}
