import { ShippingPriceDTO } from '@invenco/common-interface/shipping';
import { AccountDTO } from '@invenco/common-interface/accounts';
import { Checkbox, Select } from 'antd';
import { Form } from 'components/form';
import { DatePicker } from 'components/date-picker';
import { useDetailsModalComponent } from './useDetailsModalComponent';
import { FormModal } from '../../../../../components/form-modal';
import { DEFAULT_DATE_FORMAT } from '../../../../../constants';

type Props = {
  isNew: boolean;
  isOpen: boolean;
  shippingPrice: Partial<ShippingPriceDTO>;
  updateDetails: (data: Partial<ShippingPriceDTO>) => void;
  accounts: AccountDTO[];
  onClose: () => void;
};

export function DetailsModal({
  isNew,
  isOpen,
  accounts,
  shippingPrice,
  updateDetails,
  onClose,
}: Props) {
  const {
    models: { form, isLocationSpecific, locationsByAccount },
    operations: { submit, handleAccountChange, handleToggleSpecificLocation },
  } = useDetailsModalComponent({ isOpen, updateDetails, onClose, shippingPrice });

  const initialValues = {
    accountId: shippingPrice.accountId,
    validFrom: shippingPrice.validFrom ? new Date(shippingPrice.validFrom) : null,
    validTo: shippingPrice.validTo ? new Date(shippingPrice.validTo) : null,
    locationId: shippingPrice.locationName,
  };

  return (
    <FormModal
      title="Edit Shipping Price"
      open={isOpen}
      onSave={() => form.submit()}
      onCancel={onClose}
    >
      <Form
        layout="vertical"
        name="priceDetails"
        form={form}
        initialValues={initialValues}
        onFinish={(data: any) => submit(data)}
      >
        <Form.Item
          name="accountId"
          label="Account"
          rules={[{ required: true, message: 'Please select an account' }]}
        >
          <Select
            showSearch
            disabled={!isNew}
            filterOption={(input, opt) =>
              (opt?.label?.toString() ?? '').toLocaleLowerCase().includes(input.toLocaleLowerCase())
            }
            options={accounts.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            onChange={handleAccountChange}
          />
        </Form.Item>

        <Form.Item
          name="validFrom"
          label="Valid From"
          rules={[{ required: true, message: 'Please provide a date this price is valid from' }]}
        >
          <DatePicker format={DEFAULT_DATE_FORMAT} />
        </Form.Item>

        <Form.Item
          name="validTo"
          label="Valid To"
          rules={[{ required: true, message: 'Please provide a date this price valid to' }]}
        >
          <DatePicker format={DEFAULT_DATE_FORMAT} />
        </Form.Item>

        <Form.Item>
          <Checkbox
            checked={isLocationSpecific}
            onChange={(e) => handleToggleSpecificLocation(e.target.checked)}
          >
            Apply this price to a specific location
          </Checkbox>
        </Form.Item>

        {isLocationSpecific && (
          <Form.Item
            name="locationId"
            label="Location"
            rules={[{ required: true, message: 'Please provide a location this price applies to' }]}
          >
            <Select
              showSearch
              filterOption={(input, opt) =>
                (opt?.label?.toString() ?? '')
                  .toLocaleLowerCase()
                  .includes(input.toLocaleLowerCase())
              }
              options={locationsByAccount?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            />
          </Form.Item>
        )}
      </Form>
    </FormModal>
  );
}
