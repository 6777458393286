import { ConditionDTO, PropertyConditionDTO } from '@invenco/common-interface/automation';
import {
  ConditionOperator,
  PropertyConditionEvaluationAttributes,
  PropertyConditionName,
  PropertyConditionOperatorMap,
  PropertyConditionType,
  WorkflowConfigurationMap,
  WorkflowScopeType,
} from '@invenco/common-domain/enums';
import { InputValueDetails } from '../config/types';
import { propertyConditionOverrides } from '../config/conditions';

export const isPropertyCondition = (
  c: ConditionDTO | PropertyConditionDTO,
): c is PropertyConditionDTO => 'property' in c;

export const getPropertyConditionOperators = (name: PropertyConditionName): ConditionOperator[] => {
  const { type, inputType } = PropertyConditionEvaluationAttributes[name];
  return PropertyConditionOperatorMap[type]?.[inputType ?? type] ?? [];
};

export const getDefaultPropertyCondition = (scopeType: WorkflowScopeType): PropertyConditionDTO => {
  const property = Object.keys(
    WorkflowConfigurationMap[scopeType].propertyConditionEvaluationAttributes,
  )[0] as PropertyConditionName;
  return {
    property,
    operator: getPropertyConditionOperators(property)[0] ?? ConditionOperator.EQUAL_TO,
    // @ts-expect-error - providing a value isn't ideal for some inputs, form validation will handle this if submitted as is
    value: undefined,
  };
};

export const getPropertyConditionDetails = (
  name: PropertyConditionName,
): InputValueDetails & { operators: ConditionOperator[] } => {
  const apiAttributes = PropertyConditionEvaluationAttributes[name];
  const apiInputType = apiAttributes.inputType ?? apiAttributes.type;
  const overrides = propertyConditionOverrides[name];
  let inputType = overrides?.type;

  if (!inputType) {
    switch (apiInputType) {
      case PropertyConditionType.object: // this is not used but we need a safe default
        inputType = 'string';
        break;
      case PropertyConditionType.number:
        inputType = 'float'; // default to allowing float numbers
        break;
      default:
        inputType = apiInputType;
    }
  }

  return {
    ...overrides,
    operators: getPropertyConditionOperators(name),
    type: inputType,
  };
};
