import { useId, useState } from 'react';
import { ComponentData } from '../../shared/types';

type Props = { initiallyCollapsed?: boolean };
type Models = { isCollapsed: boolean; headerId: string };
type Operations = { toggleCollapsed: () => void };

export const useCardComponent = ({
  initiallyCollapsed = false,
}: Props): ComponentData<Models, Operations> => {
  const headerId = useId();
  const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);
  const toggleCollapsed = () => setIsCollapsed(!isCollapsed);
  return {
    models: { isCollapsed, headerId },
    operations: { toggleCollapsed },
  };
};
