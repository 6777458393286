import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosInventoryGateway } from './impl/AxiosInventoryGateway';
import { AxiosSalesGateway } from './impl/AxiosSalesGateway';
import { AxiosProductsGateway } from './impl/AxiosProductsGateway';
import { AxiosSupplyGateway } from './impl/AxiosSupplyGateway';
import { AxiosAccountsGateway } from './impl/AxiosAccountsGateway';
import { AxiosAutomationGateway } from './impl/AxiosAutomationGateway';
import { AxiosShippingGateway } from './impl/AxiosShippingGateway';
import { AxiosWarehousingGateway } from './impl/AxiosWarehousingGateway';
import { AxiosAnalyticsGateway } from './impl/AxiosAnalyticsGateway';
import { AxiosIntegrationGateway } from './impl/AxiosIntegrationGateway';
import { UserMembershipSorter } from './impl/UserMembershipSorter';
import { ObjectStore } from './ObjectStore';

// potentially refactor gateways to use a non-specific interface
export type GatewayContextData = {
  accountsGateway: AxiosAccountsGateway;
  analyticsGateway: AxiosAnalyticsGateway;
  automationGateway: AxiosAutomationGateway;
  inventoryGateway: AxiosInventoryGateway;
  productsGateway: AxiosProductsGateway;
  salesGateway: AxiosSalesGateway;
  shippingGateway: AxiosShippingGateway;
  supplyGateway: AxiosSupplyGateway;
  warehousingGateway: AxiosWarehousingGateway;
  integrationGateway: AxiosIntegrationGateway;
};

export const GatewayContext = createContext<GatewayContextData>({} as GatewayContextData);
const objectStore = new ObjectStore('accounts');
const userMembershipSorter = new UserMembershipSorter(objectStore);

export function useGateways() {
  return useContext(GatewayContext);
}

export function GatewayProvider({ children }: PropsWithChildren) {
  const { getAccessTokenSilently } = useAuth0();
  const contextData = useMemo(
    () => ({
      accountsGateway: new AxiosAccountsGateway(getAccessTokenSilently, userMembershipSorter),
      analyticsGateway: new AxiosAnalyticsGateway(getAccessTokenSilently),
      automationGateway: new AxiosAutomationGateway(getAccessTokenSilently),
      inventoryGateway: new AxiosInventoryGateway(getAccessTokenSilently),
      productsGateway: new AxiosProductsGateway(getAccessTokenSilently),
      salesGateway: new AxiosSalesGateway(getAccessTokenSilently),
      shippingGateway: new AxiosShippingGateway(getAccessTokenSilently),
      supplyGateway: new AxiosSupplyGateway(getAccessTokenSilently),
      warehousingGateway: new AxiosWarehousingGateway(getAccessTokenSilently),
      integrationGateway: new AxiosIntegrationGateway(),
    }),
    [getAccessTokenSilently],
  );

  return <GatewayContext.Provider value={contextData}>{children}</GatewayContext.Provider>;
}
