import styled from 'styled-components';

export const MysteryComponentTitleLine = styled.div`
  display: flex;
  gap: 8px;
  font-weight: normal;
  font-size: 14px;
  align-items: center;

  strong {
    font-weight: 900;
    font-size: 16px;
  }
`;
