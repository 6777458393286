import { useMemo, useState } from 'react';
import { OrderAction } from '@invenco/common-interface/sales';
import { ComponentData } from '../../../../../../shared/types';
import { Result } from '../../../../../../shared/helpers/Result';

type Models = {
  performingAction?: OrderAction;
  actions: OrderAction[];
  modalAction?: OrderAction;
};

type Operations = {
  isActionDisabled: (action: OrderAction) => boolean;
  performAction: (action: OrderAction) => Promise<void>;
  submitModalConfirm: () => void;
  closeModal: () => void;
  openModal: (key: OrderAction) => void;
};

type Props = {
  availableActions: OrderAction[];
  onAction: (action: OrderAction) => Promise<Result>;
};

export function useOrderActionsPanelComponent({
  availableActions,
  onAction,
}: Props): ComponentData<Models, Operations> {
  const [performingAction, setPerformingAction] = useState<OrderAction>();
  const [modalAction, setModalAction] = useState<OrderAction>();

  // make sure actions are in the defined order
  // NOTE: if any new OrderAction is added, be sure to add it to this list
  const actions = useMemo(
    () =>
      [
        OrderAction.CANCEL,
        OrderAction.CLOSE,
        OrderAction.APPLY_MANUAL_HOLD,
        OrderAction.REVERT_TO_DRAFT,
        OrderAction.RELEASE_MANUAL_HOLD,
        OrderAction.RELEASE,
      ].filter((action) => availableActions.includes(action)),
    [availableActions],
  );
  const isActionDisabled = (action: OrderAction) =>
    !!performingAction && performingAction !== action;

  const performAction = async (action: OrderAction) => {
    setPerformingAction(action);
    await onAction(action);
    setPerformingAction(undefined);
    setModalAction(undefined);
  };

  const submitModalConfirm = () => modalAction && performAction(modalAction);

  const openModal = (key: OrderAction) => {
    setModalAction(key);
  };

  return {
    models: { performingAction, actions, modalAction },
    operations: {
      performAction,
      isActionDisabled,
      submitModalConfirm,
      openModal,
      closeModal: () => setModalAction(undefined),
    },
  };
}
