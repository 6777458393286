import { ShipmentStatus } from '@invenco/common-interface/shipping';
import { Card } from 'components/card';
import { StatusLabel } from 'components/label';
import { ContextMenu } from 'components/context-menu';
import { OrderCurrency } from '@invenco/common-interface/sales';
import { Container, ListContainer } from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { formatDate, formatMoney } from '../../../../shared/helpers';
import { useShipmentListPage, ShipmentRow, sortOptions, views } from './useShipmentListPage';
import { LoadingSkeleton } from '../../../../components/loading-skeleton';
import { shipmentStatusTitle } from '../../../../shared/title-maps';
import { SetCostModal } from './set-cost-modal/SetCostModal';
import { DataTable, Column } from '../../../../components/data-table';
import { shipmentStatusType } from '../../../../shared/status-maps';

export function ShipmentList() {
  const {
    models: {
      isLoading,
      isLoadingAccounts,
      hasError,
      accountsById,
      selectedShipmentForCost,
      rows,
      pagination,
      total,
      query,
      view,
      filters,
      filterValues,
      sort,
    },
    operations: {
      search,
      navigate,
      updateFilters,
      changeView,
      changeSort,
      refresh,
      recalculateCost,
      setCost,
      openSetCostModal,
      closeSetCostModal,
    },
  } = useShipmentListPage();

  const columns: Column<ShipmentRow>[] = [
    {
      title: 'Name',
      key: 'name',
    },
    {
      title: 'Status',
      key: 'status',
      render: (status: ShipmentStatus) => (
        <StatusLabel status={status} title={shipmentStatusTitle} type={shipmentStatusType} />
      ),
    },
    {
      title: 'Shipped',
      key: 'shippedAt',
      render: (shippedAt: string) => shippedAt && formatDate(shippedAt),
    },
    {
      title: 'Cost',
      key: 'cost',
      align: 'right',
      render: (cost, { currency }) =>
        cost !== undefined && formatMoney(cost, (currency ?? 'AUD') as OrderCurrency),
    },
    { title: 'Carrier Service Code', key: 'carrierServiceCode' },
    { title: 'Carrier Service Name', key: 'carrierServiceName' },
    {
      title: 'Account',
      key: 'accountId',
      render: (accountId: string) =>
        isLoadingAccounts ? <LoadingSkeleton /> : accountsById?.get(accountId)?.name,
    },
    {
      key: 'id',
      title: '',
      align: 'right',
      render: (id: string, shipment) => (
        <ContextMenu
          buttonLabel="More actions"
          onSelect={({ key }) => {
            if (key === 'recalculate_cost') void recalculateCost(id);
            if (key === 'set_cost') {
              void openSetCostModal(shipment);
            }
          }}
          items={[
            shipment.shippedAt
              ? {
                  key: 'recalculate_cost',
                  label: 'Recalculate Cost',
                }
              : null,
            {
              key: 'set_cost',
              label: 'Set Cost',
            },
          ].filter((a) => a)}
        />
      ),
    },
  ];

  return (
    <Container>
      <Header
        title="Shipments"
        links={[
          { url: '/shipping', title: 'Shipping' },
          { url: '/shipping/shipments', title: 'Shipments' },
        ]}
      />

      <ListContainer>
        <Card>
          <DataTable.ViewTabs views={views} current={view ?? ''} onChange={changeView} />
          <DataTable.SearchPanel
            loading={isLoading}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
            pagination={pagination}
            total={total}
            defaultSearchValue={query}
            filters={filters}
            filterSelections={filterValues}
            onChangeFilterSelections={updateFilters}
            sort={sort}
            sortOptions={sortOptions}
            onChangeSort={changeSort}
          />
          <DataTable
            rows={rows}
            columns={columns}
            hasError={hasError}
            loading={isLoading}
            sort={sort}
            sortOptions={sortOptions}
            onChangeSort={changeSort}
          />
        </Card>
      </ListContainer>

      <SetCostModal
        isOpen={!!selectedShipmentForCost}
        onClose={closeSetCostModal}
        setCost={setCost}
        shipment={selectedShipmentForCost}
      />
    </Container>
  );
}
