import styled from 'styled-components';
import { Skeleton } from 'antd';

export const StyledSkeleton = styled(Skeleton)<{ $inline?: boolean }>`
  && {
    ${({ $inline }) => $inline && 'width: unset;'};

    li {
      height: 1.1em;
      &:not(:first-child) {
        margin-top: 0.5em;
      }
    }

    .ant-skeleton-paragraph {
      margin: 0;
    }
  }
`;
