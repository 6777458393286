import { AsnDTO } from '@invenco/common-interface/supply';
import { Link as RouterLink } from 'react-router-dom';
import { formatDate, formatNumber } from 'shared/helpers';
import { Header } from 'components/header';
import { Button } from 'components/button';
import { DataTable, Column } from 'components/data-table';
import { useAsnListPage, views } from './useAsnListPage';
import { Container, ListContainer } from '../../../../../styles/layout';
import { Card } from '../../../../../components/card';
import { DataImporter } from '../../../../../components/data-importer';
import { TemplateKey } from '../../../../../components/data-importer/types';
import { asnStatusTitle } from '../../../../../shared/title-maps';
import { StatusLabel } from '../../../../../components/label';
import { asnStatusType } from '../../../../../shared/status-maps';

const columns: Column<AsnDTO>[] = [
  { title: 'Name', key: 'name' },
  {
    title: 'Reference',
    key: 'reference',
  },
  {
    title: 'Date Expected',
    key: 'expectedAt',
    render: (date) => (date ? formatDate(date) : ''),
  },
  { title: 'Location', key: 'locationName' },
  {
    title: 'Status',
    key: 'status',
    render: (status) => <StatusLabel status={status} type={asnStatusType} title={asnStatusTitle} />,
  },
  {
    title: 'Qty Expected',
    key: 'qtyExpected',
    align: 'right',
    render: (qty) => formatNumber(qty),
  },
  {
    title: 'Qty Received',
    key: 'qtyReceived',
    align: 'right',
    render: (qty) => formatNumber(qty),
  },
];

const closedColumns: Column<AsnDTO>[] = [
  ...columns,
  {
    title: 'Date Closed',
    key: 'closedAt',
    render: (date) => (date ? formatDate(date) : ''),
  },
];

export function AsnList() {
  const {
    models: {
      isLoading,
      hasClosedRows,
      hasError,
      rows,
      pagination,
      total,
      query,
      view,
      filters,
      filterSelections,
    },
    operations: {
      search,
      navigate,
      refresh,
      changeView,
      updateFilters,
      onClickRow,
      onImportComplete,
    },
  } = useAsnListPage();

  return (
    <Container>
      <Header
        links={[
          { url: '/supply', title: 'Supply' },
          { url: '/supply/asns', title: 'ASNs' },
        ]}
        title="ASNs"
        actionContent={
          <>
            <DataImporter
              template={TemplateKey.ASNS}
              title="Import ASNs"
              onComplete={onImportComplete}
            />
            <Button type="primary">
              <RouterLink to="/supply/asns/new">Create ASN</RouterLink>
            </Button>
          </>
        }
      />
      <ListContainer>
        <Card>
          <DataTable.ViewTabs views={views} current={view ?? ''} onChange={changeView} />

          <DataTable.SearchPanel
            loading={isLoading}
            searchPlaceholderText="Search..."
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
            filters={filters}
            filterSelections={filterSelections}
            onChangeFilterSelections={updateFilters}
          />

          <DataTable
            rows={rows}
            columns={hasClosedRows ? closedColumns : columns}
            loading={isLoading}
            hasError={hasError}
            onClickRow={onClickRow}
          />
        </Card>
      </ListContainer>
    </Container>
  );
}
