import { OrderDTO } from '@invenco/common-interface/sales';
import { useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import { ComponentData } from '../../../../../../shared/types';
import { Form } from '../../../../../../components/form';
import { Result } from '../../../../../../shared/helpers/Result';

type Models = {
  form: FormInstance;
  isSaving: boolean;
};

type Operations = {
  submit: (data: Record<string, string>) => Promise<void>;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  updateOrder: (data: Partial<OrderDTO>) => Promise<Result>;
};

export function useNotesModalComponent({
  isOpen,
  onClose,
  updateOrder,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [isOpen, form]);

  const submit = async ({ notes }: Record<string, string>) => {
    setIsSaving(true);
    const result = await updateOrder({ notes });
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  return {
    models: { form, isSaving },
    operations: { submit },
  };
}
