import {
  CarrierDTO,
  CarrierServiceCategory,
  CarrierServiceDTO,
  CarrierServiceRateMatchMethod,
  ShipmentClassification,
} from '@invenco/common-interface/shipping';
import { IncoTerms } from '@invenco/common-interface/sales';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { AddressType } from '@invenco/common-interface/shared';
import { ComponentData } from '../../../../shared/types';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { BreadCrumb } from '../../../../components/header';
import { Result } from '../../../../shared/helpers/Result';
import { useEntityDetailsQuery, useGatewayMutation } from '../../../../shared/hooks/queries';

const DEFAULT_DATA: Partial<CarrierServiceDTO> = {
  category: CarrierServiceCategory.STANDARD,
  rateMatchMethod: CarrierServiceRateMatchMethod.STATE_STATE,
  externalCodes: [],
  allowableAddressTypes: [AddressType.RESIDENTIAL],
  allowableShipmentClassifications: [ShipmentClassification.DOMESTIC],
  allowableIncoTerms: [IncoTerms.DDU],
};

type Models = {
  isNew: boolean;
  isLoading: boolean;
  isLoadingCarriers: boolean;
  isSaving: boolean;
  canSave: boolean;
  carrierService: Partial<CarrierServiceDTO>;
  carriers: CarrierDTO[];
  breadcrumbs: BreadCrumb[];
};

type Operations = {
  refresh: () => Promise<void>;
  save: () => Promise<Result>;
  updateCarrierService: (updateData: Partial<CarrierServiceDTO>) => void;
  addExternalCode: (description: string) => void;
  removeExternalCode: (index: number) => void;
};

export function useCarrierServiceDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams();
  const isNew = id === 'new';
  const { shippingGateway } = useGateways();
  const navigate = useNavigate();
  const [hasChanges, setHasChanges] = useState(false);
  const [carrierService, setCarrierService] = useState<Partial<CarrierServiceDTO>>({
    ...DEFAULT_DATA,
  });

  const canSave = Boolean(
    (isNew || hasChanges) &&
      carrierService.name &&
      carrierService.code &&
      carrierService.carrierId &&
      carrierService.allowableAddressTypes?.length &&
      carrierService.allowableShipmentClassifications?.length,
  );

  const { data, isLoading, refetch } = useEntityDetailsQuery({
    parentKey: 'carrierServices',
    id,
    isNew,
    query: async (fetchId, { signal }) => {
      // TODO: remove this hack once there is a GET carrier service API
      const services = await shippingGateway.getCarrierServices(undefined, { signal });
      const matchedService = services.items.find((c) => c.id === fetchId);
      if (!matchedService) throw new Error('Could not find carrier service');
      return matchedService;
    },
  });

  const {
    data: carrierData,
    isLoading: isLoadingCarriers,
    refetch: refetchCarriers,
  } = useQuery({
    queryKey: ['carriers'],
    queryFn: ({ signal }) => shippingGateway.getCarriers(undefined, { signal }),
  });
  const carriers = carrierData?.items;

  const existingName = data?.name;
  const carrierId = data?.carrierId;
  const carrierName = carriers?.find((c) => c.id === carrierId)?.name;
  const breadcrumbs = useMemo<BreadCrumb[]>(
    () =>
      !isNew
        ? [
            { url: '/shipping/carriers', title: 'Carriers' },
            {
              url: `/shipping/carriers/${carrierId}`,
              title: carrierName ?? '',
              loading: isLoading || isLoadingCarriers,
            },
            {
              url: `/shipping/carriers/${carrierId}`,
              title: 'Carrier Services',
              loading: isLoading,
            },
            {
              url: `/shipping/carriers/${carrierId}/services/${id}`,
              title: existingName,
              loading: isLoading,
            },
          ]
        : [
            { url: `/shipping/carrier_services`, title: 'Carrier Services' },
            { url: '/shipping/carrier_services/new', title: 'New Carrier Service' },
          ],
    [id, isNew, carrierId, carrierName, existingName, isLoading, isLoadingCarriers],
  );

  useEffect(() => {
    setCarrierService(data ?? { ...DEFAULT_DATA });
    setHasChanges(false);
  }, [data]);

  const refresh = async () => {
    await Promise.all([refetch(), refetchCarriers()]);
  };

  const updateCarrierService = (updateData: Partial<CarrierServiceDTO>) => {
    setCarrierService({ ...carrierService, ...updateData });
    setHasChanges(true);
  };

  const addExternalCode = (description: string) => {
    updateCarrierService({ externalCodes: [...(carrierService.externalCodes ?? []), description] });
  };

  const removeExternalCode = (index: number) => {
    const externalCodes = [...(carrierService.externalCodes ?? [])];
    externalCodes.splice(index, 1);
    updateCarrierService({ externalCodes });
  };

  const { mutate: save, isPending: isSaving } = useGatewayMutation({
    mutationFn: () => {
      const dto = {
        name: carrierService.name,
        code: carrierService.code,
        category: carrierService.category,
        externalCodes: carrierService.externalCodes,
        rateMatchMethod: carrierService.rateMatchMethod,
        allowableAddressTypes: carrierService.allowableAddressTypes,
        allowableIncoTerms: carrierService.allowableIncoTerms,
        shipmentTrackerGatewayCode: carrierService.shipmentTrackerGatewayCode,
        allowableShipmentClassifications: carrierService.allowableShipmentClassifications,
      };
      return isNew
        ? shippingGateway.createCarrierService(carrierService.carrierId!, dto)
        : shippingGateway.updateCarrierService(id!, dto);
    },
    onSuccess: (newCarrierService) => {
      setHasChanges(false);
      if (isNew) {
        navigate(`/shipping/carrier_services/${newCarrierService.id}`);
      }
    },
    linkedQuery: ['carrierServices', id],
    successMessage: `Carrier service ${isNew ? 'created' : 'saved'}`,
  });

  return {
    models: {
      isNew,
      isLoading,
      isLoadingCarriers,
      isSaving,
      canSave,
      carrierService,
      carriers: carriers ?? [],
      breadcrumbs,
    },
    operations: {
      refresh,
      updateCarrierService,
      addExternalCode,
      removeExternalCode,
      save,
    },
  };
}
