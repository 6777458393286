import styled from 'styled-components';
import { spaceVerticalChildren } from '../../../../../../styles/mixins';

export const SubTotalLines = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 12px 0px 0px;
  ${spaceVerticalChildren(0.5)}
`;

const lineValueStyle = `
  > *:last-child {
  text-align: right;
    margin-left: 16px;
    width: 150px;
  }
`;

export const SubTotalLine = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px;
  ${lineValueStyle}
`;

export const TotalLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-top: 12px;
  border-top: 1px solid var(--gray-4);
  font-weight: 700;
  text-transform: uppercase;
  ${lineValueStyle}
`;
