import { useState } from 'react';
import { ComponentData } from 'shared/types';

type Models = {
  performingAction?: boolean;
  isShowingModalConfirm?: boolean;
};

type Operations = {
  submitModalConfirm: () => void;
  closeModal: () => void;
  openModal: () => void;
};

interface Props {
  onDelete: () => any;
}

export function useActionButtonsComponent({ onDelete }: Props): ComponentData<Models, Operations> {
  const [isShowingModalConfirm, setIsShowingModalConfirm] = useState<boolean>(false);
  const [performingAction, setPerformingAction] = useState<boolean>(false);

  const submitModalConfirm = async () => {
    setPerformingAction(true);
    await onDelete();
    setPerformingAction(false);
    setIsShowingModalConfirm(false);
  };

  return {
    models: { isShowingModalConfirm, performingAction },
    operations: {
      submitModalConfirm,
      openModal: () => setIsShowingModalConfirm(true),
      closeModal: () => setIsShowingModalConfirm(false),
    },
  };
}
