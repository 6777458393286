import { Form } from 'components/form';
import { Input } from 'components/input';
import React from 'react';

import { childConditionTypeTitle } from 'shared/title-maps';
import { isPropertyCondition } from '../../helpers/conditions';
import { childConditionTypeKey } from '../../helpers/forms';
import { SectionDivider } from './styles';

// eslint-disable-next-line import/no-cycle
import { Condition, ConditionProps } from './Condition';

export function ConditionList({
  level,
  editable,
  condition,
  formKey,
  onAddCondition,
  onDeleteCondition,
  onResetPropertyCondition,
}: ConditionProps) {
  if (isPropertyCondition(condition) || !condition.childConditions?.length) {
    return null;
  }
  return (
    <>
      <Form.Item noStyle name={[...formKey, childConditionTypeKey]}>
        <Input
          // without this hidden input, the childConditionType is not submitted with the form
          // not an ideal solution, but doesn't appear to be a way around it
          type="hidden"
        />
      </Form.Item>
      {condition.childConditions.map((childCondition, index) => (
        <React.Fragment
          key={
            // eslint-disable-next-line react/no-array-index-key
            `condition-${index}`
          }
        >
          <Condition
            editable={editable}
            level={level + 1}
            condition={childCondition}
            formKey={[...formKey, 'childConditions', index]}
            onAddCondition={onAddCondition}
            onDeleteCondition={onDeleteCondition}
            onResetPropertyCondition={onResetPropertyCondition}
          />
          {condition.childConditionType && index < (condition.childConditions?.length ?? 0) - 1 && (
            <SectionDivider>{childConditionTypeTitle[condition.childConditionType]}</SectionDivider>
          )}
        </React.Fragment>
      ))}
    </>
  );
}
