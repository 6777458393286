import { Input } from 'antd';
import { Form } from 'components/form';

function hasBillingAddress(getFieldValue: Function): Boolean {
  return (
    getFieldValue(['billingAddress', 'companyName']) ||
    getFieldValue(['billingAddress', 'contactName']) ||
    getFieldValue(['billingAddress', 'contactEmail']) ||
    getFieldValue(['billingAddress', 'contactNumber']) ||
    getFieldValue(['billingAddress', 'address1']) ||
    getFieldValue(['billingAddress', 'address2']) ||
    getFieldValue(['billingAddress', 'city']) ||
    getFieldValue(['billingAddress', 'state']) ||
    getFieldValue(['billingAddress', 'postcode']) ||
    getFieldValue(['billingAddress', 'country'])
  );
}

const ALL_BILLING_ADDRESS_FIELDS = [
  ['billingAddress', 'companyName'],
  ['billingAddress', 'contactName'],
  ['billingAddress', 'contactEmail'],
  ['billingAddress', 'contactNumber'],
  ['billingAddress', 'address1'],
  ['billingAddress', 'address2'],
  ['billingAddress', 'city'],
  ['billingAddress', 'state'],
  ['billingAddress', 'postcode'],
  ['billingAddress', 'country'],
];

export function BillingAddressForm() {
  return (
    <div data-testid="BillingAddressForm">
      <Form.Item
        name={['billingAddress', 'companyName']}
        label="Company Name"
        aria-label="Billing Address Company Name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['billingAddress', 'contactName']}
        label="Contact Name"
        dependencies={ALL_BILLING_ADDRESS_FIELDS}
        rules={[
          ({ getFieldValue }) => ({
            validator: (_, value) => {
              if (hasBillingAddress(getFieldValue) && !value) {
                return Promise.reject(new Error('Please enter a contact name'));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Row>
        <Form.Item
          name={['billingAddress', 'contactEmail']}
          label="Email"
          rules={[{ type: 'email', message: 'Please enter a valid email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={['billingAddress', 'contactNumber']} label="Phone">
          <Input />
        </Form.Item>
      </Form.Row>
      <Form.Item
        name={['billingAddress', 'address1']}
        label="Address Line 1"
        dependencies={ALL_BILLING_ADDRESS_FIELDS}
        rules={[
          ({ getFieldValue }) => ({
            validator: (_, value) => {
              if (hasBillingAddress(getFieldValue) && !value) {
                return Promise.reject(new Error('Please enter an address'));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name={['billingAddress', 'address2']} label="Address Line 2">
        <Input />
      </Form.Item>
      <Form.Row>
        <Form.Item name={['billingAddress', 'city']} label="City">
          <Input />
        </Form.Item>
        <Form.Item name={['billingAddress', 'state']} label="State">
          <Input />
        </Form.Item>
      </Form.Row>
      <Form.Row>
        <Form.Item name={['billingAddress', 'postcode']} label="Postcode">
          <Input />
        </Form.Item>
        <Form.Item
          name={['billingAddress', 'country']}
          label="Country"
          dependencies={ALL_BILLING_ADDRESS_FIELDS}
          rules={[
            ({ getFieldValue }) => ({
              validator: (_, value) => {
                if (hasBillingAddress(getFieldValue) && !value) {
                  return Promise.reject(new Error('Please enter a country'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
      </Form.Row>
    </div>
  );
}
