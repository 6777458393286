import { AppstoreOutlined, GiftOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const STYLES_BY_COLOR = {
  default: `
    background: var(--gray-4);
    color: var(--gray-8);
  `,
  blue: `
    background: var(--blue-1);
    color: var(--blue-7);
  `,
  green: `
    background: var(--green-2);
    color: var(--green-8);
  `,
  orange: `
    background: var(--orange-2);
    color: var(--orange-9);
  `,
  red: `
    background: var(--red-1);
    color: var(--red-7);
  `,
  active: `
    background: var(--blue-5);
    color: var(--gray-1);
  `,
} as const;

export type LabelColor = keyof typeof STYLES_BY_COLOR;

export const StyledLabel = styled.span<{ $color: LabelColor; $normalCase?: boolean }>`
  min-height: 20px;
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px;
  border-radius: 4px;
  font-weight: 600;
  ${({ $color }) => STYLES_BY_COLOR[$color ?? 'default']}
  ${({ $normalCase }) => !$normalCase && 'text-transform: uppercase;'}
  transition: all 0.2s ease-in-out;
`;

export const BundleIcon = styled(AppstoreOutlined)`
  svg {
    height: 16px;
    width: 16px;
  }
`;

export const SelectIcon = styled(GiftOutlined)`
  svg {
    height: 16px;
    width: 16px;
  }
`;
