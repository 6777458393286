import React, { Fragment } from 'react';
import { BillLineDTO } from '@invenco/common-interface/accounts';
import { formatNumber } from 'shared/helpers';
import {
  TableHeader,
  CategoryCol,
  ChargeQtyCol,
  ChargeRateCol,
  ChargeTotalCol,
  LineItemCategory,
  LineItemRow,
  LineItemCategoryType,
  LineItemChargeQty,
  LineItemChargeRate,
  LineItemChargeTotal,
  LineItemTotal,
  LineTotal,
  LineTotalName,
  LineTotalValue,
} from './styles';
import { useBillingDetailsComponent } from './useBillingLinesComponents';

type Props = {
  billLines: BillLineDTO[];
};

export function BillLines({ billLines }: Props) {
  const {
    models: { lineItems },
  } = useBillingDetailsComponent({ billLines });

  let total = 0;
  return (
    <>
      <TableHeader>
        <CategoryCol>Category</CategoryCol>
        <ChargeQtyCol>ChargeQty</ChargeQtyCol>
        <ChargeRateCol>ChargeRate</ChargeRateCol>
        <ChargeTotalCol>ChargeTotal</ChargeTotalCol>
      </TableHeader>
      {lineItems &&
        Object.entries(lineItems).map(([category, lines]) => {
          const linesTotal = lines.reduce((sum, { chargeTotal }) => sum + +chargeTotal!, 0);
          total += linesTotal;
          return (
            <Fragment key={category}>
              <LineItemCategory>{category}</LineItemCategory>
              {React.Children.toArray(
                lines.map((line) => (
                  <LineItemRow data-testid={`line-item-${category}`}>
                    <LineItemCategoryType>{`${line.subCategory} > ${line.chargeType}`}</LineItemCategoryType>
                    <LineItemChargeQty>
                      {Number.isInteger(line.chargeQty)
                        ? line.chargeQty
                        : line.chargeQty?.toFixed(3)}
                    </LineItemChargeQty>
                    <LineItemChargeRate>{`$${formatNumber(
                      line.chargeRate!,
                      2,
                    )}`}</LineItemChargeRate>
                    <LineItemChargeTotal>{`$${formatNumber(
                      line.chargeTotal!,
                      2,
                    )}`}</LineItemChargeTotal>
                  </LineItemRow>
                )),
              )}

              <LineItemTotal data-testid={`line-item-total-${category}`}>{`$${formatNumber(
                linesTotal,
                2,
              )}`}</LineItemTotal>
            </Fragment>
          );
        })}
      <LineTotal>
        <LineTotalName>Bill Total</LineTotalName>
        <LineTotalValue>{`$${formatNumber(total, 2)}`}</LineTotalValue>
      </LineTotal>
    </>
  );
}
