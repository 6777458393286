import styled from 'styled-components';
import { Tabs } from 'antd';

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    display: block;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    color: var(--blue-6);
  }

  .ant-tabs-ink-bar {
    background: var(--blue-6);
  }

  & {
    margin-left: -32px;
    margin-right: -32px;
  }

  .ant-tabs-nav-wrap {
    margin-left: 32px;
    margin-right: 32px;
  }

  .ant-tabs-tab {
    padding-top: 8px;
  }
`;
