import { StyledListItem } from './styles';
import { LoadingSkeleton } from '../../../../../components/loading-skeleton';

export function LoadingBandItem() {
  return (
    <StyledListItem>
      <div>
        <div>
          <span>From</span>
          <LoadingSkeleton inline width={50} />
          <span>to</span>
          <LoadingSkeleton inline width={50} />
          <span>apply a markup of</span>
          <LoadingSkeleton inline width={30} />
        </div>
      </div>
    </StyledListItem>
  );
}
