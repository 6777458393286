import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccessContext } from '@invenco/common-interface/auth';
import { useAuthToken } from '../../shared/hooks/useAuthToken';
import { getEnv } from '../../env';
import { useCommandAI } from './hooks/useCommandAI';
import { useGlobalSearch } from '../../shared/hooks/global-search/useGlobalSearch';
import { useFeatureFlag } from './hooks/useFeatureFlag';

/**
 * Bootstraps Command AI in lynk
 * Note - Most of Command AI is configured via the Command AI Dashboard, which is only accessible
 * by invitation.
 * See https://www.command.ai/docs/copilot/getting-started/copilot-101/#the-command-ai-dashboard
 */
function CommandAI() {
  const env = getEnv();
  const { userId, isLoading: isAuthLoading, accessContext, accountId, token } = useAuthToken();
  const navigate = useNavigate();
  const { isBooted, boot, addRouter, addMultiSearch, addRecords, shutdown } = useCommandAI();
  const { globalSearch } = useGlobalSearch();

  useEffect(() => {
    addRouter(navigate);
  }, [navigate, addRouter]);

  useEffect(() => {
    const bootCommandAI = async () => {
      if (!isAuthLoading && userId && token && accessContext && !isBooted) {
        await boot(
          userId,
          {
            accessContext,
            environment: env,
          },
          {
            copilotAPIHeaders: {
              /**
               * Allow Command AI to make requests to Lynk APIs using the logged in user's token.
               * API requests are configured in the Command AI Dashboard
               */
              Authorization: `Bearer ${token}`,
            },
          },
        );
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    bootCommandAI();
  }, [
    accessContext,
    accountId,
    addRouter,
    boot,
    env,
    isAuthLoading,
    isBooted,
    navigate,
    shutdown,
    token,
    userId,
  ]);

  useEffect(() => {
    if (accessContext === AccessContext.SELLER) {
      addRecords('orders', [], {
        searchTabEnabled: true,
        recordOptions: {
          url: '{{record.url}}',
          categoryName: 'Orders',
        },
      });

      addRecords('productSKUs', [], {
        searchTabEnabled: true,
        recordOptions: {
          url: '{{record.url}}',
          categoryName: 'SKUs',
        },
      });

      addRecords('ASNs', [], {
        searchTabEnabled: true,
        recordOptions: {
          url: '{{record.url}}',
          categoryName: 'ASNs',
        },
      });

      addMultiSearch(globalSearch, ['orders', 'productSKUs', 'ASNs']);
    }
  }, [accessContext, addMultiSearch, addRecords, globalSearch]);

  return null;
}

export function CommandAiWithFeatureFlag() {
  const { enableCommandAI } = useFeatureFlag();
  return enableCommandAI ? <CommandAI /> : null;
}
