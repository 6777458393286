import { CarrierDTO, CarrierServiceDTO } from '@invenco/common-interface/shipping';
import { Link as RouterLink } from 'react-router-dom';
import { formatDateTime } from 'shared/helpers';
import { Button } from 'components/button';
import { DataTable, Column } from 'components/data-table';
import { Container, ListContainer } from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { Card } from '../../../../components/card';
import { useCarrierListPage } from './useCarrierListPage';

const columns: Column<CarrierDTO>[] = [
  { title: 'Name', key: 'name' },
  { title: 'Code', key: 'code' },
  {
    title: 'Services',
    key: 'carrierServices',
    render: (services?: CarrierServiceDTO[]) =>
      services
        ?.map(({ code }) => code)
        .sort()
        .join(', '),
  },
  {
    title: 'Created',
    key: 'createdAt',
    render: (createdAt: string) => formatDateTime(createdAt),
  },
];

export function CarrierList() {
  const {
    models: { isLoading, hasError, rows, pagination, total, query },
    operations: { search, navigate, refresh, onClickRow },
  } = useCarrierListPage();

  return (
    <Container>
      <Header
        links={[{ url: '/shipping/carriers', title: 'Carriers' }]}
        title="Carriers"
        actionContent={
          <Button type="primary">
            <RouterLink to="/shipping/carriers/new">Create carrier</RouterLink>
          </Button>
        }
      />
      <ListContainer>
        <Card>
          <DataTable.SearchPanel
            loading={isLoading}
            searchPlaceholderText="Search..."
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
          />

          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            hasError={hasError}
            onClickRow={onClickRow}
          />
        </Card>
      </ListContainer>
    </Container>
  );
}
