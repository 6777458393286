import LogoIcon from 'assets/lynk-icon.svg';
import LogoName from 'assets/lynk-name.svg';
import { DownOutlined, LeftOutlined, UpOutlined } from '@ant-design/icons';
import { SvgIcon } from 'components/svg-icon';
import {
  ArrowButton,
  ItemArrowWrapper,
  ItemIconWrapper,
  ItemLink,
  ItemListWrapper,
  ItemStatic,
  LogoIconWrapper,
  LogoNameWrapper,
  LogoWrapper,
  MenuContainer,
  NavContainer,
  NavHeader,
} from './styles';
import { useNavigationComponent, NavItemState } from './useNavigationComponent';

type ItemListProps = {
  items: NavItemState[];
  onSelectItem: (id: string) => void;
  isCollapsed: boolean;
  isSubMenu?: boolean;
  id?: string;
};

function ItemList({ items, onSelectItem, isCollapsed, isSubMenu = false, id }: ItemListProps) {
  return (
    <ItemListWrapper id={id}>
      {items.map((item) => {
        const buttonContent = (
          <>
            {!isSubMenu && <ItemIconWrapper aria-hidden="true">{item.icon}</ItemIconWrapper>}
            {!isCollapsed && (
              <>
                <span>{item.title}</span>
                {!isSubMenu && !!item.children?.length && (
                  <ItemArrowWrapper>
                    {item.isOpen ? <UpOutlined /> : <DownOutlined />}
                  </ItemArrowWrapper>
                )}
              </>
            )}
          </>
        );

        const isActive = item.isActive || (isCollapsed && item.hasActiveChild);
        const subMenuId = `${item.id}-submenu`;
        const hasSubMenu = !!item.children?.length && !isSubMenu;
        const showingSubMenu = !isCollapsed && item.isOpen && hasSubMenu;

        const commonProps = {
          $isActive: isActive,
          onClick: () => onSelectItem(item.id),
          tabIndex: 0,
          'aria-label': item.title,
          'aria-current': isActive,
          'aria-expanded': hasSubMenu ? showingSubMenu : undefined,
          'aria-controls': hasSubMenu ? subMenuId : undefined,
        };

        return (
          <li key={item.id}>
            {item.url ? (
              <ItemLink to={item.url} $isSubItem={isSubMenu} {...commonProps}>
                {buttonContent}
              </ItemLink>
            ) : (
              <ItemStatic role="button" {...commonProps}>
                {buttonContent}
              </ItemStatic>
            )}
            {showingSubMenu && item.children && (
              <ItemList
                items={item.children}
                onSelectItem={onSelectItem}
                isCollapsed={isCollapsed}
                isSubMenu
                id={subMenuId}
              />
            )}
          </li>
        );
      })}
    </ItemListWrapper>
  );
}

export function Navigation() {
  const {
    models: { primaryItems, secondaryItems, isCollapsed },
    operations: { selectItem, selectHeader, toggleCollapsed, onMouseEnter, onMouseLeave },
  } = useNavigationComponent();

  return (
    <NavContainer
      $isCollapsed={isCollapsed}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <NavHeader>
        <LogoWrapper
          onClick={selectHeader}
          title={isCollapsed ? 'Expand menu' : undefined}
          aria-label={isCollapsed ? 'Expand menu' : 'LYNK home'}
          role={isCollapsed ? 'button' : 'link'}
        >
          <LogoIconWrapper>
            <SvgIcon icon={LogoIcon} />
          </LogoIconWrapper>
          {!isCollapsed && (
            <LogoNameWrapper>
              <SvgIcon icon={LogoName} />
            </LogoNameWrapper>
          )}
        </LogoWrapper>

        {!isCollapsed && (
          <ArrowButton aria-label="Collapse menu" onClick={toggleCollapsed}>
            <LeftOutlined />
          </ArrowButton>
        )}
      </NavHeader>
      <MenuContainer>
        <ItemList items={primaryItems} onSelectItem={selectItem} isCollapsed={isCollapsed} />
        {secondaryItems.length > 0 && (
          <ItemList items={secondaryItems} onSelectItem={selectItem} isCollapsed={isCollapsed} />
        )}
      </MenuContainer>
    </NavContainer>
  );
}
