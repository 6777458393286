import { ReactNode } from 'react';
import { TableProps as AntTableProps, Tag } from 'antd';
import { LeftOutlined, LoadingOutlined, ReloadOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'components/button';
import { useListTableComponent } from './useListTableComponent';
import { formatNumber } from '../../shared/helpers';
import { SearchInput } from '../search-input';
import {
  Controls,
  ControlsDivider,
  FlexSpacer,
  Options,
  OptionsTitle,
  PaginationControls,
  RightControls,
  StyledTable,
  StyledTabs,
  TableContainer,
  TotalCount,
} from './styles';
import {
  BaseRow,
  RowAction,
  TableColumn,
  TableOption,
  TableSelection,
  TableTab,
  TableUpdateCallback,
} from './types';
import { Pagination } from '../../shared/types';

// TODO (some pending backend support):
//  - filter by specific fields
//  - sort by specific fields

export type Props<T extends BaseRow> = {
  columns: TableColumn<T>[];
  rows: T[];
  rowKey?: string;
  searchPlaceholder?: string;
  tabs?: TableTab[];
  optionsTitle?: string;
  options?: TableOption[];
  hasAllOption?: boolean;
  checkable?: boolean;
  simple?: boolean;
  showSearch?: boolean;
  showPagination?: boolean;
  showReload?: boolean;
  scroll?: boolean;
  loading?: boolean;
  hasError?: boolean;
  hidePlaceholderText?: boolean;
  total?: number;
  emptyText?: string;
  pagination?: Pagination;
  selected?: TableSelection;
  onUpdate?: TableUpdateCallback;
  onClickRow?: (record: T, e: MouseEvent) => void;
  onCheckRows?: (keys: string[], records: T[]) => void;
  rowURL?: keyof T | ((record: T) => string);
  expandedRowContent?: (record: T, index: number) => ReactNode;
  selectedRowKeys?: string[];
  rowActions?: RowAction<T>[];
};

export function ListTable<T extends BaseRow>({
  columns,
  rows,
  rowKey = 'id',
  searchPlaceholder = 'Search',
  tabs,
  optionsTitle,
  options,
  hasAllOption = true,
  checkable = false,
  simple = false,
  showSearch = !simple,
  showPagination = !simple,
  showReload = !simple,
  scroll = false,
  loading = false,
  hasError = false,
  hidePlaceholderText = false,
  total,
  emptyText,
  pagination,
  selected,
  onUpdate,
  onClickRow,
  onCheckRows,
  rowURL,
  expandedRowContent,
  selectedRowKeys,
  rowActions,
}: Props<T>) {
  const {
    models: {
      defaultQuery,
      selectedTab,
      selectedOptions,
      selectedKeys,
      fullOptions,
      processedColumns,
    },
    operations: {
      selectTab,
      selectPage,
      search,
      addOption,
      removeOption,
      refresh,
      onRow,
      onCheckedChange,
    },
  } = useListTableComponent({
    rows,
    rowKey,
    columns,
    tabs,
    selected,
    options,
    selectedRowKeys,
    hasAllOption,
    onUpdate,
    onClickRow,
    onCheckRows,
    rowURL,
    rowActions,
  });

  const getEmptyMessage = () => {
    if (loading) return 'Loading...';
    return hasError ? 'Error fetching results' : emptyText || 'No results found';
  };
  // will still hide total in a simple table if it is the only control
  const hasRightControls = showPagination || (!simple && total !== undefined) || showReload;
  const showOptions = options?.length && fullOptions;

  return (
    <TableContainer $scroll={scroll}>
      {tabs?.length && (
        <StyledTabs
          onTabClick={selectTab}
          activeKey={selectedTab}
          items={tabs.map(({ key, title }) => ({ key, label: title, disabled: loading }))}
        />
      )}

      {(showSearch || hasRightControls) && (
        <Controls>
          {showSearch ? (
            <SearchInput
              onChange={(e) => search(e.target.value)}
              prefixIcon
              placeholder={searchPlaceholder}
              defaultValue={defaultQuery}
            />
          ) : (
            <FlexSpacer />
          )}
          {showSearch && hasRightControls && <ControlsDivider type="vertical" />}
          {hasRightControls && (
            <RightControls>
              {showPagination && (
                <PaginationControls>
                  <Button
                    icon={<LeftOutlined />}
                    disabled={!pagination?.prev || loading}
                    onClick={() => pagination?.prev && selectPage(pagination.prev)}
                    aria-label="Previous page"
                  />
                  <Button
                    icon={<RightOutlined />}
                    disabled={!pagination?.next || loading}
                    onClick={() => pagination?.next && selectPage(pagination.next)}
                    aria-label="Next page"
                  />
                </PaginationControls>
              )}
              {total !== undefined && (
                <TotalCount>
                  <span>Total</span>
                  <span>{formatNumber(total, 0)}</span>
                </TotalCount>
              )}
              {showReload && (
                <Button
                  icon={<ReloadOutlined />}
                  disabled={loading}
                  onClick={refresh}
                  aria-label="Reload table"
                />
              )}
            </RightControls>
          )}
        </Controls>
      )}

      {showOptions && (
        <Options>
          {optionsTitle && <OptionsTitle>{optionsTitle}</OptionsTitle>}
          {fullOptions.map(({ key, title }) => (
            <Tag.CheckableTag
              key={key}
              checked={selectedOptions?.has(key) ?? false}
              onChange={(checked) => (checked ? addOption(key) : removeOption(key))}
              // @ts-ignore - this does get passed through and is needed for a correct role
              role="checkbox"
              aria-checked={selectedOptions?.has(key)}
            >
              {title}
            </Tag.CheckableTag>
          ))}
        </Options>
      )}

      <StyledTable
        columns={processedColumns}
        dataSource={rows}
        rowKey={rowKey}
        onRow={onRow as AntTableProps<object>['onRow']}
        loading={
          loading && {
            indicator: <LoadingOutlined spin />,
            size: 'large',
          }
        }
        size="middle"
        pagination={false}
        locale={{ emptyText: getEmptyMessage() }}
        $hidePlaceholder={hidePlaceholderText}
        $simple={simple}
        $scroll={scroll}
        $hasSelectableRows={!!(onClickRow || rowURL)}
        $noHeadingBorder={!showSearch && !hasRightControls && !showOptions && !tabs?.length}
        rowClassName={(record) =>
          expandedRowContent && selectedRowKeys?.includes(record[rowKey]) ? 'selected' : ''
        }
        rowSelection={
          checkable
            ? {
                selectedRowKeys: selectedKeys,
                onChange: onCheckedChange as any,
              }
            : undefined
        }
        expandable={
          expandedRowContent
            ? {
                showExpandColumn: false,
                expandedRowKeys: selectedKeys,
                expandedRowRender: (record, index) => expandedRowContent(record as T, index),
              }
            : undefined
        }
      />
    </TableContainer>
  );
}
