import { Label, StatusLabel } from 'components/label';
import { useMemo, useState } from 'react';
import { asnStatusType } from 'shared/status-maps';
import { asnStatusTitle } from 'shared/title-maps';
import { HorizontallySpaced } from 'styles/layout';
import { Button } from 'components/button/Button';
import { assertNever, formatDate, formatNumber } from '../../shared/helpers';
import { TableColumn } from '../list-table/types';
import { MemoryTable } from '../memory-table/MemoryTable';
import { CreatedRecord, ErrorRecord, TemplateKey } from './types';

type Props = {
  template: TemplateKey;
  created: CreatedRecord[];
  errors: ErrorRecord[];
};

const getViewColumn = (urlPrefix: string): TableColumn<any> => ({
  key: 'id',
  title: '',
  align: 'right',
  render: (id) => (
    <Button type="link" href={`${urlPrefix}/${id}`} target="_blank">
      View
    </Button>
  ),
});

const getCreatedColumns = (template: TemplateKey): TableColumn<CreatedRecord>[] => {
  switch (template) {
    case TemplateKey.SKUS:
      return [
        { key: 'description', title: 'SKU', prefix: ({ name }) => <Label>{name}</Label> },
        { key: 'barcode', title: 'Barcode' },
        {
          key: 'dimensions',
          dataIndex: 'measurement',
          title: 'Dimensions (LxWxH)',
          align: 'right',
          render: (measurement) =>
            measurement ? `${measurement.length}x${measurement.width}x${measurement.height}cm` : '',
        },
        {
          key: 'weight',
          dataIndex: 'measurement',
          title: 'Weight',
          align: 'right',
          render: (measurement) => (measurement ? `${measurement.weight}kg` : ''),
        },
        getViewColumn('/products/skus'),
      ];
    case TemplateKey.ASNS:
      return [
        { key: 'name', title: 'Name' },
        { key: 'reference', title: 'Reference' },
        {
          title: 'Date Expected',
          key: 'expectedAt',
          render: (date) => formatDate(date),
        },
        {
          title: 'Status',
          key: 'status',
          render: (status) => (
            <StatusLabel status={status} type={asnStatusType} title={asnStatusTitle} />
          ),
        },
        {
          title: 'Qty Expected',
          key: 'qtyExpected',
          align: 'right',
          render: (qty) => formatNumber(qty),
        },
        getViewColumn('/supply/asns'),
      ];
    case TemplateKey.ORDERS:
      return [{ key: 'name', title: 'Name' }, getViewColumn('/sales/orders')];
    default:
      return assertNever(template);
  }
};

const getErrorColumns = (template: TemplateKey): TableColumn<ErrorRecord>[] => {
  switch (template) {
    case TemplateKey.SKUS:
      return [
        {
          key: 'sku',
          dataIndex: 'input',
          title: 'SKU',
          prefix: ({ input: { name } }) => <Label>{name}</Label>,
          render: ({ description }) => description,
        },
        {
          key: 'barcode',
          dataIndex: 'input',
          title: 'Barcode',
          render: ({ barcode }) => barcode,
        },
      ];
    case TemplateKey.ASNS:
      return [
        {
          key: 'input',
          title: 'Reference',
          render: ({ reference }) => reference,
        },
      ];
    case TemplateKey.ORDERS:
      return [
        {
          key: 'input',
          title: 'Order Name',
          render: ({ name }) => name,
        },
        {
          key: 'input',
          title: 'Currency',
          render: ({ currency }) => currency,
        },
      ];
    default:
      return assertNever(template);
  }
};

const getErrorSearchValues = (template: TemplateKey) => (record: ErrorRecord) => {
  switch (template) {
    case TemplateKey.SKUS:
      return [record.input.name, record.input.description, record.input.barcode, record.error];
    case TemplateKey.ASNS:
      return [record.input.reference, record.error];
    case TemplateKey.ORDERS:
      return [record.input.orderName, record.error];
    default:
      return assertNever(template);
  }
};

export function ResultsTable({ template, created, errors }: Props) {
  const [tab, setTab] = useState<'imported' | 'errors'>('imported');
  const columns = useMemo(
    () =>
      tab === 'imported'
        ? getCreatedColumns(template)
        : [...getErrorColumns(template), { key: 'error', title: 'Message' }],
    [template, tab],
  );
  return (
    <MemoryTable<any> // unfortunately can't type this much better (CreatedRecord | ErrorRecord doesn't work)
      columns={columns}
      rows={tab === 'imported' ? created : errors}
      searchValues={tab === 'errors' ? getErrorSearchValues(template) : undefined}
      onUpdate={(selection) =>
        // TODO: need to reset page somehow but would like to keep page managed by MemoryTable
        selection.tab && selection.tab !== tab && setTab(selection.tab as any)
      }
      tabs={
        errors.length > 0
          ? [
              {
                key: 'imported',
                title: (
                  <HorizontallySpaced $factor={0.5}>
                    <span>Imported</span>
                    <Label>{created.length}</Label>
                  </HorizontallySpaced>
                ),
              },
              {
                key: 'errors',
                title: (
                  <HorizontallySpaced $factor={0.5}>
                    <span>Errors</span>
                    <Label>{errors.length}</Label>
                  </HorizontallySpaced>
                ),
              },
            ]
          : undefined
      }
      scroll
    />
  );
}
