import styled from 'styled-components';
import { autoScroll, spaceHorizontalChildren, spaceVerticalChildren } from '../../styles/mixins';

export const CardContainer = styled.section<{
  $evenMargin?: boolean;
  $slim?: boolean;
  $scroll?: boolean;
  $fullHeight?: boolean;
}>`
  width: 100%;
  padding: ${({ $evenMargin, $slim }) => {
    if ($slim) return '8px 32px';
    return $evenMargin ? '16px 32px' : '16px 32px 32px';
  }};
  background: var(--gray-1);
  box-sizing: border-box;
  border: 1px solid var(--gray-4);
  border-radius: 16px;

  min-height: 0;
  ${({ $scroll }) => $scroll && autoScroll('var(--gray-1)')}
  ${({ $fullHeight }) => $fullHeight && 'flex: 1;'}
  overflow-wrap: break-word;

  display: grid;
  column-gap: 8px;

  // layout sections
  > .card-left-gutter {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  > .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
  }

  > .card-body {
    gap: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }

  // conditional grid layout

  &:has(> .card-header) {
    > .card-header,
    > .card-left-gutter {
      grid-row: 1;
    }
    > .card-body {
      grid-row: 2;
    }
  }

  &:has(> .card-left-gutter) {
    grid-template-columns: auto 1fr;
    padding-left: 16px;
    > .card-left-gutter {
      grid-column: 1;
    }
    > .card-header,
    > .card-body {
      grid-column: 2;
    }
  }
`;

export const CardCollapseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;

  &:hover,
  &:active {
    color: var(--gray-8);
  }
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
  }

  ${spaceHorizontalChildren(0.5)}
`;

export const CardCount = styled.div`
  background: var(--gray-4);
  color: var(--gray-8);
  font-size: 12px;
  font-weight: 600;
  border-radius: 11px;
  padding: 1px 8px;
`;

export const CardRight = styled.div`
  display: flex;
  align-items: center;
  ${spaceHorizontalChildren(0.5)}
`;

export const CardDescription = styled.div`
  font-size: 12px;
  color: var(--gray-8);
`;

export const CardContent = styled.div<{ $spaceChildren?: boolean }>`
  ${({ $spaceChildren }) => $spaceChildren && spaceVerticalChildren()}
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`;
