import { ReactNode } from 'react';
import { ArrowLeftOutlined, CheckOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button } from 'components/button';
import {
  ActionWrapper,
  BackLink,
  BottomRowContainer,
  Description,
  FetchingIndicator,
  HeaderContainer,
  HeadingWrapper,
  RefreshButton,
  TitleLineWrapper,
  TitleWrapper,
  TopRowContainer,
} from './styles';
import { LoadingSkeleton } from '../loading-skeleton';
import { BreadCrumbs, BreadCrumb } from './bread-crumbs/BreadCrumbs';
import { ProfileMenu } from './profile-menu/ProfileMenu';
import { useHeaderComponent } from './useHeaderComponent';
import { TitleForm } from './title-form/TitleForm';

type Props = {
  /** Show whether the Header is loading */
  loading?: boolean;

  /** Show whether the description is loading */
  loadDescription?: boolean;

  /** The breadcrumbs of the page */
  links?: BreadCrumb[];

  /** Title of the current page */
  title?: string;

  /** Title of the HTML document */
  documentTitle?: string;

  /** Description of the page */
  description?: ReactNode;

  /** URL to go back */
  backURL?: string;

  /** Actions that can be performed on the page */
  actionContent?: ReactNode;

  /** Extra content */
  extraContent?: ReactNode;
  onRefresh?: () => Promise<void>;

  editable?: boolean;
  editInitially?: boolean;
  editPlaceholder?: string;
  editAriaLabel?: string;
  onSave?: (title: string) => void;
};

export function Header({
  loading = false,
  loadDescription = false,
  links,
  title,
  documentTitle,
  description,
  backURL,
  actionContent,
  extraContent,
  onRefresh,
  editable = false,
  editPlaceholder,
  editInitially = false,
  editAriaLabel,
  onSave,
}: Props) {
  const {
    models: { isEditing, isFetching, refreshingState },
    operations: { openEditForm, cancelEditing, save, refresh },
  } = useHeaderComponent({
    isEditable: editable,
    editInitially,
    documentTitle: documentTitle || title,
    onSave,
    onRefresh,
  });

  // any of these elements require a title/first or description/second line
  const hasTitleLine = loading || editable || title || backURL || actionContent || onRefresh;
  const hasDescriptionLine = description || (loadDescription && loading);

  const renderTitle = () => {
    if (loading) return <LoadingSkeleton width="300px" />;
    return !loading && isEditing ? (
      <TitleForm
        title={title}
        onCancel={cancelEditing}
        onSave={save}
        placeholder={editPlaceholder}
        ariaLabel={editAriaLabel}
      />
    ) : (
      <>
        <h1>{title}</h1>
        {editable && (
          <Button
            onClick={openEditForm}
            icon={<EditOutlined />}
            type="text"
            aria-label="Edit title"
          />
        )}
        {extraContent}
      </>
    );
  };

  return (
    <HeaderContainer>
      <TopRowContainer>
        <div>
          <BreadCrumbs links={links ?? []} />
          {isFetching && <FetchingIndicator />}
        </div>
        <ProfileMenu />
      </TopRowContainer>

      {(hasTitleLine || hasDescriptionLine) && (
        <BottomRowContainer>
          {hasTitleLine && (
            <TitleLineWrapper>
              {backURL && (
                <BackLink
                  to={`${backURL}${
                    new URLSearchParams(window.location.search).get('parentSearch') || ''
                  }`}
                  aria-label="Back"
                >
                  <ArrowLeftOutlined />
                </BackLink>
              )}
              <HeadingWrapper>
                <TitleWrapper>{renderTitle()}</TitleWrapper>
                {(actionContent || onRefresh) && (
                  <ActionWrapper>
                    {onRefresh && (
                      <RefreshButton
                        type="text"
                        icon={
                          refreshingState === 'refreshed' ? (
                            <CheckOutlined />
                          ) : (
                            <ReloadOutlined spin={refreshingState === 'refreshing'} />
                          )
                        }
                        disabled={refreshingState !== 'idle' || loading}
                        aria-label="Refresh data"
                        onClick={() => void refresh()}
                      />
                    )}
                    {actionContent}
                  </ActionWrapper>
                )}
              </HeadingWrapper>
            </TitleLineWrapper>
          )}

          {hasDescriptionLine && (
            <Description $hasBackButton={!!backURL}>
              {loading ? <LoadingSkeleton width="300px" /> : description}
            </Description>
          )}
        </BottomRowContainer>
      )}
    </HeaderContainer>
  );
}
