export const DEPLOYMENT_STAGE =
  ({ dev: 'DEV', stage: 'STAGE', prod: 'PROD' } as const)[
    import.meta.env.VITE_DEPLOYMENT_STAGE?.toLowerCase()!
  ] ?? 'LOCAL';

export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
export const ONESCHEMA_CLIENT_ID = import.meta.env.VITE_ONESCHEMA_CLIENT_ID;
export const COMMAND_AI_ORG_ID = import.meta.env.VITE_COMMAND_AI_ORG_ID;
export const DATADOG_APP_ID = import.meta.env.VITE_DATADOG_APP_ID;
export const DATADOG_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;
export const COMMIT_SHA1 = import.meta.env.VITE_COMMIT_SHA1;

// Easier to mock in tests
// TODO, replace usage of DEPLOYMENT_STAGE with getEnv() in all components
export const getEnv = () => DEPLOYMENT_STAGE;
