import { CarrierDTO } from '@invenco/common-interface/shipping';
import { useEffect } from 'react';
import { Input } from 'antd';
import { Form } from '../../../../components/form';
import { FormModal } from '../../../../components/form-modal';
import { Result } from '../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  isSaving?: boolean;
  onClose: () => void;
  carrier: Partial<CarrierDTO>;
  updateCarrier: (data: Partial<CarrierDTO>) => Promise<Result>;
};

export function CarrierDetailsModal({ isOpen, isSaving, onClose, carrier, updateCarrier }: Props) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, carrier]);

  const onFinish = async (formData) => {
    const result = await updateCarrier(formData);
    if (result.isSuccess) onClose();
  };

  return (
    <FormModal
      onSave={() => form.submit()}
      isSaving={isSaving}
      open={isOpen}
      onCancel={onClose}
      title={`${carrier.name ? 'Edit' : 'Add'} Carrier`}
    >
      <Form
        form={form}
        layout="vertical"
        name="carrier"
        onFinish={(data) => void onFinish(data)}
        initialValues={carrier}
      >
        <Form.Item
          name="name"
          label="Carrier Name"
          rules={[{ required: true, message: 'Please enter carrier name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="code"
          label="Carrier Code"
          rules={[{ required: true, message: 'Please enter carrier code' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="baseTrackingUrl"
          label="Tracking URL Template"
          rules={[
            {
              pattern: /<tracking_number>/,
              message:
                'Must contain <tracking_number> in the URL, which will be substituted for the actual tracking number',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
