import { useMemoryTableComponent } from './useMemoryTableComponent';
import { BaseRow } from '../list-table/types';
import { ListTable, ListTableProps } from '../list-table';

type Props<T extends BaseRow> = ListTableProps<T> & {
  pageSize?: number;
  searchColumns?: string[];
  searchValues?: (record: T) => string[];
};

export function MemoryTable<T extends BaseRow>({
  rows,
  columns,
  pageSize,
  searchColumns,
  searchValues,
  onUpdate: customOnUpdate,
  ...rest
}: Props<T>) {
  const {
    models: { pageRows, total, pagination },
    operations: { update },
  } = useMemoryTableComponent<T>({
    rows,
    columns,
    pageSize,
    searchColumns,
    searchValues,
    customOnUpdate,
  });

  return (
    <ListTable
      columns={columns}
      rows={pageRows}
      total={total}
      pagination={pagination}
      onUpdate={update}
      showReload={false}
      {...rest}
    />
  );
}
