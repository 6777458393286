import { useCallback } from 'react';
import { QueryKey, useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useMessages } from '../../providers/MessagesProvider';
import { extractErrorMessage } from '../../helpers';
import { Result } from '../../helpers/Result';

// helper types for exporting mutations as operations
export type GatewayMutation<TData, TVar = void> = (variables: TVar) => Promise<Result<TData>>;

type Props<TData, TVar, TCon = unknown> = {
  /**
   * This links the mutation to a query, causing the query data to be updated/invalidated on success.
   * When the response body is linked (by default), the last item of the key is assumed to be the id.
   * This will be overridden using the id from the response body, if available.
   */
  linkedQuery?: QueryKey;

  /**
   * If true (default), the response data will be used to update the linked query, otherwise it will be invalidated.
   * This should be false for mutations that don't return the same data as the linked query
   * (e.g. this is linked to an order, but updating an order line)
   */
  linkResponseToQuery?: boolean;

  successMessage?: string;
  errorMessage?: string;
} & Partial<UseMutationOptions<TData, Error, TVar, TCon>>;

// = void used here to prevent "unknown" type issues when there are no variables
export function useGatewayMutation<TData, TVar = void, TCon = unknown>({
  linkedQuery,
  linkResponseToQuery = true,
  successMessage,
  errorMessage,
  onMutate,
  onSuccess,
  ...rest
}: Props<TData, TVar, TCon>) {
  const queryClient = useQueryClient();
  const messages = useMessages();

  const { mutateAsync, ...mutationData } = useMutation({
    ...rest,
    onMutate: async (variables) => {
      if (linkedQuery) {
        // if this is connected to a query, cancel any current fetches while this mutation is going through
        await queryClient.cancelQueries({ queryKey: linkedQuery });
      }
      return onMutate?.(variables);
    },
    onSuccess: (data, variables, context) => {
      // if this is connected to a query, either invalidate the current data or update it from the result data
      if (linkedQuery) {
        if (linkResponseToQuery) {
          // if we can get an id out of the response body, use that to update the query data
          // this allows creation queries (which don't know the resultant id) to automatically opt into this behaviour
          const responseId = (data as any)?.id;
          // e.g. linkedQuery = ['orders', undefined], responseId = '4', key = ['orders', '4']
          const key =
            responseId !== undefined ? [...linkedQuery.slice(0, -1), responseId] : linkedQuery;
          queryClient.setQueryData(key, data);
        } else {
          // otherwise just invalidate the linked query, causing a refetch
          void queryClient.invalidateQueries({ queryKey: linkedQuery });
        }
      }
      onSuccess?.(data, variables, context);
    },
    retry: false, // to prevent double error
  });

  const mutate = useCallback<GatewayMutation<TData, TVar>>(
    async (variables: TVar) => {
      try {
        const data = await mutateAsync(variables);
        if (successMessage) {
          messages.success(successMessage);
        }
        return Result.ok(data);
      } catch (e) {
        messages.error(errorMessage || extractErrorMessage(e) || 'An unknown error occurred');
        return Result.fail(extractErrorMessage(e));
      }
    },
    [successMessage, errorMessage, messages, mutateAsync],
  );

  return {
    ...mutationData,
    mutate,
  };
}
