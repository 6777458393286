import { useNavigate } from 'react-router';
import { Row } from './types';
import { DataTableProps } from './DataTable';

export function useRowNavigation<T extends Row = Row>({
  baseUrl,
}: {
  baseUrl: string;
}): Required<Pick<DataTableProps<T>, 'onClickRow'>> {
  const navigate = useNavigate();

  const onClickRow = (row: T, event: React.MouseEvent<HTMLTableRowElement>) => {
    const url = `${baseUrl}/${row.id}`;
    const urlWithSearchParams = window.location.search
      ? `${url}?parentSearch=${encodeURIComponent(window.location.search)}`
      : url;
    if (event.ctrlKey || event.metaKey) {
      window.open(urlWithSearchParams, '_blank');
    } else {
      navigate(urlWithSearchParams);
    }
  };

  return { onClickRow };
}
