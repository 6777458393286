import { SkuDTO } from '@invenco/common-interface/products';

import { Form } from 'components/form';
import { FormModal } from 'components/form-modal';
import { Input } from 'components/input';
import { Result } from 'shared/helpers/Result';
import { useDetailsModalComponent } from './useDetailsModalComponent';

type Props = {
  isOpen: boolean;
  sku: Partial<SkuDTO>;
  updateSku: (data: Partial<SkuDTO>) => Promise<Result>;
  onClose: () => void;
};

export function DetailsModal({ isOpen, sku, updateSku, onClose }: Props) {
  const {
    models: { form, isSaving, initialValues },
    operations: { submit },
  } = useDetailsModalComponent({ isOpen, onClose, sku, updateSku });
  return (
    <FormModal
      title="SKU Details"
      onSave={() => form.submit()}
      isSaving={isSaving}
      open={isOpen}
      onCancel={onClose}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinish={(data) => void submit(data)}
      >
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter a description' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="barcode"
          label="Barcode"
          rules={[{ required: true, message: 'Please enter a barcode' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="hsCode"
          label="HS Code"
          rules={[
            {
              pattern: /^\d{4}\.?\d{2}(?:\.?\d{2}(?:\d{2,4})?)?$/,
              message: 'Please enter valid hs code',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="countryOfOrigin"
          label="Country of Origin"
          rules={[
            {
              pattern: /\b[a-zA-Z]{2}\b/i,
              message: 'Please enter valid country of origin, only letters accepted',
            },
          ]}
        >
          <Input style={{ textTransform: 'uppercase' }} maxLength={2} />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
