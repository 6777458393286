import { ShippingPriceBandDTO } from '@invenco/common-interface/shipping';
import { useEffect, useMemo } from 'react';
import Decimal from 'decimal.js';
import { FormInstance } from 'antd';
import { Form } from 'components/form';
import { ComponentData } from '../../../../../shared/types';

type Props = {
  priceBand: ShippingPriceBandDTO;
  editing: boolean;
  onSave: (band: ShippingPriceBandDTO) => void;
};

type Models = {
  form: FormInstance;
  initialValues: Record<string, any>;
};

type Operations = {
  submit: (data: Record<string, any>) => void;
};

export function useBandItem({
  priceBand,
  editing,
  onSave,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();

  const initialValues = useMemo(
    () => ({
      maxCost: priceBand.maxCost,
      // necessary to prevent floating point errors
      markup: new Decimal(priceBand.markup).mul(100).toString(),
    }),
    [priceBand],
  );

  useEffect(() => {
    form.resetFields();
    if (editing) {
      const listener = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          form.submit();
        }
      };
      document.addEventListener('keydown', listener);
      return () => document.removeEventListener('keydown', listener);
    }
    return () => {};
  }, [editing, form]);

  const submit = (data: Record<string, any>) => {
    if (editing) {
      onSave({
        maxCost: data.maxCost ? parseFloat(data.maxCost) : undefined,
        markup: new Decimal(data.markup.replace('%', '')).div(100).toNumber(),
      });
    }
  };

  return {
    models: { form, initialValues },
    operations: { submit },
  };
}
