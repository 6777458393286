import { Input } from 'antd';
import { Form } from 'components/form';

export function ShippingAddressForm() {
  return (
    <div data-testid="ShippingAddressForm">
      <Form.Item name={['shippingAddress', 'companyName']} label="Company Name">
        <Input />
      </Form.Item>
      <Form.Item
        name={['shippingAddress', 'contactName']}
        label="Contact Name"
        rules={[{ required: true, message: 'Please enter a contact name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Row>
        <Form.Item
          name={['shippingAddress', 'contactEmail']}
          label="Email"
          rules={[{ type: 'email', message: 'Please enter a valid email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={['shippingAddress', 'contactNumber']} label="Phone">
          <Input />
        </Form.Item>
      </Form.Row>
      <Form.Item
        name={['shippingAddress', 'address1']}
        label="Address Line 1"
        rules={[{ required: true, message: 'Please enter an address' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name={['shippingAddress', 'address2']} label="Address Line 2">
        <Input />
      </Form.Item>
      <Form.Row>
        <Form.Item name={['shippingAddress', 'city']} label="City">
          <Input />
        </Form.Item>
        <Form.Item name={['shippingAddress', 'state']} label="State">
          <Input />
        </Form.Item>
      </Form.Row>
      <Form.Row>
        <Form.Item name={['shippingAddress', 'postcode']} label="Postcode">
          <Input />
        </Form.Item>
        <Form.Item
          name={['shippingAddress', 'country']}
          label="Country"
          rules={[{ required: true, message: 'Please enter a country' }]}
        >
          <Input />
        </Form.Item>
      </Form.Row>
    </div>
  );
}
