import { FormInstance, InputRef } from 'antd';
import { RefObject, useEffect, useRef } from 'react';
import { ComponentData } from '../../../shared/types';
import { Form } from '../../form';

type Models = {
  canCancel: boolean;
  form: FormInstance;
  inputRef: RefObject<InputRef>;
};

type Props = {
  title?: string;
  onCancel: () => void;
};

export function useTitleFormComponent({ title, onCancel }: Props): ComponentData<Models> {
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);
  const canCancel = !!title;

  useEffect(() => {
    inputRef.current?.select();
  }, []);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && canCancel) {
        onCancel();
      }
    };
    document.addEventListener('keydown', listener, false);
    return () => document.removeEventListener('keydown', listener, false);
  }, [onCancel, canCancel]);

  return {
    models: { canCancel, form, inputRef },
  };
}
