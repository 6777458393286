import styled from 'styled-components';
import { Button } from '../../button';

export const Container = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const FilterTags = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const ClearButton = styled(Button)`
  color: var(--gray-7);
  height: auto;
`;

export const AddFilterButton = styled(Button)`
  height: auto;
`;
