import { useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { AsnLineDTO } from '@invenco/common-interface/supply';
import { EditableTable } from 'components/editable-table';
import { EditableColumn } from 'components/editable-table/types';
import { Label } from 'components/label';
import { ListTable, ListTableProps } from 'components/list-table';
import { SkuSearch } from 'components/sku-search';
import { formatNumber } from 'shared/helpers';
import { Result } from 'shared/helpers/Result';
import { VerticallySpaced } from 'styles/layout';
import { Button } from 'components/button';
import { SkuSearchContainer } from './styles';
import { useAsnLinesComponent } from './useAsnLinesComponent';

type Props = {
  loading?: boolean;
  editable?: boolean;
  canDeleteLines?: boolean;
  asnLines: Partial<AsnLineDTO>[];
  addAsnLine: (line: Partial<AsnLineDTO>) => Promise<Result>;
  updateAsnLine: (lineId: string, line: Partial<AsnLineDTO>) => Promise<Result>;
  deleteAsnLine: (lineId: string) => void;
  showQtyReceived: boolean;
  recostLines: (asnLine: Partial<AsnLineDTO>) => Promise<Result>;
  canRecost: boolean;
};

const costColumns: EditableColumn<Partial<AsnLineDTO>>[] = [
  {
    title: 'Unit Cost',
    key: 'unitPurchaseCost',
    width: '150px',
    render: (unitPurchaseCost?: number) =>
      !unitPurchaseCost && unitPurchaseCost !== 0 ? '' : formatNumber(unitPurchaseCost, 6),
    inputValue: (line) =>
      !line.unitPurchaseCost && line.unitPurchaseCost !== 0
        ? ''
        : formatNumber(line.unitPurchaseCost, 6).replace(/,/g, ''),
    align: 'right',
    pattern: /^[0-9]*(\.[0-9]{0,6})?%?$/,
  },
  {
    title: 'Line Total',
    key: 'unitCostTotal',
    width: '150px',
    render: (unitCostTotal?: number) =>
      !unitCostTotal && unitCostTotal !== 0 ? '' : formatNumber(unitCostTotal, 2),
    inputValue: (line) =>
      !line.unitCostTotal && line.unitCostTotal !== 0
        ? ''
        : formatNumber(line.unitCostTotal, 2).replace(/,/g, ''),
    align: 'right',
    pattern: /^[0-9]*(\.[0-9]{0,2})?%?$/,
  },
];

const lineColumns = (canRecost: boolean): EditableColumn<Partial<AsnLineDTO>>[] => [
  {
    title: 'SKU / Description',
    key: 'description',
    prefix: ({ skuName }) => <Label normalCase>{skuName}</Label>,
    required: true,
    editable: !canRecost,
  },
  {
    title: 'Qty Expected',
    key: 'qtyExpected',
    width: '150px',
    render: (qty?: number) => formatNumber(qty ?? 0),
    align: 'right',
    required: true,
    pattern: 'integer',
    autoFocus: true,
    editable: !canRecost,
  },
];

const receivedColumns: EditableColumn<Partial<AsnLineDTO>>[] = [
  {
    title: 'Qty Received',
    key: 'qtyReceived',
    width: '150px',
    render: (qty?: number) => formatNumber(qty ?? 0),
    align: 'right',
    editable: false,
  },
];

export function AsnLines({
  editable,
  canDeleteLines,
  loading,
  asnLines,
  addAsnLine,
  updateAsnLine,
  deleteAsnLine,
  showQtyReceived,
  canRecost,
  recostLines,
}: Props) {
  const {
    models: { processedLines, isSelectingSku, isEditingNewLine, selectedSku, selectedLineId },
    operations: {
      selectLine,
      closeLine,
      save,
      selectNewSku,
      showSkuSelection,
      onValuesChange,
      validateForm,
    },
  } = useAsnLinesComponent({
    editable,
    asnLines,
    addAsnLine,
    updateAsnLine,
    canRecost,
    recostLines,
  });

  const columns = useMemo(
    () => [...lineColumns(canRecost), ...(showQtyReceived ? receivedColumns : []), ...costColumns],
    [showQtyReceived, canRecost],
  );

  const commonProps: ListTableProps<Partial<AsnLineDTO>> = {
    simple: true,
    columns,
    rows: processedLines,
    loading,
    hidePlaceholderText: !asnLines.length,
    onClickRow: selectLine,
  };

  const newSkuLine = isSelectingSku ? (
    <SkuSearchContainer>
      <SkuSearch prefixIcon selectedSku={selectedSku} onSelect={selectNewSku} autoFocus />
    </SkuSearchContainer>
  ) : (
    !isEditingNewLine && (
      <Button
        aria-label="Add new item"
        type="link"
        inline
        icon={<PlusOutlined />}
        onClick={showSkuSelection}
      >
        Add new item
      </Button>
    )
  );

  return editable || canRecost ? (
    <VerticallySpaced $factor={0.5}>
      <EditableTable
        {...commonProps}
        deletable={canDeleteLines && !isEditingNewLine}
        onCloseRow={closeLine}
        onSaveRow={({ id }, data) => id && save(id, data)}
        onDeleteRow={({ id }) => id && deleteAsnLine(id)}
        selectedRowKey={selectedLineId}
        rowSaveLabel={canRecost ? 'Recost' : 'Save'}
        onValuesChange={onValuesChange}
        validateForm={validateForm}
      />
      {!canRecost ? newSkuLine : null}
    </VerticallySpaced>
  ) : (
    <ListTable {...commonProps} />
  );
}
