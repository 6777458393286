import { HTMLProps, ReactNode } from 'react';
import { LabelColor, StyledLabel } from './styles';

export { type LabelColor };

type Props = {
  /** The color variant of the label */
  color?: LabelColor;

  /** By default, the label uses uppercase. Setting this to false disables this. */
  normalCase?: boolean;

  /** Label content */
  children?: ReactNode;
} & HTMLProps<HTMLSpanElement>;

export function Label({ color, normalCase, children, ...props }: Props) {
  return (
    <StyledLabel $color={color ?? 'default'} $normalCase={normalCase} {...props}>
      {children}
    </StyledLabel>
  );
}
