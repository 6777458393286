import { Card } from 'components/card';
import { Header } from 'components/header';
import { LoadingSkeleton } from 'components/loading-skeleton';
import { listingStatusType } from 'shared/status-maps';
import { listingStatusTitle, listingStrategyTitle } from 'shared/title-maps';
import { Container, HorizontallySpaced, ListContainer } from 'styles/layout';
import { DataTable, Column } from 'components/data-table';
import { ListingDTO, ListingStatus, ListingStrategy } from '@invenco/common-interface/sales';
import { Label, StatusLabel } from 'components/label';
import { formatNumber } from 'shared/helpers';
import { FilterOption } from 'components/data-table/filter-panel/types';
import { useListingsDataTable, views } from './useListingsDataTable';
import { useChannelDetailsPage } from './useChannelDetailsPage';
import { EditListingsModal } from './edit-listings-modal/EditListingsModal';

const getListingColumns = (selectedRows: Map<string, ListingDTO>): Column<ListingDTO>[] => [
  {
    title: 'Name',
    key: 'description',
    render: (description: string, row) => (
      <HorizontallySpaced $factor={0.5}>
        <Label color={selectedRows.has(row.id) ? 'active' : 'default'}>{row.name}</Label>
        <span>{description}</span>
      </HorizontallySpaced>
    ),
  },
  {
    title: 'Collections',
    key: 'collections',
    render: (collections: string[]) => collections.join(', '),
  },
  {
    title: 'Status',
    key: 'status',
    render: (status: ListingStatus) => (
      <StatusLabel status={status} type={listingStatusType} title={listingStatusTitle} />
    ),
  },
  {
    title: 'Expected',
    key: 'qtyExpected',
    align: 'right',
    render: (qtyExpected: number) => formatNumber(qtyExpected),
  },
  {
    title: 'Available',
    key: 'qtyAvailable',
    align: 'right',
    render: (qtyAvailable: number) => formatNumber(qtyAvailable),
  },
  {
    title: 'Strategy',
    key: 'strategy',
    align: 'right',
    render: (strategy: ListingStrategy) => listingStrategyTitle[strategy],
  },
  {
    title: 'Listed',
    key: 'qtyListed',
    align: 'right',
    render: (qtyListed: number) => formatNumber(qtyListed),
  },
];

const strategyOptions: FilterOption[] = [
  {
    value: ListingStrategy.DYNAMIC_AVAILABLE,
    title: listingStrategyTitle.DYNAMIC_AVAILABLE,
  },
  {
    value: ListingStrategy.PRESELL,
    title: listingStrategyTitle.PRESELL,
  },
  {
    value: ListingStrategy.SOLD_OUT,
    title: listingStrategyTitle.SOLD_OUT,
  },
];

export function ChannelDetails() {
  const {
    models: { isLoadingChannelDetails, isLoadingLocations, id, channel, breadcrumbs },
    operations: { refresh: refreshChannelDetails },
  } = useChannelDetailsPage();

  const {
    models: {
      isLoading: isLoadingListings,
      isEditListingsModalOpen,
      hasError: hasErrorLoadingListings,
      rows: listingRows,
      pagination: listingPagination,
      total: listingTotal,
      query: listingQuery,
      view: listingView,
      filterValues,
      filters,
      selectedRowsById,
      rowActions,
    },
    operations: {
      refresh: refreshListings,
      search: searchListings,
      navigate: navigateListings,
      updateFilters,
      changeView,
      toggleRowSelection,
      togglePageSelection,
      clearSelection,
      updateListings,
      closeEditListingsModal,
    },
  } = useListingsDataTable({ channelId: id });

  return (
    <Container>
      <Header
        title={channel.name}
        links={breadcrumbs}
        loading={isLoadingChannelDetails}
        loadDescription
        description={
          isLoadingLocations ? (
            <LoadingSkeleton width="300px" />
          ) : (
            <span>
              <strong>Fulfillment Locations:</strong>{' '}
              {channel.fulfillmentLocations?.map(({ name }) => name).join(', ')}
            </span>
          )
        }
        backURL="/sales/channels"
        onRefresh={refreshChannelDetails}
      />

      <ListContainer>
        <Card title="Listings">
          <DataTable.ViewTabs views={views} current={listingView ?? ''} onChange={changeView} />

          <DataTable.SearchPanel
            loading={isLoadingListings}
            searchPlaceholderText="Search..."
            defaultSearchValue={listingQuery}
            pagination={listingPagination}
            total={listingTotal}
            onSearch={searchListings}
            onNavigate={navigateListings}
            onReload={refreshListings}
            filters={filters}
            onChangeFilterSelections={updateFilters}
            filterSelections={filterValues}
          />

          <DataTable.QuickFilter
            title="Strategies"
            options={strategyOptions}
            hasAllOption
            selection={filterValues?.strategies}
            onChange={(strategies) => updateFilters({ strategies })}
          />

          <DataTable
            rows={listingRows}
            columns={getListingColumns(selectedRowsById)}
            loading={isLoadingListings}
            hasError={hasErrorLoadingListings}
            selectedRowsById={selectedRowsById}
            onToggleRowSelection={toggleRowSelection}
            onTogglePageSelection={togglePageSelection}
          />

          <DataTable.RowActions
            selectedRowsById={selectedRowsById}
            actions={rowActions}
            onClearSelection={clearSelection}
          />
        </Card>
      </ListContainer>

      <EditListingsModal
        isOpen={isEditListingsModalOpen}
        onClose={closeEditListingsModal}
        selectedListings={Array.from(selectedRowsById.values())}
        updateListings={updateListings}
      />
    </Container>
  );
}
