import { useMemo } from 'react';
import { Checkbox } from 'antd';
import { LoadingSkeleton } from 'components/loading-skeleton';
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Column, Row, SortOption, SortSelection } from './types';
import {
  BodyCell,
  HeaderCell,
  MessageRow,
  SortedHeaderInner,
  StyledDataTable,
  TableRow,
} from './styles';
import { SearchPanel } from './search-panel/SearchPanel';
import { ViewTabs } from './views/ViewTabs';
import { QuickFilter } from './quick-filter/QuickFilter';
import { RowActions } from './row-actions/RowActions';

export interface DataTableProps<T extends Row> {
  /** The rows to display in the table */
  rows?: T[];

  /** Column definitions. These may be a simple row key, or a composite via the render function. */
  columns: Column<T>[];

  /** Whether the table is loading data. */
  loading?: boolean;

  /** Whether there was an error requesting data. */
  hasError?: boolean;

  /** Simpler styling with no borders between rows and no expansion to edge of cards. */
  simple?: boolean;

  /** Override the default text displayed when there are no results. */
  emptyText?: string;

  /** Callback when a row is clicked. */
  onClickRow?: (row: T, event: React.MouseEvent<HTMLTableRowElement>) => void;

  /**
   * The selected (checked) rows, keyed by ID. This map can contain more than just the rows currently on the page.
   *
   * Should be used in conjunction with useRowSelection.
   */
  selectedRowsById?: Map<string, T>;

  /**
   * Callback for when a row's selection is toggled. Defining this will enable individual row selection.
   *
   * Should be used in conjunction with useRowSelection.
   */
  onToggleRowSelection?: (row: T) => void;

  /**
   * Callback for when a page's (i.e. all current rows in the table) selection is toggled.
   * Defining this will enable whole-page row selection.
   *
   * Should be used in conjunction with useRowSelection.
   */
  onTogglePageSelection?: (rows: T[]) => void;

  /**
   * Sorting options available. Where possible, the keys should match the respective column keys.
   * These options are used to determine which columns are sortable.
   */
  sortOptions?: SortOption[];

  /** The current sort selection. */
  sort?: SortSelection;

  /** Callback for when a column is clicked for sorting. */
  onChangeSort?: (sortSelection: SortSelection) => void;
}

function BaseTable<T extends Row>({
  rows,
  columns,
  loading,
  hasError,
  simple,
  emptyText,
  onClickRow,
  selectedRowsById,
  onToggleRowSelection,
  onTogglePageSelection,
  sort,
  sortOptions,
  onChangeSort,
}: DataTableProps<T>) {
  const isRowSelectable = typeof onToggleRowSelection === 'function';
  const isPageSelectable = typeof onTogglePageSelection === 'function';
  const isRowClickable = typeof onClickRow === 'function';

  const rowSelectionCount = useMemo(
    () =>
      rows?.reduce((acc, { id }) => acc + (selectedRowsById?.has(id.toString()) ? 1 : 0), 0) ?? 0,
    [selectedRowsById, rows],
  );

  return (
    <StyledDataTable $simple={simple}>
      <table>
        <thead>
          <TableRow>
            {isPageSelectable && (
              <HeaderCell
                $hasCheckbox
                onClick={() => onTogglePageSelection(rows ?? [])}
                $simple={simple}
              >
                <Checkbox
                  disabled={loading || !rows?.length}
                  checked={rowSelectionCount > 0 && rowSelectionCount === rows?.length}
                  indeterminate={rows && rowSelectionCount > 0 && rowSelectionCount < rows?.length}
                  aria-label={
                    rowSelectionCount === rows?.length ? 'Deselect all rows' : 'Select all rows'
                  }
                />
              </HeaderCell>
            )}

            {columns.map((column) => {
              const isSortable = sortOptions?.some((option) => option.key === column.key);
              const isActiveSort = sort?.key === column.key;
              return (
                <HeaderCell
                  key={column.key}
                  $align={column.align}
                  $simple={simple}
                  onClick={
                    isSortable
                      ? () =>
                          onChangeSort?.({
                            key: column.key,
                            direction: isActiveSort && sort?.direction === 'asc' ? 'desc' : 'asc',
                          })
                      : undefined
                  }
                >
                  {!isSortable ? (
                    column.title
                  ) : (
                    <SortedHeaderInner $active={isActiveSort}>
                      {column.title}
                      <span className="sort-icon" aria-hidden="true">
                        {isActiveSort && sort?.direction === 'desc' ? (
                          <SortDescendingOutlined />
                        ) : (
                          <SortAscendingOutlined />
                        )}
                      </span>
                    </SortedHeaderInner>
                  )}
                </HeaderCell>
              );
            })}
          </TableRow>
        </thead>

        <tbody>
          {loading ? (
            <TableRow>
              <BodyCell
                colSpan={isPageSelectable ? columns.length + 1 : columns.length}
                aria-label="Loading"
                $simple={simple}
              >
                <LoadingSkeleton rows={1} />
              </BodyCell>
            </TableRow>
          ) : null}

          {!loading &&
            (rows?.length ? (
              rows.map((row, index) => {
                const isSelected = selectedRowsById?.has(row.id.toString());
                return (
                  <TableRow
                    key={row.id}
                    $clickable={isRowClickable}
                    onClick={isRowClickable ? (event) => onClickRow(row, event) : undefined}
                  >
                    {isRowSelectable && (
                      <BodyCell
                        $hasCheckbox
                        onClick={(e) => {
                          onToggleRowSelection(row);
                          e.stopPropagation();
                        }}
                        $simple={simple}
                      >
                        <Checkbox
                          checked={isSelected}
                          aria-label={isSelected ? 'Deselect row' : 'Select row'}
                        />
                      </BodyCell>
                    )}

                    {columns.map((column) => (
                      <BodyCell key={column.key} $align={column.align} $simple={simple}>
                        {typeof column.render === 'function'
                          ? column.render(row[column.dataIndex || column.key], row, index)
                          : row[column.dataIndex || column.key]}
                      </BodyCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <MessageRow>
                <BodyCell
                  colSpan={isRowSelectable ? columns.length + 1 : columns.length}
                  $simple={simple}
                >
                  {hasError ? 'Error fetching results' : emptyText || 'No results found'}
                </BodyCell>
              </MessageRow>
            ))}
        </tbody>
      </table>
    </StyledDataTable>
  );
}

BaseTable.displayName = 'DataTable';

type DataTableInterface = typeof BaseTable & {
  SearchPanel: typeof SearchPanel;
  ViewTabs: typeof ViewTabs;
  QuickFilter: typeof QuickFilter;
  RowActions: typeof RowActions;
};

export const DataTable: DataTableInterface = BaseTable as DataTableInterface;
DataTable.SearchPanel = SearchPanel;
DataTable.ViewTabs = ViewTabs;
DataTable.QuickFilter = QuickFilter;
DataTable.RowActions = RowActions;
