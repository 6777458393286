import { ServiceAgreementDTO, LocationDTO, LocationType } from '@invenco/common-interface/accounts';
import { Form, FormInstance } from 'antd';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ComponentData } from '../../../../../shared/types';
import { useGateways } from '../../../../../gateways/GatewayProvider';
import { Result } from '../../../../../shared/helpers/Result';
import { LocalCalendarDate } from '../../../../../shared/domain/LocalCalendarDate';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  accountId: string;
  isTerminate?: boolean;
  serviceAgreement?: Partial<ServiceAgreementDTO>;
  addServiceAgreement: (data: Partial<ServiceAgreementDTO>) => Promise<Result>;
  updateServiceAgreement: (data: Partial<ServiceAgreementDTO>) => Promise<Result>;
  terminateServiceAgreement: (data: Partial<ServiceAgreementDTO>) => Promise<Result>;
};

type Models = {
  isLoadingLocations: boolean;
  form: FormInstance;
  locations?: LocationDTO[];
};

type Operations = {
  submit: (formData: Record<string, any>) => Promise<void>;
};

export function useServiceAgreementModalComponent({
  isOpen,
  onClose,
  accountId,
  isTerminate,
  addServiceAgreement,
  updateServiceAgreement,
  terminateServiceAgreement,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const { accountsGateway } = useGateways();

  const { data: locations, isLoading: isLoadingLocations } = useQuery({
    queryKey: ['locations'],
    queryFn: ({ signal }) => accountsGateway.getLocations(undefined, { signal }),
    select: ({ items }) =>
      items.filter(
        (location) => location.type === LocationType.MANAGED && location.accountId === accountId,
      ),
  });

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  const submit = async (serviceAgreement: Record<string, string>) => {
    let result;

    if (isTerminate) {
      result = await terminateServiceAgreement({
        ...serviceAgreement,
        terminationDate: LocalCalendarDate.fromJSDate(
          new Date(serviceAgreement.terminationDate),
        ).toISODate(),
      });
    } else if (serviceAgreement?.id) {
      result = await updateServiceAgreement({
        ...serviceAgreement,
        commencementDate: LocalCalendarDate.fromJSDate(
          new Date(serviceAgreement.commencementDate),
        ).toISODate(),
      });
    } else {
      result = await addServiceAgreement({
        ...serviceAgreement,
        commencementDate: LocalCalendarDate.fromJSDate(
          new Date(serviceAgreement.commencementDate),
        ).toISODate(),
      });
    }
    if (result.isSuccess) onClose();
  };

  return {
    models: {
      isLoadingLocations,
      form,
      locations,
    },
    operations: { submit },
  };
}
