import { useLocation } from 'react-router-dom';
import { Container } from '../../../styles/layout';
import { Header } from '../../../components/header';
import { SvgIcon } from '../../../components/svg-icon';
import LogoIcon from '../../../assets/lynk-icon.svg';
import { LogoWrapper, MessageContainer } from './styles';

export function NotFound() {
  const location = useLocation();
  return (
    <Container>
      <Header links={[{ title: 'Not found', url: location.pathname }]} />
      <MessageContainer>
        <LogoWrapper>
          <SvgIcon icon={LogoIcon} />
        </LogoWrapper>
        <p>Sorry, the page you are looking for does not exist.</p>
      </MessageContainer>
    </Container>
  );
}
