import { BillDTO } from '@invenco/common-interface/accounts';
import { ModalConfirm } from 'components/modal-confirm/ModalConfirm';
import { billStatusType } from 'shared/status-maps';
import { billStatusTitle } from 'shared/title-maps';
import { Card } from '../../../../components/card';
import { Container, HorizontallySpaced, ListContainer } from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { StatusLabel } from '../../../../components/label';
import { formatDate, formatDateTime, formatMoney } from '../../../../shared/helpers';
import { useBillListPage, sortOptions, views } from './useBillListPage';
import { DataTable, Column } from '../../../../components/data-table';

const renderStatusColFn = (status: string) => (
  <HorizontallySpaced $factor={0.5}>
    <StatusLabel status={status} type={billStatusType} title={billStatusTitle} />
  </HorizontallySpaced>
);
const renderTotalColFn = (total: string) => formatMoney(total);
const renderDateTimeColFn = (date: string) => (date ? formatDateTime(date) : '');
const renderDateColFn = (date: string) => (date ? formatDate(date) : '');

const columns: Column<BillDTO>[] = [
  { title: 'Bill', key: 'name' },
  { title: 'Account', key: 'accountName' },
  { title: 'Location', key: 'locationName' },
  { title: 'From', key: 'periodStart', render: renderDateColFn },
  { title: 'To', key: 'periodEnd', render: renderDateColFn },
  { title: 'Status', key: 'status', render: renderStatusColFn },
  { title: 'Last calculated', key: 'recalculatedAt', render: renderDateTimeColFn },
  { title: 'Total', key: 'total', align: 'right', render: renderTotalColFn },
];

export function BillList() {
  const {
    models: {
      isLoading,
      isCloseModalOpen,
      isClosingBills,
      hasError,
      rows,
      pagination,
      total,
      query,
      view,
      filterValues,
      filters,
      sort,
      selectedRowsById,
      rowActions,
    },
    operations: {
      search,
      navigate,
      changeView,
      changeSort,
      updateFilters,
      refresh,
      onClickRow,
      toggleRowSelection,
      togglePageSelection,
      clearSelection,
      closeBills,
      closeActionModal,
    },
  } = useBillListPage();

  return (
    <Container>
      <Header links={[{ url: '/bills', title: 'Bills' }]} title="Bills" />
      <ListContainer>
        <Card>
          <DataTable.ViewTabs views={views} current={view ?? ''} onChange={changeView} />
          <DataTable.SearchPanel
            loading={isLoading}
            searchPlaceholderText="Search..."
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
            filters={filters}
            filterSelections={filterValues}
            onChangeFilterSelections={updateFilters}
            sort={sort}
            sortOptions={sortOptions}
            onChangeSort={changeSort}
          />

          <DataTable
            columns={columns}
            rows={rows}
            loading={isLoading}
            hasError={hasError}
            onClickRow={onClickRow}
            selectedRowsById={selectedRowsById}
            onToggleRowSelection={toggleRowSelection}
            onTogglePageSelection={togglePageSelection}
            sort={sort}
            sortOptions={sortOptions}
            onChangeSort={changeSort}
          />

          <DataTable.RowActions
            actions={rowActions}
            onClearSelection={clearSelection}
            selectedRowsById={selectedRowsById}
          />
        </Card>

        <ModalConfirm
          title={`Are you sure you want to close ${selectedRowsById.size} bill${selectedRowsById.size === 1 ? '' : 's'}?`}
          description="This step is final and cannot be reversed"
          open={isCloseModalOpen}
          onOk={closeBills}
          onCancel={closeActionModal}
          maskClosable={false}
          isSubmitting={isClosingBills}
        />
      </ListContainer>
    </Container>
  );
}
