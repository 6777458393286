import type { AccountDTO } from '@invenco/common-interface/accounts';
import { useEffect } from 'react';
import { Input } from 'antd';
import { FormModal } from 'components/form-modal';
import { Form } from 'components/form';

type Props = {
  /**
   * Indicates if the account is new and should be created.
   * - When creating a new account, the account code is required
   * - When updating an existing account, the account code is not required
   */
  isNew: boolean;
  isOpen: boolean;
  onClose: () => void;
  account?: Partial<AccountDTO>;
  /**
   * Sets the local account state to the new data
   * (Note - this does not save the data to the server)
   */
  updateAccount: (data: Partial<AccountDTO>) => void;
};

export function AccountDetailsModal({ isNew, isOpen, onClose, account, updateAccount }: Props) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, account]);

  const onFinish = async (formData) => {
    updateAccount({
      ...formData,
      externalId: formData.externalId === '' ? null : formData.externalId,
    });
    onClose();
  };

  return (
    <FormModal
      open={isOpen}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      onSave={() => form.submit()}
      title={`${isNew ? 'Add' : 'Edit'} Account`}
      description="Enter the new account name and the primary user details"
    >
      <Form
        form={form}
        layout="vertical"
        name="account"
        onFinish={(data) => void onFinish(data)}
        initialValues={account}
      >
        <Form.Item
          name="name"
          label="Account Name"
          rules={[{ required: true, message: 'Please enter account name' }]}
        >
          <Input />
        </Form.Item>
        {isNew && (
          <Form.Item
            name="code"
            label="Account Code"
            rules={[{ required: true, message: 'Please enter account code' }]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="primaryName"
          label="Contact Name"
          rules={[{ required: true, message: 'Please enter contact name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="primaryEmail"
          label="Contact Email"
          rules={[{ required: true, message: 'Please enter contact email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="primaryPhone"
          label="Contact Phone"
          rules={[{ required: true, message: 'Please enter contact phone number' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="externalId" label="Billing Id (External Id)">
          <Input />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
