import styled from 'styled-components';
import { spaceHorizontalChildren, spaceVerticalChildren } from '../../../styles/mixins';

export const MenuContainer = styled.aside`
  width: 208px;
  ${spaceVerticalChildren()}

  h1 {
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Menu = styled.nav`
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    ${spaceVerticalChildren(0.5)}

    a {
      display: flex;
      align-items: center;
      opacity: 0.65;
      border-radius: 20px;
      min-height: 40px;
      padding: 0 16px;
      color: var(--gray-9);
      margin-left: -16px;
      ${spaceHorizontalChildren(0.5)}

      > *:first-child {
        font-size: 16px;
      }
      > *:last-child {
        flex: 1;
      }

      &:hover,
      &.active {
        opacity: 1;
      }

      &.active {
        font-weight: 600;
        background: var(--gray-4);
      }
    }
  }
`;
