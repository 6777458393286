import {
  ShippingMethodAutoInvite,
  ShippingMethodRateCalculationMethod,
  ShippingMethodRateMatchMethod,
  ShippingMethodStatus,
} from '@invenco/common-domain/enums/ShippingMethod';
import {
  CarrierServiceDTO,
  ShippingMethodDTO,
  CarrierAccountDTO,
} from '@invenco/common-interface/shipping';
import { Form, FormInstance } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AddShippingMethodDTO } from '../useLocationDetailsPage';
import { ComponentData } from '../../../../../shared/types';
import { useGateways } from '../../../../../gateways/GatewayProvider';
import { Result } from '../../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  existingShippingMethods: ShippingMethodDTO[];
  addShippingMethod: (shippingMethod: AddShippingMethodDTO) => Promise<Result>;
  accountId: string;
};

type Models = {
  form: FormInstance;
  isSaving: boolean;
  isLoadingCarriers: boolean;
  canSave: boolean;
  carrierServices: CarrierServiceDTO[];
  isLoadingCarrierAccounts: boolean;
  carrierAccounts: CarrierAccountDTO[];
};

type Operations = {
  submit: (formData: Record<string, string>) => Promise<void>;
  selectCarrierServiceId: (id: string) => void;
};

export function useWarehouseShippingMethodModalComponent({
  isOpen,
  onClose,
  existingShippingMethods,
  addShippingMethod,
  accountId,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const { shippingGateway } = useGateways();
  const [isSaving, setIsSaving] = useState(false);
  const [selectedCarrierServiceCarrierId, setSelectedCarrierServiceCarrierId] = useState<
    string | undefined
  >();

  const { data: serviceData, isLoading: isLoadingCarriers } = useQuery({
    queryKey: ['carrierServices'],
    queryFn: ({ signal }) => shippingGateway.getCarrierServices(undefined, { signal }),
  });

  const { data: carrierAccounts, isLoading: isLoadingCarrierAccounts } = useQuery({
    queryKey: ['carrierAccounts', accountId, selectedCarrierServiceCarrierId],
    queryFn: ({ signal }) =>
      shippingGateway.getCarrierAccounts(
        {
          accountId,
          carrierIds: selectedCarrierServiceCarrierId
            ? [selectedCarrierServiceCarrierId]
            : undefined,
        },
        { signal },
      ),
  });

  // get services which have not already been added to a shipping method of this location
  const carrierServices = useMemo(() => {
    const existingIds = new Set(existingShippingMethods.map((method) => method.carrierServiceId));
    return (
      serviceData?.items.filter((service) => !service.id || !existingIds.has(service.id)) ?? []
    );
  }, [existingShippingMethods, serviceData]);

  const canSave = carrierServices.length > 0;

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  const submit = async (formData: Record<string, string>) => {
    setIsSaving(true);
    const result = await addShippingMethod({
      name: formData.name,
      carrierServiceId: formData.carrierServiceId,
      status: ShippingMethodStatus[formData.status],
      ratesCarrierAccountId: formData.ratesCarrierAccountId,
      bookingCarrierAccountId: formData.bookingCarrierAccountId,
      rateMatchMethod: ShippingMethodRateMatchMethod[formData.rateMatchMethod],
      rateCalculationMethod: ShippingMethodRateCalculationMethod[formData.rateCalculationMethod],
      autoInvite: ShippingMethodAutoInvite[formData.autoInvite],
    });
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  const selectCarrierServiceId = (id: string) => {
    const service = carrierServices.find((s) => s.id === id);
    setSelectedCarrierServiceCarrierId(service?.carrierId);
    if (service) {
      form.setFieldsValue({
        name: service.name,
        rateMatchMethod: ShippingMethodRateMatchMethod[service.rateMatchMethod], // assumes enum values are the same
      });
    }
  };

  return {
    models: {
      form,
      isSaving,
      isLoadingCarriers,
      canSave,
      carrierServices,
      isLoadingCarrierAccounts,
      carrierAccounts: carrierAccounts?.items ?? [],
    },
    operations: { submit, selectCarrierServiceId },
  };
}
