import { SVGProps } from 'react';
import { IconContainer } from './styles';

interface Props {
  isLast: boolean;
  editing?: boolean;
}

/** A line connecting the components with their parent
 * @param isLast - whether this is the last component
 * @param editing - whether its an editable mode
 */
export function ConnectingLine({ isLast, editing, ...props }: Props & SVGProps<SVGSVGElement>) {
  const color = editing ? 'var(--blue-3)' : 'var(--gray-4)';
  return (
    <IconContainer>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
        {isLast ? (
          <>
            <path stroke={color} d="M16 14V0" strokeWidth="2" />
            <rect width="4" height="4" x="14" y="14.5" stroke="currentColor" rx=".01" />
            <path stroke={color} d="M18.5 16.5H32" strokeWidth="2" />
          </>
        ) : (
          <>
            <path stroke={color} d="M16 13.5v-14" strokeWidth="2" />
            <rect width="4" height="4" x="14" y="14" stroke="currentColor" rx=".01" />
            <path stroke={color} d="M18.5 16H32M16 32.5v-14" strokeWidth="2" />
          </>
        )}
      </svg>
    </IconContainer>
  );
}
