import { TooltipProps } from 'antd';
import { Tooltip } from 'components/tooltip';
import { TooltipIcon } from './styles';

/** Help info tooltip triggered by a small icon. Often used with inputs. */
export function HelpTooltip(props: TooltipProps) {
  return (
    <Tooltip {...props}>
      <TooltipIcon />
    </Tooltip>
  );
}
