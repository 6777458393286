import { LocationDTO } from '@invenco/common-interface/accounts';
import { Filter, FilterSelections, useDataTable, useRowNavigation } from 'components/data-table';
import { useAutoCompleteQuery, useQueryWithInput } from 'shared/hooks/queries';
import { useMemo } from 'react';
import { ComponentData, Page, Pagination } from '../../../../shared/types';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { SortOption, SortSelection } from '../../../../components/data-table/types';
import { LocationSortBy } from '../../../../gateways/impl/AxiosAccountsGateway';

export type LocationRow = LocationDTO;

export type FilterKey = 'accountId';

type Models = {
  isLoading: boolean;
  hasError: boolean;
  rows?: LocationRow[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  filters: Filter<FilterKey>[];
  filterSelections?: FilterSelections<FilterKey>;
  sort?: SortSelection;
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  changeSort: (sort: SortSelection) => void;
  updateFilters: (filters: FilterSelections<FilterKey>) => void;
  refresh: () => void;
  onClickRow: (row: LocationRow, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export const sortOptions: SortOption[] = [
  { key: 'createdAt', title: 'Created' },
  { key: 'name', title: 'Name' },
];

export function useLocationListPage(): ComponentData<Models, Operations> {
  const { accountsGateway } = useGateways();
  const { onClickRow } = useRowNavigation<LocationRow>({ baseUrl: '/locations' });
  const [tableState, setTableState, { getFilterValue }] = useDataTable({
    filterKeys: ['accountId'],
    sortOptions,
    defaultSort: { key: 'createdAt', direction: 'desc' },
  });

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'workflows',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
      accountId: getFilterValue('accountId'),
      sortBy: tableState.sort?.key as LocationSortBy,
      sortDirection: tableState.sort?.direction,
    },
    query: (input, { signal }) => accountsGateway.getLocations(input, { signal }),
  });

  const {
    options: accounts,
    isLoading: isLoadingAccounts,
    onSearch: onSearchAccounts,
  } = useAutoCompleteQuery({
    parentKey: 'accounts',
    query: (input, { signal }) => accountsGateway.getAccounts(input, { signal }),
  });

  const accountFilter = useMemo<Filter<FilterKey>>(
    () => ({
      key: 'accountId',
      title: 'Account',
      options: accounts.map((acc) => ({ value: acc.id, title: acc.name })),
      loading: isLoadingAccounts,
      onSearch: onSearchAccounts,
    }),
    [accounts, isLoadingAccounts, onSearchAccounts],
  );

  return {
    models: {
      isLoading,
      hasError: isError,
      rows: data?.items as LocationRow[] | undefined,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      filters: [accountFilter],
      filterSelections: tableState.filters,
      sort: tableState.sort,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      changeSort: (sort) => setTableState({ sort, page: undefined }),
      updateFilters: (updated: FilterSelections<FilterKey>) =>
        setTableState({ filters: updated, view: undefined, page: undefined }),
      refresh: refetch,
      onClickRow,
    },
  };
}
