import { SkuDTO } from '@invenco/common-interface/products';

import { FormModal } from '../../../../../components/form-modal';
import { Form } from '../../../../../components/form';
import { useMeasurementsModalComponent } from './useMeasurementsModalComponent';
import { MeasurementFormItems } from '../../MeasurementFormItems';
import { Result } from '../../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  sku: Partial<SkuDTO>;
  updateSku: (data: Partial<SkuDTO>) => Promise<Result>;
  onClose: () => void;
};

export function MeasurementsModal({ isOpen, sku, updateSku, onClose }: Props) {
  const {
    models: { form, isSaving, initialValues },
    operations: { submit },
  } = useMeasurementsModalComponent({ isOpen, onClose, sku, updateSku });
  return (
    <FormModal
      title="Measurements"
      onSave={() => form.submit()}
      isSaving={isSaving}
      open={isOpen}
      onCancel={onClose}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinish={(data) => void submit(data)}
      >
        <MeasurementFormItems />
      </Form>
    </FormModal>
  );
}
