import { DimensionUnit, WeightUnit } from '@invenco/common-interface/shared';

import { Select } from 'antd';
import { Form } from 'components/form';
import { Input } from 'components/input';

export function MeasurementFormItems() {
  return (
    <>
      <Form.Row>
        <Form.Item label="Length" name="length">
          <Input placeholder="Length" type="number" />
        </Form.Item>
        <Form.Item label="Width" name="width">
          <Input placeholder="Width" type="number" />
        </Form.Item>
        <Form.Item label="Height" name="height">
          <Input placeholder="Height" type="number" />
        </Form.Item>
        <Form.Item label=" " name="dimensionUnit">
          <Select aria-label="Dimension Unit" disabled>
            {Object.values(DimensionUnit).map((unit) => (
              <Select.Option key={unit} value={unit}>
                {unit}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Row>
      <Form.Row>
        <div />
        <div />
        <Form.Item label="Weight" name="weight">
          <Input placeholder="Weight" type="number" />
        </Form.Item>
        <Form.Item label=" " name="weightUnit">
          <Select aria-label="Weight Unit" disabled>
            {Object.values(WeightUnit).map((unit) => (
              <Select.Option key={unit} value={unit}>
                {unit}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Row>
    </>
  );
}
