import styled from 'styled-components';

export const LookerContainer = styled.main`
  flex: 1;
  min-height: 0;

  & > div,
  iframe {
    width: 100%;
    height: 100%;
    max-width: 2450px;
    border-radius: 16px;
  }
`;
