import React from 'react';
import { InputProps, InputRef } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { StyledInput } from './styles';

type Props = InputProps & {
  /** Whether the current search query is loading data. */
  loading?: boolean;

  /** If true, the search or loading icon will be prefixed to the input instead of suffixed. */
  prefixIcon?: boolean;
};

/** Simple input component for searching. */
export const SearchInput = React.forwardRef<InputRef, Props>(
  ({ loading, prefixIcon, placeholder, ...props }, ref) => {
    const icon = loading ? <LoadingOutlined spin /> : <SearchOutlined />;
    return (
      <StyledInput
        ref={ref}
        aria-label={props['aria-label'] || placeholder}
        placeholder={placeholder}
        {...props}
        prefix={prefixIcon ? icon : undefined}
        suffix={!prefixIcon ? icon : undefined}
      />
    );
  },
);
