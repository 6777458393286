import { Navigate, Route } from 'react-router-dom';
import { DEPLOYMENT_STAGE } from 'env';
import { DeveloperConfig } from '../../pages/home/developer-config/DeveloperConfig';
import { NotFound } from '../../pages/common/not-found/NotFound';

export const redirectRoute = (from: string, to: string) => (
  <Route path={from} element={<Navigate to={to} />} />
);

export const developerRoute = ['LOCAL', 'DEV'].includes(DEPLOYMENT_STAGE) ? (
  <Route key="dev" path="/dev-config" element={<DeveloperConfig />} />
) : null;

export const notFoundRoute = <Route key="notFound" path="*" element={<NotFound />} />;

export const commonRoutes = [developerRoute, notFoundRoute];
