import { getLogger } from '../logger/Logger';

const logger = getLogger();

const getStorage = (storage: Storage, key: string) => {
  const value = storage.getItem(key);
  if (value !== null) {
    try {
      return JSON.parse(value);
    } catch (e) {
      logger.warn(`Could not parse storage key "${key}" with value "${value}"`);
    }
  }
  return null;
};

const setStorage = (storage: Storage, key: string, value: any) => {
  storage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: string) => getStorage(localStorage, key);
export const setLocalStorage = (key: string, value: any) => setStorage(localStorage, key, value);
export const getSessionStorage = (key: string) => getStorage(sessionStorage, key);
export const setSessionStorage = (key: string, value: any) =>
  setStorage(sessionStorage, key, value);
