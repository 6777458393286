import { MembershipDTO } from '@invenco/common-interface/accounts';
import { AccessContext } from '@invenco/common-interface/auth';
import { useMemo, useState } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { useGateways } from 'gateways/GatewayProvider';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_USER_ACCOUNT_LIST_SIZE } from '../../../constants';
import { useMessages } from '../../../shared/providers/MessagesProvider';
import { ComponentData } from '../../../shared/types';
import { useGatewayMutation } from '../../../shared/hooks/queries';
import { Result } from '../../../shared/helpers/Result';
import { useCurrentUserQuery } from '../../../shared/hooks/queries/common';

type Models = {
  changingToMembershipId?: string;
  isMenuOpen: boolean;
  hasMultipleMemberships: boolean;
  activeMembership?: MembershipDTO;
  platformMembership?: MembershipDTO;
  sellerMemberships: MembershipDTO[];
  warehouseMemberships: MembershipDTO[];
  auth0User?: User;
};

type Operations = {
  logout: () => void;
  search: (query: string) => void;
  selectMembership: (membershipId: string) => Promise<Result>;
  setIsMenuOpen: (isOpen: boolean) => void;
  onDevConfig: () => void;
};

export function useProfileMenuComponent(): ComponentData<Models, Operations> {
  const navigate = useNavigate();
  const { logout, user: auth0User, getAccessTokenSilently } = useAuth0();
  const { accountsGateway } = useGateways();
  const messages = useMessages();
  const queryClient = useQueryClient();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [query, setQuery] = useState<string>();
  const [changingToMembershipId, setChangingToMembershipId] = useState<string>();

  const { data: { memberships, activeMembership } = {} } = useCurrentUserQuery();

  const {
    [AccessContext.SELLER]: sellerMemberships,
    [AccessContext.WAREHOUSE]: warehouseMemberships,
  } = useMemo(() => {
    const lists = { [AccessContext.SELLER]: [], [AccessContext.WAREHOUSE]: [] };
    memberships
      ?.filter(
        (m) =>
          (m.accessContext === AccessContext.SELLER ||
            m.accessContext === AccessContext.WAREHOUSE) &&
          m.id !== activeMembership?.id &&
          (!query || m.accountName?.toLocaleLowerCase().includes(query.toLocaleLowerCase().trim())),
      )
      .forEach((m) => {
        if (lists[m.accessContext].length < DEFAULT_USER_ACCOUNT_LIST_SIZE) {
          lists[m.accessContext].push(m);
        }
      });

    if (
      !query &&
      activeMembership &&
      [AccessContext.SELLER, AccessContext.WAREHOUSE].includes(activeMembership.accessContext)
    ) {
      lists[activeMembership.accessContext].unshift(activeMembership);
    }
    return lists;
  }, [memberships, activeMembership, query]);

  const platformMembership = useMemo(
    () => memberships?.find((m) => m.accessContext === AccessContext.PLATFORM) ?? undefined,
    [memberships],
  );

  const logoutHandler = () => logout({ logoutParams: { returnTo: window.location.origin } });

  const { mutate: selectMembership } = useGatewayMutation({
    onMutate: (membershipId: string) => setChangingToMembershipId(membershipId),
    mutationFn: (membershipId: string) => accountsGateway.setUserActiveMembership(membershipId),
    onSuccess: async (newUser) => {
      // force a refresh of the auth0 token
      await getAccessTokenSilently({ cacheMode: 'off' });

      // delay to allow routing changes to propagate after token is updated
      setTimeout(() => {
        // navigate home as routes may change
        navigate('/');

        // IMPORTANT - the cache must be cleared as query keys don't include account/membership id
        // if this is not done, initially cached data from the wrong account may be returned from queries.
        // We're doing it upon navigation and not immediately to prevent attempting to immediately refetch
        // data on screen with the wrong token.
        queryClient.clear();

        // Reset the user query data to prevent it flashing the loading state again after clearing cache
        queryClient.setQueryData(['users', 'me'], newUser);

        // update UI
        setChangingToMembershipId(undefined);
        setIsMenuOpen(false);
        const { accessContext, accountName } = newUser?.activeMembership ?? {};
        messages.info('Switched account', {
          name: accessContext === AccessContext.PLATFORM ? 'PLATFORM' : accountName,
        });
      }, 100);
    },
    onError: () => setChangingToMembershipId(undefined),
    linkedQuery: ['users', 'me'],
  });

  const onDevConfig = () => navigate('/dev-config');

  return {
    models: {
      changingToMembershipId,
      isMenuOpen,
      hasMultipleMemberships: !!memberships && memberships.length > 1,
      activeMembership,
      platformMembership,
      sellerMemberships,
      warehouseMemberships,
      auth0User,
    },
    operations: {
      logout: logoutHandler,
      search: setQuery,
      selectMembership,
      setIsMenuOpen,
      onDevConfig,
    },
  };
}
