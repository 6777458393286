import { useMemo, useState } from 'react';
import { useBatchedRequests } from 'shared/hooks/useBatchedRequests';
import { useMessages } from 'shared/providers/MessagesProvider';
import { ComponentData } from 'shared/types';
import { useImportRequests, ImportDataRecord } from './requests';
import { CreatedRecord, ErrorRecord, TemplateKey } from './types';
import { extractErrorMessage } from '../../shared/helpers';

type Props = {
  template: TemplateKey;
  onComplete?: () => void;
};

type Models = {
  isRunnerModalOpen: boolean;
  isImporterOpen: boolean;
  isRunning: boolean;
  total?: number;
  completed?: number;
  created?: CreatedRecord[];
  failures?: ErrorRecord[];
};

type Operations = {
  open: () => void;
  closeImporter: () => void;
  closeRunnerModal: () => void;
  processImportData: (data: any) => Promise<void>;
  handleError: (message: string) => void;
};

export interface ImportData {
  records: ImportDataRecord[];
}

export function useDataImporterComponent({
  template,
  onComplete,
}: Props): ComponentData<Models, Operations> {
  const messages = useMessages();
  const [isImporterOpen, setIsImporterOpen] = useState(false);
  const [isRunnerModalOpen, setIsRunnerModalOpen] = useState(false);
  const importRequests = useImportRequests(template);
  const { run, results, failures, isRunning, total } = useBatchedRequests<Record<string, any>, any>(
    { latestFirst: true },
  );

  const created = useMemo(() => results.map((result) => result.output), [results]);

  const open = () => (isRunning ? setIsRunnerModalOpen(true) : setIsImporterOpen(true));
  const closeImporter = () => setIsImporterOpen(false);
  const closeRunnerModal = () => setIsRunnerModalOpen(false);

  const handleError = (message: string) => {
    messages.error(message);
  };
  const processImportData = async (data: ImportData) => {
    setIsImporterOpen(false);
    setIsRunnerModalOpen(true);
    try {
      await run(importRequests(data.records));
      messages.success('Import completed');
      onComplete?.();
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      handleError(`Import failed${errorMessage ? `: ${errorMessage}` : ''}`);
    }
  };

  return {
    models: {
      isImporterOpen,
      isRunnerModalOpen,
      isRunning,
      total,
      created,
      completed: created.length + failures.length,
      failures,
    },
    operations: {
      processImportData,
      handleError,
      open,
      closeImporter,
      closeRunnerModal,
    },
  };
}
