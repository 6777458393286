import { WorkflowStatus } from '@invenco/common-domain/enums';
import { WorkflowDTO } from '@invenco/common-interface/automation';
import { BaseAxiosGateway, QueryRequestOptions } from '../BaseAxiosGateway';
import { GatewayName, PaginatedResponse } from '../types';

export class AxiosAutomationGateway extends BaseAxiosGateway {
  name: GatewayName = 'automation';

  async getWorkflow(id: string, options?: QueryRequestOptions): Promise<WorkflowDTO> {
    const { data } = await this.httpGet(`/v1/workflows/${id}`, options);
    return data.workflow;
  }

  async getWorkflows(
    query?: {
      take?: number;
      cursor?: string;
      status?: WorkflowStatus;
      search?: string;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<WorkflowDTO>> {
    const { data } = await this.httpGet('/v1/workflows', {
      params: {
        take: query?.take,
        cursor: query?.cursor,
        status: query?.status || undefined,
        q: query?.search,
      },
      ...options,
    });
    return {
      ...data,
      items: data.workflows,
    };
  }

  async createWorkflow(workflow: Partial<WorkflowDTO>): Promise<WorkflowDTO> {
    const { data } = await this.httpPost<{ workflow: WorkflowDTO }>('/v1/workflows', {
      data: { workflow },
    });
    return data.workflow;
  }

  async updateWorkflow(id: string, workflow: Partial<WorkflowDTO>) {
    const { data } = await this.httpPut<{ workflow: WorkflowDTO }>(`/v1/workflows/${id}`, {
      data: { workflow },
    });
    return data.workflow;
  }

  async deleteWorkflow(id: string) {
    await this.httpDelete(`/v1/workflows/${id}`);
  }
}
