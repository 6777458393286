import { ContactAddressDTO, DimensionUnit, WeightUnit } from '@invenco/common-interface/shared';
import { SkuDTO } from '@invenco/common-interface/products';
import { AsnDTO, CreateAsnDTO } from '@invenco/common-interface/supply';
import { CreateOrderDTO, OrderDTO, OrderLineDTO } from '@invenco/common-interface/sales';
import { useCallback } from 'react';
import { useGateways } from 'gateways/GatewayProvider';
import { assertNever } from 'shared/helpers';
import { useQuery } from '@tanstack/react-query';
import { getLogger } from 'shared/logger/Logger';
import { TemplateKey } from './types';

const logger = getLogger();

type PartialAsnDTO = Omit<Partial<AsnDTO>, 'asnLines'> & {
  asnLines?: Partial<AsnDTO['asnLines'][0]>[];
};

export type ImportDataRecord = Record<string, any>;

export interface ImportRequest {
  inputs: ImportDataRecord[];
  execute: (input: ImportDataRecord) => Promise<any>;
}

const getSkuDTOs = (records: Record<string, any>): Partial<SkuDTO>[] =>
  records.map((record) => {
    let purchasePrice: number | undefined;
    let sellPrice: number | undefined;

    if (record.purchase_price !== undefined) {
      purchasePrice = record.purchase_price ? +record.purchase_price : 0;
    }

    if (record.sell_price !== undefined) {
      sellPrice = record.sell_price ? +record.sell_price : 0;
    }

    const parseMeasurement = (value: any): number | undefined =>
      value === undefined ? undefined : Number(value ?? 0);
    const length = parseMeasurement(record.length);
    const width = parseMeasurement(record.width);
    const height = parseMeasurement(record.height);
    const weight = parseMeasurement(record.weight);
    const shouldIncludeMeasurement =
      length !== undefined || width !== undefined || height !== undefined || weight !== undefined;

    return {
      name: record.name,
      barcode: record.barcode,
      description: record.description,
      purchasePrice,
      sellPrice,
      hsCode: record.hs_code,
      countryOfOrigin: record.country_of_origin?.toUpperCase(),
      measurement: shouldIncludeMeasurement
        ? {
            dimensionUnit: DimensionUnit.CM,
            weightUnit: WeightUnit.KG,
            length,
            width,
            height,
            weight,
          }
        : undefined,
    };
  });

const getAsnDTOs = (records: Record<string, any>[]): PartialAsnDTO[] => {
  const asns: PartialAsnDTO[] = [];

  records.forEach((record) => {
    // Parse the string representation of the date in the CSV as 12am for that date
    // in the current system timezone offset.
    // So 01/12/2021 becomes 2021-11-30T13:00:00.000Z (UTC)
    // or 2021-12-01T00:00:00.000+11:00 (Australian Eastern Standard Time).
    // The below code works only if our time zone is AEST.
    let current = asns[asns.length - 1];
    if (!current || (record.reference && current.reference !== record.reference)) {
      let expectedAt: string | undefined;
      if (record.expected_date) {
        try {
          // using date-fns results in an invalid date when attempting to format
          const [day, month, year] = record.expected_date.split('/');
          expectedAt = new Date(+year, +month - 1, +day).toISOString();
        } catch (e) {
          // eslint-disable-next-line no-console
          logger.error(`Could not parse date ${record.expected_date}`, e);
        }
      }

      current = {
        reference: record.reference,
        locationName: record.location,
        expectedAt,
        carrier: record.carrier,
        trackingNumber: record.tracking_number,
        note: record.note,
        fromAddress: {
          contactName: record.contact_name,
          contactNumber: record.contact_number,
          contactEmail: record.contact_email,
          companyName: record.company_name,
          address1: record.address_1,
          address2: record.address_2,
          city: record.city,
          state: record.state,
          postcode: record.postcode,
          country: record.country,
        },
        asnLines: [],
      };
      asns.push(current);
    }

    if (current) {
      current.asnLines!.push({
        skuName: record.sku_code,
        description: record.description,
        qtyExpected: +record.qty_expected,
        unitPurchaseCost: record.unit_purchase_cost ? +record.unit_purchase_cost : null,
        unitCostTotal: record.unit_cost_total ? +record.unit_cost_total : null,
      });
    }
  });

  return asns;
};

const getOrderDTOs = (records: Record<string, any>[]): Partial<OrderDTO>[] => {
  let orders: Partial<OrderDTO>[] = [];
  const orderDict: { [name: string]: Partial<OrderDTO> } = {};

  records.forEach((record) => {
    const orderName = record.order_name;

    if (orderName) {
      if (!orderDict[orderName]) {
        orderDict[orderName] = <Partial<OrderDTO>>{
          name: orderName,
          channelName: record.channel_name,
          currency: record.currency,
          notes: record.notes,
          reference: record.reference,
          customerName: record.customer_name,
          email: record.email,
          phone: record.phone,
          shippingDescription: record.shipping_description,
          shippingAddress: <ContactAddressDTO>{
            contactName: record.shipping_address_contact_name,
            contactNumber: record.shipping_address_contact_number,
            contactEmail: record.shipping_address_contact_email,
            companyName: record.shipping_address_company_name,
            address1: record.shipping_address_line_1,
            address2: record.shipping_address_line_2,
            city: record.shipping_address_city,
            state: record.shipping_address_state,
            postcode: record.shipping_address_postcode,
            country: record.shipping_address_country,
          },
          orderLines: [],
        };
      }

      orderDict[orderName].orderLines?.push(<OrderLineDTO>{
        skuName: record.order_line_sku_name,
        description: record.order_line_description,
        qtyOrdered: Number(record.order_line_qty_ordered || 0),
        unitPrice: Number(record.order_line_unit_price || 0),
        discountAmount: Number(record.order_line_discount_amount || 0),
      });
    }
  });

  orders = Object.values(orderDict);
  return orders;
};

export function useImportRequests(
  template: TemplateKey,
): (records: ImportDataRecord[]) => ImportRequest {
  const { productsGateway, supplyGateway, salesGateway } = useGateways();

  const { data: channelData } = useQuery({
    queryKey: ['channels'],
    queryFn: ({ signal }) => salesGateway.getChannels(undefined, { signal }),
    enabled: true,
  });

  return useCallback(
    (records: Record<string, any>[]) => {
      switch (template) {
        case TemplateKey.SKUS:
          return {
            inputs: getSkuDTOs(records),
            execute: (sku: Partial<SkuDTO>) => productsGateway.importSku(sku),
          };
        case TemplateKey.ASNS:
          return {
            inputs: getAsnDTOs(records),
            execute: (asn: PartialAsnDTO) => supplyGateway.createAsn(asn as CreateAsnDTO),
          };
        case TemplateKey.ORDERS:
          return {
            inputs: getOrderDTOs(records),
            execute: (order: Partial<OrderDTO>) => {
              if (channelData) {
                const existingChannelNames = channelData.items?.map((channel) => channel.name);
                const channelIsValid = existingChannelNames?.includes(order.channelName || '');

                if (channelIsValid) {
                  return salesGateway.createOrder(order as CreateOrderDTO);
                }

                throw new Error(
                  `Channel should be one of the following values: 
                  "${existingChannelNames?.join(', ')}"`,
                );
              }

              throw new Error('Channel data is not available');
            },
          };
        default:
          return assertNever(template);
      }
    },
    [template, productsGateway, supplyGateway, salesGateway, channelData],
  );
}
