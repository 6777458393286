import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

export const StyledSpan = styled.span<{ $active?: boolean; $clickable?: boolean }>`
  font-size: 12px;
  height: 22px;
  border-radius: 12px;
  display: flex;
  padding: 0 8px;
  align-items: center;
  gap: 4px;
  transition: all 0.2s;

  ${({ $active }) =>
    $active
      ? `
        background: var(--blue-1);
        color: var(--blue-7);
        
        .extra {
          color: var(--blue-5);
        }
      `
      : `
        background: var(--gray-4);
        color: var(--gray-9);
        
        .extra {
          color: var(--gray-7);
        }
      `}

  ${({ $clickable, $active }) =>
    $clickable &&
    `
    cursor: pointer;
    &:hover {
      background: ${$active ? 'var(--blue-2)' : 'var(--gray-5)'};
      border-color: ${$active ? 'var(--blue-5)' : 'var(--gray-7)'};
    }
  `}
`;

export const Extra = styled.span<{ $active?: boolean }>`
  color: ${({ $active }) => ($active ? 'var(--blue-5)' : 'var(--gray-7)')};
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const DeleteButton = styled(CloseOutlined)<{ $active?: boolean }>`
  color: ${({ $active }) => ($active ? 'var(--blue-5)' : 'var(--gray-7)')} !important;
  transition: all 0.2s;
  cursor: pointer;
  position: relative;

  &:hover {
    color: ${({ $active }) => ($active ? 'var(--blue-7)' : 'var(--gray-9)')} !important;
    transform: scale(1.2);
  }

  // hit slop
  &::after {
    content: '';
    position: absolute;
    width: 125%;
    height: 125%;
    top: -12.5%;
    left: -12.5%;
  }
`;
