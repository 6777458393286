import { Form, FormInstance } from 'antd';
import { ContextMenu } from 'components/context-menu';
import { Button } from 'components/button';
import { ListTable, ListTableProps } from '../list-table';
import { ExpandedRow } from '../list-table/styles';
import { BaseRow } from '../list-table/types';
import { ButtonBlockContainer, TableWrapper } from './styles';
import { EditableColumn } from './types';
import { useEditableTableComponent } from './useEditableTableComponent';

type Props<T extends BaseRow> = Omit<ListTableProps<T>, 'columns' | 'selectedRowKeys'> & {
  columns: EditableColumn<T>[];
  selectedRowKey?: string;
  onSaveRow?: (record: T, data: Record<string, any>) => Promise<any> | any;
  onCloseRow?: (record: T) => any;
  onDeleteRow?: (record: T) => Promise<any> | any;
  validateForm?: (data: Record<string, any>) => boolean;
  deletable?: boolean;
  rowSaveLabel?: string;
  onValuesChange?: (
    changed: Record<string, any>,
    values: Record<string, any>,
    form: FormInstance,
  ) => void;
};

// TODO: save on enter
export function EditableTable<T extends BaseRow>({
  columns,
  rows,
  rowKey = 'id',
  selectedRowKey,
  onSaveRow,
  onCloseRow,
  onDeleteRow,
  deletable,
  expandedRowContent,
  validateForm,
  rowSaveLabel = 'Save',
  onValuesChange,
  ...rest
}: Props<T>) {
  const {
    models: { isSaving, isDeleting, processedColumns, form, initialValues },
    operations: { save, deleteRow },
  } = useEditableTableComponent({
    columns,
    rows,
    rowKey,
    selectedRowKey,
    onSaveRow,
    onCloseRow,
    onDeleteRow,
  });

  const renderExpandedRow = (record: T, index: number) => (
    <ExpandedRow>
      {
        // ensure layout is maintained even without expanded content
        expandedRowContent?.(record as T, index) || <div />
      }
      {selectedRowKey !== undefined &&
        record[rowKey] === selectedRowKey && ( // ensure nothing is pressed while unselected
          <ButtonBlockContainer>
            {deletable && (
              <ContextMenu
                onSelect={({ key }) => {
                  if (key === 'delete') void deleteRow();
                }}
                items={[{ key: 'delete', label: 'Delete' }]}
                loading={isDeleting}
                buttonLabel="Further actions"
              />
            )}
            <Button onClick={() => onCloseRow?.(record as T)} disabled={isSaving || isDeleting}>
              Cancel
            </Button>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldsError, getFieldsValue }) => (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isSaving}
                  disabled={
                    // disable save on deleting, field validation error, form validation failure
                    isDeleting ||
                    getFieldsError().some(({ errors }) => errors.length) ||
                    (validateForm && !validateForm(getFieldsValue()))
                  }
                >
                  {rowSaveLabel}
                </Button>
              )}
            </Form.Item>
          </ButtonBlockContainer>
        )}
    </ExpandedRow>
  );

  return (
    <Form
      form={form}
      onFinish={(data) => void save(data)}
      initialValues={initialValues}
      onValuesChange={(changed, values) => onValuesChange && onValuesChange(changed, values, form)}
    >
      <TableWrapper>
        <ListTable
          columns={processedColumns}
          rows={rows}
          rowKey={rowKey}
          selectedRowKeys={selectedRowKey ? [selectedRowKey] : undefined}
          expandedRowContent={renderExpandedRow}
          {...rest}
        />
      </TableWrapper>
    </Form>
  );
}
