import { OrderLineDTO, OrderCurrency } from '@invenco/common-interface/sales';
import { formatMoney, formatNumber } from 'shared/helpers';
import { getDiscountAmount } from '../utils';

export const getFormattedDiscount = (
  { discountRate, discountAmount }: Partial<OrderLineDTO>,
  currency: OrderCurrency | null = OrderCurrency.AUD,
) => {
  if (discountRate && discountRate > 0) return `${formatNumber(discountRate, 2)}%`;
  if (discountAmount) {
    return currency ? formatMoney(discountAmount, currency) : formatNumber(discountAmount, 2);
  }
  return '0%';
};

export const getDiscountInputValue = ({ discountRate, discountAmount }: Partial<OrderLineDTO>) => {
  if (discountRate && discountRate > 0) return `${discountRate}%`;
  return discountAmount?.toString() ?? '0%';
};

export const getDiscountFields = (
  formattedDiscount: string,
): Pick<OrderLineDTO, 'discountRate' | 'discountAmount'> => {
  let discountRate = 0;
  let discountAmount = 0;
  if (formattedDiscount) {
    const percentIndex = formattedDiscount.indexOf('%');
    if (percentIndex !== -1) {
      discountRate = +formattedDiscount.substring(0, formattedDiscount.indexOf('%'));
    } else {
      discountAmount = +formattedDiscount;
    }
  }
  return { discountRate, discountAmount };
};

export const getOrderLineFromFormData = ({
  description,
  qtyOrdered,
  unitPrice,
  discount,
}: Record<string, string>): Partial<OrderLineDTO> => ({
  description,
  qtyOrdered: qtyOrdered !== undefined ? +qtyOrdered : undefined,
  unitPrice: unitPrice !== undefined ? +unitPrice : undefined,
  ...getDiscountFields(discount),
});

export const getOrderLineTotal = (line: Partial<OrderLineDTO>) =>
  line.qtyOrdered && line.qtyOrdered > 0
    ? (line.qtyOrdered ?? 0) * (line.unitPrice ?? 0) - getDiscountAmount(line)
    : 0;
