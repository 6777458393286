import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { Label, LabelColor } from './Label';
import { EnumMap } from '../../shared/types';
import { toTitleCase } from '../../shared/helpers';

const StyledLabel = styled(Label)`
  font-size: 10px;
`;

export type StatusType =
  | 'success' // document has either completed or is functioning actively and needs no attention
  | 'progress' // document is waiting on a process, generally internal (e.g. a draft, waiting on the user, or pending, waiting on the Lynk system)
  | 'observe' // document is waiting on external process or partially complete (e.g. an open order, waiting on fulfillment)
  | 'alert' // document is in error or needs attention (e.g. a hold order)
  | 'inactive'; // document is stored but no active and needs no attention (e.g. cancelled or archived)

type Props = Omit<ComponentProps<typeof Label>, 'title' | 'type' | 'ref'> & {
  status?: string;
  type?: StatusType | Record<string, StatusType>;
  title?: string | Record<string, string>;
};

const COLOR_BY_TYPE: EnumMap<StatusType, LabelColor> = {
  success: 'green',
  progress: 'blue',
  observe: 'orange',
  alert: 'red',
  inactive: 'default',
};

export function StatusLabel({ status, type, title, children, ...props }: Props) {
  const finalType: StatusType =
    typeof type === 'string' ? type : (type && status && type[status]) || 'inactive';
  const finalTitle =
    typeof title === 'string'
      ? title
      : (title && status && title[status]) || (status && toTitleCase(status.replace('_', ' ')));
  return (
    <StyledLabel color={COLOR_BY_TYPE[finalType]} {...props}>
      {React.Children.count(children) ? children : finalTitle}
    </StyledLabel>
  );
}
