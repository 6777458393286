import { useQueryClient } from '@tanstack/react-query';
import { useGateways } from '../../../gateways/GatewayProvider';

/**
 * Search results from indivudal endpoints are called records. Each record is required to have certain
 * props so that it can be:
 * - Displayed in the search results
 * - Linked to a detail page
 */
export type GlobalSearchRecord = {
  label: string;
  url: string;
};

export type GlobalSearchResults = {
  orders: GlobalSearchRecord[];
  productSKUs: GlobalSearchRecord[];
  ASNs: GlobalSearchRecord[];
};

export const GLOBAL_SEARCH_REGEX = /.+/;

export function useGlobalSearch(resultCountPerQuery: number = 3) {
  const queryClient = useQueryClient();
  const { salesGateway, productsGateway, supplyGateway } = useGateways();

  /**
   * Accepts an input and queries multiple endpoints to return a list of records, grouped by the
   * type of query (Order, SKU, ASN).
   */
  const globalSearch = async (input: string): Promise<GlobalSearchResults> => {
    const match = input.trim().match(GLOBAL_SEARCH_REGEX);

    if (!match) {
      return { orders: [], productSKUs: [], ASNs: [] };
    }

    const searchTerm = match[0];

    try {
      const [orderResponse, productSkusResponse, asnsResponse] = await Promise.allSettled([
        queryClient.fetchQuery({
          queryKey: ['orders', { search: searchTerm, take: resultCountPerQuery }],
          queryFn: () => salesGateway.getOrders({ search: searchTerm, take: resultCountPerQuery }),
        }),
        queryClient.fetchQuery({
          queryKey: ['skus', { search: searchTerm, take: resultCountPerQuery }],
          queryFn: () => productsGateway.getSkus({ search: searchTerm, take: resultCountPerQuery }),
        }),
        queryClient.fetchQuery({
          queryKey: ['asns', { search: searchTerm, take: resultCountPerQuery }],
          queryFn: () => supplyGateway.getAsns({ search: searchTerm, take: resultCountPerQuery }),
        }),
      ]);

      const orders: GlobalSearchRecord[] = [];

      if (orderResponse.status === 'fulfilled') {
        orderResponse.value.items.forEach(({ customerName, name: orderName, id }) => {
          if (customerName && orderName && id) {
            orders.push({
              url: `/sales/orders/${id}`,
              label: `${orderName} - ${customerName}`,
            });
          }
        });
      }

      const productSKUs: GlobalSearchRecord[] = [];
      if (productSkusResponse.status === 'fulfilled') {
        productSkusResponse.value.items.forEach(({ name, id }) => {
          if (name && id) {
            productSKUs.push({
              url: `/products/skus/${id}`,
              label: `${name}`,
            });
          }
        });
      }

      const ASNs: GlobalSearchRecord[] = [];
      if (asnsResponse.status === 'fulfilled') {
        asnsResponse.value.items.forEach(({ name, id }) => {
          if (name && id) {
            ASNs.push({
              url: `/supply/asns/${id}`,
              label: `${name}`,
            });
          }
        });
      }

      return { orders, productSKUs, ASNs };
    } catch (error) {
      console.error('Error in globalSearch', error);
      // TODO - Need Datadog error push here because errors are suppressed to the user
      // but we will o11y on this
      return { orders: [], productSKUs: [], ASNs: [] };
    }
  };

  return { globalSearch };
}
