import { Card } from 'components/card';
import { Header } from 'components/header';
import { Label } from 'components/label';
import { Container } from 'styles/layout';
import { LookerContainer } from './styles';
import { useInsightDetailsPage } from './useInsightDetailsPage';

export function InsightDetails() {
  const {
    models: { insight, isLoading, breadcrumbs, embedRef },
  } = useInsightDetailsPage();
  return (
    <Container>
      <Header
        title={insight?.name}
        links={breadcrumbs}
        loading={isLoading}
        extraContent={<Label>{insight?.type}</Label>}
      />
      <Card fullHeight>
        <LookerContainer>
          <div ref={embedRef} />
        </LookerContainer>
      </Card>
    </Container>
  );
}
