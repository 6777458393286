export class Result<T = any> {
  public readonly isSuccess: boolean;
  public readonly isFailure: boolean;
  public readonly error?: string;
  public readonly value?: T;

  constructor(isSuccess: true, error: undefined, value?: T);
  constructor(isSuccess: false, error: string, value?: undefined);

  constructor(isSuccess: boolean, error?: string, value?: T) {
    if (isSuccess && error) {
      throw new Error('InvalidOperation: A result cannot be successful and contain an error');
    }
    if (!isSuccess && !error) {
      throw new Error('InvalidOperation: A failing result needs to contain an error message');
    }
    this.isSuccess = isSuccess;
    this.isFailure = !isSuccess;
    if (!isSuccess && error) {
      this.error = error;
    } else {
      this.error = undefined;
    }

    if (isSuccess && value !== undefined) {
      this.value = value;
    } else {
      this.value = undefined;
    }

    Object.freeze(this);
  }

  public getValue(): T {
    if (!this.isSuccess) {
      throw new Error("Can't get the value of an error result. Use 'errorValue' instead.");
    }
    if (this.value === undefined) {
      throw new Error('No value defined in result.');
    }

    return this.value;
  }

  public errorValue(): string {
    if (this.isSuccess) {
      throw new Error("Can't get the error of a value result. Use 'getValue' instead.");
    }
    if (this.error === undefined) {
      throw new Error('No error defined in result.');
    }
    return this.error;
  }

  static ok<U>(value?: U) {
    return new Result<U>(true, undefined, value);
  }

  static fail(error: string) {
    return new Result(false, error);
  }
}
