import { AsnDTO } from '@invenco/common-interface/supply';
import { Form } from 'components/form';
import { FormModal } from 'components/form-modal';
import { Input } from 'components/input';
import { Result } from 'shared/helpers/Result';
import { useNotesModalComponent } from './useNotesModalComponent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  note?: string | null;
  updateAsn: (data: Partial<AsnDTO>) => Promise<Result>;
};

export function NotesModal({ isOpen, note, updateAsn, onClose }: Props) {
  const {
    models: { form, isSaving },
    operations: { submit },
  } = useNotesModalComponent({ isOpen, onClose, updateAsn });
  return (
    <FormModal
      title="Notes"
      open={isOpen}
      onCancel={onClose}
      isSaving={isSaving}
      onSave={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => void submit(data)}
        initialValues={{ note }}
      >
        <Form.Item name="note">
          <Input.TextArea autoFocus rows={4} aria-label="Notes" />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
