import { ChannelDTO } from '@invenco/common-interface/sales';
import { LocationDTO } from '@invenco/common-interface/accounts';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useGateways } from '../../../../gateways/GatewayProvider';

export type ChannelWithLocationsDTO = ChannelDTO & { fulfillmentLocations: LocationDTO[] };

type Output = {
  channels: ChannelWithLocationsDTO[];
  isLoadingLocations: boolean;
};

export function useChannelsWithLocations(inputChannels?: ChannelDTO[]): Output {
  const { accountsGateway } = useGateways();

  const { data: locationsById, isLoading: isLoadingLocations } = useQuery({
    queryKey: ['locations'],
    queryFn: ({ signal }) => accountsGateway.getLocations(undefined, { signal }),
    select: ({ items }) => {
      const map = new Map<string, LocationDTO>();
      items.forEach((loc) => map.set(loc.id, loc));
      return map;
    },
  });

  const channels = useMemo(
    () =>
      inputChannels?.map((ch) => ({
        ...ch,
        fulfillmentLocations: ch.fulfillmentLocationIds
          .map((id) => locationsById?.get(id))
          .filter(Boolean) as LocationDTO[],
      })) ?? [],
    [inputChannels, locationsById],
  );

  return { channels, isLoadingLocations };
}
