import { AccountDTO } from '@invenco/common-interface/accounts';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { ComponentData, Page, Pagination } from '../../../../shared/types';
import { SortOption, SortSelection } from '../../../../components/data-table/types';
import { useDataTable, useRowNavigation } from '../../../../components/data-table';
import { useQueryWithInput } from '../../../../shared/hooks/queries';
import { AccountSortBy } from '../../../../gateways/impl/AxiosAccountsGateway';

type Models = {
  isLoading: boolean;
  hasError: boolean;
  rows?: AccountDTO[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  sort?: SortSelection;
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  changeSort: (sort: SortSelection) => void;
  refresh: () => void;
  onClickRow: (row: AccountDTO, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export const sortOptions: SortOption[] = [
  { key: 'createdAt', title: 'Created' },
  { key: 'name', title: 'Name' },
];

export function useAccountListPage(): ComponentData<Models, Operations> {
  const { accountsGateway } = useGateways();
  const [tableState, setTableState] = useDataTable({
    sortOptions,
    defaultSort: { key: 'createdAt', direction: 'desc' },
  });
  const { onClickRow } = useRowNavigation({ baseUrl: '/accounts' });

  const {
    data,
    isLoading,
    isError: hasError,
    refetch,
  } = useQueryWithInput({
    parentKey: 'accounts',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
      sortBy: tableState.sort?.key as AccountSortBy,
      sortDirection: tableState.sort?.direction,
    },
    query: (input, { signal }) => accountsGateway.getAccounts(input, { signal }),
  });

  return {
    models: {
      isLoading,
      hasError,
      rows: data?.items,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      sort: tableState.sort,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      changeSort: (sort) => setTableState({ sort, page: undefined }),
      refresh: refetch,
      onClickRow,
    },
  };
}
