import styled, { css } from 'styled-components';
import { autoScroll, spaceHorizontalChildren, spaceVerticalChildren } from './mixins';

/*
  Base layout:
  --- managed by Home ---
  <PageContainer>
    <Navigation />
    <RouteContainer>

      --- managed by container/page ---
      <Container>
        {headers}
        {page content}
      </Container>
      --- /container ---

    </RouteContainer>
  </PageContainer>
  --- /Home ---

  Page content for list page:
  <ListContainer>{content}</ListContainer>

  Page content for details page:
  <DetailContainer>
    <MainContainer>{main content}</MainContainer>
    <SideContainer>{side content}</SideContainer>
  </DetailContainer>
 */

const contentContainer = css<{ $scroll?: boolean }>`
  flex: 1;
  min-height: 0;
  ${({ $scroll }) => $scroll !== false && autoScroll()}
`;

export const PageContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

export const RouteContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  height: 100vh;
  padding: 16px 32px 16px 32px;
`;

export const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  ${spaceVerticalChildren()}
  display: flex;
  flex-direction: column;
`;

// rename if this is used for more than lists
export const ListContainer = styled.main<{ $scroll?: boolean }>`
  ${contentContainer}
  display: flex;
  flex-direction: column;
  ${spaceVerticalChildren()}
`;

// rename if two-columned layout is used for more than detail pages
export const DetailContainer = styled.div<{ $scroll?: boolean }>`
  ${contentContainer}
  display: flex;
  ${spaceHorizontalChildren()}
`;

export const MainContainer = styled.main<{ $scroll?: boolean }>`
  flex: 1;
  min-width: 0;
  ${({ $scroll }) => $scroll && autoScroll()}
  ${spaceVerticalChildren()}
`;

export const SideContainer = styled.aside<{ $scroll?: boolean }>`
  width: 280px;
  ${({ $scroll }) => $scroll && autoScroll()}
  ${spaceVerticalChildren()}
`;

export const HorizontallySpaced = styled.div<{ $factor?: number }>`
  display: flex;
  align-items: center;
  ${({ $factor = 1 }) => spaceHorizontalChildren($factor)}
`;

export const VerticallySpaced = styled.div<{ $factor?: number }>`
  ${({ $factor = 1 }) => spaceVerticalChildren($factor)}
`;

export const SpacedBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
