import { RefObject, useCallback, useEffect, useId, useRef, useState } from 'react';
import { InputRef } from 'antd';
import { ComponentData } from '../../shared/types';
import { useOptionallyControlledState } from '../../shared/hooks/useOptionallyControlledState';
import { useInfiniteScrollObserver } from '../../shared/hooks/useInfiniteScrollObserver';
import { SelectOption, SelectValue } from './types';

type Props = {
  multiple?: boolean;
  value: SelectValue;
  onChange: (value: SelectValue) => any;
  options?: SelectOption[];
  controlledValue?: SelectValue;
  onSearch?: (query: string) => any;
  canRequestMoreOptions?: boolean;
  onRequestMoreOptions?: () => any;
  disabled?: boolean;
  controlledOpen?: boolean;
  onOpenChange?: (open: boolean) => any;
  closeOnSelect?: boolean;
};

type Models = {
  query: string;
  open: boolean;
  showClearSelection: boolean;
  highlightedIndex?: number;
  listId: string;
  getOptionId: (index: number) => string;
  inputRef: RefObject<InputRef>;
  containerRef: RefObject<HTMLDivElement>;
};

type Operations = {
  setOpen: (open: boolean) => void;
  search: (query: string) => void;
  clearSearch: () => void;
  clearSelection: () => void;
  clickOption: (option: SelectOption, index: number) => void;
  removeHighlight: () => void;
};

export function usePopoverSelectComponent({
  multiple,
  options,
  value,
  onChange,
  onSearch,
  canRequestMoreOptions,
  onRequestMoreOptions,
  disabled,
  controlledOpen,
  onOpenChange,
  closeOnSelect,
}: Props): ComponentData<Models, Operations> {
  const listId = useId();
  const inputRef = useRef<InputRef>(null);

  const [highlightedIndex, setHighlightedIndex] = useState<number>();
  const [query, setQuery] = useState('');
  const [open, setOpen] = useOptionallyControlledState({
    default: false,
    controlled: controlledOpen,
    setControlled: onOpenChange,
  });

  const getOptionId = useCallback((index: number) => `${listId}-option-${index}`, [listId]);

  const { containerRef } = useInfiniteScrollObserver({
    canRequest: canRequestMoreOptions,
    onRequest: onRequestMoreOptions,
    lastElementId: options?.length ? getOptionId(options.length - 1) : undefined,
    updatedValue: options,
  });

  useEffect(() => {
    if (open) {
      // without setTimeout the trigger takes the focus back immediately
      // without preventScroll it scrolls to top of page
      setTimeout(() => inputRef.current?.focus({ preventScroll: true }));
    } else {
      setHighlightedIndex(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (highlightedIndex !== undefined) {
      const element = document.getElementById(getOptionId(highlightedIndex));
      element?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }, [highlightedIndex, getOptionId]);

  const handleSelection = useCallback(
    (option: SelectOption) => {
      if (!multiple) {
        onChange(option.value);
      } else if (value instanceof Set) {
        const newValue = new Set(value);
        if (newValue.has(option.value)) {
          newValue.delete(option.value);
        } else {
          newValue.add(option.value);
        }
        onChange(newValue);
      } else if (value === null) {
        onChange(new Set([option.value]));
      } else {
        onChange(new Set([value, option.value]));
      }

      if (closeOnSelect) {
        setOpen(false);
      }
    },
    [multiple, value, onChange, setOpen, closeOnSelect],
  );

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (options?.length) {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          setHighlightedIndex((prev) =>
            prev === undefined || prev >= options.length - 1 ? 0 : prev + 1,
          );
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setHighlightedIndex((prev) =>
            prev === undefined || prev <= 0 ? options.length - 1 : prev - 1,
          );
        }
        if (e.key === 'Enter') {
          // don't make highlightedIndex a dependency
          setHighlightedIndex((current) => {
            if (current !== undefined) {
              e.preventDefault();
              handleSelection(options[current]);
            }
            return current;
          });
        }
      }
      if (e.key === 'Escape') {
        setOpen(false);
      }
    };

    if (open) document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [handleSelection, options, open, setOpen]);

  const search = (newQuery: string) => {
    setQuery(newQuery);
    onSearch?.(newQuery);
    setHighlightedIndex(undefined);
  };

  const clearSearch = () => search(''); // update parent clear search
  const clearSelection = () => onChange(null);

  const clickOption = (option: SelectOption, index: number) => {
    if (disabled) return;
    handleSelection(option);
    setHighlightedIndex(index);
  };

  const removeHighlight = () => {
    setHighlightedIndex(undefined);
  };

  return {
    models: {
      showClearSelection: !disabled && !!multiple && value instanceof Set && value.size > 0,
      open,
      query,
      highlightedIndex,
      listId,
      getOptionId,
      inputRef,
      containerRef,
    },
    operations: {
      setOpen,
      search,
      clearSearch,
      clearSelection,
      clickOption,
      removeHighlight,
    },
  };
}
