import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { ComponentData, InsightDTO } from '../../../../shared/types';
import { useRowNavigation } from '../../../../components/data-table';

export type InsightRow = Omit<InsightDTO, 'id'> & { id: string };

type Models = {
  isLoading: boolean;
  reports: InsightRow[];
  dashboards: InsightRow[];
};

type Operations = {
  onClickRow: (row: InsightRow, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export function useInsightListPage(): ComponentData<Models, Operations> {
  const { analyticsGateway } = useGateways();
  const { onClickRow } = useRowNavigation<InsightRow>({ baseUrl: '/analytics/insights' });

  const { data: insights, isLoading } = useQuery({
    queryKey: ['insights'],
    queryFn: ({ signal }) => analyticsGateway.getInsights({ signal }),
    select: ({ items }) => items,
  });

  const reports = useMemo<InsightRow[]>(
    () => (insights?.filter((item) => item.type === 'report') as InsightRow[]) ?? [],
    [insights],
  );

  const dashboards = useMemo<InsightRow[]>(
    () => (insights?.filter((item) => item.type === 'dashboard') as InsightRow[]) ?? [],
    [insights],
  );

  return {
    models: { isLoading, reports, dashboards },
    operations: { onClickRow },
  };
}
