import { ShippingPriceBandDTO } from '@invenco/common-interface/shipping';
import { useEffect, useMemo, useState } from 'react';
import Decimal from 'decimal.js';
import { ComponentData } from '../../../../../shared/types';

type Props = {
  priceBands: ShippingPriceBandDTO[];
  addBand: (band: ShippingPriceBandDTO) => void;
  updateBand: (index: number, band: Partial<ShippingPriceBandDTO>) => void;
};

type Models = {
  editingIndex?: number;
  isAddingBand: boolean;
  examplePrice: number;
  exampleMarkups: number[];
  exampleTotalMarkup: number;
};

type Operations = {
  openAddForm: () => void;
  cancelAdding: () => void;
  saveNewBand: (band: ShippingPriceBandDTO) => void;
  editBand: (index: number) => void;
  saveBand: (index: number, band: ShippingPriceBandDTO) => void;
  cancelEditing: () => void;
};

export function usePriceBands({
  priceBands,
  addBand,
  updateBand,
}: Props): ComponentData<Models, Operations> {
  const [editingIndex, setEditingIndex] = useState<number>();
  const [isAddingBand, setIsAddingBand] = useState(false);

  // example price will be the highest max price, rounded up to the next power of 10
  const examplePrice = useMemo(() => {
    const maxCost = Math.max(...priceBands.map((b) => b.maxCost ?? 0));
    if (maxCost > 0) {
      return 10 ** Math.ceil(Math.log10(maxCost));
    }
    return 100;
  }, [priceBands]);

  const exampleMarkups = useMemo(
    () =>
      priceBands.map((band, index) => {
        let bandAmount = Math.min(band.maxCost || examplePrice, examplePrice);
        if (index > 0) {
          bandAmount = Math.max(bandAmount - (priceBands[index - 1].maxCost ?? 0), 0);
        }
        // to prevent floating point errors - not as critical here, but we have the library available
        return new Decimal(bandAmount).mul(band.markup).toDP(2).toNumber();
      }),
    [priceBands, examplePrice],
  );
  const exampleTotalMarkup = useMemo(
    () => exampleMarkups.reduce((a, b) => a + b, 0),
    [exampleMarkups],
  );

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsAddingBand(false);
        setEditingIndex(undefined);
      }
    };
    document.addEventListener('keyup', listener);
    return () => document.removeEventListener('keyup', listener);
  }, []);

  const openAddForm = () => {
    setEditingIndex(undefined);
    setIsAddingBand(true);
  };

  const cancelAdding = () => {
    setIsAddingBand(false);
  };

  const saveNewBand = (band: ShippingPriceBandDTO) => {
    setIsAddingBand(false);
    addBand(band);
  };

  const saveBand = (index: number, band: ShippingPriceBandDTO) => {
    updateBand(index, band);
    setEditingIndex(undefined);
  };

  const editBand = (index: number) => {
    setIsAddingBand(false);
    setEditingIndex(index);
  };

  const cancelEditing = () => {
    setEditingIndex(undefined);
  };

  return {
    models: { editingIndex, isAddingBand, examplePrice, exampleMarkups, exampleTotalMarkup },
    operations: {
      openAddForm,
      cancelAdding,
      saveNewBand,
      editBand,
      saveBand,
      cancelEditing,
    },
  };
}
