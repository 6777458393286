import { Divider } from 'antd';
import styled from 'styled-components';
import { visuallyHidden } from 'styles/mixins';

export const ConditionInputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr auto;
  gap: 8px;
  align-items: flex-start;

  @media (max-width: 1280px) {
    grid-template-columns: 1fr auto;
    > *:not(:last-child) {
      grid-column: 1;
    }
    > *:last-child {
      grid-row: 1;
      grid-column: 2;
    }
  }

  // Hide form labels, while still keeping them visible for screen readers
  .ant-form-item-label {
    ${visuallyHidden}
  }

  .ant-input-number {
    width: 100%;
  }

  > *:last-child {
    margin-top: 4px; // align delete button with inputs better
  }
`;

export const SectionDivider = styled(Divider).attrs({ dashed: true })`
  && {
    margin-top: 0;
    border-color: var(--gray-5);
  }

  .ant-divider-inner-text {
    color: var(--gray-9);
    font-size: 12px;
    font-weight: 600;
    padding: 0 16px;
  }
`;
