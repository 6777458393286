import type {
  AccountDTO,
  CreateAccountDTO,
  MembershipDTO,
  ServiceAgreementDTO,
} from '@invenco/common-interface/accounts';
import { AccessContext } from '@invenco/common-interface/auth';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { ComponentData } from '../../../../shared/types';
import { CreateWarehouseTenantDTO } from '../../../../gateways/types';
import { usePeriodicCall } from '../../../../shared/hooks/usePeriodicCall';
import { useMessages } from '../../../../shared/providers/MessagesProvider';
import { BreadCrumb } from '../../../../components/header';
import { useEntityDetailsQuery, useGatewayMutation } from '../../../../shared/hooks/queries';
import { Result } from '../../../../shared/helpers/Result';
import { useCurrentUserQuery } from '../../../../shared/hooks/queries/common';
import { GatewayMutation } from '../../../../shared/hooks/queries/useGatewayMutation';

type Models = {
  isNew: boolean;
  isLoading: boolean;
  isCreateAccountMutationPending: boolean;
  isUpdateAccountMutationPending: boolean;
  isDetailsModalOpen: boolean;
  isAddUserModalOpen: boolean;
  isTenantModalOpen: boolean;
  isServiceAgreementModalOpen: boolean;
  isAddingUser: boolean;
  isAddingServiceAgreement: boolean;
  isUpdatingServiceAgreement: boolean;
  isTerminatingServiceAgreement: boolean;
  isTerminate: boolean;
  canSave: boolean;
  canUpdate: boolean;
  deletingMembershipId?: string;
  breadcrumbs: BreadCrumb[];
  account: Partial<AccountDTO>;
  selectedServiceAgreement: Partial<ServiceAgreementDTO>;
};

type Operations = {
  openDetailsModal: () => void;
  closeDetailsModal: () => void;
  openAddUserModal: () => void;
  closeAddUserModal: () => void;
  openTenantModal: () => void;
  closeTenantModal: () => void;
  refresh: () => Promise<void>;
  openServiceAgreementModal: () => void;
  closeServiceAgreementModal: () => void;
  updateAccount: (data: Partial<AccountDTO>) => void;
  createAccountMutation: () => Promise<Result>;
  updateAccountMutation: GatewayMutation<AccountDTO, void>;
  addUser: (membership: Partial<MembershipDTO>) => Promise<Result>;
  addServiceAgreement: (serviceAgreement: Partial<ServiceAgreementDTO>) => Promise<Result>;
  updateServiceAgreement: (serviceAgreement: Partial<ServiceAgreementDTO>) => Promise<Result>;
  deleteMembership: (membershipId: string) => Promise<Result>;
  resendInvite: (userId: string) => Promise<Result>;
  editServiceAgreement: (id: string) => void;
  terminate: (id: string) => void;
  terminateServiceAgreement: (serviceAgreement: Partial<ServiceAgreementDTO>) => Promise<Result>;
  addToWarehouse: (data: {
    warehouseId: string;
    tenant: CreateWarehouseTenantDTO;
  }) => Promise<Result>;
};

export function useAccountDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new';
  const { accountsGateway, warehousingGateway } = useGateways();
  const navigate = useNavigate();
  const messages = useMessages();

  const [account, setAccount] = useState<Partial<AccountDTO>>({});
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(isNew);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isTenantModalOpen, setIsTenantModalOpen] = useState(false);
  const [selectedServiceAgreement, setSelectedServiceAgreement] = useState<
    Partial<ServiceAgreementDTO>
  >({});
  const [isServiceAgreementModalOpen, setIsServiceAgreementModalOpen] = useState(false);
  const [deletingMembershipId, setDeletingMembershipId] = useState<string>();
  const [isTerminate, setIsTerminate] = useState<boolean>(false);

  const { data, isLoading, refetch } = useEntityDetailsQuery({
    parentKey: 'accounts',
    id,
    query: (fetchId, { signal }) => accountsGateway.getAccount(fetchId, { signal }),
    isNew,
  });

  const { data: currentUser, refetch: refetchUser } = useCurrentUserQuery();

  const existingName = data?.name;
  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '/accounts', title: 'Accounts' },
      { url: `/accounts/${id}`, title: existingName || 'New', loading: isLoading },
    ],
    [id, existingName, isLoading],
  );

  const canSave = Boolean(
    isNew &&
      account.name &&
      account.code &&
      account.primaryName &&
      account.primaryEmail &&
      account.primaryPhone,
  );

  const canUpdate = Boolean(
    id &&
      !isNew &&
      account.name &&
      account.code &&
      account.primaryName &&
      account.primaryEmail &&
      account.primaryPhone &&
      (account.externalId || account.externalId === null),
  );

  const [triggerPeriodicRefresh, stopRefresh] = usePeriodicCall(
    refetch,
    { maximumCalls: 5, period: 1000 },
    [id, accountsGateway],
  );

  useEffect(() => {
    if (data) setAccount(data);
  }, [data]);

  useEffect(() => stopRefresh, [id, stopRefresh]);

  const updateAccount = (updateData: Partial<AccountDTO>) => {
    setAccount({ ...account, ...updateData });
  };

  const refresh = async () => {
    await refetch();
  };

  const { mutate: createAccountMutation, isPending: isCreateAccountMutationPending } =
    useGatewayMutation({
      mutationFn: () => accountsGateway.createAccount(account as CreateAccountDTO),
      onSuccess: (newAccount) => {
        messages.success('Account created', { name: newAccount.name });
        navigate(`/accounts/${newAccount.id}`);
      },
      linkedQuery: ['accounts', id],
    });

  const { mutate: updateAccountMutation, isPending: isUpdateAccountMutationPending } =
    useGatewayMutation({
      mutationFn: () =>
        accountsGateway.updateAccount(id!, {
          name: account.name,
          externalId: account.externalId,
          primaryName: account.primaryName,
          primaryEmail: account.primaryEmail,
          primaryPhone: account.primaryPhone,
        }),
      onSuccess: (updatedAccount) => {
        messages.success('Account updated', { name: updatedAccount.name });
      },
      linkedQuery: ['accounts', id],
    });

  const { mutate: addUser, isPending: isAddingUser } = useGatewayMutation({
    mutationFn: (membership: Partial<MembershipDTO>) =>
      accountsGateway.createMembership(id!, {
        ...membership,
        accessContext: AccessContext.SELLER,
      }),
    onSuccess: ({ userId }) => {
      if (currentUser?.id === userId) {
        void refetchUser();
      }
    },
    linkedQuery: ['accounts', id],
    linkResponseToQuery: false,
    successMessage: 'Added user to account',
  });

  const { mutate: addServiceAgreement, isPending: isAddingServiceAgreement } = useGatewayMutation({
    mutationFn: (val: Partial<ServiceAgreementDTO>) =>
      accountsGateway.createServiceAgreement({ ...val, accountId: id! }),
    onSuccess: (serviceAgreement) => {
      messages.success('Service agreement created', {
        name: serviceAgreement.name,
      });
    },
    linkedQuery: ['accounts', id],
    linkResponseToQuery: false,
  });

  const { mutate: updateServiceAgreement, isPending: isUpdatingServiceAgreement } =
    useGatewayMutation({
      mutationFn: (val: Partial<ServiceAgreementDTO>) =>
        accountsGateway.setServiceAgreementCommencement(selectedServiceAgreement.id!, {
          commencementDate: val.commencementDate!,
        }),
      onSuccess: (serviceAgreement) => {
        messages.success('Service agreement updated', {
          name: serviceAgreement.name,
        });
      },
      linkedQuery: ['accounts', id],
      linkResponseToQuery: false,
    });

  const { mutate: terminateServiceAgreement, isPending: isTerminatingServiceAgreement } =
    useGatewayMutation({
      mutationFn: (val: Partial<ServiceAgreementDTO>) =>
        accountsGateway.terminateServiceAgreement(selectedServiceAgreement.id!, {
          terminationDate: val.terminationDate!,
        }),
      onSuccess: (terminatedServiceAgreement) => {
        messages.success('Service agreement terminated successfully', {
          name: terminatedServiceAgreement.name,
        });
      },
      linkedQuery: ['accounts', id],
      linkResponseToQuery: false,
    });

  const { mutate: deleteMembership } = useGatewayMutation({
    mutationFn: (membershipId: string) => accountsGateway.deleteMembership(id!, membershipId),
    onMutate: (membershipId: string) => setDeletingMembershipId(membershipId),
    onSuccess: (_, membershipId) => {
      if (currentUser?.memberships?.some((m) => m.id === membershipId)) {
        void refetchUser();
      }
    },
    onSettled: () => setDeletingMembershipId(undefined),
    linkedQuery: ['accounts', id],
    linkResponseToQuery: false,
    successMessage: 'Removed user from account',
  });

  const { mutate: resendInvite } = useGatewayMutation({
    mutationFn: (userId: string) => accountsGateway.resendInvite(userId!),
    linkedQuery: ['accounts', id],
    linkResponseToQuery: false,
    successMessage: 'Resent email successfully',
  });

  const { mutate: addToWarehouse } = useGatewayMutation({
    onMutate: stopRefresh,
    mutationFn: ({
      warehouseId,
      tenant,
    }: {
      warehouseId: string;
      tenant: CreateWarehouseTenantDTO;
    }) => warehousingGateway.createWarehouseTenant(warehouseId, tenant),
    onSuccess: (tenant) => {
      triggerPeriodicRefresh();
      messages.success(`Added account to ${tenant.warehouse?.name ?? 'warehouse'}`);
    },
    linkedQuery: ['locations'],
    linkResponseToQuery: false,
  });

  const openServiceAgreement = (serviceAgreementId) => {
    const serviceAgreement = account.serviceAgreements?.find((sa) => sa.id === serviceAgreementId);

    if (serviceAgreement) {
      setSelectedServiceAgreement(serviceAgreement as ServiceAgreementDTO);
      setIsServiceAgreementModalOpen(true);
    }
  };

  const editServiceAgreement = (serviceAgreementId: string) => {
    openServiceAgreement(serviceAgreementId);
  };

  const terminate = (serviceAgreementId: string) => {
    openServiceAgreement(serviceAgreementId);
    setIsTerminate(true);
  };

  const closeServiceAgreementModal = () => {
    setSelectedServiceAgreement({});
    setIsTerminate(false);
    setIsServiceAgreementModalOpen(false);
  };

  return {
    models: {
      isNew,
      isLoading,
      isCreateAccountMutationPending,
      isUpdateAccountMutationPending,
      isAddingUser,
      isDetailsModalOpen,
      isAddUserModalOpen,
      isTenantModalOpen,
      isServiceAgreementModalOpen,
      isAddingServiceAgreement,
      isUpdatingServiceAgreement,
      isTerminatingServiceAgreement,
      canSave,
      canUpdate,
      deletingMembershipId,
      breadcrumbs,
      account,
      selectedServiceAgreement,
      isTerminate,
    },
    operations: {
      openDetailsModal: () => setIsDetailsModalOpen(true),
      closeDetailsModal: () => setIsDetailsModalOpen(false),
      openAddUserModal: () => setIsAddUserModalOpen(true),
      closeAddUserModal: () => setIsAddUserModalOpen(false),
      openTenantModal: () => setIsTenantModalOpen(true),
      closeTenantModal: () => setIsTenantModalOpen(false),
      openServiceAgreementModal: () => setIsServiceAgreementModalOpen(true),
      closeServiceAgreementModal,
      refresh,
      updateAccount,
      createAccountMutation,
      updateAccountMutation,
      addUser,
      deleteMembership,
      addToWarehouse,
      resendInvite,
      addServiceAgreement,
      updateServiceAgreement,
      terminateServiceAgreement,
      editServiceAgreement,
      terminate,
    },
  };
}
