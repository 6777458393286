import { LocationType } from '@invenco/common-interface/accounts';

import { AddressDisplay } from 'components/address-display';
import { Card } from 'components/card';
import { DetailList } from 'components/detail-list/DetailList';
import { DetailsBlock } from 'components/details-block';
import { Header } from 'components/header';
import { Label, StatusLabel } from 'components/label';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'shared/helpers';
import { shippingMethodStatusType } from 'shared/status-maps';
import { Container, DetailContainer, MainContainer, SideContainer } from 'styles/layout';
import { Button } from 'components/button';
import { ManagedShippingMethodModal } from './managed-shipping-method-modal/ManagedShippingMethodModal';
import { useLocationDetailsPage } from './useLocationDetailsPage';
import { WarehouseShippingMethodModal } from './warehouse-shipping-method-modal/WarehouseShippingMethodModal';
import { DataTable } from '../../../../components/data-table';

export function LocationDetails() {
  const {
    models: {
      isLoading,
      isLoadingShippingMethods,
      isLoadingWarehouse,
      isLoadingManagedLocations,
      isShippingMethodModalOpen,
      location,
      shippingMethods,
      warehouse,
      managedLocations,
      breadcrumbs,
    },
    operations: {
      openShippingMethodModal,
      closeShippingMethodModal,
      refresh,
      addWarehouseShippingMethod,
      addManagedShippingMethod,
      onClickManagedLocation,
    },
  } = useLocationDetailsPage();

  return (
    <Container>
      <Header
        title={location.name}
        extraContent={<Label>{location.type}</Label>}
        links={breadcrumbs}
        backURL="/locations"
        loading={isLoading}
        onRefresh={refresh}
      />
      <DetailContainer>
        <MainContainer>
          {managedLocations && (
            <Card title="Managed Locations" count={managedLocations.length}>
              <DataTable
                simple
                columns={[
                  { title: 'Account', key: 'accountName' },
                  { title: 'Name', key: 'name' },
                  {
                    title: 'Created',
                    key: 'createdAt',
                    render: (createdAt) => formatDateTime(createdAt),
                  },
                ]}
                rows={managedLocations}
                loading={isLoadingManagedLocations}
                onClickRow={onClickManagedLocation}
              />
            </Card>
          )}
          <Card
            title="Shipping Methods"
            count={shippingMethods.length}
            rightContent={
              location.type &&
              [LocationType.WAREHOUSE, LocationType.MANAGED].includes(location.type) && (
                <Button type="link" inline onClick={openShippingMethodModal}>
                  Add shipping method
                </Button>
              )
            }
          >
            <DataTable
              simple
              columns={[
                { title: 'Name', key: 'name' },
                { title: 'Carrier', key: 'carrierName' },
                { title: 'Service', key: 'carrierServiceName' },
                {
                  title: 'Status',
                  key: 'status',
                  render: (status) => (
                    <StatusLabel status={status} type={shippingMethodStatusType} />
                  ),
                },
              ]}
              rows={shippingMethods}
              loading={isLoadingShippingMethods}
            />
          </Card>
        </MainContainer>
        <SideContainer>
          <Card title="Details">
            <DetailsBlock contentTitle={location.name} loading={isLoading} loadingRows={3}>
              <DetailList
                items={[
                  { label: 'Type', value: <Label>{location.type}</Label> },
                  {
                    label: 'Account',
                    value: (
                      <Link to={`/accounts/${location.accountId}`}>{location.accountName}</Link>
                    ),
                  },
                  ...(location.type === LocationType.MANAGED
                    ? [
                        {
                          label: 'Warehouse Location',
                          value: (
                            <Link to={`/locations/${location.warehouseLocationId}`}>
                              {location.warehouseLocationName}
                            </Link>
                          ),
                        },
                      ]
                    : []),
                ]}
              />
            </DetailsBlock>
          </Card>

          {location.address && (
            <Card title="Address">
              <AddressDisplay loading={isLoading} address={location.address} />
            </Card>
          )}

          {location.type === LocationType.WAREHOUSE && (
            <Card title="Warehouse">
              <DetailsBlock
                contentTitle={warehouse?.name}
                loading={isLoadingWarehouse}
                loadingRows={4}
              >
                {warehouse ? (
                  <DetailList
                    items={[
                      { label: 'Code', value: warehouse.code },
                      { label: 'WMS', value: warehouse.wmsInstanceCode },
                      { label: 'Timezone', value: warehouse.timezone },
                    ]}
                  />
                ) : (
                  <div>No warehouse matched</div>
                )}
              </DetailsBlock>
            </Card>
          )}
        </SideContainer>
      </DetailContainer>

      {location.type === LocationType.WAREHOUSE && (
        <WarehouseShippingMethodModal
          isOpen={isShippingMethodModalOpen}
          onClose={closeShippingMethodModal}
          existingShippingMethods={shippingMethods}
          addShippingMethod={addWarehouseShippingMethod}
          accountId={location.accountId!}
        />
      )}
      {location.type === LocationType.MANAGED && location.warehouseLocationId && (
        <ManagedShippingMethodModal
          isOpen={isShippingMethodModalOpen}
          onClose={closeShippingMethodModal}
          existingShippingMethods={shippingMethods}
          warehouseLocationId={location.warehouseLocationId}
          addShippingMethod={addManagedShippingMethod}
        />
      )}
    </Container>
  );
}
