import { Card } from 'components/card';
import { Header } from 'components/header';
import { Label, StatusLabel } from 'components/label';
import { formatNumber } from 'shared/helpers';
import { locationInventoryStatusType } from 'shared/status-maps';
import { locationInventoryStatusTitle } from 'shared/title-maps';
import { Container, ListContainer } from 'styles/layout';
import { DataTable, Column } from 'components/data-table';
import { useInventoryQuantitiesPage, InventoryRow } from './useInventoryQuantitiesPage';

const columns: Column<InventoryRow>[] = [
  {
    title: 'SKU / Description',
    key: 'skuDescription',
    render: (skuDescription, row) => (
      <>
        <Label normalCase>{row.skuName}</Label> {skuDescription}
      </>
    ),
  },
  { title: 'Location', key: 'locationName' },
  {
    title: 'Status',
    key: 'status',
    render: (status) => (
      <StatusLabel
        status={status}
        type={locationInventoryStatusType}
        title={locationInventoryStatusTitle}
      />
    ),
  },
  { title: 'Expected', key: 'qtyExpected', align: 'right', render: (qty) => formatNumber(qty) },
  { title: 'On Hand', key: 'qtyOnHand', align: 'right', render: (qty) => formatNumber(qty) },
  { title: 'Reserved', key: 'qtyReserved', align: 'right', render: (qty) => formatNumber(qty) },
  { title: 'Available', key: 'qtyAvailable', align: 'right', render: (qty) => formatNumber(qty) },
];

export function InventoryQuantities() {
  const {
    models: { isLoading, hasError, rows, pagination, total, query, filters, filterValues },
    operations: { search, navigate, refresh, updateFilters },
  } = useInventoryQuantitiesPage();

  return (
    <Container>
      <Header
        links={[
          { url: '/inventory', title: 'Inventory' },
          { url: '/inventory/quantities', title: 'Quantities' },
        ]}
        title="Inventory Quantities"
      />
      <ListContainer>
        <Card>
          <DataTable.SearchPanel
            loading={isLoading}
            searchPlaceholderText="Search inventory..."
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
            filters={filters}
            filterSelections={filterValues}
            onChangeFilterSelections={updateFilters}
          />

          <DataTable rows={rows} columns={columns} loading={isLoading} hasError={hasError} />
        </Card>
      </ListContainer>
    </Container>
  );
}
