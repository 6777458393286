import { useMemo } from 'react';
import { LocationInventoryDTO } from '@invenco/common-interface/inventory';
import { Filter, FilterSelections, useDataTable } from 'components/data-table';
import { useGateways } from 'gateways/GatewayProvider';
import { useAutoCompleteQuery, useQueryWithInput } from 'shared/hooks/queries';
import { ComponentData, Page, Pagination } from 'shared/types';
import { HorizontallySpaced } from 'styles/layout';
import { Label } from 'components/label';
import { useQuery } from '@tanstack/react-query';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';

export type InventoryRow = LocationInventoryDTO;

type FilterKey = 'skuIds' | 'locationIds';

type Models = {
  isLoading: boolean;
  hasError: boolean;
  rows?: InventoryRow[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  view?: string;
  filterValues?: FilterSelections<FilterKey>;
  filters: Filter<FilterKey>[];
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  changeView: (view: string) => void;
  refresh: () => void;
  onImportComplete: () => void;
  updateFilters: (filters: FilterSelections<FilterKey>) => void;
};

export function useInventoryQuantitiesPage(): ComponentData<Models, Operations> {
  const { accountsGateway, inventoryGateway, productsGateway } = useGateways();
  const [tableState, setTableState, { getMultiFilterValues }] = useDataTable({
    multiFilterKeys: ['skuIds', 'locationIds'],
  });

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'inventory',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
      skuIds: getMultiFilterValues('skuIds'),
      locationIds: getMultiFilterValues('locationIds'),
    },
    query: (input, { signal }) => inventoryGateway.getInventories(input, { signal }),
  });

  const {
    options: skus,
    onSearch: onSkuSearch,
    isLoading: isLoadingSkus,
  } = useAutoCompleteQuery({
    parentKey: 'skus',
    query: (input, { signal }) => productsGateway.getSkus(input, { signal }),
  });

  const { data: locations, isLoading: isLoadingLocations } = useQuery({
    queryKey: ['locations'],
    queryFn: ({ signal }) => accountsGateway.getLocations(undefined, { signal }),
    select: ({ items }) => items,
  });

  const skuFilter = useMemo<Filter<FilterKey>>(
    () => ({
      key: 'skuIds',
      title: 'SKUs',
      loading: isLoadingSkus,
      options:
        skus.map((sku) => ({
          value: sku.id,
          title: (
            <HorizontallySpaced $factor={0.5}>
              <Label normalCase>{sku.name}</Label>
              <span>{sku.description}</span>
            </HorizontallySpaced>
          ),
          stringTitle: sku.name,
        })) || [],
      onSearch: onSkuSearch,
      multiple: true,
    }),
    [skus, isLoadingSkus, onSkuSearch],
  );

  const locationFilter = useMemo<Filter<FilterKey>>(
    () => ({
      key: 'locationIds',
      title: 'Locations',
      loading: isLoadingLocations,
      options: locations?.map((loc) => ({ value: loc.id, title: loc.name })),
      multiple: true,
    }),
    [locations, isLoadingLocations],
  );

  return {
    models: {
      isLoading,
      hasError: isError,
      rows: data?.items as InventoryRow[] | undefined,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      view: tableState.view,
      filterValues: tableState.filters,
      filters: [skuFilter, locationFilter],
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      changeView: (view) => setTableState({ view, page: undefined, filters: undefined }),
      refresh: refetch,
      onImportComplete: refetch,
      updateFilters: (updated: FilterSelections<FilterKey>) =>
        setTableState({ filters: updated, view: undefined, page: undefined }),
    },
  };
}
