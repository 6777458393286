import { WarehouseTenantDTO } from '@invenco/common-interface/warehousing';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { ComponentData, Page, Pagination } from '../../../../shared/types';
import { useDataTable, useRowNavigation } from '../../../../components/data-table';
import { useQueryWithInput } from '../../../../shared/hooks/queries';

type Models = {
  isLoading: boolean;
  hasError: boolean;
  rows?: WarehouseTenantDTO[];
  pagination?: Pagination;
  total?: number;
  query?: string;
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  refresh: () => void;
  onClickRow: (row: WarehouseTenantDTO, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export function useTenantListPage(): ComponentData<Models, Operations> {
  const { warehousingGateway } = useGateways();
  const [tableState, setTableState] = useDataTable();
  const { onClickRow } = useRowNavigation({ baseUrl: '/tenants' });

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'warehouseTenants',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
      include: { warehouse: true, tenant: true },
    },
    query: (input, { signal }) => warehousingGateway.getWarehouseTenants(input, { signal }),
  });

  return {
    models: {
      isLoading,
      hasError: isError,
      rows: data?.items,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      refresh: refetch,
      onClickRow,
    },
  };
}
