import React from 'react';
import styled from 'styled-components';
import { Button as AntButton, ButtonProps } from 'antd';

type Props = ButtonProps & {
  /** Whether the button is placed inline. If true, padding is removed. */
  inline?: boolean;
};

// Default to round shape unless an icon-only button
// Also adds inline prop to remove padding for a link button
export const Button = styled(
  React.forwardRef<HTMLElement>(({ inline: _, shape, ...props }: Props, ref) => (
    <AntButton
      ref={ref}
      shape={shape || (props.icon && !props.children ? 'default' : 'round')}
      {...props}
    />
  )),
)<Props>`
  &.ant-btn-round {
    padding: ${({ inline }) => (inline ? '0' : '4px 16px')};
    &.ant-btn-sm {
      padding: ${({ inline }) => (inline ? '0' : '1px 8px')};
    }
  }
  &.ant-btn-icon-only {
    border-radius: 4px;
  }
`;
