import { Container, ListContainer } from '../../../styles/layout';
import { Header } from '../../../components/header';
import { Card } from '../../../components/card';
import { Form } from '../../../components/form';
import { toTitleCase } from '../../../shared/helpers';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { ButtonRow, ConfigureRow } from './styles';
import { useDeveloperConfigPage } from './useDeveloperConfigPage';

export function DeveloperConfig() {
  const {
    models: { isConfiguringUrls, ticketForm, urlForm, baseUrls },
    operations: { configureForTicket, updateUrls, resetUrls },
  } = useDeveloperConfigPage();

  return (
    <Container>
      <Header title="Developer Config" />
      <ListContainer>
        <Card
          title="API Base URLs"
          rightContent={
            <Form
              layout="horizontal"
              name="ticket"
              form={ticketForm}
              onFinish={(data) => void configureForTicket(data.branch)}
              hideErrors
              initialValues={{ branch: 'lynk-' }}
            >
              <ConfigureRow>
                <Form.Item name="branch" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Button htmlType="submit" type="primary" loading={isConfiguringUrls}>
                  Configure for Ticket
                </Button>
              </ConfigureRow>
            </Form>
          }
        >
          <Form
            layout="vertical"
            name="baseUrls"
            form={urlForm}
            onFinish={(data) => updateUrls(data)}
            initialValues={baseUrls}
          >
            {Object.keys(baseUrls).map((key) => (
              <Form.Item key={key} name={key} label={toTitleCase(key)} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            ))}

            <ButtonRow>
              <Button htmlType="button" onClick={resetUrls}>
                Reset
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </ButtonRow>
          </Form>
        </Card>
      </ListContainer>
    </Container>
  );
}
