import { OrderLineDTO, OrderLineStatus, ChannelDTO } from '@invenco/common-interface/sales';

import { LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { formatNumber } from 'shared/helpers';
import { ActionLink } from 'components/button';
import { HorizontallySpaced } from '../../../../../../styles/layout';
import { useOrderLineDetailsComponent } from './useOrderLineDetailsComponent';
import { LineDetailsContainer, LineStatus } from './styles';
import { orderLineStatusTitle } from '../../../../../../shared/title-maps';

type Props = {
  line: Partial<OrderLineDTO>;
  channel: Partial<ChannelDTO>;
  editing?: boolean;
  onOpenAssignSku: () => void;
};

export function OrderLineDetails({
  line: {
    status = OrderLineStatus.PENDING,
    qtyReserved,
    qtyCommitted,
    qtyShipped,
    qtyBalance,
    skuId,
  },
  channel,
  editing = false,
  onOpenAssignSku,
}: Props) {
  const {
    models: { isLoading, canAssignSku, availableQuantity, showExpandedQuantities },
  } = useOrderLineDetailsComponent({ skuId, status, editing, channelId: channel?.id });
  return (
    <LineDetailsContainer $editing={editing}>
      <HorizontallySpaced>
        <span>Status</span>
        <RightOutlined />
        <LineStatus $status={status}>{orderLineStatusTitle[status].toLocaleUpperCase()}</LineStatus>
        {canAssignSku && (
          <ActionLink
            onClick={(e) => {
              e.preventDefault();
              onOpenAssignSku();
            }}
          >
            Assign SKU
          </ActionLink>
        )}
      </HorizontallySpaced>

      <HorizontallySpaced>
        <span>
          Reserved: <strong>{formatNumber(qtyReserved ?? 0)}</strong>
        </span>
        <RightOutlined />
        {showExpandedQuantities ? (
          <>
            <span>
              Committed: <strong>{formatNumber(qtyCommitted ?? 0)}</strong>
            </span>
            <RightOutlined />
            <span>
              Shipped: <strong>{formatNumber(qtyShipped ?? 0)}</strong>
            </span>
            <RightOutlined />
            <span>
              Balance: <strong>{formatNumber(qtyBalance ?? 0)}</strong>
            </span>
          </>
        ) : (
          <>
            <span>
              Available:{' '}
              {isLoading ? (
                <LoadingOutlined spin />
              ) : (
                <strong>{formatNumber(availableQuantity ?? 0)}</strong>
              )}
            </span>
            {qtyShipped && qtyShipped > 0 ? (
              <>
                <RightOutlined />
                <span>
                  Shipped: <strong>{formatNumber(qtyShipped ?? 0)}</strong>
                </span>
              </>
            ) : (
              ''
            )}
          </>
        )}
      </HorizontallySpaced>
    </LineDetailsContainer>
  );
}
