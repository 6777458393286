import { AsnDTO } from '@invenco/common-interface/supply';
import { DatePicker } from 'components/date-picker';
import { FormModal } from '../../../../../../components/form-modal';
import { useExpectedDateModalComponent } from './useExpectedDateModalComponent';
import { Form } from '../../../../../../components/form';
import { DEFAULT_DATE_FORMAT } from '../../../../../../constants';
import { Result } from '../../../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  expectedAt?: string | null;
  updateAsn: (data: Partial<AsnDTO>) => Promise<Result>;
};

export function ExpectedDateModal({ isOpen, expectedAt, updateAsn, onClose }: Props) {
  const {
    models: { form, isSaving },
    operations: { submit },
  } = useExpectedDateModalComponent({ isOpen, onClose, updateAsn });
  return (
    <FormModal
      title="Expected Date"
      description="Set the expected date that the shipment is expected to arrive at the warehouse"
      open={isOpen}
      onCancel={onClose}
      isSaving={isSaving}
      onSave={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => void submit(data)}
        initialValues={{ expectedAt: expectedAt ? new Date(expectedAt) : null }}
      >
        <Form.Item name="expectedAt" label="Expected Date" rules={[{ required: true }]}>
          <DatePicker format={DEFAULT_DATE_FORMAT} />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
