import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { ConditionDTO } from '@invenco/common-interface/automation';
import { Card } from 'components/card';
import { ContextMenu } from 'components/context-menu';
import { Form } from 'components/form';
import { Input } from 'components/input';
import { Button } from 'components/button';
import { actionsKey, elseActionsKey, rootConditionKey } from '../helpers/forms';
import { NormalizedRuleDTO } from '../helpers/ruleNormalization';
import { ActionsSection } from './actions/ActionsSection';
import { AddButton } from './AddButton';
import { ConditionList } from './conditions/ConditionList';
import { ChildSection, ParentSection, RuleHeader, StyledForm } from './styles';
import { useRuleCardComponent } from './useRuleCardComponent';

type Props = {
  index: number;
  editing: boolean;
  onEdit: () => void;
  onStopEditing: () => void;
  onDelete: () => void;
  onSave: (rule: Partial<NormalizedRuleDTO>) => void;
  rule?: Partial<NormalizedRuleDTO>;
};

export function RuleCard({ index, editing, onEdit, onStopEditing, onDelete, onSave, rule }: Props) {
  const {
    models: { form, isHidden },
    operations: {
      addCondition,
      deleteCondition,
      resetPropertyCondition,
      resetAction,
      edit,
      toggleHidden,
      submit,
    },
  } = useRuleCardComponent({ isEditing: editing, onSave, onEdit, onStopEditing, rule });

  return (
    <StyledForm
      layout="vertical"
      form={form}
      name={`rule${index}`}
      initialValues={rule}
      onFinish={submit}
      noMargin
    >
      <Card evenMargin={isHidden}>
        <RuleHeader>
          <h2>Rule #{index + 1}:</h2>
          <Form.Item
            name="name"
            label="name"
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input disabled={!editing} />
          </Form.Item>

          <div>
            {!editing && (
              <Button
                type="text"
                icon={isHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                onClick={toggleHidden}
                htmlType="button"
                aria-label={isHidden ? 'Show rule' : 'Hide rule'}
              />
            )}
            <ContextMenu
              onSelect={({ key }) => {
                if (key === 'delete') onDelete();
              }}
              items={[{ key: 'delete', label: 'Delete' }]}
              buttonLabel="Further actions"
            />
            {editing ? (
              <>
                <Button onClick={onStopEditing} htmlType="button">
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </>
            ) : (
              <Button type="primary" onClick={edit} htmlType="button">
                Edit
              </Button>
            )}
          </div>
        </RuleHeader>

        {!isHidden && (
          <>
            <ParentSection>
              <h3>If...</h3>

              <Form.Item noStyle shouldUpdate={(prev, cur) => prev.condition !== cur.condition}>
                {({ getFieldValue }) => {
                  const condition = getFieldValue(rootConditionKey) as ConditionDTO | undefined;
                  return condition ? (
                    <>
                      <ConditionList
                        level={0}
                        editable={editing}
                        condition={condition}
                        formKey={[rootConditionKey]}
                        onAddCondition={addCondition}
                        onDeleteCondition={deleteCondition}
                        onResetPropertyCondition={resetPropertyCondition}
                      />
                      {editing && condition.childConditionType && (
                        <ChildSection>
                          <AddButton
                            conditionType={condition.childConditionType}
                            onClick={() => addCondition([rootConditionKey])}
                          />
                        </ChildSection>
                      )}
                    </>
                  ) : null;
                }}
              </Form.Item>
            </ParentSection>

            <ActionsSection
              title="Then..."
              formKey={[actionsKey]}
              editable={editing}
              onResetAction={resetAction}
            />
            <ActionsSection
              title="Otherwise..."
              formKey={[elseActionsKey]}
              editable={editing}
              onResetAction={resetAction}
            />
          </>
        )}
      </Card>
    </StyledForm>
  );
}
