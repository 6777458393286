import { ShippingMethodDTO } from '@invenco/common-interface/shipping';
import { useEffect, useMemo, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { ComponentData } from '../../../../../shared/types';
import { useGateways } from '../../../../../gateways/GatewayProvider';
import { Result } from '../../../../../shared/helpers/Result';
import { useQueryWithInput } from '../../../../../shared/hooks/queries';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  existingShippingMethods: ShippingMethodDTO[];
  warehouseLocationId: string;
  addShippingMethod: (warehouseShippingMethod: ShippingMethodDTO) => Promise<Result>;
};

type Models = {
  form: FormInstance;
  isSaving: boolean;
  isLoadingWarehouseShippingMethods: boolean;
  canSave: boolean;
  warehouseShippingMethods: ShippingMethodDTO[];
};

type Operations = {
  submit: (formData: Record<string, string>) => Promise<void>;
};

export function useManagedShippingMethodModalComponent({
  isOpen,
  onClose,
  existingShippingMethods,
  warehouseLocationId,
  addShippingMethod,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const { shippingGateway } = useGateways();
  const [isSaving, setIsSaving] = useState(false);

  const { data: methodData, isLoading: isLoadingWarehouseShippingMethods } = useQueryWithInput({
    parentKey: 'shippingMethods',
    input: { locationId: warehouseLocationId },
    query: (input, { signal }) => shippingGateway.getShippingMethods(input, { signal }),
  });

  // filter out warehouse shipping methods which have already been added to a shipping method of this location
  const warehouseShippingMethods = useMemo(() => {
    const existingIds = new Set(
      existingShippingMethods.map((method) => method.warehouseShippingMethodId),
    );
    return methodData?.items.filter((method) => !method.id || !existingIds.has(method.id)) ?? [];
  }, [existingShippingMethods, methodData]);

  const canSave = warehouseShippingMethods.length > 0;

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  const submit = async (formData: Record<string, string>) => {
    setIsSaving(true);
    const warehouseShippingMethod = warehouseShippingMethods.find(
      (method) => method.id === formData.warehouseShippingMethodId,
    );
    if (!warehouseShippingMethod) {
      throw new Error(
        `Could not find warehouse shipping method with id ${formData.warehouseShippingMethodId}`,
      );
    }
    const result = await addShippingMethod(warehouseShippingMethod);
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  return {
    models: {
      form,
      isSaving,
      isLoadingWarehouseShippingMethods,
      canSave,
      warehouseShippingMethods,
    },
    operations: { submit },
  };
}
