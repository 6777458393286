import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { spaceVerticalChildren } from '../../../styles/mixins';

export const NavContainer = styled.div<{ $isCollapsed?: boolean }>`
  overflow: hidden;
  width: ${({ $isCollapsed }) => ($isCollapsed ? '80px' : '240px')};
  height: 100vh;
  padding: 16px;
  background: var(--background-2);
  border-radius: 0px 36px 36px 0px;
  transition: width 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  ${spaceVerticalChildren()}
  display: flex;
  flex-direction: column;
`;

export const NavHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 0px 16px 0px 8px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const LogoIconWrapper = styled.div`
  color: var(--gray-1);
  svg {
    width: 32px;
    height: 26px;
  }
`;

export const LogoNameWrapper = styled.div`
  color: var(--gray-1);
  width: 72px;
  height: 26px;
  margin-left: 24px;
`;

export const ArrowButton = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  margin-left: auto;
  color: var(--gray-6);
  cursor: pointer;

  &:hover {
    color: var(--gray-1);
  }

  .anticon {
    vertical-align: 0 !important;
  }
`;

export const ItemArrowWrapper = styled.span`
  margin-left: auto;
`;

export const MenuContainer = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const ItemListWrapper = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  ${spaceVerticalChildren(0.5)}
`;

type ItemProps = {
  $isActive?: boolean;
  $isSubItem?: boolean;
};

const baseItemStyles = css<ItemProps>`
  margin: 0;
  display: flex;
  align-items: center;
  min-height: 40px;
  width: 100%;
  padding: ${({ $isSubItem }) => ($isSubItem ? '0px 16px 0px 64px' : '0px 16px')};
  border-radius: 20px;
  color: ${({ $isActive }) => ($isActive ? 'var(--gray-1)' : 'var(--gray-6)')};
  ${({ $isActive }) =>
    $isActive &&
    `
    background: var(--gray-10);
    font-weight: 600;
  `};

  &:focus,
  &:hover,
  &:active {
    color: var(--gray-1);
  }

  transition:
    outline 0.2s ease-in-out,
    background 0.2s ease-in-out;
  &:focus {
    outline: none;
    position: relative;
    box-shadow: 0 0 0 2px var(--gray-8);
  }
`;

export const ItemLink = styled(Link)<ItemProps>`
  transition: none;
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  ${baseItemStyles}
`;

export const ItemStatic = styled.span<ItemProps>`
  ${baseItemStyles}
`;

export const ItemIconWrapper = styled.span`
  font-size: 16px;
  &:not(:last-child) {
    margin-right: 32px;
  }
`;
