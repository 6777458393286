import { openDB, IDBPDatabase } from 'idb';

export class ObjectStore {
  private _db!: IDBPDatabase;
  constructor(private readonly storeName: string) {}

  private async getObjectStore(tableName: string): Promise<IDBPDatabase> {
    if (this._db) return this._db;
    this._db = await openDB(this.storeName, 1, {
      upgrade(db: IDBPDatabase) {
        if (db.objectStoreNames.contains(tableName)) {
          return;
        }

        db.createObjectStore(tableName, { autoIncrement: true, keyPath: 'id' });
      },
    });
    return this._db;
  }

  async getValue<T = any>(tableName: string, id: string): Promise<T> {
    const db = await this.getObjectStore(tableName);
    const tx = db.transaction(tableName, 'readonly');
    const store = tx.objectStore(tableName);
    const result = await store.get(id);
    return result;
  }

  public async getAllValue<T = any>(tableName: string): Promise<T[]> {
    const db = await this.getObjectStore(tableName);
    const tx = db.transaction(tableName, 'readonly');
    const store = tx.objectStore(tableName);
    const result = await store.getAll();
    return result;
  }

  async putValue<T = any>(tableName: string, value: T) {
    const db = await this.getObjectStore(tableName);
    const tx = db.transaction(tableName, 'readwrite');
    const store = tx.objectStore(tableName);
    const result = await store.put(value);
    return result;
  }

  public async putBulkValue<T = any>(tableName: string, values: T[]) {
    const db = await this.getObjectStore(tableName);
    const tx = await db.transaction(tableName, 'readwrite');
    const store = tx.objectStore(tableName);
    await Promise.all(values.map((value) => store.put(value)));
  }

  async deleteValue(tableName: string, id: string): Promise<string> {
    const db = await this.getObjectStore(tableName);
    const tx = db.transaction(tableName, 'readwrite');
    const store = tx.objectStore(tableName);
    const result = await store.get(id);
    if (!result) {
      return result;
    }
    await store.delete(id);
    return id;
  }
}
