import { RefObject, useEffect, useMemo, useRef } from 'react';

type Props = {
  /** Whether there is more data to request. */
  canRequest?: boolean;

  /** The function to call when requesting more data (the last element is observed). */
  onRequest?: () => void;

  /** The ID of the last element in the list. */
  lastElementId?: string;

  /**
   * A value to watch for changes (i.e. the list data). To prevent request spamming,
   * onRequest cannot be called a second time until this value is updated.
   */
  updatedValue?: any;
};

type Output<RefT> = {
  /** Place this ref in the container of the list or nearest scrollable container. */
  containerRef: RefObject<RefT>;
};

/**
 * Hook that observes the last element in a list and calls a function when it is intersected.
 * This can be used to implement infinite scrolling.
 */
export function useInfiniteScrollObserver<RefT extends HTMLElement = HTMLDivElement>({
  canRequest,
  onRequest,
  lastElementId,
  updatedValue,
}: Props): Output<RefT> {
  const requestedMoreRef = useRef(false);
  const containerRef = useRef<RefT>(null);

  const intersectionObserver = useMemo(
    () =>
      new IntersectionObserver(
        ([{ isIntersecting }]) => {
          if (isIntersecting && canRequest && onRequest && !requestedMoreRef.current) {
            onRequest();
            requestedMoreRef.current = true;
          }
        },
        { root: containerRef.current },
      ),
    [canRequest, onRequest],
  );

  useEffect(() => {
    if (canRequest && lastElementId) {
      const element = document.getElementById(lastElementId);
      if (element) intersectionObserver.observe(element);
    }
    return () => intersectionObserver.disconnect();
  }, [canRequest, lastElementId, intersectionObserver]);

  useEffect(() => {
    // once options have been updated, allow requesting more
    requestedMoreRef.current = false;
  }, [updatedValue]);

  return { containerRef };
}
