import { useGateways } from 'gateways/GatewayProvider';
import { ComponentData, Page, Pagination } from 'shared/types';
import { useChannelsWithLocations, ChannelWithLocationsDTO } from '../useChannelsWithLocations';
import { DEFAULT_PAGE_SIZE } from '../../../../../constants';
import { useQueryWithInput } from '../../../../../shared/hooks/queries/useQueryWithInput';
import { useDataTable, useRowNavigation } from '../../../../../components/data-table';

export type ChannelRow = Omit<ChannelWithLocationsDTO, 'id'> & { id: string };

type Models = {
  isLoading: boolean;
  isLoadingLocations: boolean;
  hasError: boolean;
  rows: ChannelRow[];
  pagination?: Pagination;
  total?: number;
  query?: string;
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  refresh: () => void;
  onClickRow: (row: ChannelRow, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export function useChannelListPage(): ComponentData<Models, Operations> {
  const { salesGateway } = useGateways();
  const [tableState, setTableState] = useDataTable();
  const { onClickRow } = useRowNavigation({ baseUrl: '/sales/channels' });

  const {
    data,
    isLoading,
    isError: hasError,
    refetch,
  } = useQueryWithInput({
    parentKey: 'channels',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
    },
    query: (input, { signal }) => salesGateway.getChannels(input, { signal }),
  });

  const { channels, isLoadingLocations } = useChannelsWithLocations(data?.items);

  return {
    models: {
      isLoading,
      isLoadingLocations,
      hasError,
      rows: channels as ChannelRow[],
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      refresh: refetch,
      onClickRow,
    },
  };
}
