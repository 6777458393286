import {
  KitComponentDTO,
  SkuComponentGroupDTO,
  SkuDTO,
  SkuType,
} from '@invenco/common-interface/products';
import { useMemo, useState } from 'react';
import { Result } from '../../../../shared/helpers/Result';
import { ComponentData } from '../../../../shared/types';
import { useGatewayMutation } from '../../../../shared/hooks/queries';
import { useGateways } from '../../../../gateways/GatewayProvider';

type Props = {
  sku: Partial<SkuDTO>;
};

/**
 * The primary purpose of this hook is to manage and translate between the groups and kit components in a mystery SKU
 * and "mystery components". A mystery component represents a single part of the mystery SKU which will be fulfilled.
 *
 * This can either be:
 *   - A KitComponent with no group (also called a fixed SKU component), which is added directly to the order.
 *     This will be translated to a MysterySkuComponent, which is basically just a KitComponentDTO.
 *   - A SkuComponentGroup comprised of one or more SKU components (options); one or more of these options will be added
 *     to the order depending on the group's selection method and availability of the components. This will be
 *     translated to a MysteryGroupComponent, which is a SkuComponentGroupDTO with its components joined to it.
 */

export type MysterySkuComponent = Partial<Omit<KitComponentDTO, 'id'>> & { id: string };
export type MysteryGroupComponent = Pick<
  SkuComponentGroupDTO,
  'id' | 'name' | 'quantity' | 'selectionMethods'
> & {
  components: MysterySkuComponent[];
};

export type MysteryComponent = MysterySkuComponent | MysteryGroupComponent;

export const isMysteryGroupComponent = (
  mysteryComponent: MysteryComponent,
): mysteryComponent is MysteryGroupComponent => 'components' in mysteryComponent;

export const getMysterySkuComponents = (
  mysteryComponent?: MysteryComponent,
): MysterySkuComponent[] => {
  if (!mysteryComponent) return [];
  return isMysteryGroupComponent(mysteryComponent)
    ? mysteryComponent.components
    : [mysteryComponent];
};

type Models = {
  isNewMysteryComponentModalOpen: boolean;
  mysteryComponents: MysteryComponent[];
  editingMysteryComponent?: MysteryComponent;
};

type Operations = {
  addMysteryComponent: () => void;
  editMysteryComponent: (index: number) => void;
  saveMysteryComponent: (group: MysteryComponent) => Promise<Result>;
  deleteMysteryComponent: (index: number) => Promise<Result>;
  closeMysteryComponentModal: () => void;
};

export function useMysteryComponents({ sku }: Props): ComponentData<Models, Operations> {
  const { productsGateway } = useGateways();
  const [isNewMysteryComponentModalOpen, setIsNewMysteryComponentModalOpen] = useState(false);
  const [editingMysteryComponent, setEditingMysteryComponent] = useState<MysteryComponent>();

  const mysteryComponents = useMemo(() => {
    const finalComponents: MysteryComponent[] = [];
    if (sku?.type !== SkuType.MYSTERY) return finalComponents;
    const componentsByGroupId = new Map<string, KitComponentDTO[]>();

    sku.childKitComponents?.forEach((component) => {
      if (component.componentGroupId) {
        if (!componentsByGroupId.has(component.componentGroupId)) {
          componentsByGroupId.set(component.componentGroupId, []);
        }
        componentsByGroupId.get(component.componentGroupId)!.push(component);
      } else {
        finalComponents.push(component);
      }
    });

    sku.componentGroups?.forEach((group) => {
      finalComponents.push({
        id: group.id,
        name: group.name,
        quantity: group.quantity,
        selectionMethods: group.selectionMethods,
        components: componentsByGroupId.get(group.id) ?? [],
      });
    });

    return finalComponents;
  }, [sku]);

  const { mutate: saveMysteryComponent } = useGatewayMutation({
    mutationFn: async (newMysteryComponent: MysteryComponent) => {
      if (!sku?.id) throw new Error('No SKU available');
      const wasGrouped =
        editingMysteryComponent && isMysteryGroupComponent(editingMysteryComponent);
      const isGrouped = isMysteryGroupComponent(newMysteryComponent);
      let componentGroupId = isGrouped ? newMysteryComponent.id : null;

      if (!wasGrouped && isGrouped) {
        if (!newMysteryComponent.name) {
          throw new Error('Missing name for new component group');
        }
        if (!newMysteryComponent.quantity) {
          throw new Error('Missing quantity for new component group');
        }
        const componentGroup = await productsGateway.createSkuComponentGroup(sku.id, {
          name: newMysteryComponent.name,
          quantity: newMysteryComponent.quantity,
          selectionMethods: newMysteryComponent.selectionMethods,
        });
        componentGroupId = componentGroup.id;
      } else if (wasGrouped) {
        if (isGrouped) {
          await productsGateway.updateSkuComponentGroup(sku.id, editingMysteryComponent.id, {
            name: newMysteryComponent.name,
            quantity: newMysteryComponent.quantity,
            selectionMethods: newMysteryComponent.selectionMethods,
          });
        } else {
          await productsGateway.deleteSkuComponentGroup(sku.id, editingMysteryComponent.id);
        }
      }

      const preexistingSkuIds = new Set(
        getMysterySkuComponents(editingMysteryComponent).map((comp) => comp.componentSkuId),
      );
      const components = [
        ...(sku.childKitComponents?.filter((comp) => !preexistingSkuIds.has(comp.componentSkuId)) ??
          []),
        ...getMysterySkuComponents(newMysteryComponent).map((comp) => ({
          componentSkuId: comp.componentSkuId,
          quantity: comp.quantity,
          componentGroupId,
        })),
      ];

      return productsGateway.updateKitComponents(
        sku.id,
        components.map((comp) => ({
          componentSkuId: comp.componentSkuId,
          quantity: comp.quantity,
          componentGroupId: comp.componentGroupId,
        })),
      );
    },
    linkedQuery: ['skus', sku.id],
  });

  const { mutate: deleteMysteryComponent } = useGatewayMutation({
    mutationFn: async (index: number) => {
      if (!sku?.id) throw new Error('No SKU available');
      const component = mysteryComponents[index];
      if (!component) return;
      if (isMysteryGroupComponent(component)) {
        await productsGateway.deleteSkuComponentGroup(sku.id, component.id);
      } else {
        await productsGateway.updateKitComponents(
          sku.id,
          sku.childKitComponents
            ?.filter((comp) => comp.id !== component.id)
            .map((comp) => ({
              componentSkuId: comp.componentSkuId,
              quantity: comp.quantity,
              componentGroupId: comp.componentGroupId,
            })) ?? [],
        );
      }
    },
    linkedQuery: ['skus', sku.id],
    linkResponseToQuery: false,
    successMessage: 'Deleted component',
  });

  const editMysteryComponent = (index: number) => {
    setEditingMysteryComponent(mysteryComponents[index]);
  };

  const addMysteryComponent = () => {
    setIsNewMysteryComponentModalOpen(true);
  };

  const closeMysteryComponentModal = () => {
    setEditingMysteryComponent(undefined);
    setIsNewMysteryComponentModalOpen(false);
  };

  return {
    models: {
      isNewMysteryComponentModalOpen,
      editingMysteryComponent,
      mysteryComponents,
    },
    operations: {
      addMysteryComponent,
      editMysteryComponent,
      closeMysteryComponentModal,
      deleteMysteryComponent,
      saveMysteryComponent,
    },
  };
}
