import { LocationDTO } from '@invenco/common-interface/accounts';

import { LoadingSkeleton } from 'components/loading-skeleton';
import { Card } from 'components/card';
import { Header } from 'components/header';
import { Container, ListContainer } from 'styles/layout';
import { useChannelListPage } from './useChannelListPage';
import { DataTable } from '../../../../../components/data-table';

export function ChannelList() {
  const {
    models: { isLoading, isLoadingLocations, hasError, rows, pagination, total, query },
    operations: { search, navigate, refresh, onClickRow },
  } = useChannelListPage();
  return (
    <Container>
      <Header links={[{ url: '/sales/channels', title: 'Channels' }]} title="Channels" />
      <ListContainer>
        <Card>
          <DataTable.SearchPanel
            loading={isLoading}
            defaultSearchValue={query}
            onSearch={search}
            onNavigate={navigate}
            pagination={pagination}
            total={total}
            onReload={refresh}
          />

          <DataTable
            columns={[
              { title: 'Name', key: 'name' },
              {
                title: 'Fulfillment Locations',
                key: 'fulfillmentLocations',
                render: (locations: LocationDTO[]) =>
                  isLoadingLocations ? (
                    <LoadingSkeleton />
                  ) : (
                    locations.map(({ name }) => name).join(', ')
                  ),
              },
            ]}
            rows={rows}
            loading={isLoading}
            onClickRow={onClickRow}
            hasError={hasError}
          />
        </Card>
      </ListContainer>
    </Container>
  );
}
