import styled from 'styled-components';
import { spaceHorizontalChildren } from '../../../styles/mixins';

export const RowActionContainer = styled.div`
  > span:first-child {
    font-weight: 700;
  }
  button {
    color: var(--gray-1);
    background: none;
    .anticon {
      // fix for the close button
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &:hover,
    &:focus,
    &:active {
      &,
      .anticon {
        color: var(--gray-6);
      }
    }
    &:disabled,
    &.ant-btn-loading {
      color: var(--gray-7);
      &:hover {
        color: var(--gray-7);
      }
      &::before {
        background: none;
      }
    }
  }
  ${spaceHorizontalChildren()}
`;
