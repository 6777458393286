import { BaseAxiosGateway } from '../BaseAxiosGateway';
import { Result } from '../../shared/helpers/Result';
import { getResultFromRequest } from './utils';
import { GatewayName } from '../types';

export class AxiosIntegrationGateway extends BaseAxiosGateway {
  name: GatewayName = 'integration';

  constructor() {
    super(undefined);
  }

  async installApp(query: {
    accountId: string;
    appCode: string;
    flowName: string;
    parameters: Record<string, string>;
  }): Promise<Result<void>> {
    return getResultFromRequest(() =>
      this.httpPost('trigger/SW50ZWdyYXRpb246M2NhMWE5ODYtNjUxZS00MzkxLWFiYjItYWUxM2RmMzU2Nzg1', {
        data: query,
        // TODO: temp solution - if this solution stays, will likely need to use a per environment URL
        baseURL: 'https://hooks.integration.lynksupplychain.com',
      }),
    );
  }
}
