import type { LocationDTO } from '@invenco/common-interface/accounts';
import { MembershipDTO } from '@invenco/common-interface/accounts';
import { useEffect } from 'react';
import { FormInstance } from 'antd';
import { ComponentData } from 'shared/types';
import { Form } from 'components/form';
import { Result } from '../../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  addUser: (membership: Partial<MembershipDTO>) => Promise<Result>;
};

type Models = {
  form: FormInstance;
  locations?: LocationDTO[];
  isLoadingLocations?: boolean;
};

type Operations = {
  submit: (formData: any) => Promise<void>;
};

export function useAddUserModalComponent({
  isOpen,
  onClose,
  addUser,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  const submit = async (formData: any) => {
    const result = await addUser(formData);
    if (result.isSuccess) onClose();
  };

  return {
    models: { form },
    operations: { submit },
  };
}
