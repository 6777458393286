import { ConditionDTO, PropertyConditionDTO } from '@invenco/common-interface/automation';
import { PropertyConditionInput } from './PropertyConditionInput';
import { ChildSection } from '../styles';
import { AddButton } from '../AddButton';
import { FormKey } from '../../helpers/forms';
import { isPropertyCondition } from '../../helpers/conditions';

// eslint-disable-next-line import/no-cycle
import { ConditionList } from './ConditionList';

export type ConditionProps = {
  level: number;
  editable: boolean;
  condition: ConditionDTO | PropertyConditionDTO;
  formKey: FormKey;
  onAddCondition: (formKey: FormKey) => void;
  onDeleteCondition: (formKey: FormKey) => void;
  onResetPropertyCondition: (formKey: FormKey) => void;
};

export function Condition({
  level,
  editable,
  condition,
  formKey,
  onAddCondition,
  onDeleteCondition,
  onResetPropertyCondition,
}: ConditionProps) {
  if (isPropertyCondition(condition)) {
    return (
      <PropertyConditionInput
        formKey={formKey}
        onDelete={() => onDeleteCondition(formKey)}
        onChangeType={() => onResetPropertyCondition(formKey)}
        editable={editable}
      />
    );
  }

  if (condition.childConditions) {
    return (
      <ChildSection $alternate={level % 2 === 0}>
        <ConditionList
          level={level}
          editable={editable}
          condition={condition}
          formKey={formKey}
          onAddCondition={onAddCondition}
          onDeleteCondition={onDeleteCondition}
          onResetPropertyCondition={onResetPropertyCondition}
        />
        {editable && condition.childConditionType && (
          <AddButton
            conditionType={condition.childConditionType}
            onClick={() => onAddCondition(formKey)}
          />
        )}
      </ChildSection>
    );
  }

  return null;
}
