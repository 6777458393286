import styled from 'styled-components';
import { Progress as AntProgress } from 'antd';

export const Steps = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Step = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProgressContainer = styled.div`
  display: flex;
  height: 100%;
  padding-right: 10px;
  align-items: center;
`;

export const Progress = styled(AntProgress)`
  ${({ strokeColor }) =>
    strokeColor &&
    `
    && .ant-progress-text {
      color: ${strokeColor};
    }
  `}
`;

export const TitleWrapper = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  color: var(--gray-9);
`;

export const DescriptionWrapper = styled.div`
  flex-grow: 1;
  font-size: 12px;
  color: var(--gray-7);
`;

export const ChevronContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
`;

export const Chevron = styled.div`
  position: relative;
  height: 30px;
  width: 4px;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background: var(--gray-4);
    transform: skew(44deg, 0deg);
  }

  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    height: 50%;
    width: 100%;
    background: var(--gray-4);
    transform: skew(-44deg, 0deg);
  }
`;
