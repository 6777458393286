import { useGateways } from 'gateways/GatewayProvider';
import { useMemo } from 'react';
import { useAutoCompleteQuery } from 'shared/hooks/queries';
import { ComponentData } from 'shared/types';
import { QueryProps } from './types';

type Models = {
  isLoading: boolean;
  options: { value: string }[];
};

type Operations = {
  search: (input: string) => void;
};

export function useQuerySearchItemComponent({
  queryKey,
  query,
  valueKey,
}: QueryProps): ComponentData<Models, Operations> {
  const gateways = useGateways();
  const {
    options: rawOptions,
    isLoading,
    onSearch: search,
  } = useAutoCompleteQuery({
    parentKey: queryKey,
    query: (input, { signal }) => {
      if (!query) throw new Error('query undefined');
      return query(gateways, input, { signal });
    },
  });
  const options = useMemo(
    () => rawOptions.map((option) => ({ value: option[valueKey] })),
    [rawOptions, valueKey],
  );
  return {
    models: { isLoading, options },
    operations: { search },
  };
}
