import { StyledSkeleton } from './styles';

type Props = {
  /** Number of paragraph rows to display. Defaults to 1. */
  rows?: number;

  /** The width of each row. */
  width?: number | string;

  /**
   * Use this if you intend to place the skeleton inline with other text or elements. The parent container
   * will still need to be flex, but this prevents spacing issues.
   */
  inline?: boolean;
};

/** Standard loading skeleton to display for pieces of data still loading. */
export function LoadingSkeleton({ inline, rows = 1, width = '100%' }: Props) {
  return <StyledSkeleton $inline={inline} title={false} paragraph={{ rows, width }} active />;
}
