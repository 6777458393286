import { ModalProps } from 'antd';
import { Modal } from 'components/modal';
import { Button } from 'components/button';
import { Description } from './styles';

type Props = ModalProps & {
  isSaving?: boolean;
  onCancel: () => void;
  onSave: () => Promise<void> | void;
  saveTitle?: string;
  saveDisabled?: boolean;
  description?: string;
};

export function FormModal({
  onCancel,
  isSaving,
  onSave,
  saveTitle = 'Save',
  saveDisabled = false,
  description,
  children,
  ...props
}: Props) {
  const footerButtons = (
    <>
      <Button onClick={onCancel} disabled={isSaving} aria-label="Cancel">
        Cancel
      </Button>
      <Button
        type="primary"
        onClick={() => void onSave()}
        loading={isSaving}
        aria-label={saveTitle}
        disabled={saveDisabled}
      >
        {saveTitle}
      </Button>
    </>
  );

  return (
    <Modal forceRender onCancel={onCancel} maskClosable={false} footer={footerButtons} {...props}>
      {description && <Description>{description}</Description>}
      {children}
    </Modal>
  );
}
