import { Tooltip as AntTooltip, TooltipProps } from 'antd';

export function Tooltip(props: TooltipProps) {
  return (
    <AntTooltip
      overlayInnerStyle={{
        padding: '8px',
        borderRadius: '8px',
        background: 'var(--gray-9)',
        fontSize: '12px',
      }}
      {...props}
    />
  );
}
