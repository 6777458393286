import { FormInstance } from 'antd';
import { useState } from 'react';
import { GatewayName } from '../../../gateways/types';
import { useMessages } from '../../../shared/providers/MessagesProvider';
import { Form } from '../../../components/form';
import { baseUrlProvider } from '../../../gateways/baseUrlProvider';
import { ComponentData } from '../../../shared/types';

type Models = {
  isConfiguringUrls: boolean;
  ticketForm: FormInstance;
  urlForm: FormInstance;
  baseUrls: Record<GatewayName, string>;
};

type Operations = {
  configureForTicket: (number: string) => Promise<void>;
  updateUrls: (urls: Record<GatewayName, string>) => void;
  resetUrls: () => void;
};

export function useDeveloperConfigPage(): ComponentData<Models, Operations> {
  const messages = useMessages();
  const [isConfiguringUrls, setIsConfiguringUrls] = useState(false);

  const [ticketForm] = Form.useForm();
  const [urlForm] = Form.useForm();

  const baseUrls = baseUrlProvider.getForAllGateways();

  const configureForTicket = async (branch: string) => {
    setIsConfiguringUrls(true);
    baseUrlProvider.reset();

    const entries = await Promise.all(
      Object.entries(baseUrlProvider.getForAllGateways()).map(async ([name, baseUrl]) => {
        const branchUrl = `${baseUrl}-${branch}`;
        const res = await fetch(`${branchUrl}/v1/info`);
        if (![403, 404].includes(res.status)) {
          return [name, branchUrl];
        }
        return null;
      }),
    );
    const filteredEntries = entries.filter((x) => x !== null) as [string, string][];

    if (filteredEntries.length) {
      baseUrlProvider.setForGateways(Object.fromEntries(filteredEntries));
      messages.success(
        `Configured ${filteredEntries.length}/${
          Object.keys(baseUrls).length
        } base URLs for ${branch}`,
      );
    } else {
      messages.error(`Unable to find any matching gateways for ${branch}`);
    }

    urlForm.setFieldsValue(baseUrlProvider.getForAllGateways());
    setIsConfiguringUrls(false);
  };

  const updateUrls = (data: Record<GatewayName, any>) => {
    baseUrlProvider.setForGateways(data);
    messages.success('Updated API base URLs');
  };

  const resetUrls = () => {
    baseUrlProvider.reset();
    urlForm.setFieldsValue(baseUrlProvider.getForAllGateways());
    messages.success('Reset API base URLs');
  };

  return {
    models: { isConfiguringUrls, ticketForm, urlForm, baseUrls },
    operations: { configureForTicket, updateUrls, resetUrls },
  };
}
