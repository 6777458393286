import { Avatar, Collapse } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { HorizontallySpaced } from '../../../../../styles/layout';
import { LoadingSkeleton } from '../../../../../components/loading-skeleton';
import { CarrierCollapse } from './styles';
import { renderCollapseIcon } from './CarrierList';

export function LoadingCarrierList() {
  // would make the panels subcomponents, but the expand icon breaks unless they are direct children
  return (
    <CarrierCollapse bordered={false} expandIconPosition="end" expandIcon={renderCollapseIcon}>
      {[1, 2, 3].map((key) => (
        <Collapse.Panel
          key={key}
          header={
            <HorizontallySpaced>
              <Avatar shape="square" icon={<LoadingOutlined spin />} />
              <span>
                <LoadingSkeleton width="200px" />
              </span>
            </HorizontallySpaced>
          }
        />
      ))}
    </CarrierCollapse>
  );
}
