import { Route, Routes } from 'react-router-dom';
import { Header } from '../../components/header';
import { developerRoute } from '../../shared/helpers/routing';
import { Container } from '../../styles/layout';

export function NoContextRoutes() {
  return (
    <Routes>
      <Route
        index
        element={
          <Container>
            <Header />
          </Container>
        }
      />
      {developerRoute}
    </Routes>
  );
}
