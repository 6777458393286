import styled from 'styled-components';
import { Button } from 'components/button';
import { spaceHorizontalChildren, spaceVerticalChildren } from '../../../../../styles/mixins';

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  ${spaceVerticalChildren(0.25)}
`;

export const StyledListItem = styled.li<{ $editing?: boolean }>`
  ${({ $editing }) =>
    !$editing &&
    `
    cursor: pointer;
    &:hover {
      background-color: var(--gray-3);
    }
  `}

  padding: 8px 16px;
  margin: 0 -16px;
  border-radius: 8px;

  > *:first-child {
    display: flex;
    align-items: center;
    min-height: 32px;
    justify-content: space-between;

    > div:first-child {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex: 1;
      min-width: 0;
      ${spaceHorizontalChildren()}

      @media screen and (max-width: 1200px) {
        ${spaceHorizontalChildren(0.5)}
      }
    }
  }

  .ant-form-item {
    margin: 0;
    input {
      width: 50px;
    }
  }

  .ant-form-item-explain {
    display: none;
  }

  ${spaceHorizontalChildren()}
`;

export const AddBandButton = styled(Button)`
  && {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const BottomLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > *:last-child {
    margin-right: 32px; // pushed over to line up with the per line example markups
  }
`;

export const BandItemControls = styled.div<{ $hidden?: boolean }>`
  display: flex;
  align-items: center;
  ${spaceHorizontalChildren(0.5)}
  ${({ $hidden }) => $hidden && 'opacity: 0;'}
  opacity: 1;
  transition: opacity 0.3s;
`;

export const ActionButton = styled(Button)`
  color: var(--gray-7);
`;

export const Example = styled.span`
  color: var(--gray-7);
  font-size: 12px;
`;

export const ErrorMessage = styled.div`
  color: var(--red-5);
  font-size: 12px;
`;
