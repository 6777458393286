import { Link } from 'react-router-dom';
import { Button } from 'components/button';
import { useShippingPriceDetailsPage } from './useShippingPriceDetailsPage';
import {
  Container,
  DetailContainer,
  MainContainer,
  SideContainer,
} from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { Card } from '../../../../components/card';
import { DetailsCard } from '../../../../components/details-card';
import { DetailsBlock } from '../../../../components/details-block';
import { DetailList } from '../../../../components/detail-list/DetailList';
import { LoadingSkeleton } from '../../../../components/loading-skeleton';
import { formatDate } from '../../../../shared/helpers';
import { PriceBands } from './price-bands/PriceBands';
import { DetailsModal } from './details-modal/DetailsModal';

export function ShippingPriceDetails() {
  const {
    models: {
      isNew,
      isSaving,
      isLoading,
      isLoadingAccounts,
      isDetailsModalOpen,
      hasChanges,
      canSave,
      shippingPrice,
      numberOfLastBands,
      accounts,
      account,
      location,
      breadcrumbs,
    },
    operations: {
      refresh,
      updateDetails,
      save,
      addBand,
      updateBand,
      removeBand,
      openDetailsModal,
      closeDetailsModal,
    },
  } = useShippingPriceDetailsPage();
  return (
    <Container>
      <Header
        title={shippingPrice.name}
        documentTitle={isNew ? 'New Shipping Price' : shippingPrice.name}
        backURL="/shipping/prices"
        links={breadcrumbs}
        loading={isLoading}
        onRefresh={!isNew ? refresh : undefined}
        actionContent={
          <Button
            type="primary"
            onClick={() => void save()}
            disabled={!canSave || !hasChanges}
            loading={isSaving}
          >
            Save
          </Button>
        }
        editable
        editInitially={isNew}
        editPlaceholder="Enter shipping price name..."
        editAriaLabel="Shipping price name"
        onSave={(name) => updateDetails({ name })}
      />

      <DetailContainer>
        <MainContainer>
          <Card
            title="Price bands"
            count={shippingPrice.priceBands?.length ?? 0}
            description={
              "Each band's markup is applied in sequence as an additional percentage of the shipping cost. " +
              "The markup is applied to the portion of the cost between the previous band's (if any) maximum " +
              "and this band's maximum."
            }
          >
            <PriceBands
              loading={isLoading}
              priceBands={shippingPrice.priceBands ?? []}
              numberOfLastBands={numberOfLastBands}
              addBand={addBand}
              updateBand={updateBand}
              removeBand={removeBand}
            />
          </Card>
        </MainContainer>

        <SideContainer>
          <DetailsCard title="Details" hasEditButton onEdit={openDetailsModal}>
            <DetailsBlock loading={isLoading} loadingRows={3}>
              <DetailList
                items={[
                  {
                    label: 'Account',
                    value: isLoadingAccounts ? (
                      <LoadingSkeleton />
                    ) : (
                      account && <Link to={`/accounts/${account.id}`}>{account.name}</Link>
                    ),
                  },
                  {
                    label: 'Valid From',
                    value: shippingPrice.validFrom && formatDate(shippingPrice.validFrom),
                  },
                  {
                    label: 'Valid To',
                    value: shippingPrice.validTo && formatDate(shippingPrice.validTo),
                  },
                  {
                    label: 'Location',
                    value: isLoadingAccounts ? <LoadingSkeleton /> : location?.name,
                  },
                ]}
              />
            </DetailsBlock>
          </DetailsCard>
        </SideContainer>
      </DetailContainer>

      <DetailsModal
        isOpen={isDetailsModalOpen}
        isNew={isNew}
        shippingPrice={shippingPrice}
        accounts={accounts}
        updateDetails={updateDetails}
        onClose={closeDetailsModal}
      />
    </Container>
  );
}
