export const UNIT_PX = 16;

export const spaceHorizontalChildren = (factor = 1) => `
  > *:not(:last-child) {
    margin-right: ${factor * UNIT_PX}px;
  }
`;

export const spaceVerticalChildren = (factor = 1) => `
  > *:not(:last-child) {
    margin-bottom: ${factor * UNIT_PX}px;
  }
`;

export const smallUpperCaseText = `
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

export const autoScroll = (background = 'var(--gray-2)') => `
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background: ${background};
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-6);
    border-radius: 8px;
    border: 3px solid ${background};
  }
  
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-width: thin;
    scrollbar-color: var(--gray-6) ${background};
  }
`;

/** Hide the element, while still keeping it visible to a screen reader
 * REf: https://blog.logrocket.com/design-accessibility-css-visually-hidden-class/
 */
export const visuallyHidden = `
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
`;
