import { Radio as AntRadio, RadioProps } from 'antd';
import React, { ReactNode, useId } from 'react';
import { LabelWrapper } from './styles';

type Props = {
  /** A description of the radio option which will be displayed under the label. */
  description?: ReactNode;
} & RadioProps;

/** Expanded Radio control with support for a description element. */
export const Radio = Object.assign(
  React.forwardRef<HTMLElement, Props>(({ children, description, ...props }, ref) => {
    const labelId = useId();
    const descId = useId();
    return (
      <AntRadio
        {...props}
        ref={ref}
        aria-labelledby={labelId}
        aria-describedby={description ? descId : undefined}
      >
        <LabelWrapper>
          <span id={labelId}>{children}</span>
          {description && <span id={descId}>{description}</span>}
        </LabelWrapper>
      </AntRadio>
    );
  }),
  {
    Group: AntRadio.Group,
    Button: AntRadio.Button,
  },
);
