import { Fragment } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Separator, StyledLink } from './styles';

import { LoadingSkeleton } from '../../loading-skeleton';
import { HorizontallySpaced } from '../../../styles/layout';

export type BreadCrumb = {
  url: string;
  title?: string;
  loading?: boolean;
};

export function BreadCrumbs({ links }: { links: BreadCrumb[] }) {
  return (
    <HorizontallySpaced $factor={0.5}>
      <StyledLink icon to="/" aria-label="Home">
        <HomeOutlined />
      </StyledLink>
      {links.map((link, index) => (
        // eslint-disable-next-line react/no-array-index-key -- elements never change order
        <Fragment key={index}>
          <Separator />
          {link.loading ? (
            <LoadingSkeleton inline width={100} />
          ) : (
            <StyledLink selected={links.length - 1 === index} to={link.url}>
              {link.title}
            </StyledLink>
          )}
        </Fragment>
      ))}
    </HorizontallySpaced>
  );
}
