import { useEffect, useMemo, useState } from 'react';
import { Filter, FilterOption, FilterSelection, FilterSelections, FilterValue } from './types';
import { useOptionallyControlledState } from '../../../shared/hooks/useOptionallyControlledState';
import { ComponentData } from '../../../shared/types';
import { SelectOption, SelectValue } from '../../popover-select';
import { optionToValue } from './optionToValue';

type Props<KeyT extends string> = {
  filters: Filter<KeyT>[];
  selections?: FilterSelections<KeyT>;
  onChange: (values: FilterSelections<KeyT>) => any;
  controlledActiveFilter?: KeyT | 'new';
  onChangeActiveFilter?: (key?: KeyT | 'new') => any;
};

type Models<KeyT extends string> = {
  activeFilter: string | undefined;
  availableFilterOptions: SelectOption[];
  openFilters: Filter<KeyT>[];
};

type Operations<KeyT extends string> = {
  onFilterOpenChange: (key: KeyT | 'new', open: boolean) => void;
  updateSelection: (key: Filter<KeyT>, value: SelectValue) => void;
  addFilter: (key: KeyT) => void;
  deleteFilter: (key: KeyT) => void;
  deleteAllFilters: () => void;
};

// Given current state and a new Set value, this provides the proper filter value array.
// We have to check both our currently stored selection and the current list of options
// to match the new set of value keys with full options objects, since the option list
// now may not contain previously selected options (due to searching, etc.).
const getNewMultiSelection = (
  options: FilterOption[],
  currentSelection: FilterValue[],
  newValue: Set<string>,
): FilterValue[] => {
  const alreadyHasValue = new Set<string>();
  const existingSelection = currentSelection.filter((val) => {
    if (newValue.has(val.value)) {
      alreadyHasValue.add(val.value);
      return true;
    }
    return false;
  });

  return [
    ...existingSelection,
    ...(options
      ?.filter((opt) => !alreadyHasValue.has(opt.value) && newValue.has(opt.value))
      .map(optionToValue) ?? []),
  ];
};

export function useFilterPanelComponent<KeyT extends string>({
  filters,
  selections,
  controlledActiveFilter,
  onChange,
  onChangeActiveFilter,
}: Props<KeyT>): ComponentData<Models<KeyT>, Operations<KeyT>> {
  const [activeFilter, setActiveFilter] = useOptionallyControlledState({
    default: undefined,
    controlled: controlledActiveFilter,
    setControlled: onChangeActiveFilter,
  });
  const [openKeys, setOpenKeys] = useState(Object.keys(selections ?? {}));

  useEffect(() => {
    setOpenKeys((current) => {
      const activeKeys = Object.keys(selections ?? {});
      if (activeFilter && activeFilter !== 'new' && !activeKeys.includes(activeFilter)) {
        activeKeys.push(activeFilter);
      }
      const withoutRemovedKeys = current.filter((k) => activeKeys.includes(k));
      const addedKeys = activeKeys.filter((k) => !current.includes(k));
      return [...withoutRemovedKeys, ...addedKeys];
    });
  }, [selections, activeFilter]);

  const availableFilterOptions = useMemo(() => {
    const openKeySet = new Set(openKeys);
    return filters
      .filter((f) => !openKeySet.has(f.key))
      .map(({ key, title }) => ({ value: key, title }));
  }, [filters, openKeys]);

  const openFilters = useMemo(
    () =>
      openKeys.map((key) => filters.find((f) => f.key === key)).filter(Boolean) as Filter<KeyT>[],
    [filters, openKeys],
  );

  const updateSelection = (filter: Filter<KeyT>, newValue: SelectValue) => {
    let newSelection: FilterSelection | undefined;

    // Translating between titled objects and string values like this isn't ideal, but its impact won't be noticeable
    if (newValue) {
      if (!(newValue instanceof Set)) {
        const filterOpt = filter.options?.find((opt) => opt.value === newValue);
        newSelection = filterOpt ? optionToValue(filterOpt) : { value: newValue, title: newValue };
      } else if (newValue.size) {
        newSelection = getNewMultiSelection(
          filter.options ?? [],
          (selections?.[filter.key] ?? []) as FilterValue[],
          newValue,
        );
      }
    }

    const updated: Partial<FilterSelections<KeyT>> = { ...selections };
    if (newSelection) {
      updated[filter.key] = newSelection;
    } else {
      delete updated[filter.key];
    }

    onChange(updated);
  };

  const deleteFilter = (key: KeyT) => {
    setActiveFilter(undefined); // if it was pending
    if (selections && key in selections) {
      const newValues = { ...selections };
      delete newValues[key]; // preserve insertion order if added back
      onChange(newValues);
    }
  };

  const deleteAllFilters = () => {
    onChange({});
  };

  const onFilterOpenChange = (key: KeyT | 'new', open: boolean) => {
    if (open) {
      setActiveFilter(key);
    } else if (activeFilter === key) {
      setActiveFilter(undefined);
    }
  };

  return {
    models: {
      activeFilter,
      availableFilterOptions,
      openFilters,
    },
    operations: {
      updateSelection,
      addFilter: setActiveFilter,
      deleteFilter,
      deleteAllFilters,
      onFilterOpenChange,
    },
  };
}
