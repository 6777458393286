import { OrderLineStatus } from '@invenco/common-interface/sales';
import styled from 'styled-components';
import { orderLineStatusColor } from 'shared/status-maps';
import { spaceVerticalChildren } from 'styles/mixins';

export const LineDetailsContainer = styled.div<{ $editing?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  min-height: 32px;
  font-size: 12px;
  ${({ $editing }) =>
    $editing
      ? `
      color: var(--gray-8);
      background: var(--gray-1);
    `
      : `
      color: var(--gray-8);
      background: var(--gray-4);
    `}
`;

export const LineStatus = styled.span<{ $status: OrderLineStatus }>`
  font-weight: 700;
  color: ${({ $status }) => orderLineStatusColor[$status]};
`;

export const DescriptionField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const SkuSearchContainer = styled.div`
  padding: 16px;
  margin: 0 -16px;
  border-radius: 8px;
  background: var(--blue-1);
`;

export const BundleDetailsContainer = styled.div`
  width: 100%;
  ${spaceVerticalChildren(0.5)}
`;
