import styled from 'styled-components';

export const LabelWrapper = styled.div`
  font-size: 12px;
  line-height: 1.6667;
  display: flex;
  flex-direction: column;

  > *:first-child {
    font-weight: 700;
  }

  > *:nth-child(2) {
    color: var(--gray-8);
  }
`;
