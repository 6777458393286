import { useState } from 'react';
import { ComponentData } from '../../../../../../shared/types';
import { Result } from '../../../../../../shared/helpers/Result';

type Models = {
  isSaving: boolean;
};

type Operations = {
  submit: () => Promise<void>;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  bookAsn: () => Promise<Result>;
};

export function useBookingModalComponent({
  onClose,
  bookAsn,
}: Props): ComponentData<Models, Operations> {
  const [isSaving, setIsSaving] = useState(false);

  const submit = async () => {
    setIsSaving(true);
    const result = await bookAsn();
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  return {
    models: { isSaving },
    operations: { submit },
  };
}
