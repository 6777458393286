import styled from 'styled-components';
import { Button } from 'components/button';
import { spaceHorizontalChildren, spaceVerticalChildren } from '../../styles/mixins';

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    display: flex;
    align-items: center;
    min-height: 32px;
    justify-content: space-between;

    span:first-child {
      flex: 1;
      min-width: 0;
    }
    ${spaceHorizontalChildren()}
  }

  ${spaceVerticalChildren()}
`;

export const AddItemButton = styled(Button)`
  && {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const DeleteButton = styled(Button)`
  color: var(--gray-7);
`;

export const NewFormRow = styled.div`
  display: flex;
  width: 100%;
  ${spaceHorizontalChildren()}

  > *:first-child {
    flex: 1;
  }
`;
