import { ReactNode } from 'react';
import { ContentTitle, TitleLine } from './styles';
import { LoadingSkeleton } from '../loading-skeleton';
import { HelpTooltip } from '../help-tooltip';

type Props = {
  /** Title displayed at the top of the block. */
  title?: ReactNode;

  /** Title intended to be primary piece of content, displayed between title and content. */
  contentTitle?: ReactNode;

  /** Whether the content is loading. */
  loading?: boolean;

  /** Number of skeleton rows to display while loading. */
  loadingRows?: number;

  /** Help tooltip to display next to title. */
  tooltip?: string;

  /** The details content. */
  children?: ReactNode;
};

/** Container block for a group of details. */
export function DetailsBlock({
  title,
  contentTitle,
  children,
  loadingRows = 1,
  loading,
  tooltip,
}: Props) {
  return (
    <div>
      {title && (
        <TitleLine>
          <span>{title}</span>
          {tooltip && <HelpTooltip title={tooltip} />}
        </TitleLine>
      )}
      {contentTitle && <ContentTitle>{contentTitle}</ContentTitle>}
      {loading && loadingRows > 0 ? <LoadingSkeleton rows={loadingRows} /> : children}
    </div>
  );
}
