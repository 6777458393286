import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { message } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { spaceHorizontalChildren } from 'styles/mixins';
import { createId } from '@paralleldrive/cuid2';

export type MessageType = 'success' | 'info' | 'warning' | 'error' | 'loading' | 'fixed';
export type MessageOptions = {
  key?: string;
  name?: ReactNode;
  onClick?: (e: React.MouseEvent) => void;
};

type MessageCallback = (content: ReactNode, options?: MessageOptions) => void;
type KeyMessageCallback = (
  key: string,
  content: ReactNode,
  options?: Omit<MessageOptions, 'key'>,
) => void;

export type MessagesContextData = {
  close: (key: string) => void;
  success: MessageCallback;
  info: MessageCallback;
  warning: MessageCallback;
  error: MessageCallback;
  loading: KeyMessageCallback;
  fixed: KeyMessageCallback;
};

export const MessagesContext = createContext<MessagesContextData>({} as MessagesContextData);

export function useMessages() {
  return useContext(MessagesContext);
}

const getMessageIcon = (type: MessageType) => {
  switch (type) {
    case 'info':
      return <InfoCircleOutlined />;
    case 'warning':
      return <ExclamationCircleOutlined />;
    case 'success':
      return <CheckOutlined />;
    case 'error':
      return <CloseOutlined />;
    case 'loading':
      return <LoadingOutlined spin />;
    case 'fixed':
    default:
      return <span />;
  }
};

const ContentWithName = styled.span`
  display: inline-flex;
  align-items: center;
  ${spaceHorizontalChildren(0.5)}
  > span:last-child {
    font-size: 12px;
    font-weight: 700;
  }
`;

export function MessagesProvider({ children }: React.PropsWithChildren) {
  const [api, context] = message.useMessage();
  const contextData = useMemo<MessagesContextData>(() => {
    const open = (content: ReactNode, type: MessageType, options?: MessageOptions) => {
      // these promises last the duration of the message being on screen
      // we don't need that use case, and it is safer to void the return
      const key = options?.key ?? createId();
      return void api.open({
        content: options?.name ? (
          <ContentWithName>
            <span>{content}</span>
            <span>{options.name}</span>
          </ContentWithName>
        ) : (
          content
        ),
        type: type === 'fixed' ? 'info' : type,
        duration: ['fixed', 'loading', 'error'].includes(type) ? 0 : 3,
        key,
        onClick: (e) => {
          options?.onClick?.(e);
          if (type !== 'fixed' && type !== 'loading') message.destroy(key);
        },
        icon: getMessageIcon(type),
      });
    };

    const close = (key: string) => message.destroy(key);

    const success = (content: ReactNode, options?: MessageOptions) =>
      open(content, 'success', options);
    const info = (content: ReactNode, options?: MessageOptions) => open(content, 'info', options);
    const warning = (content: ReactNode, options?: MessageOptions) =>
      open(content, 'warning', options);
    const error = (content: ReactNode, options?: MessageOptions) => open(content, 'error', options);
    const loading = (key: string, content: ReactNode, options?: Omit<MessageOptions, 'key'>) =>
      open(content, 'loading', { ...options, key });
    const fixed = (key: string, content: ReactNode, options?: Omit<MessageOptions, 'key'>) =>
      open(content, 'fixed', { ...options, key });

    return { success, info, warning, error, loading, fixed, close };
  }, [api]);

  return (
    <MessagesContext.Provider value={contextData}>
      {context}
      {children}
    </MessagesContext.Provider>
  );
}
