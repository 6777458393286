import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import { autoScroll, spaceHorizontalChildren } from 'styles/mixins';

// TODO: replace all other instances of Modal with this or a derivative
export const Modal = styled(AntModal)`
  .ant-modal-content {
    border-radius: 16px;
    max-height: calc(100vh - 200px); // modal is fixed 100px from the top of screen
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    ${autoScroll()}
  }

  .ant-modal-header,
  .ant-modal-footer {
    min-height: 56px;
    background: none;
  }
  .ant-modal-header,
  .ant-modal-body {
    padding: 16px 32px;
  }

  .ant-modal-footer {
    padding: 8px 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${spaceHorizontalChildren()}
    > .ant-btn + .ant-btn {
      margin-left: 0;
    }
  }

  .ant-modal-title {
    font-weight: 900;
    font-size: 16px;
  }

  .ant-modal-close-x {
    width: 72px;
  }
`;
