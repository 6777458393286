import { OrderDTO } from '@invenco/common-interface/sales';
import { Form } from 'components/form';
import { FormModal } from 'components/form-modal';
import { Input } from 'components/input';
import { Result } from 'shared/helpers/Result';
import { useNotesModalComponent } from './useNotesModalComponent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  notes?: string;
  updateOrder: (data: Partial<OrderDTO>) => Promise<Result>;
};

export function NotesModal({ isOpen, notes, updateOrder, onClose }: Props) {
  const {
    models: { form, isSaving },
    operations: { submit },
  } = useNotesModalComponent({ isOpen, onClose, updateOrder });
  return (
    <FormModal
      title="Notes"
      open={isOpen}
      onCancel={onClose}
      isSaving={isSaving}
      onSave={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => void submit(data)}
        initialValues={{ notes }}
      >
        <Form.Item name="notes">
          <Input.TextArea autoFocus rows={4} aria-label="Notes" />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
