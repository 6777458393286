import { useMemo } from 'react';
import { FilterOption, FilterValue, FilterSelection } from '../filter-panel/types';
import { optionToValue } from '../filter-panel/optionToValue';
import { ComponentData } from '../../../shared/types';

type Props = {
  options: FilterOption[];
  hasAllOption?: boolean;
  selection?: FilterSelection;
  onChange: (value: FilterValue[] | null) => void;
};

type Models = {
  selectedValues: Set<string>;
  fullOptions: FilterOption[];
};

type Operations = {
  toggleOption: (key: string, checked: boolean) => void;
};

export const ALL_OPTION_KEY = 'ALL';
const ALL_OPTION: FilterOption = { value: ALL_OPTION_KEY, title: 'All' };

export function useQuickFilterComponent({
  options,
  hasAllOption,
  selection,
  onChange,
}: Props): ComponentData<Models, Operations> {
  const selectedValues = useMemo(() => {
    if (!selection) return new Set<string>();
    return new Set(Array.isArray(selection) ? selection.map((x) => x.value) : [selection.value]);
  }, [selection]);
  const fullOptions = useMemo(
    () => (hasAllOption ? [ALL_OPTION, ...options] : options),
    [hasAllOption, options],
  );

  const toggleOption = (key: string, checked: boolean) => {
    if (key === ALL_OPTION_KEY) {
      onChange(null);
      return;
    }
    const newSet = new Set(selectedValues);
    if (checked) {
      newSet.add(key);
    } else {
      newSet.delete(key);
    }

    onChange(
      newSet.size ? options.filter((opt) => newSet.has(opt.value)).map(optionToValue) : null,
    );
  };

  return {
    models: { selectedValues, fullOptions },
    operations: { toggleOption },
  };
}
