import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  .ant-tag-checkable-checked {
    background: var(--blue-6);
  }
`;

export const Title = styled.span`
  font-weight: 700;
  margin-right: 16px;
  font-size: 12px;
`;
