import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export function useUpdateEffect(fn: EffectCallback, deps?: DependencyList) {
  const isMounting = useRef(true);
  /* eslint-disable-next-line consistent-return */
  useEffect(() => {
    if (isMounting.current) {
      isMounting.current = false;
    } else {
      return fn();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, deps);
}
