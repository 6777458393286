import { AutoComplete } from 'antd';
import { SearchInput } from 'components/search-input';
import { ComponentPropsWithRef } from 'react';
import { QueryProps } from './types';
import { useQuerySearchItemComponent } from './useQuerySearchItemComponent';

interface Props extends ComponentPropsWithRef<typeof AutoComplete>, QueryProps {}

export function QuerySearchItem({
  disabled,
  placeholder,
  query,
  queryKey,
  ref,
  value,
  valueKey,
  onChange,
  ...rest
}: Props) {
  const {
    models: { isLoading, options },
    operations: { search },
  } = useQuerySearchItemComponent({ query, queryKey, valueKey });
  return (
    // Using base Ant component here our AutoComplete is based around the encoding of JSON objects
    // TODO: look into modifying our AutoComplete component to support this use case
    <AutoComplete
      options={options}
      onSearch={search}
      disabled={disabled}
      placeholder={placeholder}
      ref={ref}
      value={value}
      onChange={onChange}
      {...rest}
    >
      <SearchInput loading={isLoading} />
    </AutoComplete>
  );
}
