import { Popconfirm, Select } from 'antd';
import { AsnAction } from '@invenco/common-interface/supply';
import { Header } from 'components/header';
import { Container, DetailContainer, MainContainer, SideContainer } from 'styles/layout';
import { Card } from 'components/card';
import { DetailsCard } from 'components/details-card';
import { DetailsBlock } from 'components/details-block';
import { AddressDisplay } from 'components/address-display';
import { DetailList } from 'components/detail-list/DetailList';
import { Button } from 'components/button';

import { FullWidthSelect } from './styles';
import { formatDate } from '../../../../../shared/helpers';
import { useAsnDetailsPage } from './useAsnDetailsPage';
import { asnStatusTitle } from '../../../../../shared/title-maps';
import { StatusLabel } from '../../../../../components/label';
import { asnStatusType } from '../../../../../shared/status-maps';
import { FromAddressModal } from './from-address-modal/FromAddressModal';
import { NotesModal } from './notes-modal/NotesModal';
import { ExpectedDateModal } from './expected-date-modal/ExpectedDateModal';
import { BookingModal } from './booking-modal/BookingModal';
import { ReferenceModal } from './reference-modal/ReferenceModal';
import { AsnLines } from './asn-lines/AsnLines';

export function AsnDetails() {
  const {
    models: {
      isNew,
      isLoading,
      isLocationsLoading,
      isSaving,
      isBooking,
      isBookingModalOpen,
      isCancellingAsn,
      isExpectedDateModalOpen,
      isNotesModalOpen,
      isReferenceModalOpen,
      isAddressModalOpen,
      canEdit,
      canSave,
      canBook,
      canCancelAsn,
      asnDetails,
      asnLines,
      locations,
      breadcrumbs,
      showQtyReceived,
      canEditLocation,
      canEditReference,
    },
    operations: {
      refresh,
      addAsnLine,
      updateAsnLine,
      deleteAsnLine,
      save,
      updateDetails,
      book,
      cancelAsn,
      openBookingModal,
      openExpectedDateModal,
      openNotesModal,
      openReferenceModal,
      openFromAddressModal,
      closeExpectedDateModal,
      closeNotesModal,
      closeFromAddressModal,
      closeBookingModal,
      closeReferenceModal,
      cancel,
      recostLines,
    },
  } = useAsnDetailsPage();

  const renderActionButtons = () => {
    if (isNew) {
      return (
        <>
          <Button onClick={cancel} disabled={isSaving}>
            Cancel
          </Button>
          <Button type="primary" loading={isSaving} disabled={!canSave} onClick={() => void save()}>
            Save draft
          </Button>
        </>
      );
    }
    if (!isLoading) {
      return (
        <>
          {canCancelAsn ? (
            <Popconfirm
              placement="bottomRight"
              title="Are you sure you want to continue?"
              onConfirm={() => void cancelAsn()}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" disabled={!canCancelAsn} loading={isCancellingAsn}>
                Cancel ASN
              </Button>
            </Popconfirm>
          ) : null}
          {canBook ? (
            <Button
              type="primary"
              disabled={!canBook}
              loading={isBooking}
              onClick={openBookingModal}
            >
              Book
            </Button>
          ) : null}
        </>
      );
    }
    return null;
  };
  const dates = [
    { label: 'Expected', date: asnDetails.expectedAt },
    { label: 'Arrived', date: asnDetails.arrivedAt },
    { label: 'Closed', date: asnDetails.closedAt },
  ].filter(({ date }) => date);

  return (
    <Container>
      <Header
        title={isNew ? 'New ASN' : asnDetails.name}
        links={breadcrumbs}
        backURL="/supply/asns"
        loading={isLoading}
        loadDescription
        onRefresh={!isNew ? refresh : undefined}
        actionContent={renderActionButtons()}
        extraContent={
          asnDetails?.status && (
            <StatusLabel status={asnDetails?.status} type={asnStatusType} title={asnStatusTitle} />
          )
        }
      />
      <DetailContainer>
        <MainContainer>
          <Card title="Items" count={isLoading ? undefined : asnLines.length}>
            <AsnLines
              asnLines={asnLines}
              loading={isLoading}
              editable={canEdit}
              canDeleteLines={isNew}
              addAsnLine={addAsnLine}
              updateAsnLine={updateAsnLine}
              deleteAsnLine={deleteAsnLine}
              showQtyReceived={showQtyReceived}
              recostLines={recostLines}
              canRecost={asnDetails?.allowableActions?.includes(AsnAction.RECOST)!}
            />
          </Card>
        </MainContainer>
        <SideContainer>
          <DetailsCard title="To Warehouse">
            <DetailsBlock>
              <FullWidthSelect
                aria-label="To Warehouse"
                value={asnDetails.locationName}
                onChange={(name) => void updateDetails({ locationName: name as string })}
                loading={isLoading || isLocationsLoading}
                disabled={!canEditLocation}
              >
                {locations.map((location) => (
                  <Select.Option key={location.id} value={location.name}>
                    {location.name}
                  </Select.Option>
                ))}
              </FullWidthSelect>
            </DetailsBlock>
          </DetailsCard>
          <DetailsCard title="From Address" hasEditButton={canEdit} onEdit={openFromAddressModal}>
            <DetailsBlock>
              <AddressDisplay loading={isLoading} address={asnDetails.fromAddress} />
            </DetailsBlock>
          </DetailsCard>
          <DetailsCard title="Dates" hasEditButton={canEdit} onEdit={openExpectedDateModal}>
            <DetailsBlock loading={isLoading}>
              <DetailList
                items={dates.map(({ label, date }) => ({
                  label,
                  value: formatDate(date as string),
                }))}
              />
            </DetailsBlock>
          </DetailsCard>
          <DetailsCard
            title="Reference"
            hasEditButton={canEditReference}
            onEdit={openReferenceModal}
          >
            <DetailsBlock loading={isLoading}>{asnDetails.reference}</DetailsBlock>
          </DetailsCard>
          <DetailsCard title="Notes" onEdit={openNotesModal} hasEditButton={canEdit}>
            <DetailsBlock loading={isLoading}>{asnDetails.note}</DetailsBlock>
          </DetailsCard>
        </SideContainer>
      </DetailContainer>
      <ExpectedDateModal
        isOpen={isExpectedDateModalOpen}
        onClose={closeExpectedDateModal}
        expectedAt={asnDetails.expectedAt}
        updateAsn={updateDetails}
      />
      <NotesModal
        isOpen={isNotesModalOpen}
        onClose={closeNotesModal}
        updateAsn={updateDetails}
        note={asnDetails.note}
      />
      <FromAddressModal
        isOpen={isAddressModalOpen}
        onClose={closeFromAddressModal}
        updateAsn={updateDetails}
        fromAddress={asnDetails.fromAddress}
      />
      <BookingModal
        isOpen={isBookingModalOpen}
        onClose={closeBookingModal}
        bookAsn={book}
        expectedAt={asnDetails.expectedAt}
        locationName={asnDetails.locationName}
      />
      <ReferenceModal
        isOpen={isReferenceModalOpen}
        onClose={closeReferenceModal}
        updateAsn={updateDetails}
        reference={asnDetails.reference}
      />
    </Container>
  );
}
