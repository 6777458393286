import { ServiceAgreementDTO } from '@invenco/common-interface/accounts';
import { Input, Select } from 'antd';
import { DatePicker } from 'components/date-picker';
import { useServiceAgreementModalComponent } from './useServiceAgreementModalComponent';
import { FormModal } from '../../../../../components/form-modal';
import { Form } from '../../../../../components/form';
import { Result } from '../../../../../shared/helpers/Result';
import { LocalCalendarDate } from '../../../../../shared/domain/LocalCalendarDate';
import { DEFAULT_DATE_FORMAT } from '../../../../../constants';

type Props = {
  isOpen: boolean;
  serviceAgreement?: Partial<ServiceAgreementDTO>;
  onClose: () => void;
  isSaving?: boolean;
  accountId: string;
  isTerminate?: boolean;
  addServiceAgreement: (data: Partial<ServiceAgreementDTO>) => Promise<Result>;
  updateServiceAgreement: (data: Partial<ServiceAgreementDTO>) => Promise<Result>;
  terminateServiceAgreement: (data: Partial<ServiceAgreementDTO>) => Promise<Result>;
};

export function ServiceAgreementModal({
  isOpen,
  isSaving,
  serviceAgreement,
  onClose,
  addServiceAgreement,
  updateServiceAgreement,
  accountId,
  isTerminate,
  terminateServiceAgreement,
}: Props) {
  const {
    models: { isLoadingLocations, form, locations },
    operations: { submit },
  } = useServiceAgreementModalComponent({
    isOpen,
    onClose,
    addServiceAgreement,
    updateServiceAgreement,
    accountId,
    isTerminate,
    terminateServiceAgreement,
  });

  const getFormTitle = () => {
    if (isTerminate) {
      return 'Terminate Service Agreement';
    }
    return serviceAgreement?.id ? 'Edit Service Agreement' : 'Add Service Agreement';
  };

  return (
    <FormModal
      open={isOpen}
      onCancel={onClose}
      onSave={() => form.submit()}
      isSaving={isSaving}
      title={getFormTitle()}
    >
      <Form
        form={form}
        layout="vertical"
        name="tenant"
        onFinish={(data) => void submit({ ...data, id: serviceAgreement?.id })}
        initialValues={{
          ...serviceAgreement,
          commencementDate: serviceAgreement?.commencementDate
            ? LocalCalendarDate.fromISODate(serviceAgreement.commencementDate).toJSDate()
            : null,
          terminationDate: serviceAgreement?.terminationDate
            ? LocalCalendarDate.fromISODate(serviceAgreement.terminationDate).toJSDate()
            : null,
        }}
      >
        {serviceAgreement?.id && (
          <Form.Item name="name" label="Name">
            <Input disabled />
          </Form.Item>
        )}

        <Form.Item
          name="locationId"
          label="Location"
          rules={[{ required: true, message: 'Please select a location' }]}
        >
          <Select loading={isLoadingLocations} disabled={!!serviceAgreement?.id}>
            {locations?.map((location) => (
              <Select.Option key={location.id} value={location.id}>
                {location.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="commencementDate"
          label="Commencement Date"
          rules={[{ required: true, message: 'Please enter commencement date' }]}
        >
          <DatePicker format={DEFAULT_DATE_FORMAT} disabled={isTerminate} />
        </Form.Item>
        {isTerminate && (
          <Form.Item
            name="terminationDate"
            label="Termination Date"
            rules={[{ required: true, message: 'Please enter termination date' }]}
          >
            <DatePicker format={DEFAULT_DATE_FORMAT} />
          </Form.Item>
        )}
      </Form>
    </FormModal>
  );
}
