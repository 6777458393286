import styled from 'styled-components';
import { spaceHorizontalChildren } from '../../styles/mixins';

export const TitleLine = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  ${spaceHorizontalChildren(0.5)}
`;

export const ContentTitle = styled.div`
  font-weight: 700;
  margin-bottom: 6px;
`;
