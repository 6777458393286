import type { MembershipDTO } from '@invenco/common-interface/accounts';

import { FormModal } from 'components/form-modal';
import { Form } from 'components/form';
import { Input } from 'antd';
import { useAddUserModalComponent } from './useAddUserModalComponent';
import { Result } from '../../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  isAdding?: boolean;
  onClose: () => void;
  addUser: (membership: Partial<MembershipDTO>) => Promise<Result>;
};

export function AddUserModal({ isOpen, isAdding, onClose, addUser }: Props) {
  const {
    models: { form },
    operations: { submit },
  } = useAddUserModalComponent({ isOpen, addUser, onClose });

  return (
    <FormModal
      open={isOpen}
      onCancel={onClose}
      onSave={() => form.submit()}
      isSaving={isAdding}
      title="Invite User"
    >
      <Form form={form} layout="vertical" name="membership" onFinish={(data) => void submit(data)}>
        <Form.Item
          name="userEmail"
          label="User Email"
          rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]}
        >
          <Input autoFocus />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
