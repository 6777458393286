import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { AddFilterButton, ClearButton, Container, FilterTags } from './styles';
import { Tag } from '../../tag/Tag';
import { PopoverSelect } from '../../popover-select';
import { Filter, FilterSelection, FilterSelections } from './types';
import { useFilterPanelComponent } from './useFilterPanelComponent';
import { truncateText } from '../../../shared/helpers';

// controls the length of each displayed selected filter value in the tag of a multi-select filter
const MIN_VALUE_LENGTH = 10;
const MAX_VALUE_LENGTH = 35;
const VALUE_LENGTH_STEP = 5;

const VALUE_LENGTH_ITERATIONS = Math.round(
  (MAX_VALUE_LENGTH - MIN_VALUE_LENGTH) / VALUE_LENGTH_STEP,
);

const getSelectionLabel = (value?: FilterSelection) => {
  if (!value) return undefined;
  const valueArray = Array.isArray(value) ? value : [value];
  const maxLength =
    MAX_VALUE_LENGTH - VALUE_LENGTH_STEP * Math.min(valueArray.length - 1, VALUE_LENGTH_ITERATIONS);
  return valueArray.map((x) => truncateText(x.title, maxLength)).join(', ');
};

export type FilterPanelProps<KeyT extends string> = {
  /**
   * Available filter definitions. Each filter must have a key and title. Options can be static, or change
   * according to onSearch and onRequestMore (if infinitely scrolling) callbacks.
   */
  filters: Filter<KeyT>[];

  /** Object of current filter selections. Ensure this is in state or memoised. */
  selections?: FilterSelections<KeyT>;

  /**
   * Called when a filter value is chosen, updated, or removed. This should be used to update `selections`.
   */
  onChange: (selections: FilterSelections<KeyT>) => any;

  /**
   * Active filter key. By default, this is managed internally but if provided, the open filter will be controlled.
   */
  activeFilter?: KeyT | 'new';

  /**
   * Called when the active filter changes. If controlling the value, this should be used to update `activeFilter`.
   */
  onChangeActiveFilter?: (key?: KeyT | 'new') => any;
};

export function FilterPanel<KeyT extends string>({
  filters,
  selections,
  activeFilter: controlledActiveFilter,
  onChangeActiveFilter,
  onChange,
}: FilterPanelProps<KeyT>) {
  const {
    models: { activeFilter, availableFilterOptions, openFilters },
    operations: { updateSelection, addFilter, deleteFilter, deleteAllFilters, onFilterOpenChange },
  } = useFilterPanelComponent({
    filters,
    selections,
    controlledActiveFilter,
    onChange,
    onChangeActiveFilter,
  });

  return (
    <Container>
      {openFilters.length > 0 && (
        <FilterTags>
          {openFilters.map((filter) => {
            const selection = selections?.[filter.key];
            const hasSelection = Array.isArray(selection) ? selection.length > 0 : !!selection;
            const selectionLabel = getSelectionLabel(selection);

            return (
              <PopoverSelect
                key={filter.key}
                loading={filter.loading}
                searchable={!!filter.onSearch}
                multiple={filter.multiple}
                open={activeFilter === filter.key}
                onOpenChange={(open) => onFilterOpenChange(filter.key, open)}
                value={
                  Array.isArray(selection)
                    ? new Set(selection.map((x) => x.value))
                    : selection?.value
                }
                onChange={(val) => updateSelection(filter, val)}
                options={filter.options}
                onSearch={filter.onSearch}
                canRequestMoreOptions={filter.canRequestMore}
                onRequestMoreOptions={filter.onRequestMore}
              >
                <Tag
                  active={filter.key === activeFilter}
                  prefix={
                    <>
                      <FilterOutlined />
                      {filter.title}
                      {hasSelection && ':'}
                    </>
                  }
                  onDelete={() => {
                    deleteFilter(filter.key);
                  }}
                  aria-label={`${filter.title} filter`}
                  aria-describedby={selectionLabel}
                  deleteLabel={`Remove ${filter.title} filter`}
                >
                  {selectionLabel}
                </Tag>
              </PopoverSelect>
            );
          })}
        </FilterTags>
      )}

      {availableFilterOptions.length > 0 && (
        <PopoverSelect
          searchable={false}
          onChange={(key) => addFilter(key as KeyT)}
          open={activeFilter === 'new'}
          onOpenChange={(open) => onFilterOpenChange('new', open)}
          options={availableFilterOptions}
          closeOnSelect={false}
          placement={openFilters.length > 0 ? 'bottom' : 'bottomLeft'}
        >
          <AddFilterButton type="link" icon={<PlusOutlined aria-hidden="true" />} inline>
            Add filter
          </AddFilterButton>
        </PopoverSelect>
      )}

      {openFilters.length > 1 && (
        <ClearButton type="link" onClick={deleteAllFilters} inline>
          Clear all
        </ClearButton>
      )}
    </Container>
  );
}

FilterPanel.displayName = 'DataTable.FilterPanel';
