import { MembershipDTO } from '@invenco/common-interface/accounts';
import { AccessContext } from '@invenco/common-interface/auth';

import {
  CaretDownOutlined,
  CheckOutlined,
  ControlOutlined,
  LoadingOutlined,
  PoweroffOutlined,
  SafetyCertificateOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown } from 'antd';
import type { MenuItemGroupType, MenuItemType } from 'antd/es/menu/hooks/useItems'; // not exported from antd
import { DEPLOYMENT_STAGE } from 'env';
import {
  ItemContent,
  Membership,
  MenuContainer,
  MenuTrigger,
  ProfileContent,
  StyledDivider,
  StyledSearch,
  UserInfo,
  UserName,
} from './styles';
import { useProfileMenuComponent } from './useProfileMenuComponent';
import { LoadingSkeleton } from '../../loading-skeleton';

// done here to avoid nested component warning
const renderMenuContainer = (menu) => <MenuContainer>{menu}</MenuContainer>;

export function ProfileMenu() {
  const {
    models: {
      changingToMembershipId,
      isMenuOpen,
      hasMultipleMemberships,
      activeMembership,
      platformMembership,
      sellerMemberships,
      warehouseMemberships,
      auth0User,
    },
    operations: { logout, search, selectMembership, setIsMenuOpen, onDevConfig },
  } = useProfileMenuComponent();

  const getMembershipItems = (memberships: MembershipDTO[]) =>
    memberships.map((membership) => {
      const isActive = activeMembership?.id === membership.id;
      const isChangingTo = changingToMembershipId === membership.id;
      return {
        key: membership.id,
        className: isActive ? 'static' : undefined,
        onClick: () =>
          !isActive && !changingToMembershipId && membership.id && selectMembership(membership.id),
        label: (
          <Membership $active={isActive}>
            <span>{membership.accountName}</span>
            {isChangingTo && <LoadingOutlined spin />}
            {isActive && <CheckOutlined />}
          </Membership>
        ),
        'aria-label': `${membership.accountName}${isActive ? ' (current)' : ''}`,
        disabled: !!changingToMembershipId && !isChangingTo && !isActive,
        selected: isChangingTo,
      };
    });

  const items = [
    {
      key: 'profile',
      className: 'static',
      label: (
        <ProfileContent>
          <div>
            <UserOutlined aria-hidden="true" />
            <div>
              <div>{activeMembership?.accountName}</div>
              <UserName>{activeMembership?.userName}</UserName>
            </div>
          </div>
        </ProfileContent>
      ),
    },
    platformMembership && {
      key: 'platform',
      onClick: () =>
        activeMembership?.accessContext !== AccessContext.PLATFORM &&
        platformMembership.id &&
        selectMembership(platformMembership.id),
      label: (
        <ItemContent $active={activeMembership?.accessContext === AccessContext.PLATFORM}>
          <SafetyCertificateOutlined aria-hidden="true" />
          <span>Platform</span>
          {changingToMembershipId === platformMembership.id ? (
            <LoadingOutlined spin />
          ) : (
            activeMembership?.accessContext === AccessContext.PLATFORM && <CheckOutlined />
          )}
        </ItemContent>
      ),
    },
    ...(hasMultipleMemberships
      ? [
          {
            key: 'search-spacer',
            className: 'divider',
            label: <StyledDivider />,
          },
          {
            key: 'search',
            className: 'static',
            label: (
              <ItemContent>
                <StyledSearch
                  prefixIcon
                  placeholder="Search"
                  onChange={(e) => search(e.target.value)}
                  autoFocus
                />
              </ItemContent>
            ),
          },
        ]
      : []),
    sellerMemberships.length && {
      key: 'sellers',
      type: 'group',
      label: 'Sellers',
      children: getMembershipItems(sellerMemberships),
    },
    warehouseMemberships.length && {
      key: 'warehouses',
      type: 'group',
      label: 'Warehouses',
      children: getMembershipItems(warehouseMemberships),
    },
    {
      key: 'spacer',
      className: 'divider',
      label: <StyledDivider />,
    },
    ['LOCAL', 'DEV'].includes(DEPLOYMENT_STAGE) && {
      key: 'dev-config',
      onClick: onDevConfig,
      label: (
        <ItemContent>
          <ControlOutlined aria-hidden="true" />
          <span>Developer Config</span>
        </ItemContent>
      ),
    },
    {
      key: 'logout',
      onClick: logout,
      label: (
        <ItemContent>
          <PoweroffOutlined aria-hidden="true" />
          <span>Logout</span>
        </ItemContent>
      ),
    },
  ].filter((x) => x) as (MenuItemType | MenuItemGroupType)[];

  return (
    <Dropdown
      menu={{ items }}
      dropdownRender={renderMenuContainer}
      open={isMenuOpen}
      onOpenChange={setIsMenuOpen}
      trigger={['click']}
      placement="bottomRight"
    >
      <MenuTrigger>
        <UserInfo>
          {activeMembership && !changingToMembershipId ? (
            <div>
              {activeMembership.accessContext === AccessContext.PLATFORM
                ? 'PLATFORM'
                : activeMembership.accountName}
            </div>
          ) : (
            <LoadingSkeleton width={100} />
          )}
          <div>{auth0User?.name}</div>
        </UserInfo>
        <Avatar
          shape="circle"
          src={auth0User?.picture}
          icon={<UserOutlined />}
          aria-label={`Avatar for ${auth0User?.name}`}
        />
        <CaretDownOutlined aria-label="Open account menu" />
      </MenuTrigger>
    </Dropdown>
  );
}
