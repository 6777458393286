import { ComponentProps, forwardRef } from 'react';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from './Button';

type Props = Omit<ComponentProps<typeof Button>, 'type' | 'size' | 'icon'>;

/** A specific icon button used for triggering deletion. */
export const DeleteButton = styled(
  forwardRef<HTMLElement>((props: Props, ref) => (
    <Button type="text" size="small" icon={<CloseOutlined />} ref={ref} {...props} />
  )),
)<Props>`
  color: var(--gray-7);
`;
