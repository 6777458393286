import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import styled from 'styled-components';

/**
 * Create this DatePicker to replace momentjs with date-fn for Antd DatePicker
 * so that we do not need to use momentjs for all its values
 *
 * see this for more info https://ant.design/docs/react/replace-moment#Use-date-fns
 */
const GeneratedPicker = generatePicker(dateFnsGenerateConfig);
export const DatePicker = styled(GeneratedPicker).attrs({ size: 'small' })`
  border-radius: 4px;
`;
