import styled from 'styled-components';
import { fontStyles } from '../styles';

export const TableHeader = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid var(--gray-4);
  margin-bottom: 0px !important;
`;

export const CategoryCol = styled.div`
  display: flex;
  width: 408px;
  height: 40px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
`;

export const ChargeQtyCol = styled.div`
  display: flex;
  width: 100px;
  height: 40px;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

export const ChargeRateCol = styled.div`
  display: flex;
  width: 100px;
  height: 40px;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

export const ChargeTotalCol = styled.div`
  display: flex;
  width: 120px;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

export const LineItemCategory = styled.div`
  display: flex;
  padding: 16px 0px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  ${fontStyles}
  font-weight: 700;
  margin-bottom: 0px !important;
`;

export const LineItemRow = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  gap: 16px;
  margin-bottom: 0px !important;
`;

export const LineItemCategoryType = styled.div`
  display: flex;
  padding: 16px 0px 16px 16px;
  width: 408px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

export const LineItemChargeQty = styled.div`
  display: flex;
  width: 100px;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

export const LineItemChargeRate = styled.div`
  display: flex;
  width: 100px;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

export const LineItemChargeTotal = styled.div`
  display: flex;
  width: 120px;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

export const LineItemTotal = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  ${fontStyles}
  font-weight: 700;
  border-top: 1px solid var(--gray-4);
`;

export const LineTotal = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  gap: 16px;
`;

export const LineTotalName = styled.div`
  display: flex;
  padding: 16px 0px;
  width: 608px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
`;

export const LineTotalValue = styled.div`
  display: flex;
  width: 120px;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  justify-content: flex-end;
`;
