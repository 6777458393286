import { KitComponentDTO, SkuDTO, SkuType } from '@invenco/common-interface/products';
import { useState } from 'react';
import { SkinOutlined } from '@ant-design/icons';
import { Card } from '../../../../../components/card';
import { Button } from '../../../../../components/button';
import { HorizontallySpaced, VerticallySpaced } from '../../../../../styles/layout';
import { Column, DataTable } from '../../../../../components/data-table';
import { Label } from '../../../../../components/label';
import { ContextMenu } from '../../../../../components/context-menu';
import { formatNumber } from '../../../../../shared/helpers';
import { Result } from '../../../../../shared/helpers/Result';
import { MysteryTitleLine } from './MysteryTitleLine';
import {
  MysterySkuComponent,
  isMysteryGroupComponent,
  MysteryComponent,
} from '../useMysteryComponents';

type Props = {
  sku: Partial<SkuDTO>;
  mysteryComponents: MysteryComponent[];
  editKitComponents: () => void;
  addMysteryComponent: () => void;
  editMysteryComponent: (index: number) => void;
  deleteMysteryComponent: (index: number) => Promise<Result>;
};

const getComponentColumns = <T extends SkuType>(
  skuType?: T,
): Column<T extends SkuType.MYSTERY ? MysterySkuComponent : KitComponentDTO>[] => [
  {
    title: 'SKU / Description',
    key: 'componentSku',
    render: ({ name, description }) => (
      <HorizontallySpaced $factor={0.5}>
        <Label normalCase>{name}</Label>
        <div>{description}</div>
      </HorizontallySpaced>
    ),
  },
  {
    title: skuType === SkuType.MYSTERY ? 'Qty per Option' : 'Quantity',
    key: 'quantity',
    render: (qty: number) => formatNumber(qty),
    align: 'right',
  },
];

const kitColumns = getComponentColumns(SkuType.KIT);
const mysteryColumns = getComponentColumns(SkuType.MYSTERY);

export function ComponentsCard({
  sku,
  mysteryComponents,
  editKitComponents,
  addMysteryComponent,
  editMysteryComponent,
  deleteMysteryComponent,
}: Props) {
  const [deletingMysteryComponentIndex, setDeletingMysteryComponentIndex] = useState<number>();
  return (
    <Card
      title="Components"
      count={
        sku.type === SkuType.MYSTERY
          ? mysteryComponents.length
          : (sku.childKitComponents?.length ?? 0)
      }
      rightContent={
        sku.type === SkuType.MYSTERY ? (
          <Button type="link" onClick={addMysteryComponent} inline>
            Add component
          </Button>
        ) : (
          <Button type="link" onClick={editKitComponents} inline>
            Manage
          </Button>
        )
      }
      spaceChildren
    >
      {sku.type === SkuType.MYSTERY ? (
        <VerticallySpaced $factor={0.5}>
          {mysteryComponents.map((component, index) => {
            const isGrouped = isMysteryGroupComponent(component);
            return (
              <Card
                key={component.id}
                collapsible={isGrouped}
                leftIcon={!isGrouped ? <SkinOutlined aria-hidden="true" /> : undefined}
                initiallyCollapsed={mysteryComponents.length > 3}
                title={<MysteryTitleLine component={component} />}
                evenMargin
                rightContent={
                  <>
                    <ContextMenu
                      buttonLabel="More actions"
                      loading={deletingMysteryComponentIndex === index}
                      items={[{ key: 'delete', label: 'Delete' }]}
                      onSelect={({ key }) => {
                        if (key === 'delete') {
                          setDeletingMysteryComponentIndex(index);
                          void deleteMysteryComponent(index).finally(() => {
                            setDeletingMysteryComponentIndex(undefined);
                          });
                        }
                      }}
                    />
                    <Button type="link" onClick={() => editMysteryComponent(index)} inline>
                      Manage
                    </Button>
                  </>
                }
              >
                {isGrouped && (
                  <DataTable
                    simple
                    columns={mysteryColumns}
                    rows={component.components}
                    emptyText="Please add options"
                  />
                )}
              </Card>
            );
          })}
        </VerticallySpaced>
      ) : (
        <DataTable
          simple
          columns={kitColumns}
          rows={sku.childKitComponents ?? []}
          emptyText="Please add components"
        />
      )}
    </Card>
  );
}
