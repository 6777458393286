import { ShippingPriceBandDTO, ShippingPriceDTO } from '@invenco/common-interface/shipping';
import { AccountDTO, LocationDTO } from '@invenco/common-interface/accounts';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { sortBands } from '../sortBands';
import { ComponentData } from '../../../../shared/types';
import { BreadCrumb } from '../../../../components/header';
import { Result } from '../../../../shared/helpers/Result';
import { useEntityDetailsQuery, useGatewayMutation } from '../../../../shared/hooks/queries';

type Models = {
  isNew: boolean;
  isSaving: boolean;
  isLoading: boolean;
  isLoadingAccounts: boolean;
  isDetailsModalOpen: boolean;
  hasChanges: boolean;
  canSave: boolean;
  shippingPrice: Partial<ShippingPriceDTO>;
  numberOfLastBands?: number;
  accounts: AccountDTO[];
  account?: AccountDTO;
  location?: LocationDTO;
  breadcrumbs: BreadCrumb[];
};

type Operations = {
  refresh: () => Promise<void>;
  updateDetails: (data: Partial<ShippingPriceDTO>) => void;
  save: () => Promise<Result>;
  addBand: (band: ShippingPriceBandDTO) => void;
  updateBand: (index: number, band: Partial<ShippingPriceBandDTO>) => void;
  removeBand: (index: number) => void;
  openDetailsModal: () => void;
  closeDetailsModal: () => void;
};

export function useShippingPriceDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new';
  const { shippingGateway, accountsGateway } = useGateways();
  const navigate = useNavigate();

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [shippingPrice, setShippingPrice] = useState<Partial<ShippingPriceDTO>>({
    validFrom: new Date().toISOString(),
    priceBands: [],
  });

  const { data, isLoading, refetch } = useEntityDetailsQuery({
    parentKey: 'shippingPrices',
    id,
    isNew,
    query: (fetchId, { signal }) => shippingGateway.getShippingPrice(fetchId, { signal }),
  });

  const {
    data: allAccounts,
    isLoading: isLoadingAccounts,
    refetch: refetchAccounts,
  } = useQuery({
    queryKey: ['accounts'],
    queryFn: ({ signal }) => accountsGateway.getAccounts(undefined, { signal }),
  });

  const bands = shippingPrice?.priceBands;
  const numberOfLastBands = useMemo(
    () => (bands?.length ? bands.filter((b) => b.maxCost === undefined).length : undefined),
    [bands],
  );
  const canSave = Boolean(
    shippingPrice.name &&
      shippingPrice.accountId &&
      shippingPrice.validFrom &&
      shippingPrice.validTo &&
      numberOfLastBands === 1,
  );

  const existingName = data?.name;
  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '/shipping', title: 'Shipping' },
      { url: '/shipping/prices', title: 'Prices' },
      {
        url: `/shipping/prices/${id}`,
        title: isNew ? 'New Price' : existingName,
        loading: isLoading,
      },
    ],
    [id, isNew, isLoading, existingName],
  );

  const { data: accountDetails, refetch: refetchAccountDetails } = useQuery({
    queryKey: ['account', shippingPrice.accountId],
    queryFn: ({ signal }) => accountsGateway.getAccount(shippingPrice.accountId || '', { signal }),
  });

  const account = useMemo(
    () => allAccounts?.items.find((a) => a.id === shippingPrice.accountId),
    [allAccounts, shippingPrice.accountId],
  );
  const location = useMemo(
    () => accountDetails?.locations?.find((l) => l.id === shippingPrice.locationId),
    [accountDetails, shippingPrice.locationId],
  );

  useEffect(() => {
    setShippingPrice(
      data
        ? { ...data, priceBands: sortBands(data.priceBands) }
        : {
            validFrom: new Date().toISOString(),
            priceBands: [],
          },
    );
    setHasChanges(false);
  }, [data]);

  const refresh = async () => {
    await Promise.all([refetch(), refetchAccounts(), refetchAccountDetails()]);
  };

  const updateDetails = (newData: Partial<ShippingPriceDTO>) => {
    setShippingPrice({ ...shippingPrice, ...newData });
    setHasChanges(true);
  };

  const addBand = (band: ShippingPriceBandDTO) => {
    setShippingPrice({
      ...shippingPrice,
      priceBands: sortBands([...(shippingPrice.priceBands ?? []), band]),
    });
    setHasChanges(true);
  };

  const updateBand = (index: number, band: Partial<ShippingPriceBandDTO>) => {
    setShippingPrice({
      ...shippingPrice,
      priceBands: sortBands(
        (shippingPrice.priceBands ?? []).map((b, i) => (i === index ? { ...b, ...band } : b)),
      ),
    });
    setHasChanges(true);
  };

  const removeBand = (index: number) => {
    setShippingPrice({
      ...shippingPrice,
      priceBands: (shippingPrice.priceBands ?? []).filter((_, i) => i !== index),
    });
    setHasChanges(true);
  };

  const { mutate: save, isPending: isSaving } = useGatewayMutation({
    mutationFn: () => {
      if (isNew) {
        const dto = {
          name: shippingPrice.name,
          accountId: shippingPrice.accountId,
          validFrom: shippingPrice.validFrom,
          validTo: shippingPrice.validTo,
          locationId: shippingPrice.locationId,
          priceBands: shippingPrice.priceBands,
        };

        return shippingGateway.createShippingPrice(dto);
      }

      const dto = {
        name: shippingPrice.name,
        validFrom: shippingPrice.validFrom,
        validTo: shippingPrice.validTo,
        locationId: shippingPrice.locationId,
        priceBands: shippingPrice.priceBands,
      };

      return shippingGateway.updateShippingPrice(id!, dto);
    },
    onSuccess: (dto) => {
      setHasChanges(false);
      if (isNew) {
        navigate(`/shipping/prices/${dto.id}`);
      }
    },
    linkedQuery: ['shippingPrices', id],
    successMessage: `Shipping price ${isNew ? 'created' : 'saved'}`,
  });

  return {
    models: {
      isNew,
      isSaving,
      isLoading,
      isLoadingAccounts,
      isDetailsModalOpen,
      hasChanges,
      canSave,
      shippingPrice,
      numberOfLastBands,
      accounts: allAccounts?.items ?? [],
      account,
      location,
      breadcrumbs,
    },
    operations: {
      refresh,
      updateDetails,
      save,
      addBand,
      updateBand,
      removeBand,
      openDetailsModal: () => setIsDetailsModalOpen(true),
      closeDetailsModal: () => setIsDetailsModalOpen(false),
    },
  };
}
