import { useCallback, useRef } from 'react';

export function usePeriodicCall(
  func: Function,
  { maximumCalls, period }: { maximumCalls: number; period: number },
  deps?: any[],
): [() => void, () => void] {
  const timeout = useRef(-1);
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const callback = useCallback(func, deps ?? []);

  const stop = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = -1;
  }, []);

  const invokeCallback = useCallback(
    (interval = 0) => {
      stop();
      if (interval > 0) {
        callback();
      }
      if (interval < maximumCalls) {
        // window for typing-sake
        timeout.current = window.setTimeout(() => invokeCallback(interval + 1), period);
      }
    },
    [callback, stop, maximumCalls, period],
  );

  return [invokeCallback, stop];
}
