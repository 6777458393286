import { WarehouseTenantDTO } from '@invenco/common-interface/warehousing';
import { DataTable, Column } from 'components/data-table';
import { Container, ListContainer } from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { Card } from '../../../../components/card';
import { useTenantListPage } from './useTenantListPage';

const columns: Column<WarehouseTenantDTO>[] = [
  { title: 'Tenant', key: 'tenantName', dataIndex: 'tenant', render: ({ name }) => name },
  {
    title: 'Tenant Code',
    key: 'tenantCode',
    dataIndex: 'tenant',
    render: ({ code }) => code,
  },
  { title: 'Warehouse', key: 'warehouse', render: ({ name }) => name },
];

export function TenantList() {
  const {
    models: { isLoading, hasError, rows, pagination, total, query },
    operations: { search, navigate, refresh, onClickRow },
  } = useTenantListPage();
  return (
    <Container>
      <Header title="Tenants" links={[{ url: '/tenants', title: 'Tenants' }]} />
      <ListContainer>
        <Card>
          <DataTable.SearchPanel
            loading={isLoading}
            searchPlaceholderText="Search..."
            pagination={pagination}
            total={total}
            defaultSearchValue={query}
            onNavigate={navigate}
            onSearch={search}
            onReload={refresh}
          />

          <DataTable
            columns={columns}
            rows={rows}
            loading={isLoading}
            hasError={hasError}
            onClickRow={onClickRow}
          />
        </Card>
      </ListContainer>
    </Container>
  );
}
