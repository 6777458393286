import { OrderDTO, OrderHoldDTO, OrderLineDTO } from '@invenco/common-interface/sales';
import { List } from './styles';
import { OrderHoldItem } from './OrderHoldItem';

type Props = {
  holds: OrderHoldDTO[];
  orderDetails: Partial<OrderDTO>;
  orderLines: Partial<OrderLineDTO>[];
};

export function OrderHolds({ orderDetails, orderLines, holds }: Props) {
  return (
    <List>
      {holds.map((hold) => (
        <OrderHoldItem
          key={hold.id}
          orderDetails={orderDetails}
          orderLines={orderLines}
          hold={hold}
        />
      ))}
    </List>
  );
}
