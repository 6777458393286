import {
  ActionDTO,
  ConditionDTO,
  PropertyConditionDTO,
  RuleDTO,
} from '@invenco/common-interface/automation';
import { FormItemProps } from 'antd';
import { InputValueDetails } from '../config/types';
import { assertNever } from '../../../../../shared/helpers';

export type FormKey = (string | number)[];

// for type-safety when using form keys
export const rootConditionKey: keyof Pick<RuleDTO, 'condition'> = 'condition';
export const childConditionsKey: keyof Pick<ConditionDTO, 'childConditions'> = 'childConditions';
export const childConditionTypeKey: keyof Pick<ConditionDTO, 'childConditionType'> =
  'childConditionType';
export const conditionPropertyKey: keyof Pick<PropertyConditionDTO, 'property'> = 'property';
export const conditionOperatorKey: keyof Pick<PropertyConditionDTO, 'operator'> = 'operator';
export const conditionValueKey: keyof Pick<PropertyConditionDTO, 'value'> = 'value';
export const actionsKey: keyof Pick<RuleDTO, 'actions'> = 'actions';
export const elseActionsKey: keyof Pick<RuleDTO, 'elseActions'> = 'elseActions';
export const actionTypeKey: keyof Pick<ActionDTO, 'type'> = 'type';
export const actionParamsKey: keyof Pick<ActionDTO, 'params'> = 'params';

export const getValueViaFormKey = <Output = unknown>(
  obj: Record<string, any>,
  key: FormKey,
): Output => {
  let value: any = obj;
  // eslint-disable-next-line no-restricted-syntax
  for (const k of key) {
    value = value[k];
    if (!value) return value;
  }
  return value;
};

/** Get form field validation rules.
 * @param type The type of the value.
 * @param required Whether the value is required.
 */
export const getFormItemRules = ({
  type,
  required,
}: Pick<InputValueDetails, 'type' | 'required'>): FormItemProps['rules'] => {
  const rules = !required ? [] : [{ required: true, message: 'Please provide a value' }];
  switch (type) {
    case 'float':
      return [...rules, { pattern: /^-?\d*(\.\d+)?$/, message: 'Please provide a valid number' }];
    case 'integer':
      return [...rules, { pattern: /^-?\d*$/, message: 'Please provide a valid integer' }];
    case 'email':
      return [...rules, { type: 'email', message: 'Please provide a valid email' }];
    case 'string':
    case 'date':
    case 'search':
    case 'select':
    case 'array':
      return rules;
    default:
      return assertNever(type);
  }
};
