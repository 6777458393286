import { ListingDTO, ListingStatus, ListingStrategy } from '@invenco/common-interface/sales';
import { Radio } from 'components/radio';
import { useEditListingsModalComponent } from './useEditListingsModalComponent';
import { FormModal } from '../../../../../../components/form-modal';
import { Form } from '../../../../../../components/form';
import { StatusLabel } from '../../../../../../components/label';
import { listingStatusType } from '../../../../../../shared/status-maps';
import { listingStatusTitle, listingStrategyTitle } from '../../../../../../shared/title-maps';
import { ListingDescription, Footer, StyledFormItem } from './styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedListings: ListingDTO[];
  updateListings: (newData: Partial<ListingDTO>) => Promise<void>;
};

const INVALID_LISTING_STRATEGIES = [ListingStrategy.STATIC_RINGFENCE];

export function EditListingsModal({ isOpen, onClose, selectedListings, updateListings }: Props) {
  const {
    models: { isSaving, form, initialValues },
    operations: { submit, onListingStatusChange, onListingStrategyChange, cancelEditing },
  } = useEditListingsModalComponent({
    isOpen,
    onClose,
    selectedListings,
    updateListings,
  });

  const fetchListingStatusDescription = (status: ListingStatus) => {
    switch (status) {
      case ListingStatus.DRAFT:
        return (
          <ListingDescription aria-label="DRAFT">
            A <span>Draft</span> listing will not sync the listed quantity to the sales channel. Use
            this status to have the sync ignore the listing while you are preparing or editing it.
          </ListingDescription>
        );
      case ListingStatus.ACTIVE:
        return (
          <ListingDescription aria-label="ACTIVE">
            An <span>Active</span> listing will sync the listed quantity to the sales channel. Make
            a listing active when you’re ready to go live and include it in the sync.
          </ListingDescription>
        );
      case ListingStatus.ARCHIVED:
        return (
          <ListingDescription aria-label="ARCHIVED">
            An <span>Archived</span> listing will not sync the listed quantity to the sales channel
            and is considered inactive. Archived listings only appear under the Archived listings
            tab, and will not show under the All listings tab.
          </ListingDescription>
        );
      default:
        break;
    }
    return null;
  };

  const fetchListingStrategyDescription = (strategy: ListingStrategy) => {
    switch (strategy) {
      case ListingStrategy.DYNAMIC_AVAILABLE:
        return (
          <ListingDescription aria-label="DYNAMIC_AVAILABLE">
            A <span>Dynamic</span> listing strategy will list the available inventory from all of
            the fulfillment locations linked to this channel. Use this strategy to make whatever
            inventory you have available for sale in this channel.
          </ListingDescription>
        );
      case ListingStrategy.PRESELL:
        return (
          <ListingDescription aria-label="PRESELL">
            A <span>Presell</span> listing strategy will add the expected inventory from open ASNs
            and the available inventory from the fulfillment locations and list this as available
            for sale in this channel. Use this strategy to presell incoming stock available for sale
            in this channel even before it arrives.
          </ListingDescription>
        );
      case ListingStrategy.SOLD_OUT:
        return (
          <ListingDescription aria-label="SOLD_OUT">
            A <span>Sold out </span> listing strategy will list a fixed value of 0 in this channel.
            Use this strategy to always show 0 available stock for sale in this sales channel, even
            if you have available or expected inventory in the fulfillment locations.{' '}
          </ListingDescription>
        );
      default:
        break;
    }
    return null;
  };

  const fetchListingStrategyFormula = (strategy: ListingStrategy) => {
    switch (strategy) {
      case ListingStrategy.DYNAMIC_AVAILABLE:
        return <div>Listed Qty = Available Qty</div>;
      case ListingStrategy.PRESELL:
        return <div>Listed Qty = Expected Qty + Available Qty</div>;
      case ListingStrategy.SOLD_OUT:
        return <div>Listed Qty = 0</div>;
      default:
        break;
    }
    return null;
  };

  return (
    <FormModal
      onSave={() => form.submit()}
      open={isOpen}
      onCancel={cancelEditing}
      isSaving={isSaving}
      title={`Edit Strategy (${selectedListings.length})`}
      forceRender
    >
      <Form
        form={form}
        name="edit-listings"
        layout="vertical"
        onFinish={(data) => void submit(data)}
        initialValues={initialValues}
      >
        <StyledFormItem
          name="status"
          label="Listing status"
          extra={fetchListingStatusDescription(initialValues.status)}
        >
          <Radio.Group onChange={onListingStatusChange}>
            {Object.values(ListingStatus).map((status) => (
              <Radio key={status} value={status}>
                <StatusLabel status={status} type={listingStatusType} title={listingStatusTitle} />
              </Radio>
            ))}
          </Radio.Group>
        </StyledFormItem>
        <StyledFormItem
          name="strategy"
          label="Stock manipulation strategy"
          extra={fetchListingStrategyDescription(initialValues.strategy)}
        >
          <Radio.Group onChange={onListingStrategyChange}>
            {Object.values(ListingStrategy)
              .filter((ls) => !INVALID_LISTING_STRATEGIES.includes(ls))
              .map((strategy) => (
                <Radio key={strategy} value={strategy}>
                  {listingStrategyTitle[strategy]}
                </Radio>
              ))}
          </Radio.Group>
        </StyledFormItem>
        {initialValues.strategy && (
          <Footer>{fetchListingStrategyFormula(initialValues.strategy)}</Footer>
        )}
      </Form>
    </FormModal>
  );
}
