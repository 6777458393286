import { LocationDTO } from '@invenco/common-interface/accounts';
import { Card } from 'components/card';
import { Header } from 'components/header';
import { Label, StatusLabel } from 'components/label';
import { formatDateTime } from 'shared/helpers';
import { locationStatusType } from 'shared/status-maps';
import { locationStatusTitle } from 'shared/title-maps';
import { Container, ListContainer } from 'styles/layout';
import { DataTable, Column } from 'components/data-table';
import { useLocationListPage, sortOptions } from './useLocationListPage';

const columns: Column<LocationDTO>[] = [
  { title: 'Name', key: 'name' },
  { title: 'Type', key: 'type', render: (type) => <Label>{type}</Label> },
  { title: 'Account', key: 'accountName' },
  { title: 'Warehouse', key: 'warehouseLocationName' },
  {
    title: 'Status',
    key: 'status',
    render: (status) => (
      <StatusLabel status={status} type={locationStatusType} title={locationStatusTitle} />
    ),
  },
  {
    title: 'Created',
    key: 'createdAt',
    render: (createdAt: string) => formatDateTime(createdAt),
  },
];

export function LocationList() {
  const {
    models: {
      isLoading,
      hasError,
      rows,
      pagination,
      total,
      query,
      filters,
      filterSelections,
      sort,
    },
    operations: { search, navigate, changeSort, updateFilters, refresh, onClickRow },
  } = useLocationListPage();

  return (
    <Container>
      <Header links={[{ url: '/locations', title: 'Locations' }]} title="Locations" />
      <ListContainer>
        <Card>
          <DataTable.SearchPanel
            loading={isLoading}
            searchPlaceholderText="Search..."
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
            filters={filters}
            filterSelections={filterSelections}
            onChangeFilterSelections={updateFilters}
            sort={sort}
            sortOptions={sortOptions}
            onChangeSort={changeSort}
          />

          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            hasError={hasError}
            onClickRow={onClickRow}
            sort={sort}
            sortOptions={sortOptions}
            onChangeSort={changeSort}
          />
        </Card>
      </ListContainer>
    </Container>
  );
}
