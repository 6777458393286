import { OrderCurrency } from '@invenco/common-interface/sales';
import { formatInTimeZone } from 'date-fns-tz';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_TIME_ZONE,
  SUPPORTED_CURRENCIES,
} from '../../constants';
import { EnumMap } from '../types';

export const formatDate = (
  inputDate: number | string | Date,
  timezone = DEFAULT_TIME_ZONE,
  format = DEFAULT_DATE_FORMAT,
) => formatInTimeZone(new Date(inputDate), timezone, format);

export const formatDateTime = (
  inputDate: number | string | Date,
  timezone = DEFAULT_TIME_ZONE,
  format = DEFAULT_DATETIME_FORMAT,
) => formatInTimeZone(new Date(inputDate), timezone, format);

export const formatNumber = (number: number | string, precision?: number) => {
  const numStr = precision === undefined ? number.toString() : (+number).toFixed(precision);
  const [int, decimal] = numStr.split('.');
  return [int.replace(/\B(?=(\d{3})+(?!\d))/g, ','), decimal].filter((x) => x).join('.');
};

export const truncateNumber = (number: number, maxPrecision = 2) => {
  const [int, decimal] = number.toString().split('.');
  if (!decimal || maxPrecision === 0) return int;
  return [int, decimal.slice(0, maxPrecision)].join('.');
};

export const formatShortInt = (number: number) => {
  if (number < 10000) return number.toFixed(0);
  if (number < 999500) return `${Math.round(number / 1000)}k`;
  return `${Math.round(number / 1000000)}m`;
};

const currencySymbol: EnumMap<(typeof SUPPORTED_CURRENCIES)[number]> = {
  AUD: '$',
  USD: '$',
  GBP: '£',
  CAD: '$',
  NZD: '$',
  EUR: '€',
  HUF: 'Ft',
  SAR: 'ر.س',
  AED: 'د.إ',
  JPY: '¥',
  SGD: '$',
  THB: '฿',
  MYR: 'RM',
};

export const formatMoney = (amount: number | string, currency: OrderCurrency = OrderCurrency.AUD) =>
  `${currencySymbol[currency]}${formatNumber(amount, 2)}`;

export const formatMeasurement = (value: number, unit: string) => {
  let number = formatNumber(value, 3);
  if (number[number.length - 1] === '0') number = number.slice(0, -1);
  return `${number}${unit.toLocaleLowerCase()}`;
};

export const toTitleCase = (str: string) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toLocaleUpperCase() + txt.substring(1).toLocaleLowerCase(),
  );

export const truncateText = (text: string, maxLength: number) =>
  text.length > maxLength ? `${text.slice(0, maxLength - 3)}...` : text;

export const ensureHttp = (url: string) =>
  url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;

export const cleanTextDescription = (str: string) => str.trim().replace(/[\t\r\n]+/g, '');
