import styled from 'styled-components';
import { Collapse } from 'antd';
import { spaceHorizontalChildren } from '../../../../../styles/mixins';

export const CarrierCollapse = styled(Collapse)<{ $selectable?: boolean }>`
  background: none;

  && {
    .ant-collapse-item {
      &:not(:last-child) {
        border-bottom: 1px solid var(--gray-4);
      }

      ${({ $selectable }) =>
        $selectable &&
        `
        &:first-child {
          border-top: 1px solid var(--gray-4);
        }
        &:last-child {
          border-bottom: 1px solid var(--gray-4);
        }
      `}
    }

    .ant-collapse-header {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 1.6667;
      font-weight: 700;
      min-height: 48px;
      padding: 0;
      ${({ $selectable }) => $selectable && 'margin-left: 32px;'}

      .ant-collapse-arrow {
        right: 0;
      }

      .ant-avatar-square {
        border-radius: 4px;
      }
    }

    && {
      .ant-collapse-content-box {
        //padding: 16px 0;
        padding: 0;
        margin-left: ${({ $selectable }) => ($selectable ? '72px' : '48px')};
      }
    }
  }
`;

export const CarrierServiceLine = styled.div`
  min-height: 48px;
  display: flex;
  align-items: center;
  ${spaceHorizontalChildren(1.5)}

  .ant-checkbox + span {
    padding-left: 24px;
  }

  &,
  .ant-checkbox-wrapper {
    font-size: 12px;
    line-height: 1.6667;
    font-weight: 700;
  }
`;

export const CarrierListTitle = styled.div`
  font-weight: 600;
  min-height: 48px;
  display: flex;
  align-items: center;
  ${spaceHorizontalChildren(0.5)}
`;
