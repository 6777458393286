import {
  QuotedShippingRateDTO,
  ShipmentShippableType,
  QuotePackageDTO,
  QuoteRequestDTO,
} from '@invenco/common-interface/shipping';
import { QuoteRatingMethod } from '@invenco/common-domain/enums/Quote';
import { DimensionsDTO, DimensionUnit, WeightUnit } from '@invenco/common-interface/shared';
import { AccountDTO, LocationDTO } from '@invenco/common-interface/accounts';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import { ComponentData } from '../../../shared/types';
import { useGateways } from '../../../gateways/GatewayProvider';
import { useQueryWithInput } from '../../../shared/hooks/queries';
import { useBatchedRequests } from '../../../shared/hooks/useBatchedRequests';

export type QuoteAddressDTO = QuoteRequestDTO['to'];
export type QuotedRateRow = QuotedShippingRateDTO & { id: string; fromLocationName: string };

type Models = {
  isRequesting: boolean;
  isLoadingAccounts: boolean;
  isLoadingLocations: boolean;
  isAddressModalOpen: boolean;
  canRequest: boolean;
  selectedPackageName?: string;
  quotedRates?: QuotedRateRow[];
  requestsCompleted?: number;
  requestsTotal?: number;
  accounts?: AccountDTO[];
  locations?: LocationDTO[];

  accountId?: string;
  locationId: string | null;
  signatureRequired: boolean;
  address?: QuoteAddressDTO;
  packages: QuotePackageDTO[];
};

type Operations = {
  setAccount: (accountId: string) => void;
  setLocation: (locationId: string | null) => void;
  setSignatureRequired: (value: boolean) => void;
  setAddress: (address: QuoteAddressDTO) => void;
  selectPackage: (pkg: QuotePackageDTO) => void;
  deselectPackage: () => void;
  addNewPackage: () => void;
  updatePackage: (name: string, weight: number, dims: Omit<DimensionsDTO, 'unit'>) => void;
  removePackage: (name: string) => void;
  request: () => Promise<void>;
  openAddressModal: () => void;
  closeAddressModal: () => void;
};

const getPackageName = (index: number) => `Package ${index + 1}`;

const getNewPackage = (index: number) => ({
  name: getPackageName(index),
  weight: { value: 1, unit: WeightUnit.KG },
  dimensions: { width: 10, height: 10, length: 10, unit: DimensionUnit.CM },
});

export function useRequestQuotePage(): ComponentData<Models, Operations> {
  const { accountsGateway, shippingGateway } = useGateways();

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [selectedPackageName, setSelectedPackageName] = useState<string>();

  const [accountId, setAccountId] = useState<string>();
  const [locationId, setLocationId] = useState<string | null>(null);
  const [packages, setPackages] = useState<QuotePackageDTO[]>([getNewPackage(0)]);
  const [address, setAddress] = useState<QuoteAddressDTO>();
  const [signatureRequired, setSignatureRequired] = useState(false);

  const { data: accounts, isLoading: isLoadingAccounts } = useQuery({
    queryKey: ['accounts'],
    queryFn: ({ signal }) => accountsGateway.getAccounts(undefined, { signal }),
    select: ({ items }) => items,
  });

  const { data: locations, isLoading: isLoadingLocations } = useQueryWithInput({
    parentKey: 'locations',
    input: { accountId },
    query: (input, { signal }) => accountsGateway.getLocations(input, { signal }),
    select: ({ items }) => items,
    enabled: !!accountId,
  });

  const canRequest = Boolean(
    accountId && locations && packages.length && address?.address1 && address?.addressType,
  );

  const {
    run: runRequests,
    isRunning: isRequesting,
    results,
    completed: requestsCompleted,
    total: requestsTotal,
  } = useBatchedRequests<LocationDTO, QuotedRateRow[]>();

  const request = async () => {
    if (!locations || !accountId || !address) return;
    await runRequests({
      inputs: locationId ? locations.filter(({ id }) => id === locationId) : locations,
      execute: async (location) => {
        const result = await shippingGateway.getShippingQuote({
          shipment: {
            shippableId: 'N/A',
            shippableType: ShipmentShippableType.FULFILLMENT,
            accountId,
            shipmentDate: new Date().toISOString(),
            signatureRequired,
            from: { locationId: location.id },
            to: address,
            packages,
          },
          parameters: {
            ratingMethod: QuoteRatingMethod.CHEAPEST,
          },
        });
        return result.shippingRates.map((rate, index) => ({
          ...rate,
          id: `${location.id}-${index}`,
          fromLocationName: location.name,
        }));
      },
    });
  };

  const quotedRates = useMemo(
    () =>
      requestsCompleted > 0
        ? results.flatMap(({ output }) => output).sort((a, b) => a.totalCost - b.totalCost)
        : undefined,
    [results, requestsCompleted],
  );

  useEffect(() => {
    if (accounts) {
      setAccountId((prev) => prev || accounts[0]?.id);
    }
  }, [accounts]);

  useEffect(() => {
    setLocationId(null);
  }, [accountId]);

  const addNewPackage = () => {
    const newPackages = [...packages, getNewPackage(packages.length)];
    setPackages(newPackages);
    setSelectedPackageName(newPackages[newPackages.length - 1].name);
  };

  const updatePackage = (name: string, weight: number, dims: Omit<DimensionsDTO, 'unit'>) => {
    setPackages(
      packages.map((pkg) => {
        if (pkg.name === name) {
          const newPkg = cloneDeep(pkg);
          newPkg.weight.value = weight;
          newPkg.dimensions = { ...pkg.dimensions, ...dims };
          return newPkg;
        }
        return pkg;
      }),
    );
  };

  const removePackage = (name: string) => {
    setPackages(
      packages
        .filter((pkg) => pkg.name !== name)
        .map((pkg, index) => ({ ...pkg, name: getPackageName(index) })),
    );
    setSelectedPackageName(undefined);
  };

  return {
    models: {
      isRequesting,
      isLoadingAccounts,
      isLoadingLocations,
      isAddressModalOpen,
      canRequest,
      selectedPackageName,
      accounts,
      locations,
      quotedRates,
      requestsCompleted,
      requestsTotal,

      accountId,
      locationId,
      signatureRequired,
      address,
      packages,
    },
    operations: {
      setAccount: setAccountId,
      setLocation: setLocationId,
      setSignatureRequired,
      setAddress,
      selectPackage: ({ name }) => setSelectedPackageName(name),
      deselectPackage: () => setSelectedPackageName(undefined),
      addNewPackage,
      updatePackage,
      removePackage,
      request,
      openAddressModal: () => setIsAddressModalOpen(true),
      closeAddressModal: () => setIsAddressModalOpen(false),
    },
  };
}
