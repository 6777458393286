import styled from 'styled-components';
import { Progress } from 'antd';
import { Modal } from '../modal';
import { spaceVerticalChildren } from '../../styles/mixins';

export const RunnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${spaceVerticalChildren()}
  > *:last-child {
    flex: 1;
    min-height: 0;
  }
`;

export const StyledProgress = styled(Progress)`
  && {
    display: flex;
    align-items: center;
    .ant-progress-outer {
      margin: 0;
      padding: 0;
      width: unset;
      flex: 1;
    }
    .ant-progress-text {
      width: unset;
    }

    .ant-progress-bg {
      background: var(--red-4);
    }
    .ant-progress-success-bg {
      background: var(--blue-6);
    }
  }
`;

export const FullscreenModal = styled(Modal)`
  && {
    top: 24px;
    max-width: calc(100vw - 48px);
    padding-bottom: 0;

    .ant-modal-content {
      height: calc(100vh - 48px);
      max-height: calc(100vh - 48px);

      .ant-modal-body {
        flex: 1;
      }
    }
  }
`;
