import { ActionName } from '@invenco/common-domain/enums';
import { skuNameValueInputDetails } from './commonValueInputs';

import { ActionDetails } from './types';

export const actionDetails: Record<ActionName, ActionDetails> = {
  ENSURE_ORDER_LINE: {
    title: 'Ensure the order contains',
    params: [
      { key: 'skuName', title: 'SKU Name', required: true, ...skuNameValueInputDetails },
      { key: 'unitPrice', title: 'Unit Price', type: 'float', required: true },
      { key: 'description', title: 'Description', type: 'string' },
      { key: 'qtyOrdered', title: 'Qty Ordered', type: 'integer', required: true },
    ],
  },
  APPLY_MANUAL_HOLD: { title: 'Apply a manual hold' },
  SET_SHIPPING_PROFILE: {
    title: "Set the order's shipping profile to",
    params: [
      {
        key: 'shippingProfileName',
        title: 'Shipping Profile Name',
        required: true,
        type: 'select', // can't search the shipping profiles API
        queryKey: 'shippingProfiles',
        valueKey: 'name',
        query: ({ salesGateway }, _, options) => salesGateway.getShippingProfiles(options),
      },
    ],
  },
  SET_DELIVERY_INSTRUCTIONS: {
    title: "Set the order's delivery instructions to",
    params: [
      {
        key: 'deliveryInstructions',
        title: 'Delivery Instructions',
        required: true,
        type: 'string',
      },
    ],
  },
  SET_PACKING_INSTRUCTIONS: {
    title: "Set the order's packing instructions to",
    params: [
      {
        key: 'packingInstructions',
        title: 'Packing Instructions',
        required: true,
        type: 'string',
      },
    ],
  },
  CREATE_FIRST_FULFILLMENT: { title: 'Create the first fulfillment' },

  SET_WAREHOUSE_OPERATION_OPTIONS: {
    title: 'Set the warehouse operation options',
    params: [
      {
        key: 'packSlipPrint',
        title: 'Pack Slip Print',
        type: 'select',
        options: ['NO_PRINT', 'PRINT_LABEL', 'PRINT_A4'],
      },
      {
        key: 'allowablePickStrategies',
        title: 'Allowable Pick Strategies',
        type: 'select',
        multi: true,
        options: ['DEFAULT', 'BATCH', 'DISCRETE', 'SINGLE', 'TOTE'],
      },
      { key: 'workStreamName', title: 'Workstream Name', type: 'string' },
      { key: 'packMethod', title: 'Pack Method', type: 'string' },
      { key: 'labelTemplate', title: 'Label Template', type: 'string' },
    ],
  },

  // some unsupported presently
  RELEASE_ORDER: { title: 'Release the order', disabled: true },
  REVERT_ORDER_TO_DRAFT: { title: 'Revert the order to draft', disabled: true },
  ADD_ORDER_LINE: { title: 'Add a line to the order', disabled: true },
  SET_ORDER_LINE: { title: 'Set a line on the order', disabled: true },
  TRIGGER_RULE: { title: 'Trigger another rule', disabled: true },
};
