import { Form } from 'components/form';
import { Input } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'components/button';
import { TitleFormWrapper } from './styles';
import { useTitleFormComponent } from './useTitleFormComponent';

type Props = {
  title?: string;
  placeholder?: string;
  ariaLabel?: string;
  onCancel: () => void;
  onSave: (title: string) => void;
};

type FormData = { title: string };

export function TitleForm({ title = '', placeholder, ariaLabel, onCancel, onSave }: Props) {
  const {
    models: { form, inputRef, canCancel },
  } = useTitleFormComponent({ title, onCancel });
  return (
    <TitleFormWrapper>
      <Form
        form={form}
        name="title"
        layout="horizontal"
        initialValues={{ title }}
        onFinish={(data) => onSave((data as FormData).title)}
      >
        <Form.Item name="title" rules={[{ required: true }]}>
          <Input autoFocus ref={inputRef} placeholder={placeholder} aria-label={ariaLabel} />
        </Form.Item>
        <div>
          {canCancel && (
            <Button
              type="text"
              icon={<CloseOutlined />}
              aria-label="Cancel editing title"
              onClick={onCancel}
            />
          )}
          <Button
            icon={<CheckOutlined />}
            type="text"
            onClick={() => form.submit()}
            aria-label="Save title"
          />
        </div>
      </Form>
    </TitleFormWrapper>
  );
}
