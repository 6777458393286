import { OrderCurrency, FulfilmentServiceType } from '@invenco/common-interface/sales';
import { PropertyConditionName } from '@invenco/common-domain/enums';
import { skuNameValueInputDetails } from './commonValueInputs';
import { InputValueDetails } from './types';

export const propertyConditionOverrides: Partial<Record<PropertyConditionName, InputValueDetails>> =
  {
    skuName: skuNameValueInputDetails,
    tagName: {
      type: 'search',
      queryKey: 'tags',
      valueKey: 'name',
      query: ({ salesGateway }, input, options) => salesGateway.getTags(input, options),
    },
    channelName: {
      type: 'search',
      queryKey: 'channels',
      valueKey: 'name',
      query: ({ salesGateway }, input, options) => salesGateway.getChannels(input, options),
    },
    currency: {
      type: 'select',
      options: Object.values(OrderCurrency),
    },
    totalItems: { type: 'integer' },
    totalPrice: { type: 'float' },
    totalVolume: { type: 'float' },
    customerOrderNumber: { type: 'integer' },
    WAREHOUSE_SHIPMENT_TOTAL_ITEMS: { type: 'integer' },
    WAREHOUSE_SHIPMENT_TOTAL_VOLUME: { type: 'float' },
    WAREHOUSE_SHIPMENT_TOTAL_WEIGHT: { type: 'float' },
    WAREHOUSE_SHIPMENT_FULFILLMENT_SERVICE_TYPE: {
      type: 'select',
      options: Object.values(FulfilmentServiceType),
    },
  };
