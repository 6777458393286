import { Space, Form, Select, Input } from 'antd';
import { ShippingMethodType } from '@invenco/common-domain/enums';
import { ShippingMethodDTO } from '@invenco/common-interface/shipping';
import { IncoTerms } from '@invenco/common-interface/sales/ShippingProfileDTO';
import { Container, DetailContainer, MainContainer, SideContainer } from 'styles/layout';
import { Header } from 'components/header';
import { Radio } from 'components/radio';
import { Button } from 'components/button';
import { useWarehouseCarrierServiceDetailsPage } from './useWarehouseCarrierServiceDetailsPage';
import { Card } from '../../../../components/card';
import { DetailsCard } from '../../../../components/details-card';
import { DetailsBlock } from '../../../../components/details-block';
import { EditableList } from '../../../../components/editable-list';

export function WarehouseCarrierServiceDetails() {
  const {
    models: {
      wmsInstances,
      isLoadingWmsInstances,
      carriers,
      isLoadingCarriers,
      carrierAccounts,
      isLoadingCarrierAccounts,
      shippingMethods,
      isLoadingShippingMethods,
      warehouseCarrierService,
      isLoading,
      primaryExportCode,
      secondaryExportCodes,
      isSaving,
      canSave,
    },
    operations: {
      selectWmsInstanceId,
      selectCarrierId,
      selectCarrierAccountId,
      selectCarrierServiceId,
      selectShippingMethodId,
      updatePrimaryExportCode,
      addSecondaryExportCode,
      removeSecondaryExportCode,
      updateWarehouseCarrierService,
      save,
      cancel,
    },
  } = useWarehouseCarrierServiceDetailsPage();

  const pageTitle = [
    warehouseCarrierService?.carrierServiceCode,
    warehouseCarrierService?.signatureRequired ? 'SIG' : 'ATL',
    warehouseCarrierService?.incoTerms,
  ]
    .filter(Boolean)
    .join('_');

  const breadcrumbs = [
    { url: '/warehouseCarrierServices', title: 'Warehouse Carrier services' },
    { url: '', title: pageTitle, loading: isLoading },
  ];

  const renderShippingMethodName = (shippingMethod: ShippingMethodDTO): string =>
    `${shippingMethod.name || ''}_${shippingMethod.type === ShippingMethodType.WAREHOUSE ? shippingMethod.locationId : shippingMethod.warehouseLocationId}_${shippingMethod.accountName || ''}_${shippingMethod.bookingCarrierAccountName || ''}`;

  return (
    <Container>
      <Header
        title={pageTitle}
        documentTitle={pageTitle}
        backURL="/warehouseCarrierServices"
        links={breadcrumbs}
        loadDescription
        actionContent={
          <>
            <Button disabled={isSaving} onClick={cancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              loading={isSaving}
              disabled={!canSave}
              onClick={() => void save()}
            >
              Save
            </Button>
          </>
        }
      />
      <DetailContainer>
        <MainContainer>
          <Card title="Primary export code">
            <Input
              value={primaryExportCode}
              onChange={(e) => updatePrimaryExportCode(e.target.value)}
            />
          </Card>
          <Card
            title="Secondary export codes"
            count={
              isLoading
                ? undefined
                : warehouseCarrierService.codes?.filter((c) => !c.isPrimary).length
            }
            description=""
          >
            <EditableList
              loading={isLoading}
              items={secondaryExportCodes ?? []}
              onAdd={addSecondaryExportCode}
              onRemove={removeSecondaryExportCode}
              descriptor="export code"
            />
          </Card>
        </MainContainer>

        <SideContainer>
          <DetailsCard>
            <DetailsBlock>
              <Form layout="vertical">
                <Form.Item label="Wms Instance">
                  <Select
                    value={warehouseCarrierService.wmsInstanceId}
                    loading={isLoading || isLoadingWmsInstances}
                    options={wmsInstances?.map((instance) => ({
                      value: instance.id,
                      label: instance.code,
                    }))}
                    onChange={selectWmsInstanceId}
                  />
                </Form.Item>
                <Form.Item label="Carrier">
                  <Select
                    value={warehouseCarrierService.carrierId}
                    loading={isLoading || isLoadingCarriers}
                    options={carriers?.map((carrier) => ({
                      value: carrier.id,
                      label: carrier.name,
                    }))}
                    onChange={selectCarrierId}
                  />
                </Form.Item>
                <Form.Item label="Carrier Service">
                  <Select
                    value={warehouseCarrierService.carrierServiceId}
                    options={carriers
                      ?.find((carrier) => carrier.id === warehouseCarrierService.carrierId)
                      ?.carrierServices?.map((carrierService) => ({
                        value: carrierService.id,
                        label: carrierService.name,
                      }))}
                    onChange={selectCarrierServiceId}
                    loading={isLoading || isLoadingCarriers}
                  />
                </Form.Item>
                <Form.Item label="Booking Carrier Account">
                  <Select
                    value={warehouseCarrierService.carrierAccountId}
                    loading={isLoading || isLoadingCarrierAccounts}
                    onChange={selectCarrierAccountId ?? ''}
                  >
                    <Select.Option value="default">Default (ie. null)</Select.Option>
                    {carrierAccounts?.map((carrierAccount) => (
                      <Select.Option key={carrierAccount.id} value={carrierAccount.id}>
                        {carrierAccount.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Shipping Method">
                  <Select
                    value={warehouseCarrierService.shippingMethodId}
                    loading={isLoading || isLoadingShippingMethods}
                    onChange={selectShippingMethodId}
                    disabled={warehouseCarrierService.carrierAccountId === 'default'}
                  >
                    <Select.Option value="default">Default (ie. null)</Select.Option>
                    {shippingMethods?.map((shippingMethod) => (
                      <Select.Option key={shippingMethod.id} value={shippingMethod.id}>
                        <>{renderShippingMethodName(shippingMethod)}</>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </DetailsBlock>
            <DetailsBlock title="Signature on delivery" tooltip="">
              <Radio.Group
                onChange={({ target: { value } }) =>
                  updateWarehouseCarrierService({ signatureRequired: value === 'yes' })
                }
                value={warehouseCarrierService.signatureRequired ? 'yes' : 'no'}
              >
                <Space direction="vertical">
                  <Radio value="yes" description="Parcel must be signed for">
                    Signature required
                  </Radio>
                  <Radio value="no" description="Leave in a safe place">
                    Authority to leave
                  </Radio>
                </Space>
              </Radio.Group>
            </DetailsBlock>

            <DetailsBlock title="Export terms of trade" tooltip="">
              <Radio.Group
                onChange={({ target: { value } }) =>
                  updateWarehouseCarrierService({ incoTerms: value })
                }
                value={warehouseCarrierService.incoTerms}
              >
                <Space direction="vertical">
                  <Radio
                    value={IncoTerms.DDU}
                    description="Delivered with duties and taxes unpaid - receiver to pay."
                  >
                    DDU
                  </Radio>
                  <Radio
                    value={IncoTerms.DDP}
                    description="Delivered with duties and taxes paid - shipper to pay."
                  >
                    DDP
                  </Radio>
                </Space>
              </Radio.Group>
            </DetailsBlock>
          </DetailsCard>
        </SideContainer>
      </DetailContainer>
    </Container>
  );
}
