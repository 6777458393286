import { ShippingPriceBandDTO } from '@invenco/common-interface/shipping';

import { PlusOutlined } from '@ant-design/icons';
import { VerticallySpaced } from '../../../../../styles/layout';
import { AddBandButton, BottomLine, ErrorMessage, Example, StyledList } from './styles';
import { BandItem } from './BandItem';
import { usePriceBands } from './usePriceBands';
import { formatMoney } from '../../../../../shared/helpers';
import { LoadingBandItem } from './LoadingBandItem';

type Props = {
  loading: boolean;
  priceBands: ShippingPriceBandDTO[];
  numberOfLastBands?: number;
  addBand: (band: ShippingPriceBandDTO) => void;
  updateBand: (index: number, band: Partial<ShippingPriceBandDTO>) => void;
  removeBand: (index: number) => void;
};

export function PriceBands({
  loading,
  priceBands,
  numberOfLastBands,
  addBand,
  updateBand,
  removeBand,
}: Props) {
  const {
    models: { editingIndex, isAddingBand, examplePrice, exampleMarkups, exampleTotalMarkup },
    operations: { openAddForm, cancelAdding, saveNewBand, editBand, saveBand, cancelEditing },
  } = usePriceBands({ priceBands, addBand, updateBand });
  return (
    <VerticallySpaced>
      {(priceBands.length > 0 || isAddingBand || loading) && (
        <StyledList>
          {loading ? (
            <>
              <LoadingBandItem />
              <LoadingBandItem />
            </>
          ) : (
            priceBands.map((band, index) => (
              <BandItem
                key={
                  // unfortunately these have nothing guaranteed unique other than the id
                  // eslint-disable-next-line react/no-array-index-key
                  index
                }
                priceBand={band}
                previousBand={priceBands[index - 1]}
                exampleMarkup={exampleMarkups[index]}
                editing={editingIndex === index}
                onSave={(updatedBand) => saveBand(index, updatedBand)}
                onCancel={cancelEditing}
                onEdit={() => editBand(index)}
                onDelete={() => removeBand(index)}
                hideControls={editingIndex !== undefined && editingIndex !== index}
              />
            ))
          )}

          {isAddingBand && (
            <BandItem
              priceBand={{ markup: 0 }}
              previousBand={priceBands[priceBands.length - 1]}
              editing
              onCancel={cancelAdding}
              onSave={saveNewBand}
            />
          )}
        </StyledList>
      )}

      <BottomLine>
        {!isAddingBand && (
          <AddBandButton
            type="link"
            icon={<PlusOutlined />}
            onClick={openAddForm}
            aria-label="Add new band"
          >
            Add new band
          </AddBandButton>
        )}
        {priceBands.length > 0 && (
          <Example>
            For <strong>{formatMoney(examplePrice)}</strong>, the total markup ={' '}
            <strong>{formatMoney(exampleTotalMarkup)}</strong>
          </Example>
        )}
      </BottomLine>

      {numberOfLastBands !== undefined && numberOfLastBands !== 1 && (
        <ErrorMessage>
          {numberOfLastBands === 0 && 'Missing a final band with no maximum cost.'}
          {numberOfLastBands > 1 &&
            'There a multiple bands with no maximum cost. There should only be one.'}
        </ErrorMessage>
      )}
    </VerticallySpaced>
  );
}
