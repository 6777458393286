import styled from 'styled-components';

export const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const ModalDescription = styled.div`
  margin-left: 38px;
  margin-top: 8px;
  font-size: 14px;
`;

export const ModalBody = styled.div`
  padding-top: 16px;
`;

export const ModalIcon = styled.span`
  float: left;
  margin-right: 16px;
  font-size: 22px;
  color: var(--orange-6);
`;

export const ModalButtons = styled.div`
  margin-top: 24px;
  text-align: right;

  button + button {
    margin-left: 8px;
  }
`;
