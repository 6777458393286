import styled, { css } from 'styled-components';
import { Divider, Table, Tabs } from 'antd';
import { autoScroll, spaceHorizontalChildren, spaceVerticalChildren } from '../../styles/mixins';

export const TableContainer = styled.div<{ $scroll?: boolean }>`
  ${spaceVerticalChildren()}
  ${({ $scroll }) =>
    $scroll &&
    `
    height: 100%;
    display: flex;
    flex-direction: column;
  `}
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  ${spaceHorizontalChildren(1.5)}

  button,
  input,
  .ant-input-affix-wrapper {
    border-radius: 4px;
  }
`;

export const FlexSpacer = styled.div`
  flex: 1;
`;

export const RightControls = styled.div`
  display: flex;
  align-items: center;
  ${spaceHorizontalChildren()}
`;

export const PaginationControls = styled.div`
  display: flex;
  align-items: center;
  ${spaceHorizontalChildren(0.5)}
`;

export const TotalCount = styled.div`
  display: flex;
  align-items: center;
  > span:first-child {
    text-transform: uppercase;
    margin-right: 8px;
  }
  > span:last-child {
    background: var(--gray-4);
    border-radius: 4px;
    padding: 0px 8px;
    font-weight: 600;
    color: var(--gray-8);
  }
`;

export const ControlsDivider = styled(Divider)`
  & {
    height: 32px;
    margin: 0;
    border-left-color: var(--gray-4);
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: center;

  .ant-tag-checkable-checked {
    background: var(--blue-6);
  }
`;

export const OptionsTitle = styled.span`
  font-weight: 700;
  margin-right: 16px;
  font-size: 12px;
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    display: block;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    color: var(--blue-6);
  }

  .ant-tabs-ink-bar {
    background: var(--blue-6);
  }

  & {
    margin-left: -32px;
    margin-right: -32px;
  }

  .ant-tabs-nav-wrap {
    margin-left: 32px;
    margin-right: 32px;
  }

  .ant-tabs-tab {
    padding-top: 8px;
  }
`;

const simpleBleedAmount = '16px';
const normalBleedAmount = '32px';

const tableBleed = (amount: string) => `
  margin-left: -${amount};
  margin-right: -${amount};
  td, th {
    &:first-child {
      padding-left: ${amount};
      }
    &:last-child {
      padding-right: ${amount};
      }
  }
`;

const simpleBleedBorder = (side: 'top' | 'bottom') => `
  &:not(:first-child):not(:last-child) {
    border-${side}: 1px solid var(--gray-4);
  }

  &:first-child,
  &:last-child {
    position: relative;
    border-${side}: none;
    &::after {
      content: '';
      position: absolute;
      ${side}: 0;
      height: 1px;
      background-color: var(--gray-4);
    }
  }

  &:first-child::after {
    left: ${simpleBleedAmount};
    right: 0;
  }
  &:last-child::after {
    left: 0;
    right: ${simpleBleedAmount};
  }
`;

// Note on height: table cells don't respond to min-height,
// and will expand anyway when set to a specific height
const regularStyle = css<{ $noHeadingBorder?: boolean }>`
  ${tableBleed(normalBleedAmount)}

  td,
  th {
    border-top: 1px solid var(--gray-4);
    border-bottom: none;
    height: 40px;
  }
  tr.selected td,
  tr.ant-table-expanded-row td {
    height: 36px;
  }

  ${({ $noHeadingBorder }) => $noHeadingBorder && `th { border-top: none; }`}
`;

const simpleStyle = css`
  ${tableBleed(simpleBleedAmount)}

  td,
  th {
    height: 48px;
    border-bottom: none;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  tr.selected td,
  tr.ant-table-expanded-row td {
    height: 44px;
  }

  th {
    ${simpleBleedBorder('bottom')}
  }

  tr.selected td {
    ${simpleBleedBorder('top')}
  }
`;

export const StyledTable = styled(Table)<{
  $simple?: boolean;
  $scroll?: boolean;
  $hasSelectableRows?: boolean;
  $hidePlaceholder?: boolean;
  $noHeadingBorder?: boolean;
}>`
  & {
    max-width: unset;
    ${({ $scroll }) => $scroll && autoScroll('var(--gray-1)')}
  }

  // Ant uses a chain of specifiers for td/th, so high specificity needed to override
  &&& {
    td,
    th {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }

    th {
      background: var(--gray-1);
      font-weight: 600;
      &::before {
        content: none !important;
        width: 0 !important;
      }
    }

    tr.selected td,
    tr.ant-table-expanded-row td {
      background: var(--gray-1);
    }

    // create effectively an 8px separation between the connected rows (as opposed to 16px)
    tr.selected td {
      padding-bottom: 4px !important;
    }
    tr.selected:not(:first-child) td {
      border-top: 1px solid var(--gray-4);
    }
    tr.ant-table-expanded-row td {
      padding-top: 4px !important;
    }

    tr.selected td {
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 0;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 0;
      }
    }
    tr.ant-table-expanded-row td {
      &:first-child {
        border-bottom-left-radius: 8px;
        border-top-left-radius: 0;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 0;
      }
    }

    ${({ $simple }) => ($simple ? simpleStyle : regularStyle)}
  }

  ${({ $hasSelectableRows }) =>
    $hasSelectableRows &&
    `
    .ant-table-row {
      cursor: pointer;
    }
  `}

  ${({ $hidePlaceholder }) =>
    $hidePlaceholder &&
    `
    .ant-table-placeholder {
      display: none;
    }
  `}

  .ant-spin {
    color: var(--blue-6);
  }
  .ant-spin-dot:not(.anticon) {
    // glitch seems to make this show for a split second after loading finishes
    display: none;
  }
`;

export const PrefixContainer = styled.div<{ $align?: 'left' | 'center' | 'right' }>`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: ${({ $align = 'left' }) =>
    ({ left: 'flex-start', center: 'center', right: 'flex-end' })[$align]};
`;

export const ExpandedRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${spaceHorizontalChildren()}
`;

export const RowActionContainer = styled.div`
  > span:first-child {
    font-weight: 700;
  }
  button {
    color: var(--gray-1);
    background: none;
    .anticon {
      // fix for the close button
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &:hover,
    &:focus,
    &:active {
      &,
      .anticon {
        color: var(--gray-6);
      }
    }
    &:disabled,
    &.ant-btn-loading {
      color: var(--gray-7);
      &:hover {
        color: var(--gray-7);
      }
      &::before {
        background: none;
      }
    }
  }
  ${spaceHorizontalChildren()}
`;
