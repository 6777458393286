import { Checkbox } from 'antd';
import { Form } from 'components/form';
import { BillingTitle } from './styles';
import { BillingAddressForm } from './BillingAddressForm';
import { ShippingAddressForm } from './ShippingAddressForm';

type Props = {
  showIgnoreAddressCorrection: boolean;
};

export function AddressForm({ showIgnoreAddressCorrection }: Props) {
  return (
    <>
      <ShippingAddressForm />

      <Form.Item name="copyAddress" valuePropName="checked">
        <Checkbox>Billing address is the same as shipping address</Checkbox>
      </Form.Item>
      {showIgnoreAddressCorrection && (
        <Form.Item name="ignoreAddressCorrection" valuePropName="checked">
          <Checkbox>Skip address correction</Checkbox>
        </Form.Item>
      )}

      <Form.Item noStyle shouldUpdate={(prev, current) => prev.copyAddress !== current.copyAddress}>
        {({ getFieldValue }) =>
          getFieldValue('copyAddress') === false && (
            <>
              <BillingTitle>Billing Address</BillingTitle>
              <BillingAddressForm />
            </>
          )
        }
      </Form.Item>
    </>
  );
}
