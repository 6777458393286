import styled from 'styled-components';

export const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const ConfigureRow = styled.div`
  display: flex;
  gap: 8px;
  input {
    max-width: 120px;
  }
`;
