import { Form } from 'components/form';
import styled from 'styled-components';
import { spaceHorizontalChildren, spaceVerticalChildren, visuallyHidden } from 'styles/mixins';

// ensure all the disabled items have their content visible
export const StyledForm = styled(Form)`
  && {
    .ant-input[disabled],
    .ant-select-disabled .ant-select-selector,
    .ant-select-disabled .ant-select-selection-item-content,
    .ant-picker-input > input[disabled] {
      color: var(--gray-9);
    }
  }
`;

export const RuleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${spaceHorizontalChildren()}

  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 900;
    line-height: 2rem;
  }
  .ant-form-item {
    flex: 1;
  }

  // Hide form labels, while still keeping them visible for screen readers
  .ant-form-item-label {
    ${visuallyHidden}
  }

  > *:last-child {
    display: flex;
    align-items: center;
    ${spaceHorizontalChildren(0.5)}
  }
`;

export const ParentSection = styled.div<{ $hasErrors?: boolean }>`
  padding: 8px 16px 16px;
  background: var(--gray-3);
  border: 1px solid ${({ $hasErrors }) => ($hasErrors ? 'var(--red-5)' : 'var(--gray-5)')};
  border-radius: 16px;
  box-sizing: border-box;
  ${spaceVerticalChildren(0.5)}

  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
`;

export const ChildSection = styled.div<{ $alternate?: boolean }>`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--gray-5);
  background: ${({ $alternate }) => ($alternate ? 'var(--gray-3)' : 'var(--gray-1)')};
  ${spaceVerticalChildren(0.5)}
`;

export const ActionChildSection = styled(ChildSection)`
  display: grid;
  align-items: start;
  gap: 8px;
  grid-template-columns: 1fr 1fr auto;
  // form controls
  > *:not(:last-child) {
    // children start at 1
    &:nth-child(odd) {
      grid-column: 1;
    }
    &:nth-child(even) {
      grid-column: 2;
    }
  }

  @media (max-width: 1280px) {
    grid-template-columns: 1fr auto;
    && {
      > *:not(:last-child) {
        grid-column: 1;
      }
    }
  }

  // delete button container - align with first row of form controls
  > *:last-child {
    grid-row: 1;
    grid-column: 3;
  }
`;
