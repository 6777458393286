import { FormRule } from 'antd';
import { useMemo } from 'react';
import { ComponentData } from '../../shared/types';
import { EditableColumnPattern } from './types';

type Props = {
  rules?: FormRule[];
  required?: boolean;
  pattern?: EditableColumnPattern;
};

type Models = { itemRules?: FormRule[] };
type Operations = { normalize?: (value: any, prevValue?: any) => any };

const transformPattern = (pattern?: EditableColumnPattern): RegExp | undefined => {
  switch (pattern) {
    case 'integer':
      return /^[0-9]*$/;
    case 'float':
      return /^[0-9]*(\.[0-9]{0,2})?$/; // default float is limited to two decimal places
    default:
      return pattern;
  }
};

export function useEditableCellComponent({
  rules,
  required,
  pattern,
}: Props): ComponentData<Models, Operations> {
  const transformedPattern = transformPattern(pattern);

  const itemRules = useMemo(() => {
    const list = [...(rules ?? [])];
    if (required) list.push({ required: true });
    if (transformedPattern) list.push({ pattern: transformedPattern });
    return list;
  }, [rules, required, transformedPattern]);

  const normalize = useMemo(
    () =>
      transformedPattern
        ? (value, prevValue) => (transformedPattern?.test(value) ? value : prevValue)
        : undefined,
    [transformedPattern],
  );

  return {
    models: { itemRules },
    operations: { normalize },
  };
}
