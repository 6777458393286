import { ReactNode } from 'react';
import { DeleteButton, Extra, StyledSpan } from './styles';

type Props = {
  /** The label text of the tag. */
  children: ReactNode;

  /** Whether this tag is active. */
  active?: boolean;

  /** A prefix element, usually an icon. */
  prefix?: ReactNode;

  /** A suffix element, usually an icon. */
  suffix?: ReactNode;

  /** Callback on click. If provided, the tag can be hovered over and pressed. */
  onClick?: () => void;

  /** If provided, a delete button will be shown and this callback will be called when it's clicked. */
  onDelete?: () => void;

  /** The aria-label for the delete button. */
  deleteLabel?: string;

  /** Accessible label for this tag. */
  'aria-label'?: string;

  /** Accessible description for this tag. */
  'aria-description'?: string;
};

export function Tag({
  children,
  active,
  prefix,
  suffix,
  onClick,
  onDelete,
  deleteLabel = 'Delete',
  'aria-label': ariaLabel,
  'aria-description': ariaDescription,
}: Props) {
  return (
    <StyledSpan
      $active={active}
      $clickable={!!onClick}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      aria-pressed={active}
      aria-label={ariaLabel}
      aria-describedby={ariaDescription}
    >
      {prefix && <Extra $active={active}>{prefix}</Extra>}
      {children}
      {suffix && <Extra $active={active}>{suffix}</Extra>}
      {onDelete && (
        <DeleteButton
          $active={active}
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          aria-label={deleteLabel}
        />
      )}
    </StyledSpan>
  );
}
