import { Button } from 'components/button';
import { ContextMenu } from 'components/context-menu';
import { ModalConfirm } from 'components/modal-confirm';
import { useActionButtonsComponent } from './useActionButtonsComponent';

interface Props {
  canSave: boolean;
  isSaving: boolean;
  onCancel: () => any;
  onDelete: () => any;
  onSave: () => any;
  canDelete: boolean;
}

export function ActionButtons({
  canSave,
  isSaving,
  onCancel,
  onDelete,
  onSave,
  canDelete = true,
}: Props) {
  const {
    models: { isShowingModalConfirm, performingAction },
    operations: { submitModalConfirm, closeModal, openModal },
  } = useActionButtonsComponent({ onDelete });
  return (
    <>
      {canDelete && (
        <ContextMenu
          onSelect={({ key }) => {
            if (key === 'delete') void openModal();
          }}
          items={[{ key: 'delete', label: 'Delete' }]}
          buttonLabel="Further actions"
        />
      )}
      <Button
        disabled={isSaving}
        onClick={() => {
          void onCancel();
        }}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        aria-label={isSaving ? 'Saving' : 'Save'}
        loading={isSaving}
        disabled={!canSave}
        onClick={() => {
          void onSave();
        }}
      >
        Save
      </Button>
      <ModalConfirm
        title="Are you sure to delete this workflow?"
        description="This action cannot be undone, and the workflow will be deleted"
        open={isShowingModalConfirm}
        onOk={submitModalConfirm}
        onCancel={closeModal}
        maskClosable={false}
        isSubmitting={performingAction!}
        forceRender
      />
    </>
  );
}
