import { DimensionUnit, WeightUnit } from '@invenco/common-interface/shared';
import { useGateways } from 'gateways/GatewayProvider';
import { useNavigate } from 'react-router-dom';
import { FormInstance } from 'antd';
import { FulfillmentStrategy, SkuType } from '@invenco/common-interface/products';
import { Form } from '../../../../../components/form';
import { ComponentData } from '../../../../../shared/types';
import { useGatewayMutation } from '../../../../../shared/hooks/queries';
import { Result } from '../../../../../shared/helpers/Result';

type Props = {
  onClose: () => void;
};

type Models = {
  isSaving: boolean;
  form: FormInstance;
  initialValues: Record<string, any>;
};

type Operations = {
  submit: (values: Record<string, string>) => Promise<Result>;
};

export function useCreateSkuModalComponent({ onClose }: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const { productsGateway } = useGateways();
  const navigate = useNavigate();

  const { mutate: submit, isPending: isSaving } = useGatewayMutation({
    mutationFn: ({
      type,
      name,
      description,
      barcode,
      dimensionUnit,
      length,
      width,
      height,
      weightUnit,
      weight,
    }: Record<string, string>) => {
      const measurementIsSpecified = !!length || !!width || !!height || !!weight;
      const mappedType = SkuType[type];
      return productsGateway.createSku({
        type: mappedType,
        fulfillmentStrategy:
          mappedType === SkuType.STANDARD ? FulfillmentStrategy.STOCK : FulfillmentStrategy.BUNDLE,
        name,
        description,
        barcode,
        measurement: measurementIsSpecified
          ? {
              dimensionUnit: DimensionUnit[dimensionUnit] ?? DimensionUnit.CM,
              length: parseFloat(length || '0'),
              width: parseFloat(width || '0'),
              height: parseFloat(height || '0'),
              weightUnit: WeightUnit[weightUnit] ?? WeightUnit.KG,
              weight: parseFloat(weight || '0'),
            }
          : undefined,
      });
    },
    onSuccess: (newSku) => {
      onClose();
      navigate(`/products/skus/${newSku.id}`);
    },
    linkedQuery: ['skus', undefined], // undefined is replaced by id from response sku
    successMessage: 'Product created',
  });

  return {
    models: {
      isSaving,
      form,
      initialValues: {
        dimensionUnit: DimensionUnit.CM,
        weightUnit: WeightUnit.KG,
        type: SkuType.STANDARD,
      },
    },
    operations: { submit },
  };
}
