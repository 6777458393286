import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'components/button';
import { BaseRow, RowAction } from './types';
import { RowActionContainer } from './styles';

type Props<T extends BaseRow> = {
  performingAction?: RowAction<T>;
  actions: RowAction<T>[];
  selectionCount: number;
  onAction: (action: RowAction<T>) => void;
  onClose: () => void;
};

export function RowActionContent<T extends BaseRow>({
  performingAction,
  actions,
  selectionCount,
  onAction,
  onClose,
}: Props<T>) {
  return (
    <RowActionContainer>
      <span>{selectionCount} selected:</span>
      {actions.map((action) => (
        <Button
          key={action.key}
          type="text"
          onClick={() => onAction(action)}
          disabled={action.disabled || (!!performingAction && action.key !== performingAction.key)}
          loading={performingAction?.key === action.key}
        >
          {action.title}
        </Button>
      ))}
      <Button type="text" icon={<CloseOutlined />} onClick={onClose} aria-label="Clear selection" />
    </RowActionContainer>
  );
}
