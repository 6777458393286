import { useEffect, useState } from 'react';

/** If value is undefined and isLoading is true, return the previous value. Otherwise, return the new value. */
export const useLoadedValue = <T = any>(value: T, isLoading?: boolean): T => {
  const [loadedValue, setLoadedValue] = useState(value);
  useEffect(() => {
    if (!isLoading || value !== undefined) {
      setLoadedValue(value);
    }
  }, [isLoading, value]);
  return loadedValue;
};
