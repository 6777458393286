import type { UserDTO, MembershipDTO } from '@invenco/common-interface/accounts';
import { ObjectStore } from '../ObjectStore';

type Membership = MembershipDTO & { lastAccessedAt?: Date | null };

type MembershipAccessLog = {
  id: string;
  memberships: Membership[];
};

export class UserMembershipSorter {
  constructor(private readonly objectStore: ObjectStore) {}

  async getUserMembership(user: UserDTO): Promise<Membership[]> {
    if (!user.memberships) return [];

    const memberships = await Promise.all(
      user.memberships?.map(async (membership) => {
        const lastAccessedAt = await this.checkForLastAccessedAt(user, membership.id!);
        return {
          ...membership,
          lastAccessedAt:
            lastAccessedAt || (membership.id === user.activeMembershipId ? new Date() : null),
        };
      }),
    );
    await this.objectStore.putValue<MembershipAccessLog>('membershipAccessLog', {
      id: user.id!,
      memberships,
    });
    return this.getSortedMemberships(user);
  }

  async updateMembershipLastAccessedAt(user: UserDTO, membershipId: string): Promise<Membership[]> {
    const accessLog = await this.objectStore.getValue<MembershipAccessLog>(
      'membershipAccessLog',
      user.id!,
    );
    if (!accessLog) return [];
    const membership = accessLog.memberships.find((m) => m.id === membershipId);
    membership!.lastAccessedAt = new Date();
    await this.objectStore.putValue<MembershipAccessLog>('membershipAccessLog', accessLog);
    return this.getSortedMemberships(user);
  }

  async getSortedMemberships(user: UserDTO): Promise<Membership[]> {
    const accessLog = await this.objectStore.getValue<MembershipAccessLog>(
      'membershipAccessLog',
      user.id!,
    );

    const lastAccessedAccounts = accessLog.memberships
      .filter((membership) => membership.lastAccessedAt)
      .sort((a, b) => (b.lastAccessedAt!.getTime() > a.lastAccessedAt!.getTime() ? 1 : -1));
    const otherAccounts = accessLog.memberships
      .filter((membership) => !membership.lastAccessedAt)
      .sort((a, b) => a.accountName?.localeCompare(b.accountName));

    return [...lastAccessedAccounts, ...otherAccounts];
  }

  private async checkForLastAccessedAt(user: UserDTO, membershipId: string): Promise<Date | null> {
    const accessLog = await this.objectStore.getValue<MembershipAccessLog>(
      'membershipAccessLog',
      user.id!,
    );
    if (!accessLog) return null;
    return accessLog.memberships.find((m) => m.id === membershipId)?.lastAccessedAt || null;
  }
}
