import {
  SkuDTO,
  KitComponentDTO,
  SkuType,
  FulfillmentStrategy,
  CreateSkuComponentGroupDTO,
  PatchSkuComponentGroupDTO,
  SkuComponentGroupDTO,
} from '@invenco/common-interface/products';
import { BaseAxiosGateway, QueryRequestOptions } from '../BaseAxiosGateway';
import { GatewayName, PaginatedResponse } from '../types';

export type GetSkusQuery = {
  take?: number;
  cursor?: string;
  search?: string;
  type?: string;
  skuNames?: string[];
  excludeSkuIds?: string[];
  allowOverTake?: boolean;
};

export class AxiosProductsGateway extends BaseAxiosGateway {
  name: GatewayName = 'products';

  async getSkus(
    query?: GetSkusQuery,
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<SkuDTO>> {
    const params = {
      take: query?.take,
      cursor: query?.cursor,
      q: query?.search,
      type: query?.type,
      names: query?.skuNames?.join(','),
      excludeSkuIds: query?.excludeSkuIds?.join(','),
    };

    const { data } = await this.httpGet('/v1/skus', { params, ...options });
    return { ...data, items: data.skus };
  }

  async getSku(productId: string, options?: QueryRequestOptions): Promise<SkuDTO> {
    const { data } = await this.httpGet(`/v1/skus/${productId}`, options);
    return data.sku;
  }

  async getMany(
    query?: GetSkusQuery,
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<SkuDTO>> {
    const params = {
      take: query?.take,
      cursor: query?.cursor,
      q: query?.search,
      type: query?.type,
      excludeSkuIds: query?.excludeSkuIds?.join(','),
    };
    const { data } = await this.httpPost('/v1/skus/get_many', { data: params, ...options });
    return { ...data, items: data.skus };
  }

  async createSku(sku: Partial<SkuDTO>): Promise<SkuDTO> {
    const { data } = await this.httpPost<{ sku: SkuDTO }>('/v1/skus', { data: { sku } });
    return data.sku;
  }

  async updateSku(id: string, sku: Partial<SkuDTO>): Promise<SkuDTO> {
    const { data } = await this.httpPatch<{ sku: SkuDTO }>(`/v1/skus/${id}`, { data: { sku } });
    return data.sku;
  }

  async importSku(sku: Partial<SkuDTO>): Promise<SkuDTO> {
    const { data } = await this.httpPost<{ sku: SkuDTO }>('/v1/skus/import', { data: { sku } });
    return data.sku;
  }

  async convertSku(
    id: string,
    updates: { type: SkuType; fulfillmentStrategy: FulfillmentStrategy },
  ): Promise<SkuDTO> {
    const { data } = await this.httpPost<{ sku: SkuDTO }>(`/v1/skus/${id}/convert`, {
      data: updates,
    });
    return data.sku;
  }

  async updateKitComponents(
    id: string,
    kitComponents: Partial<KitComponentDTO>[],
  ): Promise<SkuDTO> {
    const { data } = await this.httpPut<{ sku: SkuDTO }>(`/v1/skus/${id}/kit_components`, {
      data: { kitComponents },
    });
    return data.sku;
  }

  async createSkuComponentGroup(
    skuId: string,
    skuComponentGroup: CreateSkuComponentGroupDTO,
  ): Promise<SkuComponentGroupDTO> {
    const { data } = await this.httpPost<{ skuComponentGroup: SkuComponentGroupDTO }>(
      `/v1/skus/${skuId}/component_groups`,
      {
        data: { skuComponentGroup },
      },
    );
    return data.skuComponentGroup;
  }

  async updateSkuComponentGroup(
    skuId: string,
    skuComponentGroupId: string,
    groupData: PatchSkuComponentGroupDTO,
  ): Promise<SkuComponentGroupDTO> {
    const { data } = await this.httpPatch<{ skuComponentGroup: SkuComponentGroupDTO }>(
      `/v1/skus/${skuId}/component_groups/${skuComponentGroupId}`,
      {
        data: { skuComponentGroup: groupData },
      },
    );
    return data.skuComponentGroup;
  }

  async deleteSkuComponentGroup(skuId: string, skuComponentGroupId: string): Promise<void> {
    await this.httpDelete<{ sku: SkuComponentGroupDTO }>(
      `/v1/skus/${skuId}/component_groups/${skuComponentGroupId}`,
    );
  }

  async exportAllSkusToBlob(): Promise<Blob> {
    const { data } = await this.httpGet('/v1/skus/export', { responseType: 'blob' });
    return data;
  }
}
