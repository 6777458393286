import { Select } from 'antd';
import { ShipmentDTO } from '@invenco/common-interface/shipping';
import { OrderCurrency } from '@invenco/common-interface/sales';
import { Form } from 'components/form';
import { FormModal } from 'components/form-modal';
import { Input } from 'components/input';
import { Result } from 'shared/helpers/Result';
import { useSetCostModalComponent } from './useSetCostModalComponent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  shipment?: ShipmentDTO;
  setCost: (data: Partial<ShipmentDTO>) => Promise<Result>;
};

export function SetCostModal({ isOpen, shipment, setCost, onClose }: Props) {
  const {
    models: { form, isSaving },
    operations: { submit },
  } = useSetCostModalComponent({ isOpen, onClose, setCost });
  return (
    <FormModal
      title="Set Cost"
      open={isOpen}
      onCancel={onClose}
      isSaving={isSaving}
      onSave={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => void submit({ ...shipment, ...data })}
        initialValues={shipment}
      >
        <Form.Item
          name="cost"
          label="Cost"
          rules={[
            {
              required: true,
              pattern: /^\d+(\.\d{1,2})?$/,
              message: 'Please enter valid cost',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Currency">
          <Select aria-label="Currency" value={OrderCurrency.AUD} disabled>
            <Select.Option key={OrderCurrency.AUD} value={OrderCurrency.AUD}>
              {OrderCurrency.AUD}
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </FormModal>
  );
}
