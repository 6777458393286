import { WarehouseCarrierServiceDTO } from '@invenco/common-interface/warehousing';
import { DataTable, Column } from 'components/data-table';
import { Button } from 'components/button';
import { Container, ListContainer } from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { Card } from '../../../../components/card';
import { useWarehouseCarrierServiceListPage } from './useWarehouseCarrierServiceListPage';
import { AddWarehouseCarrierServiceModal } from '../add-warehouse-carrier-service-modal/AddWarehouseCarrierServiceModal';

const columns: Column<WarehouseCarrierServiceDTO>[] = [
  { title: 'Carrier', key: 'carrierCode' },
  { title: 'Carrier Service Code', key: 'carrierServiceCode' },
  { title: 'SIG', key: 'signatureRequired', render: (sig) => (sig ? 'TRUE' : 'FALSE') },
  { title: 'Inco', key: 'incoTerms' },
  { title: 'Carrier Account', key: 'shippingMethodBookingAccountName' },
  {
    title: 'Primary Code',
    key: 'code',
    dataIndex: 'codes',
    render: (codes) => codes.find((code) => code.isPrimary)?.code,
  },
  { title: 'WMS', key: 'wmsInstanceName' },
];

export function WarehouseCarrierServiceList() {
  const {
    models: {
      isLoading,
      hasError,
      rows,
      pagination,
      total,
      query,
      isCreateWarehouseCarrierServiceModalOpen,
      wmsInstances,
      isLoadingWmsInstances,
      carriers,
      isLoadingCarriers,
    },
    operations: {
      search,
      navigate,
      refresh,
      openCreateWarehouseCarrierServiceModal,
      closeCreateWarehouseCarrierServiceModal,
      createWarehouseCarrierService,
      onClickRow,
    },
  } = useWarehouseCarrierServiceListPage();
  return (
    <Container>
      <Header
        title="Warehouse Carrier Services"
        links={[{ url: '/warehouseCarrierServices', title: 'Warehouse Carrier Services' }]}
        actionContent={
          <Button type="primary" onClick={openCreateWarehouseCarrierServiceModal}>
            Add warehouse carrier service
          </Button>
        }
      />
      <ListContainer>
        <Card>
          <DataTable.SearchPanel
            loading={isLoading}
            searchPlaceholderText="Search..."
            pagination={pagination}
            total={total}
            defaultSearchValue={query}
            onNavigate={navigate}
            onSearch={search}
            onReload={refresh}
          />

          <DataTable
            columns={columns}
            rows={rows}
            loading={isLoading}
            hasError={hasError}
            onClickRow={onClickRow}
          />
        </Card>
      </ListContainer>
      <AddWarehouseCarrierServiceModal
        isOpen={isCreateWarehouseCarrierServiceModalOpen}
        onClose={closeCreateWarehouseCarrierServiceModal}
        wmsInstances={wmsInstances}
        isLoadingWmsInstances={isLoadingWmsInstances}
        carriers={carriers}
        isLoadingCarriers={isLoadingCarriers}
        createWarehouseCarrierService={createWarehouseCarrierService}
      />
    </Container>
  );
}
