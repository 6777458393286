import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createId } from '@paralleldrive/cuid2';
import {
  AsnAction,
  AsnDTO,
  AsnLineDTO,
  AsnReceiptStatus,
  AsnStatus,
  CreateAsnDTO,
} from '@invenco/common-interface/supply';
import { ComponentData } from 'shared/types';
import { ContactAddressDTO } from '@invenco/common-interface/shared';
import { LocationDTO, LocationStatus } from '@invenco/common-interface/accounts';
import { useGateways } from '../../../../../gateways/GatewayProvider';
import { useMessages } from '../../../../../shared/providers/MessagesProvider';
import { BreadCrumb } from '../../../../../components/header';
import { Result } from '../../../../../shared/helpers/Result';
import {
  useEntityDetailsQuery,
  useGatewayMutation,
  useQueryWithInput,
} from '../../../../../shared/hooks/queries';
import { removeFalsyKeys, removeNullOrUndefinedKeys } from '../../../../../shared/helpers/object';

const getAsnCreateDTO = (asnDetails: Partial<AsnDTO>, asnLines: Partial<AsnLineDTO>[]) =>
  ({
    ...asnDetails,
    fromAddress: asnDetails.fromAddress
      ? (removeFalsyKeys(asnDetails.fromAddress) as ContactAddressDTO)
      : undefined,
    asnLines: asnLines.map(
      ({ skuName, description, qtyExpected, unitPurchaseCost, unitCostTotal }) => ({
        skuName,
        description,
        qtyExpected,
        unitPurchaseCost,
        unitCostTotal,
      }),
    ),
    locationName: asnDetails.locationName,
  }) as CreateAsnDTO;

const getAsnUpdateDTO = (asnDetails: Partial<AsnDTO>) => ({
  ...asnDetails,
  fromAddress: asnDetails.fromAddress
    ? (removeFalsyKeys(asnDetails.fromAddress) as ContactAddressDTO)
    : undefined,
});

type Models = {
  isNew: boolean;
  isLoading: boolean;
  isLocationsLoading: boolean;
  isSaving: boolean;
  isBooking: boolean;
  isBookingModalOpen: boolean;
  isCancellingAsn: boolean;
  isExpectedDateModalOpen: boolean;
  isNotesModalOpen: boolean;
  isReferenceModalOpen: boolean;
  isAddressModalOpen: boolean;
  canEdit: boolean;
  canSave: boolean;
  canBook: boolean;
  canCancelAsn: boolean;
  showQtyReceived: boolean;
  asnDetails: Partial<AsnDTO>;
  asnLines: Partial<AsnLineDTO>[];
  locations: Partial<LocationDTO>[];
  breadcrumbs: BreadCrumb[];
  canEditLocation: boolean;
  canEditReference: boolean;
};

type Operations = {
  refresh: () => Promise<void>;
  save: () => Promise<Result>;
  addAsnLine: (asnLine: Partial<AsnLineDTO>) => Promise<Result>;
  updateAsnLine: (lineId: string, asnLine: Partial<AsnLineDTO>) => Promise<Result>;
  deleteAsnLine: (lineId: string) => void;
  updateDetails: (data: Partial<AsnDTO>) => Promise<Result>;
  book: () => Promise<Result>;
  cancelAsn: () => Promise<Result>;
  openBookingModal: () => void;
  openExpectedDateModal: () => void;
  openReferenceModal: () => void;
  openNotesModal: () => void;
  openFromAddressModal: () => void;
  closeBookingModal: () => void;
  closeExpectedDateModal: () => void;
  closeNotesModal: () => void;
  closeFromAddressModal: () => void;
  closeReferenceModal: () => void;
  cancel: () => void;
  recostLines: (asnLine: Partial<AsnLineDTO>) => Promise<Result>;
};

export function useAsnDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams();
  const { supplyGateway, accountsGateway } = useGateways();
  const messages = useMessages();
  const navigate = useNavigate();

  const [asnDetails, setAsnDetails] = useState<Partial<AsnDTO>>({});
  const [asnLines, setAsnLines] = useState<Partial<AsnLineDTO>[]>([]);

  const [isExpectedDateModalOpen, setIsExpectedDateModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [isReferenceModalOpen, setIsReferenceModalOpen] = useState(false);

  const isNew = id === 'new';
  const canEdit = Boolean(isNew || asnDetails?.allowableActions?.includes(AsnAction.EDIT));
  const canEditReference = Boolean(
    isNew ||
      ([AsnStatus.DRAFT, AsnStatus.OPEN].includes(asnDetails?.status!) &&
        [AsnReceiptStatus.NOT_CREATED, AsnReceiptStatus.WAITING].includes(
          asnDetails?.receiptStatus!,
        )),
  );
  const showQtyReceived =
    asnDetails?.status === AsnStatus.CANCELLED || asnDetails?.status === AsnStatus.CLOSED;
  const canEditLocation = isNew || asnDetails?.status === AsnStatus.DRAFT;

  const { data, isLoading, refetch } = useEntityDetailsQuery({
    parentKey: 'asns',
    id,
    isNew,
    query: (fetchId, { signal }) => supplyGateway.getAsn(fetchId, { signal }),
  });

  const {
    data: locations,
    isLoading: isLocationsLoading,
    refetch: refreshLocations,
  } = useQueryWithInput({
    parentKey: 'locations',
    input: { status: LocationStatus.ACTIVE },
    query: (input, options) => accountsGateway.getLocations(input, options),
    select: ({ items }) => items,
  });

  const setAsnData = (asn?: AsnDTO | null) => {
    if (asn) {
      const { asnLines: newLines, ...details } = asn;
      setAsnDetails(details);
      setAsnLines(newLines ?? []);
    } else {
      setAsnDetails({});
      setAsnLines([]);
    }
  };

  const existingName = asnDetails?.name;
  const breadcrumbs = useMemo(
    () => [
      { url: '', title: 'Supply' },
      { url: '/supply/asns', title: 'ASNs' },
      { url: `/supply/asns/${id}`, title: existingName || 'New', loading: isLoading },
    ],
    [id, existingName, isLoading],
  );

  useEffect(() => {
    setAsnData(data);
  }, [data]);

  useEffect(() => {
    if (isNew && locations && !asnDetails.locationName) {
      setAsnDetails({ ...asnDetails, locationName: locations[0].name });
    }
  }, [isNew, locations, asnDetails]);

  const refresh = async () => {
    await Promise.all([refetch(), refreshLocations()]);
  };

  const { mutate: save, isPending: isCreating } = useGatewayMutation({
    mutationFn: () =>
      supplyGateway.createAsn(
        getAsnCreateDTO({ ...asnDetails, status: AsnStatus.DRAFT }, asnLines),
      ),
    onSuccess: (asn) => {
      messages.success('ASN created', { name: asn.name });
      navigate(`/supply/asns/${asn.id}`);
    },
    linkedQuery: ['asns', id],
  });

  const { mutate: updateExistingAsn, isPending: isUpdating } = useGatewayMutation({
    mutationFn: (asn: Partial<AsnDTO>) => supplyGateway.updateAsn(id!, getAsnUpdateDTO(asn)),
    linkedQuery: ['asns', id],
    successMessage: 'ASN updated',
  });

  const updateDetails = async (asnData: Partial<AsnDTO>) => {
    const asn = { ...asnDetails, ...asnData };
    setAsnDetails(asn);
    return isNew ? Result.ok() : updateExistingAsn(removeNullOrUndefinedKeys(asn));
  };

  // TODO: have booking modal close itself
  const { mutate: book, isPending: isBooking } = useGatewayMutation({
    mutationFn: () => supplyGateway.bookAsn(id!),
    linkedQuery: ['asns', id],
    successMessage: 'ASN booked',
  });

  const { mutate: cancelAsn, isPending: isCancellingAsn } = useGatewayMutation({
    mutationFn: () => supplyGateway.cancelAsn(id!),
    linkedQuery: ['asns', id],
    successMessage: 'ASN cancelled',
  });

  const { mutate: addAsnLineToExisting } = useGatewayMutation({
    mutationFn: (asnLine: Partial<AsnLineDTO>) => supplyGateway.addAsnLine(id!, asnLine),
    onSuccess: (newLine) => setAsnLines([...asnLines, newLine]),
    linkedQuery: ['asns', id],
    linkResponseToQuery: false,
    successMessage: 'Added line to ASN',
  });

  const addAsnLine = async (asnLine: Partial<AsnLineDTO>) => {
    if (isNew) {
      const newLine = { id: createId(), ...asnLine };
      setAsnLines([...asnLines, newLine]);
      return Result.ok();
    }
    return addAsnLineToExisting(asnLine);
  };

  const { mutate: updateExistingAsnLine } = useGatewayMutation({
    mutationFn: ({ id: lineId, ...asnLine }: Partial<AsnLineDTO>) => {
      if (!lineId) throw new Error('Line ID is required');
      return supplyGateway.updateAsnLine(id!, lineId, asnLine);
    },
    onSuccess: (updatedLine) =>
      setAsnLines(asnLines.map((line) => (line.id === updatedLine.id ? updatedLine : line))),
    linkedQuery: ['asns', id],
    linkResponseToQuery: false,
    successMessage: 'Updated line',
  });

  const { mutate: recostLines } = useGatewayMutation({
    mutationFn: (asnLine: Partial<AsnLineDTO>) => {
      if (!asnLine.id) throw new Error('Line ID is required');
      return supplyGateway.recostAsnLines(id!, [asnLine]);
    },
    onSuccess: () => {
      messages.success('Recosted line successfully');
    },
    linkedQuery: ['asns', id],
    linkResponseToQuery: false,
  });

  const updateAsnLine = async (lineId: string, asnLine: Partial<AsnLineDTO>) => {
    if (isNew) {
      setAsnLines(asnLines.map((line) => (line.id === lineId ? { ...line, ...asnLine } : line)));
      return Result.ok();
    }
    return updateExistingAsnLine({ id: lineId, ...asnLine });
  };

  const deleteAsnLine = (lineId: string) => {
    if (!isNew) return;
    setAsnLines(asnLines.filter((line) => line.id !== lineId));
  };

  const cancel = () => navigate('/supply/asns');

  // placed here due dependency on mutations
  const canSave = Boolean(
    !isLoading &&
      !isCreating &&
      !isBooking &&
      !isCancellingAsn &&
      asnLines.length > 0 &&
      asnDetails.fromAddress &&
      asnDetails.expectedAt &&
      asnDetails.locationName,
  );

  const canBook = Boolean(
    !isLoading &&
      !isCreating &&
      !isBooking &&
      !isCancellingAsn &&
      asnDetails?.allowableActions?.includes(AsnAction.BOOK),
  );

  const canCancelAsn = Boolean(
    !isLoading &&
      !isCreating &&
      !isCancellingAsn &&
      asnDetails?.allowableActions?.includes(AsnAction.CANCEL) &&
      asnDetails.receiptStatus !== AsnReceiptStatus.PROCESSING,
  );

  return {
    models: {
      isNew,
      isLoading,
      isLocationsLoading,
      isSaving: isCreating || isUpdating,
      isBooking,
      isBookingModalOpen,
      isCancellingAsn,
      isExpectedDateModalOpen,
      isNotesModalOpen,
      isReferenceModalOpen,
      isAddressModalOpen,
      canEdit,
      canSave,
      canBook,
      canCancelAsn,
      showQtyReceived,
      asnDetails,
      asnLines,
      locations: locations ?? [],
      breadcrumbs,
      canEditLocation,
      canEditReference,
    },
    operations: {
      refresh,
      addAsnLine,
      updateAsnLine,
      deleteAsnLine,
      save,
      updateDetails,
      book,
      cancelAsn,
      openBookingModal: () => setIsBookingModalOpen(true),
      openExpectedDateModal: () => setIsExpectedDateModalOpen(true),
      openNotesModal: () => setIsNotesModalOpen(true),
      openReferenceModal: () => setIsReferenceModalOpen(true),
      openFromAddressModal: () => setIsAddressModalOpen(true),
      closeBookingModal: () => setIsBookingModalOpen(false),
      closeExpectedDateModal: () => setIsExpectedDateModalOpen(false),
      closeNotesModal: () => setIsNotesModalOpen(false),
      closeFromAddressModal: () => setIsAddressModalOpen(false),
      closeReferenceModal: () => setIsReferenceModalOpen(false),
      cancel,
      recostLines,
    },
  };
}
