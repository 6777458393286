import { Button } from 'components/button';
import { Alert } from 'components/alert';
import { FullscreenModal, RunnerContainer, StyledProgress } from './styles';
import { ResultsTable } from './ResultsTable';
import { formatShortInt } from '../../shared/helpers';
import { CreatedRecord, ErrorRecord, TemplateKey } from './types';

type Props = {
  template: TemplateKey;
  title: string;
  open: boolean;
  running: boolean;
  totalCount?: number;
  completedCount?: number;
  createdRecords?: CreatedRecord[];
  errorRecords?: ErrorRecord[];
  onCancel: () => void;
};

export function DataImporterRunnerModal({
  template,
  title,
  open,
  running,
  totalCount,
  completedCount,
  createdRecords,
  errorRecords,
  onCancel,
}: Props) {
  const completedLabel = `${formatShortInt(completedCount ?? 0)}/${formatShortInt(
    totalCount ?? 0,
  )}`;
  return (
    <FullscreenModal
      title={title}
      open={open}
      onCancel={onCancel}
      footer={
        <Button onClick={onCancel} type={running ? 'default' : 'primary'}>
          {running ? 'Close' : 'Done'}
        </Button>
      }
      width="auto"
      // hack to prevent modal from animating when switching from OneSchema
      // it will also prevent it from animating when closing while running, but that'll be a rare occurrence
      transitionName={running ? '' : undefined}
      maskTransitionName={running ? '' : undefined}
    >
      <RunnerContainer>
        {running && (
          <Alert
            type="warning"
            message="Be sure not to navigate away from this page while the import is running, otherwise it will cease."
            showIcon
          />
        )}
        {totalCount !== undefined && (
          <StyledProgress
            percent={((completedCount ?? 0) / totalCount) * 100}
            success={{ percent: ((createdRecords?.length ?? 0) / totalCount) * 100 }}
            format={() => completedLabel}
          />
        )}

        <ResultsTable
          template={template}
          created={createdRecords ?? []}
          errors={errorRecords ?? []}
        />
      </RunnerContainer>
    </FullscreenModal>
  );
}
