import { useCallback, useState } from 'react';
import { Row } from './types';

type Output<T extends Row> = {
  selectedRowsById: Map<string, T>;
  toggleRowSelection: (row: T) => void;
  togglePageSelection: (rows: T[]) => void;
  clearSelection: () => void;
};

export function useRowSelection<T extends Row = Row>(): Output<T> {
  const [selectedRowsById, setSelectedRowsById] = useState(new Map<string, T>());

  const toggleRowSelection = useCallback(
    (row: T) =>
      setSelectedRowsById((prev) => {
        const newMap = new Map(prev);
        const id = row.id.toString();
        if (newMap.has(id)) {
          newMap.delete(id);
        } else {
          newMap.set(id, row);
        }
        return newMap;
      }),
    [],
  );

  const togglePageSelection = useCallback(
    (rows: T[]) =>
      setSelectedRowsById((prev) => {
        const newMap = new Map(prev);
        const everyRowsSelected = rows.every(({ id }) => newMap.has(id.toString()));
        if (everyRowsSelected) {
          rows.forEach(({ id }) => newMap.delete(id.toString()));
        } else {
          rows.forEach((row) => newMap.set(row.id.toString(), row));
        }
        return newMap;
      }),
    [],
  );

  const clearSelection = useCallback(() => setSelectedRowsById(new Map()), []);

  return {
    selectedRowsById,
    toggleRowSelection,
    togglePageSelection,
    clearSelection,
  };
}
