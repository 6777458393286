import styled from 'styled-components';
import { Input as AntInput, InputNumber as AntInputNumber, Select as AntSelect } from 'antd';

export const Input = styled(AntInput)`
  border-radius: 4px;
  padding: 4px 12px;
`;

export const InputNumber = styled(AntInputNumber<number>)`
  border-radius: 4px;
`;

export const Select = styled(AntSelect)`
  && .ant-select-selector {
    border-radius: 4px;
  }
`;
