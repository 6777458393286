import { BaseAxiosGateway, QueryRequestOptions } from '../BaseAxiosGateway';
import { InsightDTO } from '../../shared/types';
import { GatewayName } from '../types';

export class AxiosAnalyticsGateway extends BaseAxiosGateway {
  name: GatewayName = 'analytics';

  async getInsight(
    id: string,
    query?: {
      email?: string;
      firstName?: string;
      lastName?: string;
    },
    options?: QueryRequestOptions,
  ): Promise<{ insight: InsightDTO; lookerEmbedUrl: string }> {
    const params = {
      email: query?.email,
      firstName: query?.firstName,
      lastName: query?.lastName,
    };
    const { data } = await this.httpGet(`/v1/insights/${id}`, { params, ...options });
    return data;
  }

  async getInsights(options?: QueryRequestOptions): Promise<{ items: InsightDTO[] }> {
    const { data } = await this.httpGet('/v1/insights', options);
    return { ...data, items: data.insights };
  }
}
