import styled from 'styled-components';

export const fontStyles = `
font-size: 14px;
line-height: 22px;
color: var(--gray-9);
`;

export const AccountName = styled.div`
  ${fontStyles}
  font-weight: 700;
`;

export const PeriodHeader = styled.div`
  ${fontStyles}
  font-weight: 600;
  padding-bottom: 8px;
`;

export const PeriodRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const PeriodCol = styled.div`
  display: flex;
  width: 100px;
  flex-direction: column;
  align-items: flex-start;
  div {
    ${fontStyles}
    font-weight: 400;
  }
`;
