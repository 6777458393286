/* Common data queries shared across the app */

import { useQuery } from '@tanstack/react-query';
import { useGateways } from '../../../gateways/GatewayProvider';

export const useCurrentUserQuery = () => {
  const { accountsGateway } = useGateways();
  return useQuery({
    queryKey: ['users', 'me'],
    queryFn: ({ signal }) => accountsGateway.getCurrentUser({ signal }),
    staleTime: 5 * 60 * 1000, // 5 mins - to ensure we don't refetch on each page mount
  });
};
