import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useMessages } from '../providers/MessagesProvider';
import { extractErrorMessage } from '../helpers';

const BATCH_SIZE = 50;

export type BatchRequest<Input, Output> = {
  execute: (input: Input) => Promise<Output>;
  inputs: Input[];
};
export type BatchSuccess<Input, Output> = {
  input: Input;
  output: Output;
};
export type BatchFailure<Input> = {
  input: Input;
  error: string;
};

type Props<Input, Output> = {
  latestFirst?: boolean;
  progressMessage?: (state: {
    results: BatchSuccess<Input, Output>[];
    failures: BatchFailure<Input>[];
    started: number;
    total: number;
    isRunning: boolean;
  }) => ReactNode;
  messageKey?: string;
};

// TODO: look into using useMutation here or in conjunction with this hook
export function useBatchedRequests<Input, Output>(options?: Props<Input, Output>) {
  const { latestFirst = false, messageKey = 'BATCH_REQUEST_MSG', progressMessage } = options || {};
  const messages = useMessages();
  const [results, setResults] = useState<BatchSuccess<Input, Output>[]>([]);
  const [failures, setFailures] = useState<BatchFailure<Input>[]>([]);
  const [isRunning, setIsRunning] = useState(false);
  const [total, setTotal] = useState<number>();
  const [started, setStarted] = useState<number>();
  const cancelRef = useRef(false);
  const counterRef = useRef(0);

  useEffect(() => {
    cancelRef.current = false;
    return () => {
      cancelRef.current = true;
    };
  }, []);

  useEffect(() => {
    if (isRunning && progressMessage) {
      messages.loading(
        messageKey,
        progressMessage({ results, failures, started: started ?? 0, total: total ?? 0, isRunning }),
      );
    }
  }, [isRunning, progressMessage, messageKey, messages, started, total, results, failures]);

  const run = useCallback(
    async ({ execute, inputs }: BatchRequest<Input, Output>) => {
      setIsRunning(true);
      setResults([]);
      setFailures([]);
      setStarted(0);
      setTotal(inputs.length);
      counterRef.current += 1;
      const callId = counterRef.current;
      // protects against dismounting and double calling
      const shouldCancel = () => cancelRef.current || callId !== counterRef.current;

      const batches: Input[][] = [];
      inputs.forEach((input) => {
        const batch = batches[batches.length - 1];
        if (!batch || batch.length === BATCH_SIZE) {
          batches.push([input]);
        } else {
          batch.push(input);
        }
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const batch of batches) {
        if (shouldCancel()) {
          break;
        }

        setStarted((current) => (current || 0) + batch.length);
        // eslint-disable-next-line no-await-in-loop
        await Promise.all(
          batch.map(async (input) => {
            try {
              const result = await execute(input);
              if (shouldCancel()) return;
              const record = { input, output: result };
              setResults((current) => (latestFirst ? [record, ...current] : [...current, record]));
            } catch (e) {
              if (shouldCancel()) return;
              const record = { input, error: extractErrorMessage(e) };
              setFailures((current) => (latestFirst ? [record, ...current] : [...current, record]));
            }
          }),
        );
      }

      if (!shouldCancel()) {
        setIsRunning(false);
        if (progressMessage) {
          // only way to get the current state from within the ongoing run call
          setResults((currentResults) => {
            setFailures((currentFailures) => {
              let messageFunc = messages.success;
              if (currentFailures.length) {
                messageFunc = currentResults.length ? messages.warning : messages.error;
              }
              messageFunc(
                progressMessage({
                  results: currentResults,
                  failures: currentFailures,
                  started: inputs.length,
                  total: inputs.length,
                  isRunning: false,
                }),
                { key: messageKey },
              );
              return currentFailures;
            });
            return currentResults;
          });
        }
      }
    },
    [latestFirst, messageKey, messages, progressMessage],
  );

  return {
    run,
    results,
    failures,
    isRunning,
    started,
    completed: results.length + failures.length,
    total,
  };
}
