import { OrderDTO } from '@invenco/common-interface/sales';

import { useCustomerSearchComponent } from './useCustomerSearchComponent';
import { AutoComplete } from '../../../../../../components/auto-complete';

type Props = {
  updateOrder: (data: Partial<OrderDTO>) => any;
  onCreateNewCustomer: () => any;
};

const getLabel = ({ name, email }: Partial<OrderDTO>) => (
  <div>
    <strong>{name}</strong>
    <br />
    {email}
  </div>
);

export function CustomerSearch({ updateOrder, onCreateNewCustomer }: Props) {
  const {
    models: { isLoading, options },
    operations: { search, selectOption },
  } = useCustomerSearchComponent({ updateOrder });

  return (
    <AutoComplete
      loading={isLoading}
      options={options}
      onSelect={selectOption}
      onSearch={search}
      prefixIcon
      placeholder="Select customer"
      label={getLabel}
      prefixButtonLabel="+ Create new customer"
      onPrefixClick={onCreateNewCustomer}
    />
  );
}
