import { SkuSelectionMethod } from '@invenco/common-interface/products';
import {
  selectionMethodOptions,
  findSelectionMethodOption,
  getSelectionMethodsLabel,
} from '../selectionMethods';
import { Select } from '../../../../../components/input';

type Props = {
  value?: SkuSelectionMethod[];
  onChange: (value: SkuSelectionMethod[]) => void;
};

export function SelectSelectionMethods({ value, onChange }: Props) {
  const hasCustomMethod = value?.length && !findSelectionMethodOption(value);
  const currentLabel = getSelectionMethodsLabel(value);
  return (
    <Select
      aria-label="Preferred method of selecting options"
      value={currentLabel}
      onChange={(label) => {
        const option = selectionMethodOptions.find((o) => o.label === label);
        if (option) {
          onChange(option.value);
        }
      }}
    >
      {selectionMethodOptions.map(({ label }) => (
        <Select.Option key={label} value={label}>
          {label}
        </Select.Option>
      ))}
      {hasCustomMethod && <Select.Option value={currentLabel}>{currentLabel}</Select.Option>}
    </Select>
  );
}
