import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';

type QueryKey<Input> = [string, Input];
export type InputQueryFunction<T, Input> = (
  input: Input,
  context: QueryFunctionContext<QueryKey<Input>>,
) => Promise<T>;

type Props<T, Input, Data> = {
  parentKey: string;
  input: Input;
  query: InputQueryFunction<T, Input>;
} & Omit<Partial<UseQueryOptions<T, Error, Data, QueryKey<Input>>>, 'queryFn' | 'queryKey'>;

export function useQueryWithInput<T, Input, Data = T>({
  parentKey,
  input,
  query,
  ...rest
}: Props<T, Input, Data>) {
  return useQuery({
    queryKey: [parentKey, input] as QueryKey<Input>,
    queryFn: (context) => query(input, context),
    ...rest,
  });
}
