import { useCallback, useEffect, useMemo, useState } from 'react';
import { RowAction } from './types';
import { Row } from '../types';
import { useMessages } from '../../../shared/providers/MessagesProvider';
import { RowActionBar } from './RowActionBar';

export type RowActionProps<T extends Row> = {
  actions: RowAction<T>[];
  selectedRowsById?: Map<string, T>;
  onClearSelection: () => void;
  messageKey?: string;
};

export function RowActions<T extends Row>({
  actions,
  selectedRowsById,
  onClearSelection,
  messageKey = 'data-table-actions',
}: RowActionProps<T>) {
  const messages = useMessages();
  const [pendingAction, setPendingAction] = useState<string>();
  const selectedRows = useMemo(
    () => Array.from(selectedRowsById?.values() ?? []),
    [selectedRowsById],
  );

  const isActionAvailable = useMemo(() => {
    const map = new Map<string, boolean>();
    actions.forEach((action) => {
      map.set(action.key, action.canActOnRow ? selectedRows.every(action.canActOnRow) : true);
    });
    return map;
  }, [actions, selectedRows]);

  const onRowAction = useCallback(
    async (action: RowAction<any>) => {
      if (selectedRows.length) {
        setPendingAction(action.key);
        await action.action(selectedRows);
        setPendingAction(undefined);
      }
    },
    [selectedRows],
  );

  useEffect(() => {
    if (selectedRows.length) {
      messages.fixed(
        messageKey,
        <RowActionBar
          pendingAction={pendingAction}
          actions={actions}
          selectionCount={selectedRows.length}
          isActionAvailable={isActionAvailable}
          onAction={onRowAction}
          onClose={onClearSelection}
        />,
      );
    }
    return () => void messages.close(messageKey);
  }, [
    messages,
    selectedRows,
    messageKey,
    actions,
    isActionAvailable,
    pendingAction,
    onRowAction,
    onClearSelection,
  ]);

  return null;
}

RowActions.displayName = 'DataTable.RowActions';
