import styled from 'styled-components';

export const StyledDefList = styled.dl`
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 0 8px;
  margin-bottom: 0;

  dt {
    grid-column: 1;
  }
  dd {
    grid-column: 2;
  }
`;
