import { FormModal } from '../../../../../../components/form-modal';
import { useBookingModalComponent } from './useBookingModalComponent';
import { formatDate } from '../../../../../../shared/helpers';
import { Result } from '../../../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  bookAsn: () => Promise<Result>;
  expectedAt?: string | null;
  locationName?: string | null;
};

export function BookingModal({ isOpen, bookAsn, onClose, expectedAt, locationName }: Props) {
  const {
    models: { isSaving },
    operations: { submit },
  } = useBookingModalComponent({ isOpen, onClose, bookAsn });
  return (
    <FormModal
      title="Confirm Booking"
      open={isOpen}
      onCancel={onClose}
      isSaving={isSaving}
      onSave={submit}
      saveTitle="Book"
    >
      <p>
        Please confirm your booking request to arrive at <strong>[{locationName}]</strong>{' '}
        {expectedAt ? `on the ${formatDate(expectedAt as string)}` : ''}.
      </p>

      <p>
        If you would like to make a change to the arrival date, please click on cancel and edit the
        arrival date on the form.
      </p>
    </FormModal>
  );
}
