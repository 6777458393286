import {
  ActionName,
  ChildConditionType,
  WorkflowConfigurationMap,
  WorkflowScopeType,
  WorkflowStatus,
} from '@invenco/common-domain/enums';
import { WorkflowDTO } from '@invenco/common-interface/automation';
import { BreadCrumb } from 'components/header';
import { useGateways } from 'gateways/GatewayProvider';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Result } from 'shared/helpers/Result';
import { useEntityDetailsQuery, useGatewayMutation } from 'shared/hooks/queries';
import { ComponentData } from 'shared/types';
import {
  NormalizedRuleDTO,
  normalizeRule,
  transformDateToISOStrings,
} from './helpers/ruleNormalization';
import { getDefaultPropertyCondition } from './helpers/conditions';
import { actionDetails } from './config/actions';

type Props = {
  scopeType: WorkflowScopeType;
};

type Models = {
  isNew: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isDetailsModalOpen: boolean;
  canSave: boolean;
  editingRuleIndex?: number;
  workflowDetails: Partial<WorkflowDTO>;
  rules: Partial<NormalizedRuleDTO>[];
  breadcrumbs: BreadCrumb[];
};

type Operations = {
  refresh: () => Promise<void>;
  save: () => Promise<Result>;
  updateDetails: (workflow: Partial<WorkflowDTO>) => void;
  updateActiveStatus: (active: boolean) => void;
  editRule: (ruleIndex: number) => void;
  cancelEditingRule: () => void;
  addRule: () => void;
  updateRule: (ruleIndex: number, rule: Partial<NormalizedRuleDTO>) => void;
  deleteRule: (ruleIndex: number) => void;
  cancel: () => void;
  // We can't use just "delete" because it's a reserved word
  deleteWorkflow: () => Promise<void>;
  openDetailsModal: () => void;
  closeDetailsModal: () => void;
};

const getDefaultNewWorkflow = (scopeType: WorkflowScopeType): Partial<WorkflowDTO> => {
  const config = WorkflowConfigurationMap[scopeType];
  return {
    dataType: config.dataTypes[0],
    triggerType: config.triggerTypes[0],
    status: WorkflowStatus.ACTIVE,
    ...(scopeType === WorkflowScopeType.WMS_INSTANCE ? { scopeType } : {}),
  };
};

const getDefaultNewRule = (scopeType: WorkflowScopeType): Omit<NormalizedRuleDTO, 'name'> => {
  const actionName = Object.keys(
    WorkflowConfigurationMap[scopeType].actionTypeOutcomeAttributes,
  ).find((name) => actionDetails[name] && !actionDetails[name].disabled);
  return {
    condition: {
      childConditionType: ChildConditionType.ALL,
      childConditions: [
        {
          childConditionType: ChildConditionType.ANY,
          childConditions: [getDefaultPropertyCondition(scopeType)],
        },
      ],
    },
    actions: [
      ...(actionName
        ? [
            {
              type: actionName as ActionName,
              params: {},
            },
          ]
        : []),
    ],
    elseActions: [],
  };
};

export function useWorkflowDetailsPage({ scopeType }: Props): ComponentData<Models, Operations> {
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new';

  const { automationGateway } = useGateways();
  const navigate = useNavigate();

  const [workflowDetails, setWorkflowDetails] = useState<Partial<WorkflowDTO>>(
    getDefaultNewWorkflow(scopeType),
  );
  const [rules, setRules] = useState<Partial<NormalizedRuleDTO>[]>([]);
  const [editingRuleIndex, setEditingRuleIndex] = useState<number>();
  const [hasChanges, setHasChanges] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const { data, isLoading, refetch } = useEntityDetailsQuery<WorkflowDTO>({
    parentKey: 'workflows',
    id,
    isNew,
    query: (fetchId, { signal }) => automationGateway.getWorkflow(fetchId, { signal }),
  });

  const existingName = data?.name;
  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '/automation', title: 'Automation' },
      { url: '/automation/workflows', title: 'Workflows' },
      {
        url: `/automation/workflows/${id}`,
        title: isNew ? 'New' : existingName,
        loading: isLoading,
      },
    ],
    [id, isNew, existingName, isLoading],
  );

  const canSave = useMemo(
    () =>
      Boolean(
        (isNew || hasChanges) &&
          editingRuleIndex === undefined &&
          workflowDetails.name &&
          workflowDetails.dataType &&
          workflowDetails.triggerType &&
          (scopeType !== WorkflowScopeType.WMS_INSTANCE || workflowDetails.scopeId) &&
          rules.length &&
          !rules.some((rule) => !rule.name || !rule.condition || !rule.actions?.length),
      ),
    [isNew, hasChanges, workflowDetails, rules, editingRuleIndex, scopeType],
  );

  useEffect(() => {
    if (data) {
      const { rules: newRules, ...details } = data;
      setWorkflowDetails(details);
      setRules(newRules.map((rule) => normalizeRule(rule, scopeType)) ?? []);
    } else {
      setWorkflowDetails(getDefaultNewWorkflow(scopeType));
      setRules([]);
    }
  }, [scopeType, data]);

  const getRequestDTO = () =>
    ({
      scopeType: workflowDetails.scopeType,
      scopeId: workflowDetails.scopeId,
      name: workflowDetails.name,
      description: workflowDetails.description,
      dataType: workflowDetails.dataType,
      triggerType: workflowDetails.triggerType,
      status: workflowDetails.status,
      rules: transformDateToISOStrings(rules),
    }) as WorkflowDTO;

  const refresh = async () => {
    await refetch();
  };

  const updateDetails = (updateData: Partial<WorkflowDTO>) => {
    setWorkflowDetails({ ...workflowDetails, ...updateData });
    setHasChanges(true);
  };

  const updateActiveStatus = (active: boolean) => {
    updateDetails({ status: active ? WorkflowStatus.ACTIVE : WorkflowStatus.INACTIVE });
  };

  const addRule = () => {
    setRules([...rules, getDefaultNewRule(scopeType)]);
    setHasChanges(true);
    setEditingRuleIndex(rules.length);
  };

  const updateRule = (ruleIndex: number, rule: Partial<NormalizedRuleDTO>) => {
    // shallow merge ({ ...r, ...rule }) is currently the only way I've found that satisfies all scenarios
    // deep merge via lodash restores deleted conditions/actions because
    // { ...rule } strips the rule of its id which breaks the submission
    // a shallow merge keeps properties at the top level (rule id), but relies on the form for everything else
    setRules(rules.map((r, index) => (index === ruleIndex ? { ...r, ...rule } : r)));
    setHasChanges(true);
  };

  const deleteRule = (ruleIndex: number) => {
    setRules(rules.filter((_, index) => index !== ruleIndex));
    setHasChanges(true);
  };

  const { mutate: save, isPending: isSaving } = useGatewayMutation({
    mutationFn: () => {
      const dto = getRequestDTO();
      return isNew
        ? automationGateway.createWorkflow(dto)
        : automationGateway.updateWorkflow(id!, dto);
    },
    onSuccess: (dto) => {
      setHasChanges(false);
      if (isNew) {
        navigate(`/automation/workflows/${dto.id}`);
      }
    },
    linkedQuery: ['workflows', id],
    successMessage: `Workflow ${isNew ? 'created' : 'saved'}`,
  });

  const cancel = () => navigate('/automation/workflows');

  /** Delete current workflow */
  const deleteWorkflow = async () => {
    await automationGateway.deleteWorkflow(workflowDetails.id!);
    navigate(`/automation/workflows`);
  };

  return {
    models: {
      isNew,
      isLoading,
      isSaving,
      canSave,
      editingRuleIndex,
      isDetailsModalOpen,
      workflowDetails,
      rules,
      breadcrumbs,
    },
    operations: {
      refresh,
      deleteWorkflow,
      save,
      updateDetails,
      updateActiveStatus,
      editRule: setEditingRuleIndex,
      cancelEditingRule: () => setEditingRuleIndex(undefined),
      addRule,
      updateRule,
      deleteRule,
      cancel,
      openDetailsModal: () => setIsDetailsModalOpen(true),
      closeDetailsModal: () => setIsDetailsModalOpen(false),
    },
  };
}
