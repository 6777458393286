import React, { ReactNode } from 'react';
import { StyledDefList } from './styles';

type Props = {
  /**
   * An array of items to display. The label/value can be any node type, however if label is not a string
   * or would duplicate another label, you must also provide a key value due to map iteration.
   */
  items: Array<{ label: ReactNode; value: ReactNode; key?: string }>;
  testId?: string;
};

/** A simple key-value list. */
export function DetailList({ items, testId }: Props) {
  return (
    <StyledDefList data-testid={testId}>
      {items.map(({ label, value, key }) => (
        <React.Fragment key={typeof label === 'string' ? label : key}>
          <dt aria-label={typeof label === 'string' ? label : undefined}>{label ?? <>&nbsp;</>}</dt>
          <dd aria-label={typeof value === 'string' ? value : undefined}>{value ?? <>&nbsp;</>}</dd>
        </React.Fragment>
      ))}
    </StyledDefList>
  );
}
