import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { useDocumentTitle } from '../../../../shared/hooks/useDocumentTitle';
import { Card } from '../../../../components/card';
import { useShippingSettingsComponent } from './useShippingSettingsComponent';
import { LoadingSkeleton } from '../../../../components/loading-skeleton';
import { Message, ProfileIcon, ProfileLabels, ProfileList } from './styles';

function LoadingItem() {
  return (
    <li>
      <div>
        <ProfileLabels>
          <div style={{ marginBottom: '6px' }}>
            <LoadingSkeleton width="min(200px, 100%)" />
          </div>
          <div>
            <LoadingSkeleton width="min(500px, 100%)" />
          </div>
        </ProfileLabels>
        <ProfileIcon>
          <RightOutlined />
        </ProfileIcon>
      </div>
    </li>
  );
}

export function ShippingSettings() {
  useDocumentTitle('Settings: Shipping');
  const {
    models: { shippingProfiles, isLoading, hasError },
  } = useShippingSettingsComponent();

  let message;
  if (hasError) {
    message = 'Error fetching shipping profiles';
  } else if (!shippingProfiles?.length) {
    message = 'No shipping profiles';
  }

  return (
    <Card
      title="Shipping profiles"
      rightContent={<Link to="profile/new">Create shipping profile</Link>}
    >
      {!isLoading && message ? (
        <Message>{message}</Message>
      ) : (
        <ProfileList>
          {isLoading ? (
            <>
              <LoadingItem />
              <LoadingItem />
              <LoadingItem />
            </>
          ) : (
            shippingProfiles.map(({ id, name, shippingDescriptions }) => (
              <li key={id}>
                <Link to={`profile/${id}`} aria-label={name}>
                  <ProfileLabels>
                    <div>{name}</div>
                    <div>{shippingDescriptions?.join(', ')}</div>
                  </ProfileLabels>
                  <ProfileIcon>
                    <RightOutlined />
                  </ProfileIcon>
                </Link>
              </li>
            ))
          )}
        </ProfileList>
      )}
    </Card>
  );
}
