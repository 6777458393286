import { Input } from 'antd';
import { Form } from 'components/form';
import { Button } from 'components/button';
import { NewFormRow } from './styles';

type Props = {
  onSave: (text: string) => void;
  onCancel: () => void;
  descriptor?: string;
  placeholder?: string;
};

export function NewItemForm({ onSave, onCancel, descriptor, placeholder }: Props) {
  const [form] = Form.useForm();
  const label = placeholder || (descriptor && `Enter a ${descriptor}`);
  return (
    <Form
      form={form}
      name="newListItem"
      layout="horizontal"
      onFinish={(data) => onSave(data.text)}
      initialValues={{ text: '' }}
    >
      <NewFormRow>
        <Form.Item name="text" rules={[{ required: true }]}>
          <Input
            autoFocus
            // TODO: figure out proper indefinite article if necessary
            placeholder={label}
            aria-label={label}
          />
        </Form.Item>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={() => form.submit()}>
          Add
        </Button>
      </NewFormRow>
    </Form>
  );
}
