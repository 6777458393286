export interface Logger {
  trace: (...args) => void;
  debug: (...args) => void;
  info: (...args) => void;
  warn: (...args) => void;
  error: (...args) => void;
}

/* eslint-disable no-console */
const logger = Object.freeze({
  trace: (...args) => (console.trace || console.log)(...args),
  debug: (...args) => (console.debug || console.log)(...args),
  info: (...args) => (console.info || console.log)(...args),
  warn: (...args) => (console.warn || console.log)(...args),
  error: (...args) => (console.error || console.log)(...args),
});
/* eslint-enable no-console */

export const getLogger: () => Logger = () => logger;
