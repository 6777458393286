import { ListingStatus, OrderLineStatus, OrderStatus } from '@invenco/common-interface/sales';
import { AsnStatus } from '@invenco/common-interface/supply';
import { LocationInventoryStatus } from '@invenco/common-interface/inventory';
import { ShippingMethodStatus } from '@invenco/common-domain/enums/ShippingMethod';
import { ShipmentStatus } from '@invenco/common-interface/shipping';
import { TenantMode } from '@invenco/common-interface/warehousing';
import {
  BillStatus,
  InvoiceStatus,
  LocationStatus,
  ServiceAgreementStatus,
} from '@invenco/common-interface/accounts';
import { WorkflowStatus } from '@invenco/common-domain/enums';
import { StatusType } from 'components/label';
import { EnumMap } from './types';

export const orderStatusType: EnumMap<OrderStatus, StatusType> = {
  draft: 'progress',
  pending: 'progress',
  hold: 'alert',
  open: 'observe',
  closed: 'success',
  cancelled: 'inactive',
};

export const orderLineStatusType: EnumMap<OrderLineStatus, StatusType> = {
  BACKORDER: 'alert',
  CLOSED: 'success',
  ITEM_NOT_FOUND: 'alert',
  OPEN: 'observe',
  PARTIAL: 'observe',
  PENDING: 'progress',
};

export const listingStatusType: EnumMap<ListingStatus, StatusType> = {
  DRAFT: 'progress',
  ACTIVE: 'success',
  ARCHIVED: 'inactive',
};

export const asnStatusType: EnumMap<AsnStatus, StatusType> = {
  DRAFT: 'progress',
  PENDING: 'progress',
  OPEN: 'observe',
  CLOSED: 'success',
  CANCELLED: 'inactive',
};

export const locationInventoryStatusType: EnumMap<LocationInventoryStatus, StatusType> = {
  in_stock: 'success',
  out_of_stock: 'alert',
};

export const shippingMethodStatusType: EnumMap<ShippingMethodStatus, StatusType> = {
  ACTIVE: 'success',
  INACTIVE: 'inactive',
  ARCHIVED: 'inactive',
};

export const shipmentStatusType: EnumMap<ShipmentStatus, StatusType> = {
  DRAFT: 'progress',
  WAITING: 'progress',
  PROCESSING: 'observe',
  SHIPPED: 'success',
  CANCELLED: 'inactive',
};

export const tenantModeType: EnumMap<TenantMode, StatusType> = {
  MIGRATION: 'progress',
  LIVE: 'success',
};

export const locationStatusType: EnumMap<LocationStatus, StatusType> = {
  ACTIVE: 'success',
  ARCHIVED: 'inactive',
};

export const workflowStatusType: EnumMap<WorkflowStatus, StatusType> = {
  ACTIVE: 'success',
  INACTIVE: 'inactive',
};

export const orderLineStatusColor: EnumMap<OrderLineStatus> = {
  ITEM_NOT_FOUND: 'var(--red-6)',
  BACKORDER: 'var(--red-6)',
  OPEN: 'var(--orange-6)',
  CLOSED: 'var(--green-6)',
  PARTIAL: 'var(--orange-6)',
  PENDING: 'var(--blue-6)',
};

export const billStatusType: EnumMap<BillStatus, StatusType> = {
  OPEN: 'progress',
  PENDING: 'observe',
  CLOSED: 'success',
};

export const invoiceStatusType: EnumMap<InvoiceStatus, StatusType> = {
  PENDING: 'progress',
  OPEN: 'observe',
};

export const serviceAgreementStatusType: EnumMap<ServiceAgreementStatus, StatusType> = {
  ACTIVE: 'success',
  TERMINATED: 'inactive',
};
