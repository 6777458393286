import React, { ReactNode } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import {
  CardCollapseButton,
  CardContainer,
  CardContent,
  CardCount,
  CardDescription,
  CardRight,
  CardTitle,
} from './styles';
import { formatNumber } from '../../shared/helpers';
import { useCardComponent } from './useCardComponent';
import { HelpTooltip } from '../help-tooltip';

type Props = {
  /** Title displayed at the top of the card. */
  title?: ReactNode;

  /** Description displayed below the title. */
  description?: ReactNode;

  /**
   * A numeric label displayed next to the title.
   * Generally used for the resource count associated with the card's content.
   */
  count?: number;

  /** A help tooltip displayed next to the title. */
  tooltip?: String | ReactNode;

  /**
   * An icon to display on the left, pushing right the content of the card. Useful for aligning collapsible and
   * non-collapsible cards together.
   */
  leftIcon?: ReactNode;

  /** Any content to be displayed on the right side of the card's header. */
  rightContent?: ReactNode;

  /**
   * Whether the card is collapsible. Will display a button to toggle the visibility of the card's children.
   * This will override leftIcon if specified.
   */
  collapsible?: boolean;

  /** If true, and the card is collapsible, it will be collapsed by default. * */
  initiallyCollapsed?: boolean;

  /** Whether to apply even margin to the card. By default, the bottom has a greater margin than other sides. */
  evenMargin?: boolean;

  /** Slims the card and evens it margins. Generally used for single-line content without a title. */
  slim?: boolean;

  /** Enables scrolling in the case of content overflow. */
  scroll?: boolean;

  /** Whether to automatically space the card's children. */
  spaceChildren?: boolean;

  /** Whether to flex to fill a flex column parent. */
  fullHeight?: boolean;

  /** The content of the card. */
  children?: ReactNode;
};

/** The basic organisational unit of the interface. */
export function Card({
  title,
  description,
  count,
  tooltip,
  collapsible,
  initiallyCollapsed,
  leftIcon,
  rightContent,
  slim = false,
  evenMargin = false,
  scroll = false,
  spaceChildren = true,
  fullHeight = false,
  children,
}: Props) {
  const {
    models: { isCollapsed, headerId },
    operations: { toggleCollapsed },
  } = useCardComponent({ initiallyCollapsed });
  return (
    <CardContainer
      $evenMargin={evenMargin || isCollapsed || (!description && !React.Children.count(children))}
      $slim={slim}
      $scroll={scroll}
      $fullHeight={fullHeight}
      aria-labelledby={headerId}
    >
      {(collapsible || !!leftIcon) && (
        <div className="card-left-gutter">
          {collapsible ? (
            <CardCollapseButton
              aria-label={isCollapsed ? 'Expand' : 'Collapse'}
              onClick={toggleCollapsed}
            >
              {isCollapsed ? <DownOutlined /> : <UpOutlined />}
            </CardCollapseButton>
          ) : (
            leftIcon
          )}
        </div>
      )}
      {(title || count !== undefined || rightContent) && (
        <div className="card-header">
          <CardTitle>
            {title && <h2 id={headerId}>{title}</h2>}
            {tooltip && <HelpTooltip title={tooltip} />}
            {count !== undefined && <CardCount>{formatNumber(count, 0)}</CardCount>}
          </CardTitle>
          {rightContent && <CardRight>{rightContent}</CardRight>}
        </div>
      )}
      <div className="card-body">
        {description && <CardDescription>{description}</CardDescription>}
        {!isCollapsed && <CardContent $spaceChildren={spaceChildren}>{children}</CardContent>}
      </div>
    </CardContainer>
  );
}
