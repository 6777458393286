import { ChildConditionType, WorkflowScopeType } from '@invenco/common-domain/enums';
import { ConditionDTO, PropertyConditionDTO, RuleDTO } from '@invenco/common-interface/automation';
import { getDefaultPropertyCondition, isPropertyCondition } from './conditions';

export type NormalizedRuleDTO = Omit<RuleDTO, 'condition'> & {
  condition: {
    childConditionType: ChildConditionType;
    childConditions: {
      childConditionType: ChildConditionType;
      childConditions: PropertyConditionDTO[];
    }[];
  };
};

const dateRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

// ensure any Date objects or date-like strings in any object structure are converted to ISO date strings
export const transformDateToISOStrings = (input: any): any => {
  if (input instanceof Date) {
    return input.toISOString();
  }
  if (Array.isArray(input)) {
    return input.map(transformDateToISOStrings);
  }
  if (typeof input === 'object' && input !== null) {
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => [key, transformDateToISOStrings(value)]),
    );
  }
  if (typeof input === 'string' && dateRegex.test(input)) {
    return new Date(input).toISOString();
  }
  return input;
};

// ensure any ISO date strings in any object structure are converted to Date objects
export const transformISOStringToDates = (input: any): any => {
  if (typeof input === 'string' && dateRegex.test(input)) {
    return new Date(input);
  }
  if (Array.isArray(input)) {
    return input.map(transformISOStringToDates);
  }
  if (typeof input === 'object' && input !== null) {
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => [key, transformISOStringToDates(value)]),
    );
  }
  return input;
};

/*
  We expect the root condition to be three-tiered: ALL group -> ANY groups -> PROPERTY conditions
  This is required for the UI to function correctly and what we want the user to ultimately create.
  We do support the inverse ANY group -> ALL groups as well, though by default the above will be used.
 */
export const normalizeRule = (rule: RuleDTO, scopeType: WorkflowScopeType): NormalizedRuleDTO => {
  let { condition } = rule;

  if (isPropertyCondition(condition)) {
    // root condition is a property condition: place it at the leaf of an ALL -> ANY structure
    condition = {
      childConditionType: ChildConditionType.ALL,
      childConditions: [
        {
          childConditionType: ChildConditionType.ANY,
          childConditions: [condition],
        },
      ],
    };
  } else if (condition.childConditions?.length) {
    // root condition is a group: this is good, just ensure it has the proper fields
    condition = {
      ...condition,
      childConditionType: condition.childConditionType ?? ChildConditionType.ALL,
      childConditions: (condition.childConditions ?? []).map((childCondition) => {
        // if any second level condition is a property condition, it must be wrapped in a group condition
        // using the opposite condition type of the root condition group
        if (isPropertyCondition(childCondition)) {
          return {
            childConditionType:
              (condition as ConditionDTO).childConditionType === ChildConditionType.ANY
                ? ChildConditionType.ALL
                : ChildConditionType.ANY,
            childConditions: [childCondition],
          };
        }
        return {
          ...childCondition,
          childConditionType: childCondition.childConditionType ?? ChildConditionType.ANY,
        };
      }),
    };
  } else {
    // there is no condition, create the default structure with a default initial condition
    condition = {
      ...condition,
      childConditionType: condition.childConditionType ?? ChildConditionType.ALL,
      childConditions: [
        {
          childConditionType: ChildConditionType.ANY,
          childConditions: [getDefaultPropertyCondition(scopeType)],
        },
      ],
    };
  }

  // need Date objects for the sake of DatePicker inputs
  return transformISOStringToDates({
    ...rule,
    condition,
  }) as NormalizedRuleDTO;
};
