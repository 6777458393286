import { ListingDTO, ListingStatus, ListingStrategy } from '@invenco/common-interface/sales';
import { Form, FormInstance } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { ComponentData } from '../../../../../../shared/types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedListings: ListingDTO[];
  updateListings: (newData: Partial<ListingDTO>) => Promise<void>;
};

type Models = {
  isSaving: boolean;
  form: FormInstance;
  initialValues: Record<string, any>;
};

type Operations = {
  submit: (data: Record<string, any>) => Promise<void>;
  onListingStatusChange: (e) => void;
  onListingStrategyChange: (e) => void;
  cancelEditing: () => void;
};

export function useEditListingsModalComponent({
  isOpen,
  onClose,
  selectedListings,
  updateListings,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [selectedListingStatus, setListingStatus] = useState<ListingStatus | undefined>();
  const [selectedListingStrategy, setListingStrategy] = useState<ListingStrategy | undefined>();

  useEffect(() => {
    form.resetFields();
  }, [isOpen, form]);

  // if all the selected listings have the same value, we can pre-select it in the form
  const initialValues = useMemo(
    () =>
      selectedListings.reduce<{ status?: ListingStatus; strategy?: ListingStrategy }>(
        (acc, listing, index) => ({
          // eslint-disable-next-line no-nested-ternary
          status: !selectedListingStatus
            ? index === 0 || acc.status === listing.status
              ? listing.status
              : undefined
            : selectedListingStatus,
          // eslint-disable-next-line no-nested-ternary
          strategy: !selectedListingStrategy
            ? index === 0 || acc.strategy === listing.strategy
              ? listing.strategy
              : undefined
            : selectedListingStrategy,
        }),
        {},
      ),
    [selectedListings, selectedListingStatus, selectedListingStrategy],
  );

  const onListingStatusChange = (e) => setListingStatus(e.target.value);
  const onListingStrategyChange = (e) => setListingStrategy(e.target.value);

  const cancelEditing = () => {
    setListingStatus(undefined);
    setListingStrategy(undefined);
    onClose();
  };

  const submit = async (data: Record<string, any>) => {
    setIsSaving(true);
    await updateListings(data);
    setIsSaving(false);
    onClose();
  };

  return {
    models: { isSaving, form, initialValues },
    operations: { submit, onListingStatusChange, onListingStrategyChange, cancelEditing },
  };
}
