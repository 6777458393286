import { useCallback, useEffect, useState } from 'react';

type Input<T> = {
  /** The default value. */
  default: T;

  /** The controlled value. Will override default state and update the value when changed. */
  controlled?: T;

  /** The setter for the controlled value. Called when the setter is called. */
  setControlled?: (value: T) => void;
};

/**
 * Hook that returns a state value that can be optionally controlled.
 * The setter will ensure both local and controlled state is updated.
 */
export function useOptionallyControlledState<T>({
  default: defaultValue,
  controlled,
  setControlled,
}: Input<T>): [T, (value: T | ((previous: T) => T)) => void] {
  const [value, setLocal] = useState(controlled === undefined ? defaultValue : controlled);

  useEffect(() => {
    if (controlled !== undefined) {
      setLocal(controlled);
    }
  }, [controlled]);

  const setValue = useCallback(
    (newValue: T | ((previous: T) => T)) => {
      if (newValue instanceof Function) {
        setLocal((prev) => {
          const returned = newValue(prev);
          setControlled?.(returned);
          return returned;
        });
      } else {
        setLocal(newValue);
        setControlled?.(newValue);
      }
    },
    [setControlled],
  );

  return [value, setValue];
}
