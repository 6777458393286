import { ShippingMethodDTO } from '@invenco/common-interface/shipping';

import { Select } from 'antd';
import { useManagedShippingMethodModalComponent } from './useManagedShippingMethodModalComponent';
import { FormModal } from '../../../../../components/form-modal';
import { Form } from '../../../../../components/form';
import { Result } from '../../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  existingShippingMethods: ShippingMethodDTO[];
  warehouseLocationId: string;
  addShippingMethod: (warehouseShippingMethod: ShippingMethodDTO) => Promise<Result>;
};

export function ManagedShippingMethodModal({
  isOpen,
  onClose,
  existingShippingMethods,
  warehouseLocationId,
  addShippingMethod,
}: Props) {
  const {
    models: {
      form,
      isSaving,
      isLoadingWarehouseShippingMethods,
      canSave,
      warehouseShippingMethods,
    },
    operations: { submit },
  } = useManagedShippingMethodModalComponent({
    isOpen,
    onClose,
    warehouseLocationId,
    existingShippingMethods,
    addShippingMethod,
  });

  return (
    <FormModal
      onSave={() => form.submit()}
      isSaving={isSaving}
      open={isOpen}
      onCancel={onClose}
      saveTitle="Add"
      saveDisabled={!canSave}
      title="Add Managed Shipping Method"
      description={
        !isLoadingWarehouseShippingMethods && warehouseShippingMethods.length === 0
          ? 'All available warehouse shipping methods have already been added'
          : undefined
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="warehouseShippingMethod"
        onFinish={(data) => void submit(data)}
      >
        <Form.Item
          name="warehouseShippingMethodId"
          label="Warehouse Shipping Method"
          rules={[{ required: true, message: 'Please select a warehouse shipping method' }]}
        >
          <Select>
            {warehouseShippingMethods.map((warehouseShippingMethod) => (
              <Select.Option key={warehouseShippingMethod.id} value={warehouseShippingMethod.id}>
                {warehouseShippingMethod.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </FormModal>
  );
}
