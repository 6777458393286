import styled from 'styled-components';
import { Form } from 'antd';

export const ListingDescription = styled.div`
  span {
    font-weight: 700;
    color: var(--gray-8);
  }
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  div {
    font-weight: 900;
    color: var(--gray-8);
    width: 100%;
    text-align: center;
  }
  font-size: 14px;
  line-height: 22px;
  background-color: var(--gray-4);
  border-radius: 4px;
`;

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }
`;
