import { GatewayName } from './types';
import { getSessionStorage, setSessionStorage } from '../shared/helpers';
import { getLogger } from '../shared/logger/Logger';

const logger = getLogger();
const BASE_URL_STORAGE_KEY = 'lynk/base-urls';

const defaultUrls: Record<GatewayName, string> = {
  accounts: '/api/accounts',
  analytics: '/api/analytics',
  automation: '/api/automation',
  integration: '/api/integration',
  inventory: '/api/inventory',
  products: '/api/products',
  sales: '/api/sales',
  shipping: '/api/shipping',
  supply: '/api/supply',
  warehousing: '/api/warehousing',
} as const;

class BaseUrlProvider {
  private gatewayUrls: Record<GatewayName, string>;

  constructor() {
    this.gatewayUrls = { ...defaultUrls };
    this.loadFromSession();
  }

  getForGateway(name: GatewayName) {
    return this.gatewayUrls[name];
  }

  getForAllGateways() {
    return { ...this.gatewayUrls };
  }

  setForGateways(urls: Partial<Record<GatewayName, string>>) {
    Object.keys(this.gatewayUrls).forEach((key) => {
      if (urls[key]) {
        this.gatewayUrls[key] = urls[key];
      }
    });
    this.saveToSession();
  }

  reset() {
    this.gatewayUrls = { ...defaultUrls };
    sessionStorage.removeItem(BASE_URL_STORAGE_KEY);
  }

  private loadFromSession() {
    const storedUrls = getSessionStorage(BASE_URL_STORAGE_KEY);

    if (storedUrls) {
      try {
        Object.keys(this.gatewayUrls).forEach((key) => {
          if (storedUrls[key]) {
            this.gatewayUrls[key] = storedUrls[key];
          }
        });
      } catch (e) {
        logger.warn(`Error parsing base URLs from session storage: ${e}`);
      }
    }
  }

  private saveToSession() {
    setSessionStorage(BASE_URL_STORAGE_KEY, this.gatewayUrls);
  }
}

export const baseUrlProvider = new BaseUrlProvider();
