import { Select } from 'antd';
import { ComponentPropsWithRef } from 'react';
import { QueryProps } from './types';
import { useQuerySelectItemComponent } from './useQuerySelectItemComponent';

interface Props extends ComponentPropsWithRef<typeof Select>, QueryProps {}

export function QuerySelectItem({
  disabled,
  placeholder,
  query,
  queryKey,
  ref,
  value,
  valueKey,
  onChange,
  ...rest
}: Props) {
  const {
    models: { isLoading, options },
  } = useQuerySelectItemComponent({ query, queryKey, valueKey });
  return (
    <Select
      showSearch
      loading={isLoading}
      options={options}
      disabled={disabled}
      placeholder={placeholder}
      ref={ref}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
}
