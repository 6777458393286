import { Tag } from 'antd';
import { Container, Title } from './styles';
import { FilterOption, FilterValue, FilterSelection } from '../filter-panel/types';
import { useQuickFilterComponent, ALL_OPTION_KEY } from './useQuickFilterComponent';

export type QuickFilterProps = {
  title: string;
  options: FilterOption[];
  hasAllOption?: boolean;
  selection?: FilterSelection;
  onChange: (value: FilterValue[] | null) => void;
};

export function QuickFilter({
  title,
  options,
  hasAllOption,
  selection,
  onChange,
}: QuickFilterProps) {
  const {
    models: { selectedValues, fullOptions },
    operations: { toggleOption },
  } = useQuickFilterComponent({
    options,
    hasAllOption,
    selection,
    onChange,
  });

  return (
    <Container>
      <Title>{title}</Title>
      {fullOptions.map((opt) => {
        const isChecked =
          opt.value === ALL_OPTION_KEY ? !selectedValues.size : selectedValues.has(opt.value);
        return (
          <Tag.CheckableTag
            key={opt.value}
            checked={isChecked}
            onChange={(checked) => toggleOption(opt.value, checked)}
            // @ts-ignore - this does get passed through and is needed for a correct role
            role="checkbox"
            aria-checked={isChecked}
          >
            {opt.title || opt.value}
          </Tag.CheckableTag>
        );
      })}
    </Container>
  );
}

QuickFilter.displayName = 'DataTable.QuickFilter';
