import { SkuSelectionMethod } from '@invenco/common-interface/products';
import { MysteryComponentTitleLine } from '../styles';
import { Label, SkuLabel } from '../../../../../components/label';
import { defaultSelectionMethodOption, findSelectionMethodOption } from '../selectionMethods';
import { skuSelectionMethodTitle } from '../../../../../shared/title-maps';
import { Tag } from '../../../../../components/tag';
import { isMysteryGroupComponent, MysteryComponent } from '../useMysteryComponents';

export function MysteryTitleLine({ component }: { component: MysteryComponent }) {
  if (!isMysteryGroupComponent(component)) {
    return (
      <MysteryComponentTitleLine>
        <strong>{component.quantity}</strong>
        <span>x</span>
        <SkuLabel name={component.componentSku?.name} />
        <strong>{component.componentSku?.description}</strong>
      </MysteryComponentTitleLine>
    );
  }
  const option = component.selectionMethods?.length
    ? findSelectionMethodOption(component.selectionMethods)
    : defaultSelectionMethodOption;
  const principleMethod = option?.value.find(
    (method) => method !== SkuSelectionMethod.MOST_UNIQUE_TO_ORDER,
  );
  const principleMethodLabel = principleMethod
    ? skuSelectionMethodTitle[principleMethod]
    : 'Custom';
  const methodPrefersUnique = option?.value[0] === SkuSelectionMethod.MOST_UNIQUE_TO_ORDER;

  return (
    <MysteryComponentTitleLine>
      <strong>{component.quantity}</strong>
      <span>x</span>
      <strong>{component.name}</strong>
      <span>from</span>
      <Tag>{component.components.length}</Tag>
      <span>option{component.components.length === 1 ? '' : 's'}</span>
      <Label aria-label={`selected by ${principleMethodLabel.toLocaleLowerCase()}`}>
        {principleMethodLabel}
      </Label>
      {methodPrefersUnique && <Label aria-label="preferring uniqueness in the order">Unique</Label>}
    </MysteryComponentTitleLine>
  );
}
