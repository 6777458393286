import { ComponentData } from 'shared/types';
import { useEntityDetailsQuery } from 'shared/hooks/queries';
import { useGateways } from 'gateways/GatewayProvider';
import { useMemo } from 'react';
import { BreadCrumb } from 'components/header';
import { useParams } from 'react-router';
import { useChannelsWithLocations, ChannelWithLocationsDTO } from '../useChannelsWithLocations';

type Models = {
  isLoadingChannelDetails: boolean;
  isLoadingLocations: boolean;
  id: string;
  breadcrumbs: BreadCrumb[];
  channel: Partial<ChannelWithLocationsDTO>;
};

type Operations = {
  refresh: () => Promise<void>;
};

export function useChannelDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams<{ id: string }>();
  const { salesGateway } = useGateways();
  const {
    data: channelData,
    isLoading: isLoadingChannelDetails,
    refetch,
  } = useEntityDetailsQuery({
    parentKey: 'channels',
    id,
    isNew: false,
    query: (fetchId, { signal }) => salesGateway.getChannel(fetchId, { signal }),
  });
  const { channels, isLoadingLocations } = useChannelsWithLocations(
    channelData ? [channelData] : [],
  );

  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '', title: 'Sales' },
      { url: '/sales/channels', title: 'Channels' },
      {
        url: `/sales/channels/${id}`,
        title: channelData?.name,
        loading: isLoadingChannelDetails,
      },
    ],
    [id, channelData, isLoadingChannelDetails],
  );

  return {
    models: {
      isLoadingChannelDetails,
      isLoadingLocations,
      id: id!,
      breadcrumbs,
      channel: channels[0] || {},
    },
    operations: {
      refresh: async () => {
        await refetch();
      },
    },
  };
}
