import { SkuSelectionMethod } from '@invenco/common-interface/products';
import { EnumMap } from '../../../../shared/types';

export const selectionMethodOptions: Array<{
  value: SkuSelectionMethod[];
  label: string;
}> = [
  {
    value: [SkuSelectionMethod.MOST_UNIQUE_TO_ORDER, SkuSelectionMethod.MOST_AVAILABLE],
    label: 'Most available, preferring uniqueness in the order',
  },
  {
    value: [SkuSelectionMethod.MOST_UNIQUE_TO_ORDER, SkuSelectionMethod.LEAST_AVAILABLE],
    label: 'Least available, preferring uniqueness in the order',
  },
  {
    value: [SkuSelectionMethod.MOST_UNIQUE_TO_ORDER, SkuSelectionMethod.RANDOM],
    label: 'Random, preferring uniqueness in the order',
  },
  {
    value: [SkuSelectionMethod.MOST_AVAILABLE],
    label: 'Most available only',
  },
  {
    value: [SkuSelectionMethod.LEAST_AVAILABLE],
    label: 'Least available only',
  },
  {
    value: [SkuSelectionMethod.RANDOM],
    label: 'Random',
  },
];

export const defaultSelectionMethodOption = selectionMethodOptions[0];

export const areSelectionMethodsEqual = (a?: SkuSelectionMethod[], b?: SkuSelectionMethod[]) =>
  (!a && !b) || (a && b && a.length === b.length && a.every((v, i) => v === b[i]));

const customMethodTitleMap: EnumMap<SkuSelectionMethod> = {
  MOST_UNIQUE_TO_ORDER: 'unique to order',
  MOST_AVAILABLE: 'most available',
  LEAST_AVAILABLE: 'least available',
  RANDOM: 'random',
};

export const findSelectionMethodOption = (selectionMethods: SkuSelectionMethod[]) =>
  selectionMethodOptions.find((option) => areSelectionMethodsEqual(option.value, selectionMethods));

export const getSelectionMethodsLabel = (selectionMethods?: SkuSelectionMethod[]) =>
  (!selectionMethods || selectionMethods.length === 0
    ? defaultSelectionMethodOption
    : findSelectionMethodOption(selectionMethods)
  )?.label ??
  `Custom method (${selectionMethods?.map((method) => customMethodTitleMap[method]).join(', ')})`;
