import styled from 'styled-components';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { autoScroll } from '../../styles/mixins';

export const ContentContainer = styled.div`
  min-width: 200px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 320px;
`;

export const OptionList = styled.ul`
  padding: 0;
  margin: 0;
  flex: 1;
  ${autoScroll('var(--gray-1)')}

  li {
    display: flex;
    padding: 4px 12px;
    border-radius: 4px;
    gap: 8px;
    justify-content: space-between;
    user-select: none;

    &[aria-selected='true'] {
      font-weight: bold;
      background: var(--blue-1);
    }

    &:not([aria-disabled='true']) {
      cursor: pointer;

      &:hover,
      &.highlighted {
        &[aria-selected='true'] {
          opacity: 0.75;
        }

        &:not([aria-selected='true']) {
          background: var(--gray-3);
        }
      }
    }
  }
`;

export const OptionCheck = styled(CheckOutlined)`
  color: var(--blue-6);
  font-size: 18px;
`;

export const ClearSearch = styled(CloseOutlined)`
  color: var(--gray-7);
  cursor: pointer;

  &:hover {
    color: var(--gray-8);
  }
`;

export const NoResults = styled.div`
  padding: 8px;
  text-align: center;
  color: var(--gray-7);
`;

export const ClearSelectionContainer = styled.div`
  margin-top: 4px;
  text-align: center;

  button {
    color: var(--gray-7);
  }
`;
