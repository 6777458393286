import { ShipmentDTO } from '@invenco/common-interface/shipping';
import { OrderCurrency } from '@invenco/common-interface/sales';
import { useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import { ComponentData } from '../../../../../shared/types';
import { Form } from '../../../../../components/form';
import { Result } from '../../../../../shared/helpers/Result';

type Models = {
  form: FormInstance;
  isSaving: boolean;
};

type Operations = {
  submit: (data: Record<string, string>) => Promise<void>;
};

type setCostInput = Pick<ShipmentDTO, 'cost' | 'currency'>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setCost: (data: setCostInput) => Promise<Result>;
};

export function useSetCostModalComponent({
  isOpen,
  onClose,
  setCost,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [isOpen, form]);

  const submit = async (shipment: Record<string, string>) => {
    setIsSaving(true);
    const result = await setCost({
      ...shipment,
      cost: +shipment.cost,
      currency: OrderCurrency.AUD,
    });
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  return {
    models: { form, isSaving },
    operations: { submit },
  };
}
