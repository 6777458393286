import {
  WorkflowDataType,
  WorkflowScopeType,
  WorkflowStatus,
  WorkflowTriggerType,
} from '@invenco/common-domain/enums';
import { Select, Switch } from 'antd';
import { Card } from 'components/card';
import { Form } from 'components/form';
import { Header } from 'components/header';
import { Input } from 'components/input';
import { StatusLabel } from 'components/label';
import { workflowStatusType } from 'shared/status-maps';
import {
  workflowDataTypeTitle,
  workflowStatusTitle,
  workflowTriggerTypeTitle,
} from 'shared/title-maps';
import { Container, DetailContainer, MainContainer, SideContainer } from 'styles/layout';
import { Button } from 'components/button';
import { ActionButtons } from './action-buttons/ActionButtons';
import { RuleCard } from './rule-card/RuleCard';
import { useWorkflowDetailsPage } from './useWorkflowDetailsPage';
import { WorkflowScopeTypeContext } from './WorkflowScopeTypeContext';
import { WmsInstanceSelect } from './WmsInstanceSelect';

type Props = {
  scopeType: WorkflowScopeType;
};

export function WorkflowDetails({ scopeType }: Props) {
  const {
    models: {
      isNew,
      isLoading,
      isSaving,
      canSave,
      editingRuleIndex,
      workflowDetails,
      rules,
      breadcrumbs,
    },
    operations: {
      refresh,
      save,
      updateDetails,
      updateActiveStatus,
      editRule,
      cancelEditingRule,
      addRule,
      updateRule,
      deleteRule,
      cancel,
      deleteWorkflow,
    },
  } = useWorkflowDetailsPage({ scopeType });

  return (
    <WorkflowScopeTypeContext.Provider value={scopeType}>
      <Container>
        <Header
          title={workflowDetails.name}
          documentTitle={isNew ? 'New Workflow' : workflowDetails.name}
          backURL="/automation/workflows"
          links={breadcrumbs}
          loading={isLoading}
          loadDescription
          description={workflowDetails.description}
          extraContent={
            <StatusLabel
              type={workflowStatusType}
              title={workflowStatusTitle}
              status={workflowDetails.status}
            />
          }
          onRefresh={!isNew ? refresh : undefined}
          actionContent={
            <ActionButtons
              canSave={canSave}
              isSaving={isSaving}
              onCancel={cancel}
              onDelete={deleteWorkflow}
              onSave={save}
              canDelete={!isNew}
            />
          }
          editable
          editInitially={isNew}
          editPlaceholder="Enter workflow name..."
          editAriaLabel="Workflow name"
          onSave={(name) => updateDetails({ name })}
        />

        <DetailContainer>
          <MainContainer>
            <Card
              title="Rules"
              count={rules.length}
              rightContent={
                <Button type="link" inline onClick={addRule}>
                  Add rule
                </Button>
              }
            />

            {rules.map((rule, index) => (
              <RuleCard
                key={
                  // eslint-disable-next-line react/no-array-index-key
                  `${rule.name}-${index}`
                }
                index={index}
                editing={editingRuleIndex === index}
                onEdit={() => editRule(index)}
                onStopEditing={cancelEditingRule}
                onDelete={() => deleteRule(index)}
                onSave={(newRule) => updateRule(index, newRule)}
                rule={rule}
              />
            ))}
          </MainContainer>

          <SideContainer>
            <Card title="Details">
              <Form layout="vertical">
                <Form.Item label="Active" colon={false}>
                  <Switch
                    loading={isLoading}
                    disabled={isSaving}
                    checked={workflowDetails.status === WorkflowStatus.ACTIVE}
                    onChange={(checked) => updateActiveStatus(checked)}
                  />
                </Form.Item>

                {scopeType === WorkflowScopeType.WMS_INSTANCE && (
                  <Form.Item label="WMS Instance">
                    <WmsInstanceSelect
                      value={workflowDetails.scopeId}
                      onChange={(scopeId) => updateDetails({ scopeId })}
                      disabled={!isNew || isSaving}
                    />
                  </Form.Item>
                )}

                <Form.Item label="Data Type">
                  <Select
                    loading={isLoading}
                    disabled
                    value={workflowDetails.dataType}
                    onChange={(value) => updateDetails({ dataType: value })}
                  >
                    {Object.values(WorkflowDataType).map((type) => (
                      <Select.Option key={type} value={type}>
                        {workflowDataTypeTitle[type]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Trigger">
                  <Select
                    loading={isLoading}
                    disabled
                    value={workflowDetails.triggerType}
                    onChange={(value) => updateDetails({ triggerType: value })}
                  >
                    {Object.values(WorkflowTriggerType).map((type) => (
                      <Select.Option key={type} value={type}>
                        {workflowTriggerTypeTitle[type]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Description">
                  <Input.TextArea
                    disabled={isSaving || isLoading}
                    value={workflowDetails.description}
                    onChange={(e) => updateDetails({ description: e.target.value })}
                    rows={4}
                  />
                </Form.Item>
              </Form>
            </Card>
          </SideContainer>
        </DetailContainer>
      </Container>
    </WorkflowScopeTypeContext.Provider>
  );
}
