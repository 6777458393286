import React from 'react';
import {
  InfoItem,
  InfoTitle,
  InfoBody,
  ShipmentPanel,
  ShipmentTracking,
  ShipmentInfo,
} from './styles';
import { ensureHttp } from '../../../../../../shared/helpers';

export function Shipment({
  shipment: { name, carrierServiceName, carrierName, trackingNumber, trackingUrl },
}) {
  return (
    <ShipmentPanel>
      <ShipmentInfo>
        <InfoItem $align="left" $gridRow={1} $gridCol={1}>
          <InfoTitle>Shipment Name</InfoTitle>
          <InfoBody>{name}</InfoBody>
        </InfoItem>
        <InfoItem $align="center" $gridRow={1} $gridCol={2}>
          <InfoTitle>Shipping Method</InfoTitle>
          <InfoBody>{carrierServiceName}</InfoBody>
        </InfoItem>
        {/* <InfoItem $align="right" $gridRow={1} $gridCol={3}>
        <InfoTitle>Shipping Cost</InfoTitle>
        <InfoBody>{cost ? `$${parseFloat(cost).toFixed(2)}` : '$0.00'}</InfoBody>
      </InfoItem> */}

        <InfoItem $align="left" $gridRow={2} $gridCol={1}>
          <InfoTitle>Carrier Name</InfoTitle>
          <InfoBody>{carrierName}</InfoBody>
        </InfoItem>
        <InfoItem $align="center" $gridRow={2} $gridCol={2}>
          <InfoTitle>Carrier Tracking</InfoTitle>
          <InfoBody $color="var(--blue-6)">
            {trackingUrl ? (
              <a href={ensureHttp(trackingUrl)} target="_blank" rel="noopener noreferrer">
                {trackingNumber}
              </a>
            ) : (
              trackingNumber
            )}
          </InfoBody>
        </InfoItem>
        <InfoItem $align="right" $gridRow={2} $gridCol={3}>
          <InfoTitle>Estimated Delivery</InfoTitle>
          <InfoBody>&ndash;</InfoBody>
        </InfoItem>
      </ShipmentInfo>
      <ShipmentTracking />
    </ShipmentPanel>
  );
}
