import { Form } from 'antd';
import styled from 'styled-components';
import { HorizontallySpaced } from 'styles/layout';
import { spaceHorizontalChildren } from 'styles/mixins';

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
  width: 100%;

  .ant-form-item-explain {
    display: none;
  }
`;

const bleedAmount = '16px';
export const TableWrapper = styled.div`
  &&& {
    .ant-table-wrapper {
      margin-left: -${bleedAmount};
      margin-right: -${bleedAmount};
    }

    tr.selected td {
      border-top: none;
      // disables border hack via pseudo element for simple table
      &::after {
        display: none;
      }
    }

    td,
    th {
      &:first-child {
        padding-left: ${bleedAmount};
      }
      &:last-child {
        padding-right: ${bleedAmount};
      }
    }
    tr.selected,
    tr.ant-table-expanded-row {
      td {
        background-color: var(--blue-1) !important;
        cursor: default;
      }
    }
  }
`;

export const ButtonBlockContainer = styled(HorizontallySpaced)`
  align-self: flex-end;
  ${spaceHorizontalChildren(0.5)}
`;
