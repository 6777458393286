export const removeFalsyKeys = <T extends Record<string, any>>(obj: T): Partial<T> =>
  Object.entries(obj).reduce((result, [key, value]) => {
    /* eslint-disable-next-line no-param-reassign */
    if (value) result[key] = value;
    return result;
  }, {});

export const removeNullOrUndefinedKeys = <T extends { [key: string]: any }>(obj: T): T =>
  Object.entries(obj).reduce((result, [key, value]) => {
    if (value !== null && value !== undefined) {
      /* eslint-disable-next-line no-param-reassign */
      result[key] = value;
    }
    return result;
  }, {}) as T;
