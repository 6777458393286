import { ReactNode } from 'react';
import { Popover, PopoverProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  ClearSearch,
  ClearSelectionContainer,
  ContentContainer,
  InnerContainer,
  NoResults,
  OptionCheck,
  OptionList,
} from './styles';
import { Input } from '../input';
import { usePopoverSelectComponent } from './usePopoverSelectComponent';
import { Button } from '../button';
import { SelectOption, SelectValue } from './types';

// no other way to get ant-popover-inner-content
import './overrides.scss';

type Props = {
  /**
   * Whether multiple options can be selected. If true, the value will be a Set<string>
   * and the popover will no longer automatically close upon selection.
   */
  multiple?: boolean;

  /**
   * If set to false, the search input will be hidden. This can be used with a small number of preset options.
   */
  searchable?: boolean;

  /** Disables search, clearing, and option selection. The popover can still be opened. */
  disabled?: boolean;

  /** Whether new options are being loaded. */
  loading?: boolean;

  /**
   * The list of options to display in the popover.
   * These should change in response to onSearch (if the popover is searchable).
   */
  options?: SelectOption[];

  /**
   * The current value, a Set if multiple is true, otherwise a string, or null. This is a controlled
   * component, but this can be ignored in instances where the selected value should not be persisted.
   */
  value?: SelectValue;

  /**
   * Called when a selection changes the value. If selection is cleared, this will be null.
   * If multiple is true, this will be a Set<string> of the current option values,
   * otherwise it will be a string of the selected value. Use this to update value.
   *
   * If possible, this value ought to be a callback for the sake of performance.
   */
  onChange: (value: SelectValue) => any;

  /** Called when the search input changes. This is not debounced. */
  onSearch?: (query: string) => any;

  /**
   * Whether there are more options (another page) that could be requested for the current query.
   * If true, and onRequestMoreOptions is provided, when the user scrolls to the bottom of the list
   * more will be loaded.
   */
  canRequestMoreOptions?: boolean;

  /**
   * Called when the user scrolls to the bottom of the current list (provided canRequestMoreOptions is true).
   * Use this to trigger the next page in an infinite scroll query.
   */
  onRequestMoreOptions?: () => any;

  /** Placeholder text for the search input. */
  placeholder?: string;

  /**
   * Whether the popover should close when an option is selected. By this is true for single selection,
   * and false for multi-selection. Specify this prop to override the default behavior.
   */
  closeOnSelect?: boolean;

  /**
   * Whether the popover is open. If not provided, the popover will be controlled by the component.
   */
  open?: boolean;

  /**
   * Called when the popover is opened or closed. Update the controlled open value here if using it.
   */
  onOpenChange?: (open: boolean) => any;

  /** The placement of the popover relative to the trigger. Bottom left by default. */
  placement?: PopoverProps['placement'];

  /** The node to open the popover on click. */
  children?: ReactNode;
};

/**
 * A select input that opens inside a popover with a list of options. You may select single or multiple options,
 * search for options, and load more options as needed.
 */
export function PopoverSelect({
  multiple,
  options,
  value = null,
  onChange,
  onSearch,
  canRequestMoreOptions,
  onRequestMoreOptions,
  placeholder,
  disabled,
  loading,
  searchable = true,
  closeOnSelect = !multiple,
  open: controlledOpen,
  onOpenChange,
  placement = 'bottomLeft',
  children,
}: Props) {
  const {
    models: {
      showClearSelection,
      open,
      query,
      highlightedIndex,
      listId,
      getOptionId,
      inputRef,
      containerRef,
    },
    operations: { setOpen, search, clearSearch, clearSelection, clickOption, removeHighlight },
  } = usePopoverSelectComponent({
    multiple,
    options,
    value,
    onChange,
    onSearch,
    canRequestMoreOptions,
    onRequestMoreOptions,
    disabled,
    controlledOpen,
    onOpenChange,
    closeOnSelect,
  });

  const getInputSuffix = () => {
    if (loading) {
      return <LoadingOutlined spin />;
    }
    if (query && !disabled) {
      return (
        <ClearSearch aria-label="Clear search" role="button" onClick={clearSearch} tabIndex={0} />
      );
    }
    // prevents focus error from dynamic toggling of prefix/suffix
    return <span />;
  };

  const activeDescendant =
    highlightedIndex !== undefined ? getOptionId(highlightedIndex) : undefined;

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      showArrow={false}
      placement={placement}
      align={{ offset: [0, -8] }}
      trigger="click"
      autoAdjustOverflow={{ adjustX: 1, adjustY: 0 }}
      overlayClassName="popover-select"
      content={
        <ContentContainer ref={containerRef}>
          <InnerContainer>
            {searchable && (
              <Input
                ref={inputRef}
                placeholder={placeholder}
                disabled={disabled}
                value={query}
                onChange={(e) => search(e.target.value)}
                suffix={getInputSuffix()}
                role="combobox"
                aria-autocomplete="list"
                aria-expanded
                aria-controls={listId}
                aria-activedescendant={activeDescendant}
              />
            )}

            {options?.length === 0 && query !== '' && <NoResults>No results</NoResults>}
            {options?.length ? (
              <OptionList
                id={listId}
                role="listbox"
                aria-multiselectable={multiple}
                aria-activedescendant={searchable ? undefined : activeDescendant}
              >
                {options?.map((option, index) => {
                  const selected =
                    value instanceof Set ? value.has(option.value) : value === option.value;
                  return (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <li
                      key={option.value}
                      id={getOptionId(index)}
                      role="option"
                      onClick={() => clickOption(option, index)}
                      onMouseMove={removeHighlight}
                      aria-selected={selected}
                      aria-disabled={disabled}
                      className={highlightedIndex === index ? 'highlighted' : undefined}
                    >
                      {option.title || option.value}
                      {selected && multiple && <OptionCheck aria-hidden="true" />}
                    </li>
                  );
                })}
              </OptionList>
            ) : null}
          </InnerContainer>

          {showClearSelection && (
            <ClearSelectionContainer>
              <Button
                type="text"
                size="small"
                aria-label="Clear selection"
                role="button"
                onClick={clearSelection}
                tabIndex={0}
              >
                Clear
              </Button>
            </ClearSelectionContainer>
          )}
        </ContentContainer>
      }
    >
      {children}
    </Popover>
  );
}
