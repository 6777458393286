import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const Grid = styled.div`
  color: var(--gray-8);
  display: grid;
  grid-template-columns: 2.5rem auto auto 1fr;
  column-gap: 0.5rem;
  align-items: center;
`;

// `display: contents` here is an accessibility feature
// https://www.matuzo.at/blog/the-dark-side-of-the-grid/
export const Component = styled.li`
  display: contents;
`;

export const Quantity = styled(Text)`
  color: inherit;
  justify-self: end;
  font-weight: bold;
`;

export const SkuDescription = styled(Text)`
  color: inherit;
  padding: 0 1rem;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 2rem;
  width: 2rem;
`;
