import { ShippingPriceDTO } from '@invenco/common-interface/shipping';
import { Form, FormInstance } from 'antd';
import { useEffect, useState } from 'react';
import { useGateways } from 'gateways/GatewayProvider';
import { useQuery } from '@tanstack/react-query';
import { ComponentData } from '../../../../../shared/types';

type Props = {
  isOpen: boolean;
  shippingPrice: Partial<ShippingPriceDTO>;
  updateDetails: (data: Partial<ShippingPriceDTO>) => void;
  onClose: () => void;
};

type Models = {
  form: FormInstance;
  locationsByAccount: any[];
  isLocationSpecific: boolean;
};

type Operations = {
  submit: (data: Record<string, any>) => void;
  handleAccountChange: (value: string) => void;
  handleToggleSpecificLocation: (value: boolean) => void;
};

export function useDetailsModalComponent({
  isOpen,
  updateDetails,
  onClose,
  shippingPrice,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();

  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>(
    shippingPrice.accountId,
  );
  const { accountsGateway } = useGateways();
  const [isLocationSpecific, setIsLocationSpecific] = useState<boolean>(false);

  const submit = (data: Record<string, any>) => {
    updateDetails({
      accountId: data.accountId,
      validTo: data.validTo,
      validFrom: data.validFrom,
      locationId: data.locationId || '',
    });
    onClose();
  };

  const handleAccountChange = (accountId: string) => {
    setSelectedAccountId(accountId);
  };

  const handleToggleSpecificLocation = (value: boolean) => {
    setIsLocationSpecific(value);
  };

  const { data: accountDetails, refetch: refetchAccountDetails } = useQuery({
    queryKey: ['account', selectedAccountId, shippingPrice],
    queryFn: ({ signal }) => {
      const accountId = selectedAccountId || shippingPrice.accountId || '';
      return accountsGateway.getAccount(accountId, { signal });
    },
  });

  useEffect(
    /* eslint-disable-next-line prefer-arrow-callback */
    function onFirstOpen() {
      if (isOpen) {
        form.resetFields();
        void refetchAccountDetails();
        setIsLocationSpecific(!!shippingPrice.locationId);
      }
    },
    [isOpen, shippingPrice, form, refetchAccountDetails],
  );

  useEffect(
    /* eslint-disable-next-line prefer-arrow-callback */
    function whenAccountReselected() {
      if (isOpen && selectedAccountId && accountDetails) {
        if (accountDetails.locations && accountDetails.locations.length > 0) {
          form.setFieldsValue({
            locationId: accountDetails.locations[0].id,
          });
        } else {
          form.setFieldsValue({
            locationId: '',
          });
        }
      }
    },
    [isOpen, form, selectedAccountId, accountDetails],
  );

  return {
    models: {
      form,
      locationsByAccount: accountDetails?.locations || [],
      isLocationSpecific,
    },
    operations: {
      submit,
      handleAccountChange,
      handleToggleSpecificLocation,
    },
  };
}
