import { BillAction, BillDTO, BillStatus } from '@invenco/common-interface/accounts';
import { useMemo, useState } from 'react';
import { useBatchedRequests } from '../../../../shared/hooks/useBatchedRequests';
import { useAutoCompleteQuery, useQueryWithInput } from '../../../../shared/hooks/queries';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { ComponentData, Page, Pagination } from '../../../../shared/types';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import {
  Filter,
  FilterSelections,
  useDataTable,
  useRowNavigation,
  View,
} from '../../../../components/data-table';
import { FilterOption } from '../../../../components/data-table/filter-panel/types';
import { billStatusTitle } from '../../../../shared/title-maps';
import { useRowSelection } from '../../../../components/data-table/useRowSelection';
import { RowAction } from '../../../../components/data-table/row-actions/types';
import { SortOption, SortSelection } from '../../../../components/data-table/types';
import { BillSortBy } from '../../../../gateways/impl/AxiosAccountsGateway';

type FilterKey = 'status' | 'accountId';

type Models = {
  isLoading: boolean;
  isClosingBills: boolean;
  isCloseModalOpen: boolean;
  hasError: boolean;
  rows?: BillDTO[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  view?: string;
  filterValues?: FilterSelections<FilterKey>;
  filters: Filter<FilterKey>[];
  sort?: SortSelection;
  selectedRowsById: Map<string, BillDTO>;
  rowActions: RowAction<BillDTO>[];
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  changeView: (view: string) => void;
  changeSort: (sort: SortSelection) => void;
  updateFilters: (filters: FilterSelections<FilterKey>) => void;
  refresh: () => void;
  onClickRow: (row: BillDTO, event: React.MouseEvent<HTMLTableRowElement>) => void;
  toggleRowSelection: (row: BillDTO) => void;
  togglePageSelection: (rows: BillDTO[]) => void;
  clearSelection: () => void;
  closeBills: () => Promise<void>;
  closeActionModal: () => void;
};

export const sortOptions: SortOption[] = [
  { key: 'createdAt', title: 'Created' },
  { key: 'periodStart', title: 'From' },
];

const statusOrder = [BillStatus.OPEN, BillStatus.PENDING, BillStatus.CLOSED];

export const views: View<FilterKey>[] = [
  { key: '', title: 'All' },
  ...statusOrder.map((status) => ({
    key: status,
    title: billStatusTitle[status],
    filters: { status: { value: status, title: billStatusTitle[status] } },
  })),
];

const statusOptions: FilterOption[] = statusOrder.map((status) => ({
  value: status,
  title: billStatusTitle[status],
}));

export function useBillListPage(): ComponentData<Models, Operations> {
  const { accountsGateway } = useGateways();
  const { onClickRow } = useRowNavigation<BillDTO>({ baseUrl: '/bills' });
  const { selectedRowsById, toggleRowSelection, togglePageSelection, clearSelection } =
    useRowSelection<BillDTO>();
  const [tableState, setTableState, { getFilterValue }] = useDataTable({
    filterKeys: ['status', 'accountId'],
    views,
    sortOptions,
    defaultSort: { key: 'createdAt', direction: 'desc' },
  });
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isClosingBills, setIsClosingBills] = useState(false);

  const {
    data,
    isLoading,
    isError: hasError,
    refetch,
  } = useQueryWithInput({
    parentKey: 'bills',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
      status: getFilterValue<BillStatus>('status'),
      accountId: getFilterValue('accountId'),
      sortBy: tableState.sort?.key as BillSortBy,
      sortDirection: tableState.sort?.direction,
    },
    query: (input, { signal }) => accountsGateway.getBills(input, { signal }),
    enabled: true,
  });

  const {
    options: accounts,
    isLoading: isLoadingAccounts,
    onSearch: onSearchAccounts,
  } = useAutoCompleteQuery({
    parentKey: 'accounts',
    query: (input, { signal }) => accountsGateway.getAccounts(input, { signal }),
  });

  const accountFilter = useMemo<Filter<FilterKey>>(
    () => ({
      key: 'accountId',
      title: 'Account',
      options: accounts.map((acc) => ({ value: acc.id, title: acc.name })),
      loading: isLoadingAccounts,
      onSearch: onSearchAccounts,
    }),
    [accounts, isLoadingAccounts, onSearchAccounts],
  );

  const filters: Filter<FilterKey>[] = [
    {
      key: 'status',
      title: 'Status',
      options: statusOptions,
    },
    accountFilter,
  ];

  const { run: runClose } = useBatchedRequests<BillDTO, void>({
    messageKey: 'bill-close',
    progressMessage: ({ results, failures, total }) =>
      `Closed bills (${results.length}/${total}${
        failures.length ? ` - ${failures.length} failed` : ''
      })`,
  });

  const { run: runRecalculate } = useBatchedRequests<BillDTO, void>({
    messageKey: 'bill-recalculate',
    progressMessage: ({ results, failures, total }) =>
      `Recalculated bills (${results.length}/${total}${
        failures.length ? ` - ${failures.length} failed` : ''
      })`,
  });

  const rowActions: RowAction<BillDTO>[] = [
    {
      key: 'close',
      title: 'Close',
      action: () => setIsCloseModalOpen(true),
      canActOnRow: (row) => row.allowableActions.includes(BillAction.CLOSE),
    },
    {
      key: 'recalculate',
      title: 'Recalculate',
      action: async (inputs) => {
        await runRecalculate({
          execute: ({ id }) => accountsGateway.recalculateBill(id),
          inputs,
        });
        void refetch();
      },
      canActOnRow: (row) => row.allowableActions.includes(BillAction.RECALCULATE),
    },
  ];

  const closeBills = async () => {
    setIsClosingBills(true);
    await runClose({
      execute: (bill) => accountsGateway.closeBill(bill.id),
      inputs: Array.from(selectedRowsById.values()),
    });
    setIsCloseModalOpen(false);
    setIsClosingBills(false);
    clearSelection();
    void refetch();
  };

  const closeActionModal = () => {
    setIsCloseModalOpen(false);
  };

  return {
    models: {
      isLoading,
      isCloseModalOpen,
      isClosingBills,
      hasError,
      rows: data?.items,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      view: tableState.view,
      filterValues: tableState.filters,
      filters,
      sort: tableState.sort,
      selectedRowsById,
      rowActions,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      changeView: (view) => setTableState({ view, page: undefined, filters: undefined }),
      changeSort: (sort) => setTableState({ sort, page: undefined }),
      updateFilters: (updated) =>
        setTableState({ filters: updated, page: undefined, view: undefined }),
      refresh: refetch,
      onClickRow,
      toggleRowSelection,
      togglePageSelection,
      clearSelection,
      closeBills,
      closeActionModal,
    },
  };
}
