import { OrderLineDTO } from '@invenco/common-interface/sales';
import { SkuLabel } from 'components/label';
import { ConnectingLine } from './ConnectingLine';
import { Component, Grid, Quantity, SkuDescription } from './styles';

interface Props {
  components: Partial<OrderLineDTO>[];
  editing?: boolean;
}

export function BundleComponents({ components, editing }: Props) {
  return (
    <Grid role="list" aria-label="Bundle components">
      {components.map((c, index) => (
        <Component
          key={c.id}
          // Screen readers see this label instead of the component's content,
          // which we hide with `aria-hidden` attributes
          aria-label={`${c.qtyOrdered} of ${c.skuName} - ${c.description}`}
        >
          <ConnectingLine isLast={index === components.length - 1} editing={editing} aria-hidden />
          <Quantity aria-hidden>{c.qtyOrdered}</Quantity>
          <span aria-hidden>x</span>
          <span aria-hidden>
            <SkuLabel active={editing} name={c.skuName} />
            <SkuDescription>{c.description}</SkuDescription>
          </span>
        </Component>
      ))}
    </Grid>
  );
}
