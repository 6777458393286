import { Form, FormInstance } from 'antd';
import { SkuDTO } from '@invenco/common-interface/products';
import { useEffect, useMemo, useState } from 'react';
import { ComponentData } from '../../../../../shared/types';
import { Result } from '../../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  sku: Partial<SkuDTO>;
  updateSku: (data: Partial<SkuDTO>) => Promise<Result>;
  onClose: () => void;
};

type Models = {
  form: FormInstance;
  isSaving: boolean;
  initialValues: Record<string, any>;
};

type Operations = {
  submit: (data: Record<string, string>) => Promise<void>;
};

export function useDetailsModalComponent({
  isOpen,
  sku,
  updateSku,
  onClose,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  const initialValues = useMemo(
    () => ({
      description: sku.description,
      barcode: sku.barcode,
      hsCode: sku.hsCode,
      countryOfOrigin: sku.countryOfOrigin,
    }),
    [sku],
  );

  useEffect(() => {
    form.resetFields();
  }, [isOpen, form]);

  const submit = async (data: Record<string, string>) => {
    setIsSaving(true);
    const result = await updateSku({
      ...data,
      countryOfOrigin: (data.countryOfOrigin?.toUpperCase() || null)!,
    });
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  return {
    models: { form, isSaving, initialValues },
    operations: { submit },
  };
}
