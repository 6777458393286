import styled from 'styled-components';
import { Form as AntForm, FormProps } from 'antd';
import { spaceHorizontalChildren } from 'styles/mixins';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  ${spaceHorizontalChildren(1)}

  > * {
    flex: 1;
  }
  > .ant-form-item:last-child {
    margin-bottom: 16px;
  }
`;

type Props = FormProps & {
  /** No error messages will be shown if true. */
  hideErrors?: boolean;

  /** Removes the default bottom margin. */
  noMargin?: boolean;
};

// TODO: replace global-styles Form with this
export const Form = Object.assign(
  styled(AntForm).withConfig({
    shouldForwardProp: (prop) => !['hideErrors', 'noMargin'].includes(prop),
  })<Props>`
    .ant-form-item {
      margin-bottom: 0;
      ${({ layout, noMargin }) =>
        layout === 'vertical' &&
        `
      &:not(:last-child) {
        margin-bottom: ${noMargin ? '0' : '16px'};
      }
    `}
    }

    label,
    .ant-form-item-explain {
      font-size: 12px;
    }
    label:not(.ant-checkbox-wrapper) {
      font-weight: 600;
    }
    .ant-form-item-explain {
      margin-top: 2px;
      font-weight: normal;
      ${({ hideErrors }) => hideErrors && 'display: none;'}
    }

    .ant-form-item-required::before {
      display: none !important;
    }

    .ant-form-item-label {
      padding-bottom: 4px;
    }

    input {
      border-radius: 4px;
      padding: 4px 12px;
    }

    input,
    textarea,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 4px;
    }

    // other controls have this already
    .ant-picker,
    .ant-input-number {
      width: 100%;
    }
  `,
  { Row },
);
