import { AccountDTO } from '@invenco/common-interface/accounts';
import { Form, FormInstance } from 'antd';
import { WarehouseDTO } from '@invenco/common-interface/warehousing';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ComponentData } from '../../../../../shared/types';
import { CreateWarehouseTenantDTO } from '../../../../../gateways/types';
import { useGateways } from '../../../../../gateways/GatewayProvider';
import { Result } from '../../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  account: Partial<AccountDTO>;
  onClose: () => void;
  addToWarehouse: (data: {
    warehouseId: string;
    tenant: CreateWarehouseTenantDTO;
  }) => Promise<Result>;
};

type Models = {
  isSaving: boolean;
  isLoadingWarehouses: boolean;
  form: FormInstance;
  warehouses?: WarehouseDTO[];
};

type Operations = {
  submit: (formData: Record<string, any>) => Promise<void>;
};

export function useTenantModalComponent({
  isOpen,
  onClose,
  account,
  addToWarehouse,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const { warehousingGateway } = useGateways();
  const [isSaving, setIsSaving] = useState(false);

  const { data: warehouses, isLoading: isLoadingWarehouses } = useQuery({
    queryKey: ['warehouses'],
    queryFn: ({ signal }) => warehousingGateway.getWarehouses(undefined, { signal }),
  });

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  const submit = async ({ warehouseId, tenantName, tenantCode }: Record<string, any>) => {
    setIsSaving(true);
    const result = await addToWarehouse({
      warehouseId,
      tenant: {
        tenantAccountId: account.id!,
        tenantName,
        tenantCode,
        warehouseTenantOptions: {},
      },
    });
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  return {
    models: {
      isSaving,
      isLoadingWarehouses,
      form,
      warehouses: warehouses?.items,
    },
    operations: { submit },
  };
}
