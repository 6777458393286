import { PropertyConditionName, WorkflowConfigurationMap } from '@invenco/common-domain/enums';
import { Select } from 'antd';
import { Form } from 'components/form';
import { conditionOperatorTitle, propertyConditionNameTitle } from 'shared/title-maps';
import { DeleteButton } from 'components/button';
import {
  conditionOperatorKey,
  conditionPropertyKey,
  conditionValueKey,
  FormKey,
  getFormItemRules,
} from '../../helpers/forms';
import { ValueFormItem } from '../value-form-item/ValueFormItem';
import { ConditionInputRow } from './styles';
import { useWorkflowScopeType } from '../../WorkflowScopeTypeContext';
import { getPropertyConditionDetails } from '../../helpers/conditions';

type Props = {
  editable: boolean;
  formKey: FormKey;
  onDelete: () => void;
  onChangeType: () => void;
};

export function PropertyConditionInput({ formKey, onDelete, onChangeType, editable }: Props) {
  const scopeType = useWorkflowScopeType();
  return (
    <ConditionInputRow data-testid="condition-row">
      <Form.Item
        label="Condition property"
        name={[...formKey, conditionPropertyKey]}
        rules={[{ required: true, message: 'Please select a property' }]}
      >
        <Select
          disabled={!editable}
          onSelect={onChangeType}
          options={Object.keys(
            WorkflowConfigurationMap[scopeType].propertyConditionEvaluationAttributes,
          ).map((property) => ({
            value: property,
            label: propertyConditionNameTitle[property],
          }))}
          showSearch
          filterOption={(input, option) =>
            option?.label.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ?? false
          }
        />
      </Form.Item>
      <Form.Item noStyle dependencies={[...formKey, conditionPropertyKey]}>
        {({ getFieldValue }) => {
          const property = getFieldValue([
            ...formKey,
            conditionPropertyKey,
          ]) as PropertyConditionName;
          const details = getPropertyConditionDetails(property);
          const { operators, required = true, ...itemDetails } = details;
          return (
            <>
              <Form.Item
                label="Condition operator"
                name={[...formKey, conditionOperatorKey]}
                rules={[{ required: true, message: 'Please select an operator' }]}
              >
                <Select
                  disabled={!editable}
                  options={operators.map((operator) => ({
                    value: operator,
                    label: conditionOperatorTitle[operator],
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ?? false
                  }
                />
              </Form.Item>

              <ValueFormItem
                disabled={!editable}
                label="Condition value"
                name={[...formKey, conditionValueKey]}
                rules={getFormItemRules({ type: details.type, required })}
                {...itemDetails}
              />
            </>
          );
        }}
      </Form.Item>
      <DeleteButton
        htmlType="button"
        aria-label="Remove condition"
        onClick={onDelete}
        disabled={!editable}
      />
    </ConditionInputRow>
  );
}
