import { useSearchParams } from 'react-router-dom';
import { getEnv } from '../../../env';
import { useCurrentUserQuery } from '../../../shared/hooks/queries/common';

export function useFeatureFlag() {
  const env = getEnv();

  const [searchParams] = useSearchParams();
  const { data: user, isSuccess: isUserQuerySuccess } = useCurrentUserQuery();

  let enableCommandAI = false;

  // Always enabled in dev/local environments
  if (env === 'DEV' || env === 'LOCAL') {
    enableCommandAI = true;
  }
  // staff only in prod/stage
  else if (
    (env === 'PROD' || env === 'STAGE') &&
    isUserQuerySuccess &&
    user?.email.toLocaleLowerCase().includes('@invenco.net')
  ) {
    enableCommandAI = true;
  }

  // Allow disabling via query param in any environment
  if (searchParams.get('disable-ai') === 'true') {
    enableCommandAI = false;
  }

  return {
    enableCommandAI,
  };
}
