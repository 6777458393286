import { Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import { DatePicker } from 'components/date-picker';
import { Form } from 'components/form';
import { Input, InputNumber } from 'components/input';
import { ComponentPropsWithRef } from 'react';
import { assertNever } from 'shared/helpers';
import { DEFAULT_DATETIME_FORMAT, DEFAULT_TIME_FORMAT } from '../../../../../../constants';
import { InputValueDetails, InputValueType } from '../../config/types';
import { QuerySearchItem } from './QuerySearchItem';
import { QuerySelectItem } from './QuerySelectItem';
import { ItemWrapper } from './styles';

interface Props
  extends ComponentPropsWithRef<typeof Input>,
    Pick<InputValueDetails, 'queryKey' | 'query' | 'valueKey' | 'multi'> {
  /** Field label */
  label: string;
  /** Field _name_ */
  name: NamePath;
  /** Field validation rules */
  rules?: Rule[];
  /** Field selection options */
  options?: string[];
  /** Field value type */
  type: InputValueType;
}

export function ValueFormItem({
  label,
  name,
  options,
  rules,
  type,
  query,
  queryKey,
  valueKey,
  placeholder = label,
  multi,
  disabled,
}: Props) {
  const getInput = () => {
    switch (type) {
      case 'string':
      case 'email':
        return <Input placeholder={placeholder} disabled={disabled} />;
      case 'float':
        return <InputNumber placeholder={placeholder} disabled={disabled} />;
      case 'integer':
        return <InputNumber precision={0} placeholder={placeholder} disabled={disabled} />;

      case 'date':
        return (
          <DatePicker
            format={DEFAULT_DATETIME_FORMAT}
            showTime={{ format: DEFAULT_TIME_FORMAT }}
            placeholder={placeholder}
            allowClear={false}
            disabled={disabled}
          />
        );

      case 'select':
        if (query && queryKey && valueKey) {
          return (
            <QuerySelectItem
              query={query}
              queryKey={queryKey}
              valueKey={valueKey}
              placeholder={placeholder}
              disabled={disabled}
              mode={multi ? 'multiple' : undefined}
              allowClear
            />
          );
        }
        if (!options) {
          throw new Error(
            'Value input configuration error: options or query/queryKey/valueKey must be defined',
          );
        }
        return (
          <Select
            showSearch
            options={options.map((option) => ({ value: option }))}
            disabled={disabled}
            placeholder={placeholder}
            mode={multi ? 'multiple' : undefined}
            allowClear
          />
        );

      case 'search':
        if (!query || !queryKey || !valueKey) {
          throw new Error(
            'Value input configuration error: query, queryKey, and valueKey must be defined for search type',
          );
        }
        return (
          <QuerySearchItem
            query={query}
            queryKey={queryKey}
            valueKey={valueKey}
            placeholder={placeholder}
            disabled={disabled}
            allowClear
          />
        );
      case 'array':
        return <Select disabled={disabled} placeholder={placeholder} mode="tags" allowClear />;

      default:
        return assertNever(type);
    }
  };

  return (
    <ItemWrapper>
      <Form.Item
        name={name}
        label={label}
        rules={rules}
        // ensure that empty values are stripped from the action params
        normalize={(value) =>
          value === '' || (Array.isArray(value) && !value.length) ? undefined : value
        }
      >
        {getInput()}
      </Form.Item>
    </ItemWrapper>
  );
}
