import { AsnDTO } from '@invenco/common-interface/supply';
import { useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import { ComponentData } from '../../../../../../shared/types';
import { Form } from '../../../../../../components/form';
import { Result } from '../../../../../../shared/helpers/Result';

type Models = {
  form: FormInstance;
  isSaving: boolean;
};

type Operations = {
  submit: (data: Record<string, string>) => Promise<void>;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  updateAsn: (data: Partial<AsnDTO>) => Promise<Result>;
};

export function useReferenceModalComponent({
  isOpen,
  onClose,
  updateAsn,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [isOpen, form]);

  const submit = async ({ reference }: Record<string, string>) => {
    setIsSaving(true);
    const result = await updateAsn({ reference });
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  return {
    models: { form, isSaving },
    operations: { submit },
  };
}
