import type { AsnDTO, AsnLineDTO, AsnStatus, CreateAsnDTO } from '@invenco/common-interface/supply';
import { BaseAxiosGateway, QueryRequestOptions } from '../BaseAxiosGateway';
import { GatewayName, PaginatedResponse } from '../types';

export class AxiosSupplyGateway extends BaseAxiosGateway {
  name: GatewayName = 'supply';

  async getAsn(id: string, options?: QueryRequestOptions): Promise<AsnDTO> {
    const { data } = await this.httpGet(`/v1/asns/${id}`, options);
    return data.asn;
  }

  async getAsns(
    query?: {
      take?: number;
      cursor?: string;
      search?: string;
      statuses?: AsnStatus[];
      locationIds?: string[];
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<AsnDTO>> {
    const { data } = await this.httpGet('/v1/asns', {
      params: {
        take: query?.take,
        cursor: query?.cursor,
        q: query?.search,
        statuses: query?.statuses?.join(','),
        locations: query?.locationIds?.join(','),
      },
      ...options,
    });
    return { ...data, items: data.asns };
  }

  async createAsn(asn: CreateAsnDTO): Promise<AsnDTO> {
    const { data } = await this.httpPost<{ asn: AsnDTO }>('/v1/asns', { data: { asn } });
    return data.asn;
  }

  async updateAsn(asnId: string, asn: Partial<AsnDTO>): Promise<AsnDTO> {
    const { data } = await this.httpPatch<{ asn: AsnDTO }>(`/v1/asns/${asnId}`, { data: { asn } });
    return data.asn;
  }

  async bookAsn(asnId: string): Promise<AsnDTO> {
    const { data } = await this.httpPost<{ asn: AsnDTO }>(`/v1/asns/${asnId}/book`);
    return data.asn;
  }

  async cancelAsn(asnId: string): Promise<AsnDTO> {
    const { data } = await this.httpPost<{ asn: AsnDTO }>(`/v1/asns/${asnId}/cancel`);
    return data.asn;
  }

  async addAsnLine(asnId: string, asnLine: Partial<AsnLineDTO>): Promise<AsnLineDTO> {
    const { data } = await this.httpPost<{ asnLine: AsnLineDTO }>(`/v1/asns/${asnId}/asn_lines`, {
      data: { asnLine },
    });
    return data.asnLine;
  }

  async updateAsnLine(
    asnId: string,
    asnLineId: string,
    asnLine: Partial<AsnLineDTO>,
  ): Promise<AsnLineDTO> {
    const { data } = await this.httpPatch<{ asnLine: AsnLineDTO }>(
      `/v1/asns/${asnId}/asn_lines/${asnLineId}`,
      { data: { asnLine } },
    );
    return data.asnLine;
  }

  async deleteAsnLine(asnId: string, asnLineId: string): Promise<void> {
    await this.httpDelete(`/v1/asns/${asnId}/asn_lines/${asnLineId}`);
  }

  async recostAsnLines(asnId: string, asnLines: Partial<AsnLineDTO>[]): Promise<AsnDTO> {
    const { data } = await this.httpPost<{ asn: AsnDTO }>(`/v1/asns/${asnId}/recost`, {
      data: { recostLines: asnLines },
    });
    return data.asn;
  }
}
