import { OrderDTO } from '@invenco/common-interface/sales';
import { Form } from 'components/form';
import { FormModal } from 'components/form-modal';
import { Input } from 'components/input';
import { Result } from 'shared/helpers/Result';
import { useInstructionModalComponent } from './useInstructionModalComponent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  deliveryInstructions?: string;
  packingInstructions?: string;
  updateOrder: (data: Partial<OrderDTO>) => Promise<Result>;
};

export function InstructionModal({
  isOpen,
  deliveryInstructions,
  packingInstructions,
  updateOrder,
  onClose,
}: Props) {
  const {
    models: { form, isSaving },
    operations: { submit },
  } = useInstructionModalComponent({ isOpen, onClose, updateOrder });
  return (
    <FormModal
      title="Instructions"
      open={isOpen}
      onCancel={onClose}
      isSaving={isSaving}
      onSave={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => void submit(data)}
        initialValues={{ deliveryInstructions, packingInstructions }}
      >
        <Form.Item name="deliveryInstructions" label="Delivery Instructions">
          <Input.TextArea autoFocus rows={4} aria-label="DeliveryInstructions" />
        </Form.Item>
        <Form.Item name="packingInstructions" label="Packing Instructions">
          <Input.TextArea autoFocus rows={4} aria-label="PackingInstructions" />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
