import { WorkflowDTO } from '@invenco/common-interface/automation';
import { WorkflowScopeType, WorkflowStatus } from '@invenco/common-domain/enums';
import { View, useDataTable, useRowNavigation } from 'components/data-table';
import { useQueryWithInput } from 'shared/hooks/queries';
import { workflowStatusTitle } from 'shared/title-maps';
import { useQuery } from '@tanstack/react-query';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { ComponentData, Page, Pagination } from '../../../../shared/types';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';

export type WorkflowRow = WorkflowDTO;

export type FilterKey = 'status';

type Props = {
  scopeType: WorkflowScopeType;
};

type Models = {
  isLoading: boolean;
  hasError: boolean;
  rows?: WorkflowRow[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  view?: string;
  isLoadingWmsInstances: boolean;
  wmsInstanceIdToCode?: Record<string, string>;
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  changeView: (view: string) => void;
  refresh: () => void;
  onImportComplete: () => void;
  onClickRow: (row: WorkflowRow, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export const views: View<FilterKey>[] = [
  { key: '', title: 'All' },
  ...[WorkflowStatus.ACTIVE, WorkflowStatus.INACTIVE].map((status) => ({
    key: status,
    title: workflowStatusTitle[status],
    filters: { status: { value: status, title: workflowStatusTitle[status] } },
  })),
];

export function useWorkflowListPage({ scopeType }: Props): ComponentData<Models, Operations> {
  const { automationGateway, warehousingGateway } = useGateways();
  const { onClickRow } = useRowNavigation<WorkflowRow>({ baseUrl: '/automation/workflows' });
  const [tableState, setTableState, { getFilterValue }] = useDataTable({
    filterKeys: ['status'],
    views,
  });

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'workflows',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
      status: getFilterValue<WorkflowStatus>('status'),
    },
    query: (input, { signal }) => automationGateway.getWorkflows(input, { signal }),
  });

  const { data: wmsInstanceIdToCode, isLoading: isLoadingWmsInstances } = useQuery({
    queryKey: ['wmsInstances'],
    queryFn: ({ signal }) => warehousingGateway.getWmsInstances(undefined, { signal }),
    enabled: scopeType === WorkflowScopeType.WMS_INSTANCE,
    select: (wmsInstances) =>
      wmsInstances?.reduce(
        (acc, { id, code }) => {
          acc[id] = code;
          return acc;
        },
        {} as Record<string, string>,
      ),
  });

  return {
    models: {
      isLoading,
      hasError: isError,
      rows: data?.items as WorkflowRow[] | undefined,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      view: tableState.view,
      isLoadingWmsInstances,
      wmsInstanceIdToCode,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      changeView: (view) => setTableState({ view, page: undefined, filters: undefined }),
      refresh: refetch,
      onImportComplete: refetch,
      onClickRow,
    },
  };
}
