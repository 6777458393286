import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { useUpdateEffect } from '../../shared/hooks/useUpdateEffect';
import { DataTableState, SortOption, SortSelection } from './types';
import { View } from './views/types';
import { FilterValue } from './filter-panel/types';

type Props<FilterKeyT extends string> = {
  /** Override the default page size. */
  pageSize?: number;

  /** Keys for filters that are single-select. */
  filterKeys?: FilterKeyT[];

  /** Keys for filters that are multi-select. */
  multiFilterKeys?: FilterKeyT[];

  /** Views that can be selected. Make sure these are not defined each render. */
  views?: View<FilterKeyT>[];

  sortOptions?: SortOption[];

  defaultSort?: SortSelection;
};

type Output<FilterKeyT extends string> = [
  DataTableState<FilterKeyT>,
  (update: Partial<DataTableState<FilterKeyT>>) => void,
  {
    getFilterValue: <T = string>(key: FilterKeyT) => T | undefined;
    getMultiFilterValues: <T = string>(key: FilterKeyT) => T[] | undefined;
    hasFilterValue: (key: FilterKeyT, value: string) => boolean;
  },
];

const FILTER_VALUE_SEPARATOR = '\x01'; // field separator character

const encodeFilterValue = (x?: FilterValue) => {
  if (!x) return undefined;
  return !x.title || x.title === x.value
    ? x.value
    : `${x.value}${FILTER_VALUE_SEPARATOR}${x.title}`;
};

const decodeFilterValue = (x: string): FilterValue => {
  const split = x.split(FILTER_VALUE_SEPARATOR, 2);
  const value = split[0].trim();
  const title = split[1]?.trim();
  return { value, title: title || value };
};

export function useDataTable<FilterKeyT extends string = string>({
  pageSize = DEFAULT_PAGE_SIZE,
  filterKeys,
  multiFilterKeys,
  views,
  sortOptions,
  defaultSort,
}: Props<FilterKeyT> = {}): Output<FilterKeyT> {
  const location = useLocation();

  const locationData = useMemo<DataTableState<FilterKeyT>>(() => {
    const params = new URLSearchParams(location.search);
    const take = params.get('take');
    const cursor = params.get('cursor') || undefined;
    const filters: DataTableState<FilterKeyT>['filters'] = {};

    if (filterKeys) {
      filterKeys.forEach((key) => {
        const value = params.get(key);
        if (value) {
          filters[key] = decodeFilterValue(value);
        }
      });
    }

    if (multiFilterKeys) {
      multiFilterKeys.forEach((key) => {
        const value = params.get(key);
        if (value) {
          filters[key] = value
            .split(',')
            .map(decodeFilterValue)
            .filter((x) => x.value);
        }
      });
    }

    const sortKey = params.get('sortBy');
    const sortDirection = params.get('sortDirection');
    let sort = defaultSort;
    if (sortKey && sortOptions?.some((x) => x.key === sortKey)) {
      sort = { key: sortKey, direction: sortDirection === 'desc' ? 'desc' : 'asc' };
    }

    return {
      query: params.get('query') || undefined,
      view: params.get('view') || undefined,
      page:
        take || cursor
          ? {
              // ensure user can't choose whatever take they want
              take: take ? Math.sign(+take) * pageSize : undefined,
              cursor,
            }
          : undefined,
      sort,
      filters,
    };
    // putting key arrays in here leads to updating every render when they rarely (if ever) change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, pageSize]);

  const updateLocation = useCallback(
    (newState: Partial<DataTableState<FilterKeyT>>) => {
      const params = new URLSearchParams(location.search);

      const assignments: Record<string, string | undefined> = {
        query: newState.query,
        view: newState.view,
        cursor: newState.page?.cursor,
        take: newState.page?.take?.toString(),
        sortBy: newState.sort?.key,
        sortDirection: newState.sort?.direction,
      };

      filterKeys?.forEach((key) => {
        assignments[key] = encodeFilterValue(newState.filters?.[key] as FilterValue);
      });
      multiFilterKeys?.forEach((key) => {
        assignments[key] = (newState.filters?.[key] as FilterValue[] | undefined)
          ?.map(encodeFilterValue)
          .join(',');
      });

      Object.entries(assignments).forEach(([key, value]) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });

      const encodedParams = params.toString();
      window.history.pushState(
        {},
        '',
        [location.pathname, encodedParams && `?${encodedParams}`, location.hash]
          .filter((x) => x)
          .join(''),
      );
    },
    [filterKeys, location.hash, location.pathname, location.search, multiFilterKeys],
  );

  const [state, setState] = useState(locationData);

  useUpdateEffect(() => {
    setState(locationData);
  }, [locationData]);

  const expandedState = useMemo<DataTableState<FilterKeyT>>(
    () => ({
      ...state,
      filters: {
        ...(state.view ? views?.find((v) => v.key === state.view)?.filters : undefined),
        ...state.filters,
      },
    }),
    [state, views],
  );

  const updateState = useCallback(
    (update: Partial<DataTableState<FilterKeyT>>) =>
      setState((prev) => {
        const newState = { ...prev, ...update };
        updateLocation(newState);
        return newState;
      }),
    [updateLocation],
  );

  const getFilterValue = useCallback(
    <T = string>(key: FilterKeyT) => {
      const selection = expandedState.filters?.[key];
      if (!selection || Array.isArray(selection)) return undefined;
      return selection.value as T;
    },
    [expandedState.filters],
  );
  const getMultiFilterValues = useCallback(
    <T = string>(key: FilterKeyT) => {
      const selection = expandedState.filters?.[key];
      if (!Array.isArray(selection)) return undefined;
      return selection.map((x) => x.value) as T[];
    },
    [expandedState.filters],
  );

  const hasFilterValue = useCallback(
    (key: FilterKeyT, value: string) => {
      const selection = expandedState.filters?.[key];
      if (!selection) return false;
      if (Array.isArray(selection)) {
        return selection.some((x) => x.value === value);
      }
      return selection.value === value;
    },
    [expandedState.filters],
  );

  return [expandedState, updateState, { getFilterValue, getMultiFilterValues, hasFilterValue }];
}
