import React from 'react';
import { useOrderFulfillmentsComponent } from './useOrderFulfillmentsComponent';
import { Fulfillment } from './Fulfillment';
import { Card } from '../../../../../../components/card';
import { FulfillmentsContainer } from './styles';

export function OrderFulfillments({ loading, fulfillments = [] }) {
  const {
    models: { shipmentsByFulfillmentId, shipDateByFulfillmentId },
  } = useOrderFulfillmentsComponent({ fulfillments });
  return (
    <Card title="Fulfillments" count={loading ? undefined : fulfillments.length}>
      <FulfillmentsContainer>
        {fulfillments.map((f) => (
          <Fulfillment
            key={f.id}
            fulfillment={f}
            shipments={shipmentsByFulfillmentId[f.id] ?? []}
            shipDate={shipDateByFulfillmentId[f.id]}
          />
        ))}
      </FulfillmentsContainer>
    </Card>
  );
}
