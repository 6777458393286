import { CloseOutlined } from '@ant-design/icons';
import { LoadingSkeleton } from 'components/loading-skeleton';
import { DeleteButton } from './styles';

type Props = {
  text?: string;
  loading?: boolean;
  onRemove?: () => void;
  descriptor?: string;
};

export function ListItem({ text, onRemove, loading, descriptor = '' }: Props) {
  return (
    <li aria-label={text}>
      <span>{loading ? <LoadingSkeleton /> : text}</span>
      <DeleteButton
        type="text"
        size="small"
        icon={<CloseOutlined />}
        onClick={onRemove}
        aria-label={`Remove ${descriptor}`}
        disabled={loading}
      />
    </li>
  );
}
