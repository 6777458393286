import styled from 'styled-components';
import { Badge } from 'antd';
import { spaceHorizontalChildren } from '../../../styles/mixins';
import { Button } from '../../button';

export const Wrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SearchPanelContainer = styled.div`
  display: flex;
  align-items: center;

  ${spaceHorizontalChildren(1.5)}
  button,
  input,
  .ant-input-affix-wrapper {
    border-radius: 4px;
  }
`;

export const SearchSegment = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
`;

export const FilterButton = styled(Button)<{ $open?: boolean }>`
  ${({ $open }) =>
    $open &&
    `
    border-color: var(--blue-7);
    color: var(--blue-7);
  `}
`;

export const FilterBadge = styled(Badge)`
  sup {
    color: var(--gray-8);
    background: var(--gray-4);
    font-size: 10px;
    border: none;
    font-weight: bold;
  }
`;

export const ControlsDivider = styled.div`
  height: 32px;
  margin: 0;
  border-left: 1px solid var(--gray-4);
`;

export const PaginationControls = styled.div`
  display: flex;
  align-items: center;
  ${spaceHorizontalChildren(0.5)}
`;

export const RightControls = styled.div`
  display: flex;
  align-items: center;
  ${spaceHorizontalChildren()}
`;

export const TotalCount = styled.div<{ $loading?: boolean }>`
  display: flex;
  align-items: center;
  > span:first-child {
    text-transform: uppercase;
    margin-right: 8px;
  }
  > span:last-child {
    background: var(--gray-4);
    border-radius: 4px;
    padding: 0 8px;
    font-weight: 600;
    color: var(--gray-8);
    transition: opacity 0.2s ease-in-out;
    ${({ $loading }) => $loading && 'opacity: 0.5;'}
  }
`;
