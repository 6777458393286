import styled from 'styled-components';

export const List = styled.ul`
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  gap: 16px;
  min-height: 48px;
  align-items: center;
`;
