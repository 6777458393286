import { AsnDTO, AsnStatus } from '@invenco/common-interface/supply';
import {
  Filter,
  FilterSelections,
  useDataTable,
  useRowNavigation,
  View,
} from 'components/data-table';
import { asnStatusTitle } from 'shared/title-maps';
import { useQueryWithInput } from 'shared/hooks/queries';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useGateways } from '../../../../../gateways/GatewayProvider';
import { DEFAULT_PAGE_SIZE } from '../../../../../constants';
import { ComponentData, Page, Pagination } from '../../../../../shared/types';

export type AsnRow = AsnDTO;

export type FilterKey = 'statuses' | 'locationIds';

type Models = {
  isLoading: boolean;
  hasClosedRows: boolean;
  hasError: boolean;
  rows?: AsnRow[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  view?: string;
  filters: Filter<FilterKey>[];
  filterSelections?: FilterSelections<FilterKey>;
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  changeView: (view: string) => void;
  updateFilters: (filters: FilterSelections<FilterKey>) => void;
  refresh: () => void;
  onImportComplete: () => void;
  onClickRow: (row: AsnRow, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export const views: View<FilterKey>[] = [
  { key: '', title: 'All' },
  ...[AsnStatus.DRAFT, AsnStatus.OPEN, AsnStatus.CLOSED, AsnStatus.CANCELLED].map((status) => ({
    key: status,
    title: asnStatusTitle[status],
    filters: { statuses: [{ value: status, title: asnStatusTitle[status] }] },
  })),
];

const statusOptions = [
  AsnStatus.DRAFT,
  AsnStatus.OPEN,
  AsnStatus.PENDING,
  AsnStatus.CLOSED,
  AsnStatus.CANCELLED,
].map((status) => ({
  value: status,
  title: asnStatusTitle[status],
}));

export function useAsnListPage(): ComponentData<Models, Operations> {
  const { accountsGateway, supplyGateway } = useGateways();
  const { onClickRow } = useRowNavigation<AsnRow>({ baseUrl: '/supply/asns' });
  const [tableState, setTableState, { getMultiFilterValues }] = useDataTable({
    multiFilterKeys: ['statuses', 'locationIds'],
    views,
  });

  const statusValues = getMultiFilterValues('statuses');
  const hasClosedRows = !statusValues?.length || statusValues.includes(AsnStatus.CLOSED);

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'asns',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
      statuses: getMultiFilterValues<AsnStatus>('statuses'),
      locationIds: getMultiFilterValues('locationIds'),
    },
    query: (input, { signal }) => supplyGateway.getAsns(input, { signal }),
  });

  const { data: locations, isLoading: isLoadingLocations } = useQuery({
    queryKey: ['locations'],
    queryFn: ({ signal }) => accountsGateway.getLocations(undefined, { signal }),
    select: ({ items }) => items,
  });

  const locationFilter = useMemo<Filter<FilterKey>>(
    () => ({
      key: 'locationIds',
      title: 'Locations',
      loading: isLoadingLocations,
      options: locations?.map((loc) => ({ value: loc.id, title: loc.name })),
      multiple: true,
    }),
    [locations, isLoadingLocations],
  );

  const filters: Filter<FilterKey>[] = [
    {
      key: 'statuses',
      title: 'Status',
      options: statusOptions,
      multiple: true,
    },
    locationFilter,
  ];

  return {
    models: {
      isLoading,
      hasClosedRows,
      hasError: isError,
      rows: data?.items as AsnRow[] | undefined,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      view: tableState.view,
      filters,
      filterSelections: tableState.filters,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      changeView: (view) => setTableState({ view, page: undefined, filters: undefined }),
      updateFilters: (updated) =>
        setTableState({ filters: updated, view: undefined, page: undefined }),
      refresh: refetch,
      onImportComplete: refetch,
      onClickRow,
    },
  };
}
