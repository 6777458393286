import { AddressType } from '@invenco/common-interface/shared';
import { Input, Select } from 'antd';
import { Form } from '../../../components/form';
import { FormModal } from '../../../components/form-modal';
import { QuoteAddressDTO } from './useRequestQuotePage';
import { addressTypeTitle } from '../../../shared/title-maps';

type Props = {
  open: boolean;
  address?: QuoteAddressDTO;
  onClose: () => void;
  onSave: (address: QuoteAddressDTO) => void;
};

export function AddressModal({ open, address, onClose, onSave }: Props) {
  const [form] = Form.useForm();
  return (
    <FormModal
      title="To Address"
      open={open}
      onSave={() => {
        form.submit();
        onClose();
      }}
      onCancel={onClose}
      forceRender
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => onSave(data)}
        initialValues={address ?? { addressType: AddressType.RESIDENTIAL }}
      >
        <Form.Item
          name="address1"
          label="Address Line 1"
          rules={[{ required: true, message: 'Please enter an address' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="address2" label="Address Line 2">
          <Input />
        </Form.Item>
        <Form.Row>
          <Form.Item name="city" label="City">
            <Input />
          </Form.Item>
          <Form.Item name="state" label="State">
            <Input />
          </Form.Item>
        </Form.Row>
        <Form.Row>
          <Form.Item name="postcode" label="Postcode">
            <Input />
          </Form.Item>
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: 'Please enter a country' }]}
          >
            <Input />
          </Form.Item>
        </Form.Row>
        <Form.Item name="addressType" label="Type">
          <Select>
            {Object.values(AddressType).map((type) => (
              <Select.Option key={type} value={type}>
                {addressTypeTitle[type]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </FormModal>
  );
}
