import { CloseOutlined } from '@ant-design/icons';
import { Button } from '../../button';
import { Row } from '../types';
import { RowAction } from './types';
import { RowActionContainer } from './styles';

type Props<T extends Row> = {
  pendingAction?: string;
  actions: RowAction<T>[];
  isActionAvailable: Map<string, boolean>;
  selectionCount: number;
  onAction: (action: RowAction<T>) => void;
  onClose: () => void;
};

export function RowActionBar<T extends Row>({
  pendingAction,
  actions,
  isActionAvailable,
  selectionCount,
  onAction,
  onClose,
}: Props<T>) {
  return (
    <RowActionContainer>
      <span>{selectionCount} selected:</span>
      {actions.map((action) => {
        const isPending = action.key === pendingAction;
        return (
          <Button
            key={action.key}
            type="text"
            onClick={() => onAction(action)}
            disabled={action.disabled || isPending || !isActionAvailable.get(action.key)}
            loading={isPending}
          >
            {action.title}
          </Button>
        );
      })}
      <Button type="text" icon={<CloseOutlined />} onClick={onClose} aria-label="Clear selection" />
    </RowActionContainer>
  );
}
