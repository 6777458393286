import type {
  AccountDTO,
  CreateAccountDTO,
  LocationDTO,
  MembershipDTO,
  UserDTO,
  LocationStatus,
  BillDTO,
  BillStatus,
  ServiceAgreementDTO,
  UpdateAccountDTO,
} from '@invenco/common-interface/accounts';
import snakeCase from 'lodash/snakeCase';
import { BaseAxiosGateway, QueryRequestOptions, TokenGetter } from '../BaseAxiosGateway';
import { GatewayName, PaginatedResponse, SortDirection } from '../types';
import { UserMembershipSorter } from './UserMembershipSorter';

export type AccountSortBy = 'createdAt' | 'name';
export type LocationSortBy = 'createdAt' | 'name';
export type BillSortBy = 'createdAt' | 'periodStart';

export class AxiosAccountsGateway extends BaseAxiosGateway {
  name: GatewayName = 'accounts';

  constructor(
    getAccessToken: TokenGetter,
    private readonly userMembershipSorter: UserMembershipSorter,
  ) {
    super(getAccessToken);
  }

  async getAccount(id: string, options?: QueryRequestOptions): Promise<AccountDTO> {
    const { data } = await this.httpGet(`/v1/accounts/${id}`, options);
    return data.account;
  }

  async getAccounts(
    query?: {
      take?: number;
      cursor?: string;
      search?: string;
      sortBy?: AccountSortBy;
      sortDirection?: SortDirection;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<AccountDTO>> {
    const params = {
      take: query?.take,
      cursor: query?.cursor,
      q: query?.search,
      sortBy: query?.sortBy ? snakeCase(query.sortBy) : undefined,
      sortDirection: query?.sortDirection,
    };
    const { data } = await this.httpGet('/v1/accounts', { params, ...options });
    return { ...data, items: data.accounts };
  }

  async createAccount(account: CreateAccountDTO): Promise<AccountDTO> {
    const { data } = await this.httpPost<{ account: AccountDTO }>('/v1/accounts', {
      data: { account },
    });
    return data.account;
  }

  async updateAccount(
    accountId: Required<AccountDTO['id']>,
    account: UpdateAccountDTO,
  ): Promise<AccountDTO> {
    const { data } = await this.httpPatch<{ account: AccountDTO }>(`/v1/accounts/${accountId}`, {
      data: { account },
    });
    return data.account;
  }

  async createMembership(
    accountId: string,
    membership: Partial<MembershipDTO>,
  ): Promise<MembershipDTO> {
    const { data } = await this.httpPost<{ membership: MembershipDTO }>(
      `/v1/accounts/${accountId}/memberships`,
      { data: { membership } },
    );
    return data.membership;
  }

  async deleteMembership(accountId: string, membershipId: string): Promise<void> {
    await this.httpDelete(`/v1/accounts/${accountId}/memberships/${membershipId}`);
  }

  async resendInvite(userId: string): Promise<void> {
    await this.httpPost(`/v1/users/${userId}/resend_invite`);
  }

  async getCurrentUser(options?: QueryRequestOptions): Promise<UserDTO> {
    const { data } = await this.httpGet('/v1/users/me', options);
    return {
      ...data.user,
      memberships: await this.userMembershipSorter.getUserMembership(data.user),
    };
  }

  async setUserActiveMembership(activeMembershipId: string): Promise<UserDTO> {
    const { data } = await this.httpPatch<{ user: UserDTO }>('/v1/users/me', {
      data: { user: { activeMembershipId } },
    });
    return {
      ...data.user,
      memberships: await this.userMembershipSorter.updateMembershipLastAccessedAt(
        data.user,
        activeMembershipId,
      ),
    };
  }

  async getLocation(id: string, options?: QueryRequestOptions): Promise<LocationDTO> {
    const { data } = await this.httpGet(`/v1/locations/${id}`, options);
    return data.location;
  }

  async getLocations(
    query?: {
      take?: number;
      cursor?: string;
      accountId?: string;
      search?: string;
      locationIds?: string[];
      status?: LocationStatus;
      sortBy?: LocationSortBy;
      sortDirection?: SortDirection;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<LocationDTO>> {
    const { data } = await this.httpGet('/v1/locations', {
      params: {
        take: query?.take,
        cursor: query?.cursor,
        accountId: query?.accountId,
        status: query?.status,
        locationIds: query?.locationIds?.join(','),
        q: query?.search,
        sortBy: query?.sortBy ? snakeCase(query.sortBy) : undefined,
        sortDirection: query?.sortDirection,
      },
      ...options,
    });
    return { ...data, items: data.locations };
  }

  async createLocation(location: Partial<LocationDTO>): Promise<LocationDTO> {
    const { data } = await this.httpPost<{ location: LocationDTO }>('/v1/locations', {
      data: { location },
    });
    return data.location;
  }

  async getBills(
    query?: {
      take?: number;
      cursor?: string;
      search?: string;
      status?: BillStatus;
      accountId?: string;
      sortBy?: BillSortBy;
      sortDirection?: SortDirection;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<BillDTO>> {
    const { data } = await this.httpGet('/v1/bills', {
      params: {
        take: query?.take,
        cursor: query?.cursor,
        q: query?.search,
        status: query?.status,
        accountId: query?.accountId,
        sortBy: query?.sortBy ? snakeCase(query.sortBy) : undefined,
        sortDirection: query?.sortDirection,
      },
      ...options,
    });
    return { ...data, items: data.bills };
  }

  async getBill(id: string, options?: QueryRequestOptions): Promise<BillDTO> {
    const { data } = await this.httpGet(`/v1/bills/${id}`, options);
    return data.bill;
  }

  async closeBill(billId: string): Promise<void> {
    await this.httpPost(`/v1/bills/${billId}/close`);
  }

  async recalculateBill(billId: string): Promise<void> {
    await this.httpPost(`/v1/bills/${billId}/recalculate`);
  }

  async createServiceAgreement(
    serviceAgreement: Partial<ServiceAgreementDTO>,
  ): Promise<ServiceAgreementDTO> {
    const { data } = await this.httpPost<{ serviceAgreement: ServiceAgreementDTO }>(
      '/v1/service_agreements',
      {
        data: { serviceAgreement },
      },
    );
    return data.serviceAgreement;
  }

  async setServiceAgreementCommencement(
    serviceAgreementId: string,
    serviceAgreement: { commencementDate: string },
  ): Promise<ServiceAgreementDTO> {
    const { data } = await this.httpPatch<{ serviceAgreement: ServiceAgreementDTO }>(
      `/v1/service_agreements/${serviceAgreementId}`,
      {
        data: { serviceAgreement },
      },
    );
    return data.serviceAgreement;
  }

  async terminateServiceAgreement(
    serviceAgreementId: string,
    serviceAgreement: { terminationDate: string },
  ): Promise<ServiceAgreementDTO> {
    const { data } = await this.httpPost<{ serviceAgreement: ServiceAgreementDTO }>(
      `/v1/service_agreements/${serviceAgreementId}/terminate`,
      {
        data: { serviceAgreement },
      },
    );
    return data.serviceAgreement;
  }
}
