import { useEffect, useRef, useState } from 'react';
import { Alert, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { getLogger } from 'shared/logger/Logger';
import { useAuthToken } from '../../../../shared/hooks/useAuthToken';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { Container } from '../../../../styles/layout';
import { Header } from '../../../../components/header';

const logger = getLogger();

// NOTE: if there are similar integration installs in the future,
// probably best to refactor this into a generic page with an appCode route param
export function SkubanaInstall() {
  const { integrationGateway } = useGateways();
  const parameters = useQueryParams();
  const { accountId } = useAuthToken();
  const [result, setResult] = useState<boolean>();
  const requestSent = useRef(false);

  useEffect(() => {
    if (accountId && !requestSent.current) {
      requestSent.current = true;
      integrationGateway
        .installApp({
          accountId,
          appCode: 'skubana',
          flowName: 'Authentication',
          parameters: Object.fromEntries(parameters.entries()),
        })
        .then((callResult) => {
          setResult(callResult.isSuccess);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          logger.error(`Install failed: ${e}`);
          setResult(false);
        });
    }
  }, [accountId, parameters, integrationGateway]);

  const getAlertMessage = () => {
    if (result === undefined) {
      return (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
          <Alert type="info" message="Installing..." />
        </Spin>
      );
    }
    if (result) {
      return <Alert type="success" message="Installed successfully!" />;
    }
    return <Alert type="error" message="Installation failed!" />;
  };

  return (
    <Container>
      <Header title="Skubana Installation" links={[{ url: '', title: 'Integration' }]} />
      {getAlertMessage()}
    </Container>
  );
}
