import { AsnDTO } from '@invenco/common-interface/supply';
import { Form } from 'components/form';
import { FormModal } from 'components/form-modal';
import { Input } from 'components/input';
import { Result } from 'shared/helpers/Result';
import { useReferenceModalComponent } from './useReferenceModalComponent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  reference?: string | null;
  updateAsn: (data: Partial<AsnDTO>) => Promise<Result>;
};

export function ReferenceModal({ isOpen, reference, updateAsn, onClose }: Props) {
  const {
    models: { form, isSaving },
    operations: { submit },
  } = useReferenceModalComponent({ isOpen, onClose, updateAsn });
  return (
    <FormModal
      title="Reference"
      open={isOpen}
      onCancel={onClose}
      isSaving={isSaving}
      onSave={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => void submit(data)}
        initialValues={{ reference }}
      >
        <Form.Item name="reference">
          <Input.TextArea autoFocus rows={4} aria-label="Reference" />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
