import styled from 'styled-components';
import { Radio } from '../../../../../components/radio';

export const TypeRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  .ant-radio-button-wrapper {
    flex: 1;
    text-align: center;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;
