import styled from 'styled-components';
import { spaceHorizontalChildren } from '../../../styles/mixins';

export const TitleFormWrapper = styled.div`
  form {
    display: flex;
    align-items: center;
    ${spaceHorizontalChildren(0.5)}
  }

  input {
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
    padding: 2px 4px;
    margin-top: -3px;
    margin-bottom: -3px;
    margin-left: -5px;
    background: var(--gray-4);
    border: 1px solid var(--gray-5);
    width: calc(100% + 8px);
  }

  .ant-form-item-explain {
    display: none;
  }
`;
