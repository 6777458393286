import { SkuDTO } from '@invenco/common-interface/products';

import { Form } from 'components/form';
import { FormModal } from 'components/form-modal';
import { Input } from 'components/input';
import { Result } from 'shared/helpers/Result';
import { usePriceModalComponent } from './usePriceModalComponent';

type Props = {
  isOpen: boolean;
  sku: Partial<SkuDTO>;
  updateSku: (data: Partial<SkuDTO>) => Promise<Result>;
  onClose: () => void;
};

export function PriceModal({ isOpen, sku, updateSku, onClose }: Props) {
  const {
    models: { form, isSaving, initialValues },
    operations: { submit },
  } = usePriceModalComponent({ isOpen, onClose, sku, updateSku });

  return (
    <FormModal
      title="Prices"
      onSave={() => form.submit()}
      isSaving={isSaving}
      open={isOpen}
      onCancel={onClose}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinish={(data) => void submit(data)}
      >
        <Form.Item
          name="purchasePrice"
          label="Purchase Price"
          rules={[
            { required: true, message: 'Please enter a purchase price' },
            { pattern: /^\d+(\.\d{1,2})?$/, message: 'Must be a valid monetary amount' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="sellPrice"
          label="Sell Price"
          rules={[
            { required: true, message: 'Please enter a sell price' },
            { pattern: /^\d+(\.\d{1,2})?$/, message: 'Must be a valid monetary amount' },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
