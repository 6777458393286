import { Dropdown, MenuProps } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Button } from 'components/button';

type Props = {
  /** A list of action items. Each should have a key and a label. */
  items: MenuProps['items'];

  /** Called when an item is selected. The item's key (and other details) will be provided. */
  onSelect: MenuProps['onClick'];

  /** Toggles the loading state of dropdown button. */
  loading?: boolean;

  /** Accessible label for the dropdown button. */
  buttonLabel?: string;
};

/** A simple dropdown context menu triggered by a button. */
export function ContextMenu({ items, onSelect, loading = false, buttonLabel }: Props) {
  return (
    <Dropdown menu={{ items, onClick: onSelect }} trigger={['click']} disabled={loading}>
      <Button type="text" loading={loading} icon={<MoreOutlined />} aria-label={buttonLabel} />
    </Dropdown>
  );
}
