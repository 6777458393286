import { KitComponentDTO, SkuType } from '@invenco/common-interface/products';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { FormModal } from '../../../../../components/form-modal';
import { useMysteryComponentModal } from './useMysteryComponentModal';
import { Form } from '../../../../../components/form';
import { Input, InputNumber } from '../../../../../components/input';
import { VerticallySpaced } from '../../../../../styles/layout';
import { TypeRadioGroup } from './styles';
import { SelectSelectionMethods } from './SelectSelectionMethods';
import { DataTable } from '../../../../../components/data-table';
import { SkuSearch } from '../../../../../components/sku-search';
import { Button } from '../../../../../components/button';
import { Result } from '../../../../../shared/helpers/Result';
import {
  getMysterySkuComponents,
  isMysteryGroupComponent,
  MysteryComponent,
} from '../useMysteryComponents';

type Props = {
  open: boolean;
  existingMysteryComponent?: MysteryComponent;
  allKitComponents: KitComponentDTO[];
  onSave: (component: MysteryComponent) => Promise<Result>;
  onClose: () => void;
};

export function MysteryComponentModal({
  open,
  existingMysteryComponent,
  allKitComponents,
  onSave,
  onClose,
}: Props) {
  const {
    models: { isSaving, canSave, type, mysteryComponent, excludeSkuIds },
    operations: {
      changeType,
      updateGroupDetails,
      addChildComponent,
      updateChildComponent,
      deleteChildComponent,
      submit,
    },
  } = useMysteryComponentModal({
    isOpen: open,
    existingMysteryComponent,
    allKitComponents,
    onSave,
    onClose,
  });

  const componentTable = (
    <DataTable
      simple
      rows={getMysterySkuComponents(mysteryComponent)}
      emptyText="Add options"
      columns={[
        {
          title: 'SKU / Description',
          key: 'componentSku',
          render: (componentSku, { id }, index) => (
            <SkuSearch
              autoFocus={type === 'fixed' || (index > 0 && !componentSku)}
              selectedSku={componentSku}
              onSelect={(sku) =>
                updateChildComponent(id, { componentSku: sku, componentSkuId: sku?.id })
              }
              queryOptions={{ excludeSkuIds, type: SkuType.STANDARD }}
            />
          ),
        },
        {
          title: type === 'fixed' ? 'Quantity' : `Qty per Option`,
          key: 'quantity',
          align: 'right',
          render: (qty, { id }, index) => (
            <InputNumber
              aria-label={type === 'fixed' ? 'Quantity' : `Quantity for option ${index + 1}`}
              type="number"
              value={qty}
              onChange={(value) => updateChildComponent(id, { quantity: +(value || 0) })}
              precision={0}
              min={1}
            />
          ),
        },
        ...(type === 'group'
          ? ([
              {
                key: '',
                title: '',
                render: (_, { id }, index) => (
                  <Button
                    type="text"
                    icon={<CloseOutlined />}
                    aria-label={`Delete option ${index + 1}`}
                    onClick={() => id && deleteChildComponent(id)}
                    disabled={getMysterySkuComponents(mysteryComponent).length === 1}
                  />
                ),
                align: 'right',
              },
            ] as const)
          : []),
      ]}
    />
  );

  return (
    <FormModal
      title={existingMysteryComponent ? 'Manage component' : 'Add component'}
      onSave={submit}
      open={open}
      onCancel={onClose}
      isSaving={isSaving}
      saveDisabled={!canSave}
      width={700}
      forceRender={false}
    >
      <VerticallySpaced $factor={1}>
        <TypeRadioGroup
          options={[
            { label: 'Mystery Group', value: 'group' },
            { label: 'Fixed SKU', value: 'fixed' },
          ]}
          value={type}
          onChange={(e) => changeType(e.target.value)}
          optionType="button"
        />

        {isMysteryGroupComponent(mysteryComponent) ? (
          <>
            <Form layout="vertical">
              <Form.Item
                label="Group name"
                tooltip="A name or description for this group of options."
              >
                <Input
                  aria-label="Group name"
                  autoFocus
                  value={mysteryComponent.name ?? ''}
                  onChange={(e) => updateGroupDetails({ name: e.target.value })}
                />
              </Form.Item>

              <Form.Item
                label="Group selection quantity"
                tooltip="The number of options to select from this group for each mystery SKU sold."
              >
                <InputNumber
                  aria-label="Group selection quantity"
                  value={mysteryComponent.quantity}
                  onChange={(quantity) => quantity && updateGroupDetails({ quantity })}
                  precision={0}
                  min={1}
                />
              </Form.Item>

              <Form.Item
                label="Preferred method of selecting options"
                tooltip={`When this SKU is ordered, Lynk must pick from the options below${
                  mysteryComponent.quantity > 1
                    ? ` ${mysteryComponent.quantity} times. Each time, this`
                    : '. This'
                } method will be used to determine which of the options is most suitable.`}
              >
                <SelectSelectionMethods
                  value={mysteryComponent.selectionMethods}
                  onChange={(selectionMethods) => updateGroupDetails({ selectionMethods })}
                />
              </Form.Item>
            </Form>

            <VerticallySpaced $factor={0.5}>
              <div>
                Add the option SKUs and the required quantity to configure this mystery group.
              </div>

              {componentTable}

              <Button
                type="link"
                inline
                icon={<PlusOutlined aria-hidden />}
                onClick={addChildComponent}
              >
                Add option
              </Button>
            </VerticallySpaced>
          </>
        ) : (
          <VerticallySpaced $factor={0.5}>
            <div>Add the fixed SKU and the required quantity to configure this component.</div>
            {componentTable}
          </VerticallySpaced>
        )}
      </VerticallySpaced>
    </FormModal>
  );
}
