import { AxiosResponse } from 'axios';
import { extractErrorMessage } from 'shared/helpers';
import { Result } from '../../shared/helpers/Result';

export async function getResultFromRequest<T = any>(
  callback: () => Promise<AxiosResponse<T>>,
  validStatuses = [200, 201, 204],
): Promise<Result<T>> {
  try {
    const response = await callback();
    if (validStatuses.includes(response.status)) {
      return Result.ok(response.data);
    }
    return Result.fail(
      (response.data as any)?.message || `Unexpected status code: ${response.status}`,
    );
  } catch (error) {
    return Result.fail(extractErrorMessage(error));
  }
}
