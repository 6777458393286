import { AutoComplete as AntAutoComplete } from 'antd';
import { ComponentProps, ReactElement, ReactNode, useMemo, useState } from 'react';

import { Label } from 'components/label';
import { SearchInput } from '../search-input';
import { DropdownMenuWrapper, ListRow, NO_SELECT_CLASS, PrefixButton } from './styles';

type Props<T> = Omit<ComponentProps<typeof SearchInput>, 'onSelect'> & {
  /** A list of option objects, which can take any form */
  options: T[];

  /** Called when an option is selected with respective option object. */
  onSelect: (option: T) => any;

  /** Called when a search query is entered. This should trigger an update to the options list. */
  onSearch: (query: string) => any;

  /**
   * Callback to generate a label element from a given option object. By default, option.name and option.description
   * are displayed.
   */
  label?: (option: T) => ReactNode;

  /** Label element for a prefix button. No prefix button will appear if this is not specified. */
  prefixButtonLabel?: ReactNode;

  /** Called when the prefix button is pressed. */
  onPrefixClick?: () => any;

  /** Whether to disable the search input. */
  disabled?: boolean;

  /** Indicates that options are being loaded in response to a query. */
  loading?: boolean;
};

type DefaultT = { name: string; description: string };

// special values are used to ensure either onPrefixClick is triggered or no action is taken
// prefix button must be a special "option" because injecting it into the dropdown is unreliable
// empty text must an "option", because the prefix button is and the default empty text only displays with no options
const PREFIX_VALUE = '_PREFIX';
const EMPTY_VALUE = '_EMPTY';

/** An autocomplete search input which runs on arbitrary option objects. */
export function AutoComplete<T>({
  options,
  label,
  onSelect,
  onSearch,
  prefixButtonLabel,
  onPrefixClick,
  disabled = false,
  loading = false,
  autoFocus,
  ...inputProps
}: Props<T>) {
  const [value, setValue] = useState('');
  const parsedOptions = useMemo(
    () => [
      ...(prefixButtonLabel
        ? [
            {
              value: PREFIX_VALUE,
              label: (
                <div className={NO_SELECT_CLASS}>
                  <PrefixButton>{prefixButtonLabel}</PrefixButton>
                </div>
              ),
            },
          ]
        : []),
      ...(options.length
        ? options.map((opt) => ({
            value: JSON.stringify(opt),
            label: (
              <ListRow>
                {label ? (
                  label(opt)
                ) : (
                  <>
                    <Label normalCase>{(opt as DefaultT).name}</Label>
                    <span>{(opt as DefaultT).description}</span>
                  </>
                )}
              </ListRow>
            ),
          }))
        : [
            {
              value: EMPTY_VALUE,
              label: (
                <ListRow $noResults className={NO_SELECT_CLASS}>
                  No matching results
                </ListRow>
              ),
            },
          ]),
    ],
    [options, label, prefixButtonLabel],
  );

  const dropdownRender = (menu: ReactElement) => <DropdownMenuWrapper>{menu}</DropdownMenuWrapper>;

  return (
    <AntAutoComplete
      style={{ width: '100%' }}
      dropdownStyle={{ borderRadius: '8px' }}
      dropdownAlign={{ points: ['tr', 'br'] }}
      dropdownRender={dropdownRender}
      options={parsedOptions}
      value={value}
      onChange={(newValue) => ![EMPTY_VALUE, PREFIX_VALUE].includes(newValue) && setValue(newValue)}
      onSelect={(newValue) =>
        newValue === PREFIX_VALUE
          ? onPrefixClick?.()
          : newValue && newValue !== EMPTY_VALUE && onSelect(JSON.parse(newValue))
      }
      onSearch={onSearch}
      disabled={disabled}
      autoFocus={autoFocus}
    >
      <SearchInput {...inputProps} loading={loading} />
    </AntAutoComplete>
  );
}
