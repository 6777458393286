import { Select } from 'antd';
import { WorkflowConfigurationMap } from '@invenco/common-domain/enums';
import { useWorkflowScopeType } from '../../WorkflowScopeTypeContext';
import { ActionChildSection } from '../styles';
import { Form } from '../../../../../../components/form';
import { actionParamsKey, actionTypeKey, FormKey, getFormItemRules } from '../../helpers/forms';
import { actionDetails } from '../../config/actions';
import { ValueFormItem } from '../value-form-item/ValueFormItem';
import { DeleteButton } from '../../../../../../components/button';
import { ActionDetails } from '../../config/types';

type Props = {
  formKey: FormKey;
  details?: ActionDetails;
  onChangeType: () => void;
  onDelete: () => void;
  editable: boolean;
};

export function Action({ formKey, details, editable, onDelete, onChangeType }: Props) {
  const scopeType = useWorkflowScopeType();
  const hasSingleParam = details?.params?.length === 1;

  return (
    <ActionChildSection>
      <Form.Item
        name={[...formKey, actionTypeKey]}
        label="Action type"
        rules={[{ required: true, message: 'A valid action is required' }]}
      >
        <Select
          placeholder="Action type"
          disabled={!editable}
          onChange={onChangeType}
          options={Object.keys(WorkflowConfigurationMap[scopeType].actionTypeOutcomeAttributes)
            .filter((name) => !actionDetails[name]?.disabled)
            .map((name) => ({ value: name, label: actionDetails[name]?.title ?? name }))}
          showSearch
          filterOption={(input, option) =>
            option?.label.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ?? false
          }
        />
      </Form.Item>

      {
        /* spacer element to force all params to next line while keeping the type select's size consistent */
        !hasSingleParam && <div />
      }

      {details?.params?.map(({ key, title, ...paramDetails }) => (
        <ValueFormItem
          {...paramDetails}
          key={key}
          name={[...formKey, actionParamsKey, key]}
          label={title}
          rules={getFormItemRules(paramDetails)}
          placeholder={title}
          required={paramDetails.required}
          disabled={!editable}
        />
      ))}
      <DeleteButton
        htmlType="button"
        aria-label="Remove condition"
        onClick={onDelete}
        disabled={!editable}
      />
    </ActionChildSection>
  );
}
