import styled from 'styled-components';

export const SameAddressText = styled.span`
  color: var(--gray-8);
`;

export const ShippingProfileTitle = styled.div`
  font-weight: 700;
`;

export const ShippingDescription = styled.div`
  font-size: 12px;
  color: var(--gray-8);
`;

export const OrderCount = styled.div`
  display: flex;
  height: 38px;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-8);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

export const CardSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

export const CardSubHeaderTitle = styled.div<{
  $applyPaddingTop?: boolean;
}>`
  display: flex;
  ${({ $applyPaddingTop }) => $applyPaddingTop && 'padding-top: 8px;'}
  padding-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const CardSubHeaderContent = styled.div`
  font-size: 14px;
  color: var(--gray-9);
  font-weight: 400;
  line-height: 22px;
  overflow-wrap: break-word;
`;
