import { ChildConditionType, PropertyConditionName } from '@invenco/common-domain/enums';
import {
  ActionDTO,
  ConditionDTO,
  PropertyConditionDTO,
} from '@invenco/common-interface/automation';
import { Form, FormInstance } from 'antd';
import { useEffect, useState } from 'react';
import { ComponentData } from 'shared/types';
import { actionDetails } from '../config/actions';
import {
  getDefaultPropertyCondition,
  getPropertyConditionDetails,
  isPropertyCondition,
} from '../helpers/conditions';
import {
  actionParamsKey,
  childConditionsKey,
  conditionOperatorKey,
  conditionPropertyKey,
  conditionValueKey,
  FormKey,
  rootConditionKey,
} from '../helpers/forms';
import { NormalizedRuleDTO } from '../helpers/ruleNormalization';
import { useWorkflowScopeType } from '../WorkflowScopeTypeContext';

type Props = {
  isEditing: boolean;
  onSave: (rule: Partial<NormalizedRuleDTO>) => void;
  onEdit: () => void;
  onStopEditing: () => void;
  rule?: Partial<NormalizedRuleDTO>;
};

type Models = {
  form: FormInstance;
  isHidden: boolean;
};

type Operations = {
  edit: () => void;
  toggleHidden: () => void;
  addCondition: (formKey: FormKey) => void;
  deleteCondition: (formKey: FormKey) => void;
  resetPropertyCondition: (formKey: FormKey) => void;
  resetAction: (formKey: FormKey) => void;
  submit: (formData: FormData) => void;
};

/** formData is presumed to be the structure of a normalized rule */
export type FormData = Partial<NormalizedRuleDTO>;

export function useRuleCardComponent({
  isEditing,
  onSave,
  onEdit,
  onStopEditing,
  rule,
}: Props): ComponentData<Models, Operations> {
  const [isHidden, setIsHidden] = useState(false);
  const [form] = Form.useForm();
  const scopeType = useWorkflowScopeType();

  useEffect(() => {
    // always update in response to rule changes, but only if we're not currently editing the rule
    if (!isEditing) {
      form.resetFields();
    }
  }, [form, isEditing, rule]);

  const removeEmptyConditions = (conditions: (ConditionDTO | PropertyConditionDTO)[]) =>
    conditions
      .map((condition) => ({
        ...condition,
        childConditions: !isPropertyCondition(condition)
          ? removeEmptyConditions(condition.childConditions ?? [])
          : undefined,
      }))
      .filter((condition) => isPropertyCondition(condition) || condition.childConditions?.length);

  const edit = () => {
    setIsHidden(false);
    onEdit();
  };

  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  // adds a new property condition to the condition at formKey
  // if the condition is at the root level, it will create a new group also
  const addCondition = (formKey: FormKey) => {
    const parent = form.getFieldValue(formKey) as ConditionDTO | undefined;
    if (!parent || isPropertyCondition(parent)) return;
    const isRoot = formKey.length <= 1; // ['condition'] / rootConditionKey
    form.setFieldValue(
      [...formKey, childConditionsKey],
      [
        ...(parent.childConditions ?? []),
        !isRoot
          ? getDefaultPropertyCondition(scopeType)
          : {
              childConditionType:
                parent.childConditionType === ChildConditionType.ANY
                  ? ChildConditionType.ALL
                  : ChildConditionType.ANY,
              childConditions: [getDefaultPropertyCondition(scopeType)],
            },
      ],
    );
  };

  // deletes the property condition at formKey
  // if one or more condition groups are left empty after, they will also be deleted
  const deleteCondition = (formKey: FormKey) => {
    const parentKey = formKey.slice(0, -2);
    const childIndex = formKey.slice(-1)[0];
    const parent = form.getFieldValue(parentKey) as ConditionDTO | undefined;
    if (!parent || typeof childIndex !== 'number') return;
    const childConditions = parent.childConditions?.filter((_, i) => i !== childIndex) ?? [];
    form.setFieldValue([...parentKey, childConditionsKey], childConditions);

    const currentData = form.getFieldValue([rootConditionKey, childConditionsKey]) ?? [];
    form.setFieldValue([rootConditionKey, childConditionsKey], removeEmptyConditions(currentData));
  };

  // for a property condition, clears the value and updates the operator based on the selected property
  const resetPropertyCondition = (formKey: FormKey) => {
    form.setFieldValue([...formKey, conditionValueKey], undefined);
    const property = form.getFieldValue([...formKey, conditionPropertyKey]) as
      | PropertyConditionName
      | undefined;
    const details = property ? getPropertyConditionDetails(property) : undefined;
    form.setFieldValue([...formKey, conditionOperatorKey], details?.operators[0] ?? '');
  };

  // updates params for an action to the default values (if not already specified) based on selected type
  const resetAction = (formKey: FormKey) => {
    const action = form.getFieldValue(formKey) as ActionDTO | undefined;
    if (!action) return;
    const details = actionDetails[action.type];
    const defaults = Object.fromEntries(
      details?.params?.map((param) => [param.key, param.initialValue]) ?? [],
    );
    // if the values were already set at one point in the form, use those while falling back to defaults
    form.setFieldValue([...formKey, actionParamsKey], { ...defaults, ...action.params });
  };

  const submit = (formData: FormData) => {
    // formData is presumed to be the structure of a normalized rule
    onSave(formData as FormData);
    onStopEditing();
  };

  return {
    models: { form, isHidden },
    operations: {
      addCondition,
      deleteCondition,
      resetPropertyCondition,
      resetAction,
      edit,
      toggleHidden,
      submit,
    },
  };
}
