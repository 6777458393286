import { ActionName } from '@invenco/common-domain/enums';
import { Form } from 'components/form';
import { actionDetails } from '../../config/actions';
import { actionsKey, actionTypeKey, FormKey, getValueViaFormKey } from '../../helpers/forms';
import { AddButton } from '../AddButton';
import { ChildSection, ParentSection } from '../styles';
import { Action } from './Action';

type Props = {
  title: string;
  formKey: FormKey;
  editable: boolean;
  onResetAction: (formKey: FormKey) => void;
};

export function ActionsSection({ title, formKey, editable, onResetAction }: Props) {
  return (
    <Form.List
      name={formKey}
      rules={[
        {
          message: 'At least one action is required',
          validator: async (_, actions) => {
            if (formKey[0] === actionsKey && actions?.length < 1) {
              return Promise.reject(new Error('At least one action is required'));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <ParentSection $hasErrors={errors.length > 0}>
          <h3>{title}</h3>
          {fields.map((field) => (
            <Form.Item
              key={field.key}
              noStyle
              shouldUpdate={(prev, cur) => {
                const typeKey = [...formKey, field.name, actionTypeKey];
                return getValueViaFormKey(prev, typeKey) !== getValueViaFormKey(cur, typeKey);
              }}
            >
              {({ getFieldValue }) => {
                // NOTE: Form.List prefixes names with its form key for Form.Item elements
                // Hence the full key is used here and in shouldUpdate, but the key passed into Action is relative
                const type = getFieldValue([...formKey, field.name, actionTypeKey]) as
                  | ActionName
                  | undefined;
                const details = type ? actionDetails[type] : undefined;
                return (
                  <Action
                    key={field.key}
                    formKey={[field.name]}
                    details={details}
                    onDelete={() => remove(field.name)}
                    onChangeType={() => onResetAction([...formKey, field.name])}
                    editable={editable}
                  />
                );
              }}
            </Form.Item>
          ))}

          {editable && (
            <ChildSection>
              <AddButton onClick={() => add({ params: {} })}>Action</AddButton>
            </ChildSection>
          )}
          <Form.ErrorList errors={errors} />
        </ParentSection>
      )}
    </Form.List>
  );
}
