import { useMemo } from 'react';
import { useGateways } from '../../../../../../gateways/GatewayProvider';
import { useQueryWithInput } from '../../../../../../shared/hooks/queries';

export const useOrderFulfillmentsComponent = ({ fulfillments = [] }) => {
  const { shippingGateway } = useGateways();
  const { data } = useQueryWithInput({
    parentKey: 'shipments',
    input: { shippableIds: fulfillments.map((f) => f.id) },
    query: (input) => shippingGateway.getShipments(input),
    enabled: fulfillments.length > 0,
  });

  const shipmentsByFulfillmentId = useMemo(() => {
    const map = {};

    if (data?.items) {
      data.items.forEach((shipment) => {
        const id = shipment.shippableId;
        if (!map[id]) {
          map[id] = [];
        }
        map[id].push(shipment);
      });
    }

    fulfillments.forEach(({ id }) => {
      map[id] = map[id] ?? [];
    });
    return map;
  }, [data, fulfillments]);

  const shipDateByFulfillmentId = useMemo(
    () =>
      Object.fromEntries(
        fulfillments.map(({ id }) => [
          id,
          (shipmentsByFulfillmentId[id] ?? []).reduce(
            (current, { shippedAt }) =>
              !current || (shippedAt && shippedAt > current) ? shippedAt : current,
            undefined,
          ),
        ]),
      ),
    [fulfillments, shipmentsByFulfillmentId],
  );

  return {
    models: {
      shipmentsByFulfillmentId,
      shipDateByFulfillmentId,
    },
  };
};
