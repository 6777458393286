import { Select } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useGateways } from '../../../../gateways/GatewayProvider';

type Props = {
  value?: string;
  onChange: (id: string) => void;
  disabled?: boolean;
};

export function WmsInstanceSelect({ value, onChange, disabled }: Props) {
  const { warehousingGateway } = useGateways();
  const { data, isLoading } = useQuery({
    queryKey: ['wmsInstances'],
    queryFn: ({ signal }) => warehousingGateway.getWmsInstances(undefined, { signal }),
  });
  return (
    <Select
      loading={isLoading}
      disabled={disabled}
      value={value}
      onChange={onChange}
      options={data?.map((instance) => ({ value: instance.id, label: instance.code }))}
    />
  );
}
