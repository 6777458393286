import { StyledTabs } from './styles';

import { ViewTab } from './types';

type Props = {
  views: ViewTab[];
  current: string;
  onChange: (key: string) => void;
};

export function ViewTabs({ views, current, onChange }: Props) {
  return (
    <StyledTabs
      onTabClick={(key) => onChange(key)}
      activeKey={current}
      items={views.map(({ key, title }) => ({ key, label: title }))}
    />
  );
}

ViewTabs.displayName = 'DataTable.ViewTabs';
