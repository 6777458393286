import { useMemo } from 'react';
import { BillableTransactionCategory, BillLineDTO } from '@invenco/common-interface/accounts';
import { ComponentData } from 'shared/types';

type Models = {
  lineItems: Record<string, Partial<BillLineDTO>[]>;
};

type Operations = {};

type Props = {
  billLines: Partial<BillLineDTO>[];
};

export function useBillingDetailsComponent({
  billLines,
}: Props): ComponentData<Models, Operations> {
  const lineItems = useMemo(() => {
    const obj: Record<string, Partial<BillLineDTO>[]> = {};
    if (!billLines) return obj;
    [
      BillableTransactionCategory.FULFILLMENT,
      BillableTransactionCategory.INVENTORY,
      BillableTransactionCategory.SHIPPING,
      BillableTransactionCategory.PLATFORM,
    ].forEach((category) => {
      const filteredCategory = billLines.filter((line) => line.category === category);
      if (filteredCategory.length) obj[category] = filteredCategory;
    });
    return obj;
  }, [billLines]);

  return {
    models: {
      lineItems,
    },
    operations: {},
  };
}
