import styled from 'styled-components';
import { Divider } from 'antd';
import { spaceHorizontalChildren } from '../../../styles/mixins';
import { SearchInput } from '../../search-input';

export const MenuTrigger = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 40px;
  cursor: pointer;
  ${spaceHorizontalChildren(0.5)}
  .anticon {
    font-size: 12px;
  }
`;

export const UserInfo = styled.div`
  font-size: 12px;
  text-align: right;
  > *:first-child {
    font-weight: 600;
  }
  > *:last-child {
    opacity: 0.65;
  }
`;

export const MenuContainer = styled.div`
  .ant-dropdown-menu {
    padding: 8px 0 16px;

    .ant-dropdown-menu-item {
      display: flex;
      align-items: center;
      padding: 0px 24px;

      &.static,
      &.divider {
        cursor: default;
        &:hover {
          background-color: var(--white);
        }
      }

      &.divider {
        min-height: unset;
      }
    }

    .ant-dropdown-menu-item-group-title {
      font-size: 12px;
      color: var(--gray-7);
      padding: 8px 24px;
    }
    .ant-dropdown-menu-item-group-list {
      margin: 0;
    }
  }
`;

export const ItemContent = styled.div<{ $active?: boolean; $selectable?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  ${spaceHorizontalChildren(0.5)}

  .anticon {
    font-size: 16px;
  }
  > *:nth-child(2) {
    flex: 1;
  }

  ${({ $active }) => $active && 'font-weight: 600;'}
  ${({ $selectable }) => $selectable && 'cursor: pointer;'}
`;

export const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;

  > div {
    display: flex;
    align-items: flex-start;
    ${spaceHorizontalChildren(0.5)}
    .anticon {
      line-height: 24px;
      font-size: 16px;
    }
    > *:nth-child(2) {
      flex: 1;
    }
  }
`;

export const UserName = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--gray-7);
`;

export const Membership = styled.div<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 24px;
  font-size: 14px;
  min-height: 32px;
  ${spaceHorizontalChildren(0.5)}

  .anticon {
    font-size: 16px;
  }

  > *:first-child {
    flex: 1;
  }

  ${({ $active }) => ($active ? 'font-weight: 600;' : 'cursor: pointer;')}
`;

export const StyledSearch = styled(SearchInput)`
  margin: 0 -8px;
  width: unset;
  padding: 4px 8px;
  .anticon {
    font-size: 16px;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 8px -8px;
`;
