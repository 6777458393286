import styled from 'styled-components';
import { ButtonProps } from 'antd';
import { smallUpperCaseText } from 'styles/mixins';
import { Button } from './Button';

/** A specifically styled link Button used to trigger actions */
export const ActionLink = styled(({ htmlType = 'button', ...props }: ButtonProps) => (
  <Button htmlType={htmlType} type="link" inline {...props} />
))<ButtonProps>`
  height: 24px;
  ${smallUpperCaseText}
  color: var(--blue-6);
`;
