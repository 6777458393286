import { LocationInventoryDTO } from '@invenco/common-interface/inventory';
import { BaseAxiosGateway, QueryRequestOptions } from '../BaseAxiosGateway';
import { GatewayName, PaginatedResponse } from '../types';

export class AxiosInventoryGateway extends BaseAxiosGateway {
  name: GatewayName = 'inventory';

  async getInventories(
    query?: {
      take?: number;
      cursor?: string;
      search?: string;
      locationIds?: string[];
      skuName?: string;
      skuIds?: string[];
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<LocationInventoryDTO>> {
    const params = {
      take: query?.take,
      cursor: query?.cursor,
      q: query?.search,
      locationIds: query?.locationIds?.join(','),
      skuName: query?.skuName,
      skuIds: query?.skuIds?.join(','),
    };
    const { data } = await this.httpGet('/v1/inventories', { params, ...options });
    return { ...data, items: data.inventories };
  }
}
