import { ContactAddressDTO } from '@invenco/common-interface/shared';
import { AsnDTO } from '@invenco/common-interface/supply';
import { Col, Row } from 'antd';
import { Form } from 'components/form';
import { FormModal } from 'components/form-modal';
import { Input } from 'components/input';
import { Result } from 'shared/helpers/Result';
import { useFromAddressModalComponent } from './useFromAddressModalComponent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  fromAddress?: ContactAddressDTO;
  updateAsn: (data: Partial<AsnDTO>) => Promise<Result>;
};

export function FromAddressModal({ isOpen, fromAddress, updateAsn, onClose }: Props) {
  const {
    models: { form, isSaving },
    operations: { submit },
  } = useFromAddressModalComponent({ isOpen, onClose, updateAsn });
  return (
    <FormModal
      title="From Address"
      description="Address details of where the goods will be shipped from"
      open={isOpen}
      onCancel={onClose}
      isSaving={isSaving}
      onSave={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => void submit(data)}
        initialValues={fromAddress}
      >
        <Form.Item name="companyName" label="Company Name">
          <Input />
        </Form.Item>
        <Form.Item
          name="contactName"
          label="Contact Name"
          rules={[
            {
              required: true,
              message: 'Please enter contact name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="contactNumber" label="Phone">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="contactEmail" label="Email">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="address1"
          label="Address line 1"
          rules={[
            {
              required: true,
              message: 'Please enter address',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="address2" label="Address line 2">
          <Input />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="city" label="City">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="state" label="State">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="postcode" label="Postcode">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: 'Please enter country',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </FormModal>
  );
}
