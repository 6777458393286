/**
 * Facade for the CommandAI SDK so that we don't have to reference the globals directly
 * through the codebase.
 *
 * 🔗 See [SDK Docs](https://www.command.ai/docs/category/lifecycle/) for more information
 */
export function useCommandAI() {
  const { CommandBar } = window;

  /**
   * The type definition for shareState() is possibly wrong because it returns undefined when
   * CommandAI is not booted. So always check it is defined before using the state
   */
  const commandAiState = CommandBar.shareState();

  return {
    /**
     * useful to check if CommandAI is booted in the event of re-renders so that redunant boots are
     * prevented
     */
    isBooted: commandAiState?.isBooted ?? false,
    /**
     * bootstrap CommandAI
     * Must be called at least once while rendering the app
     * @returns a promise that resolves when CommandAI is booted
     */
    boot: CommandBar.boot.bind(CommandBar),
    /**
     * add a router to the CommandAI instance such as React Router. This allows CommandAI to make
     * page transitions without a full page load
     *
     * 🔗 See [SDK Docs](https://www.command.ai/docs/developer/adding-a-router/) for more information
     */
    addRouter: CommandBar.addRouter.bind(CommandBar),
    /**
     * Used to group Spotlight records into their correct categories when they are returned from a
     * search query
     */
    addMultiSearch: CommandBar.addMultiSearch.bind(CommandBar),
    /**
     * Used to define different types of records in Spotlight
     */
    addRecords: CommandBar.addRecords.bind(CommandBar),
    /**
     * Shuts down the CommandAI instance
     */
    shutdown: CommandBar.shutdown.bind(CommandBar),
  };
}
