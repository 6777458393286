import styled from 'styled-components';
import { Input } from 'antd';

export const StyledInput = styled(Input)`
  height: 32px;
  border-radius: 4px;
  .ant-input-prefix {
    margin-right: 8px;
  }
`;
