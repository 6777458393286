import { useQuery } from '@tanstack/react-query';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import { useAuth0 } from '@auth0/auth0-react';
import { RefCallback, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getLogger } from 'shared/logger/Logger';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { BreadCrumb } from '../../../../components/header';
import { ComponentData, InsightDTO } from '../../../../shared/types';

const logger = getLogger();

type Models = {
  insight?: InsightDTO;
  isLoading: boolean;
  breadcrumbs: BreadCrumb[];
  embedRef: RefCallback<HTMLElement>;
};

export function useInsightDetailsPage(): ComponentData<Models> {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth0();
  const { analyticsGateway } = useGateways();

  const insightQuery = useMemo(
    () =>
      user
        ? {
            email: user.email,
            // Prefer given name, otherwise split the first name off the full name
            firstName: user.given_name || user.name?.substring(0, user.name.indexOf(' ')),
            // Prefer middle + family name, otherwise split everything but the first name off the full name
            lastName: user.family_name
              ? `${user.middle_name} ${user.family_name}`
              : user.name?.substring(user.name.indexOf(' ') + 1),
          }
        : undefined,
    [user],
  );

  const { data, isLoading } = useQuery({
    queryKey: ['insights', id, insightQuery],
    queryFn: ({ signal }) => analyticsGateway.getInsight(id!, insightQuery, { signal }),
    enabled: !!id && !!insightQuery,
    refetchInterval: false,
    refetchOnReconnect: false,
  });
  const { insight, lookerEmbedUrl } = data || {};

  const name = insight?.name;
  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '/analytics', title: 'Analytics' },
      { url: '/analytics/insights/', title: 'Insights' },
      { url: `/analytics/insights/${id}`, title: name, isLoading },
    ],
    [id, isLoading, name],
  );

  const embedRef = useCallback(
    (node: HTMLElement | null) => {
      if (!node || !lookerEmbedUrl) return;
      // eslint-disable-next-line no-param-reassign
      node.innerHTML = '';

      // TODO: no promises or events resolve
      //  This issue may have solutions: https://github.com/looker-open-source/embed-sdk/issues/63
      //  That said the effort may not be worth it as we will likely be migrating service
      LookerEmbedSDK.createDashboardWithUrl(lookerEmbedUrl)
        .appendTo(node)
        .build()
        .connect()
        .then((dashboard) => {
          document.querySelector('#run')?.addEventListener('click', () => {
            dashboard.send('dashboard:run');
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          logger.error('An unexpected error occurred', error);
        });
    },
    [lookerEmbedUrl],
  );

  return {
    models: { insight, breadcrumbs, isLoading, embedRef },
  };
}
