import { useEffect, useRef, useState } from 'react';
import { useIsFetching } from '@tanstack/react-query';
import { ComponentData } from 'shared/types';
import { useDocumentTitle } from 'shared/hooks/useDocumentTitle';

type Props = {
  isEditable: boolean;
  editInitially: boolean;
  documentTitle?: string;
  onSave?: (title: string) => void;
  onRefresh?: () => Promise<void>;
};

type RefreshState = 'idle' | 'refreshing' | 'refreshed';

type Models = {
  isEditing: boolean;
  isFetching: boolean;
  refreshingState: RefreshState;
};

type Operations = {
  openEditForm: () => void;
  cancelEditing: () => void;
  save: (title: string) => void;
  refresh: () => Promise<void>;
};

const REFRESH_INACTIVE_TIME = 1000;

export function useHeaderComponent({
  isEditable,
  documentTitle,
  editInitially,
  onSave,
  onRefresh,
}: Props): ComponentData<Models, Operations> {
  const [isEditing, setIsEditing] = useState(false);
  const [refreshingState, setRefreshingState] = useState<RefreshState>('idle');
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const isFetching = useIsFetching() > 0;
  useDocumentTitle(documentTitle);

  const openEditForm = () => {
    if (isEditable) setIsEditing(true);
  };

  const save = (newTitle: string) => {
    if (onSave) onSave(newTitle);
    setIsEditing(false);
  };

  const refresh = async () => {
    if (onRefresh) {
      setRefreshingState('refreshing');
      await onRefresh();
    }
    setRefreshingState('refreshed');
    timerRef.current = setTimeout(() => setRefreshingState('idle'), REFRESH_INACTIVE_TIME);
  };

  useEffect(() => {
    if (editInitially) setIsEditing(true);
  }, [editInitially]);

  useEffect(
    () => () => {
      if (timerRef.current !== undefined) {
        clearTimeout(timerRef.current);
      }
    },
    [],
  );

  return {
    models: { isEditing, isFetching, refreshingState },
    operations: {
      openEditForm,
      cancelEditing: () => setIsEditing(false),
      save,
      refresh,
    },
  };
}
