import styled from 'styled-components';
import { spaceHorizontalChildren } from '../../../../styles/mixins';

export const ProfileList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    > * {
      min-height: 64px;
      display: flex;
      align-items: center;
      color: var(--gray-9);
      ${spaceHorizontalChildren(1)}

      margin: 0 -32px;
      padding: 0 32px;

      &:hover {
        background: var(--gray-2);
      }
    }

    &:not(:last-child) > * {
      border-bottom: 1x solid var(--gray-4);
    }
  }
`;

export const ProfileLabels = styled.div`
  flex: 1;
  min-width: 0;
  > *:first-child {
    font-weight: 600;
  }
  > *:last-child {
    color: var(--gray-8);
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ProfileIcon = styled.div`
  font-size: 16px;
  margin-right: 16px;
`;

export const Message = styled.div`
  text-align: center;
  color: var(--gray-8);
`;
