import styled from 'styled-components';
import { Button } from 'components/button';
import { spaceHorizontalChildren } from '../../styles/mixins';

export const NO_SELECT_CLASS = 'no-item-select';

export const ListRow = styled.div<{ $noResults?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 8px 12px;
  width: 100%;
  ${({ $noResults }) => $noResults && 'color: var(--gray-7);'}
  ${spaceHorizontalChildren()}
`;

export const DropdownMenuWrapper = styled.div`
  .ant-select-item {
    padding: 0;
    &:has(.ant-select-item-option-content > .${NO_SELECT_CLASS}) {
      cursor: default;
    }
  }
  .ant-select-item-option-active {
    background: var(--blue-1);
    &:has(.ant-select-item-option-content > .${NO_SELECT_CLASS}) {
      background: var(--gray-1);
    }
  }
`;

export const PrefixButton = styled(Button)`
  &&& {
    margin: 0 4px 4px;
    border-radius: 4px;
    background: var(--gray-3);
    width: calc(100% - 8px);
    font-weight: 700;
    padding: 5px 12px;
    border: none;
    text-align: left;
    &:hover,
    &:active,
    &:focus {
      background: var(--blue-1);
      color: var(--gray-9);
    }
  }
`;
