import { AddressDTO, ContactAddressDTO } from '@invenco/common-interface/shared';

import { LoadingSkeleton } from '../loading-skeleton';

type Props = {
  /** The address DTO to display. Can fully or partially filled. */
  address?: AddressDTO | ContactAddressDTO;

  /** Whether the address is loading. Will display loading skeleton when true. */
  loading?: boolean;
};

/** Simple component to display an address object. */
export function AddressDisplay({ address, loading }: Props) {
  if (loading) {
    return <LoadingSkeleton rows={4} />;
  }

  const {
    companyName,
    contactName,
    contactNumber,
    contactEmail,
    address1,
    address2,
    country,
    type: _,
    ...rest
  } = (address as any) || {}; // unideal typecast

  const location = Object.values(rest).filter(Boolean).join(', ');

  return (
    <div>
      {Object.entries({
        companyName,
        contactName,
        contactNumber,
        contactEmail,
        address1,
        address2,
        location,
        country,
      })
        .filter(([, text]) => text)
        .map(([key, text]) => (
          <div key={key}>{text}</div>
        ))}
    </div>
  );
}
