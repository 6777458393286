import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { AddItemButton, StyledList } from './styles';
import { ListItem } from './ListItem';
import { VerticallySpaced } from '../../styles/layout';
import { NewItemForm } from './NewItemForm';

type Props = {
  /** Whether the list is in a loading state. */
  loading?: boolean;

  /** The list of string items currently in the list. */
  items: string[];

  /** Callback for adding an item to the list. */
  onAdd: (text: string) => void;

  /** Callback for removing an item from the list. */
  onRemove: (index: number) => void;

  /** The descriptor for the kind of items in the list. */
  descriptor?: string;

  /** Placeholder text for the new item input. */
  placeholder?: string;
};

/** A list of string items that can be added to and removed from. */
export function EditableList({
  loading = false,
  items,
  onAdd,
  onRemove,
  descriptor = '',
  placeholder = '',
}: Props) {
  const [isAdding, setIsAdding] = useState(false);
  const addItem = (text: string) => {
    onAdd(text);
    setIsAdding(false);
  };

  return (
    <VerticallySpaced>
      <StyledList>
        {loading ? (
          <ListItem loading />
        ) : (
          items.map((text, index) => (
            <ListItem
              key={text}
              text={text}
              onRemove={() => onRemove(index)}
              descriptor={descriptor}
            />
          ))
        )}
      </StyledList>
      {isAdding ? (
        <NewItemForm
          onSave={addItem}
          onCancel={() => setIsAdding(false)}
          descriptor={descriptor}
          placeholder={placeholder}
        />
      ) : (
        <AddItemButton
          type="link"
          icon={<PlusOutlined />}
          onClick={() => setIsAdding(true)}
          aria-label={`Add ${descriptor}`}
          disabled={loading}
        >
          Add {descriptor}
        </AddItemButton>
      )}
    </VerticallySpaced>
  );
}
