import { AccountDTO } from '@invenco/common-interface/accounts';

import { Input, Select } from 'antd';
import { CreateWarehouseTenantDTO } from '../../../../../gateways/types';
import { useTenantModalComponent } from './useTenantModalComponent';
import { FormModal } from '../../../../../components/form-modal';
import { Form } from '../../../../../components/form';
import { Result } from '../../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  account: Partial<AccountDTO>;
  onClose: () => void;
  addToWarehouse: (data: {
    warehouseId: string;
    tenant: CreateWarehouseTenantDTO;
  }) => Promise<Result>;
};

export function TenantModal({ isOpen, account, onClose, addToWarehouse }: Props) {
  const {
    models: { isSaving, isLoadingWarehouses, form, warehouses },
    operations: { submit },
  } = useTenantModalComponent({ isOpen, account, onClose, addToWarehouse });

  return (
    <FormModal
      open={isOpen}
      onCancel={onClose}
      onSave={() => form.submit()}
      isSaving={isSaving}
      title="Invite to Warehouse"
    >
      <Form
        form={form}
        layout="vertical"
        name="tenant"
        onFinish={(data) => void submit(data)}
        initialValues={{ tenantName: account.name, tenantCode: '' }}
      >
        <Form.Item
          name="warehouseId"
          label="Warehouse"
          rules={[{ required: true, message: 'Please select a warehouse' }]}
        >
          <Select loading={isLoadingWarehouses}>
            {warehouses?.map((warehouse) => (
              <Select.Option key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="tenantName"
          label="Tenant Name"
          rules={[{ required: true, message: 'Please provide a tenant name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="tenantCode"
          label="Tenant Code"
          rules={[{ required: true, message: 'Please provide a tenant code' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
