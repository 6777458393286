import { ShippingPriceBandDTO } from '@invenco/common-interface/shipping';
import Decimal from 'decimal.js';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from 'components/form';
import { Input } from 'components/input';
import { formatMoney } from 'shared/helpers';
import { ActionButton, BandItemControls, Example, StyledListItem } from './styles';
import { useBandItem } from './useBandItem';

type Props = {
  priceBand: ShippingPriceBandDTO;
  previousBand?: ShippingPriceBandDTO;
  editing: boolean;
  exampleMarkup?: number;
  hideControls?: boolean;
  onEdit?: () => void;
  onCancel: () => void;
  onSave: (band: ShippingPriceBandDTO) => void;
  onDelete?: () => void;
};

export function BandItem({
  priceBand,
  previousBand,
  exampleMarkup,
  editing,
  hideControls,
  onEdit,
  onCancel,
  onSave,
  onDelete,
}: Props) {
  const {
    models: { form, initialValues },
    operations: { submit },
  } = useBandItem({ priceBand, editing, onSave });

  const getMaxCostElems = () => {
    if (editing) {
      return (
        <>
          <span>to</span>
          <Form.Item name="maxCost" rules={[{ pattern: /^\d+(\.\d{1,2})?$/ }]}>
            <Input autoFocus prefix="$" />
          </Form.Item>
        </>
      );
    }
    return !priceBand.maxCost && priceBand.maxCost !== 0 ? (
      <>
        <span>and</span> <strong>up</strong>
      </>
    ) : (
      <>
        <span>to</span>
        <strong>{formatMoney(priceBand.maxCost)}</strong>
      </>
    );
  };

  return (
    <StyledListItem $editing={editing} onClick={() => !editing && onEdit?.()}>
      <Form form={form} name="band" initialValues={initialValues} onFinish={(data) => submit(data)}>
        <div>
          <span>From</span>
          <strong>
            {previousBand && !previousBand.maxCost && previousBand.maxCost !== 0 ? (
              <>&mdash;</>
            ) : (
              // ensure we don't use 0.01 for the first band
              formatMoney(previousBand ? (previousBand.maxCost ?? 0) + 0.01 : 0)
            )}
          </strong>
          {getMaxCostElems()}
          <span>apply a markup of</span>
          {editing ? (
            <Form.Item name="markup" rules={[{ required: true }, { pattern: /^\d+(\.\d+)?%?$/ }]}>
              <Input suffix="%" />
            </Form.Item>
          ) : (
            <strong>{new Decimal(priceBand.markup).mul(100).toString()}%</strong>
          )}
        </div>

        <BandItemControls $hidden={hideControls}>
          {!editing && exampleMarkup !== undefined && (
            <Example>+ {formatMoney(exampleMarkup)}</Example>
          )}

          {editing ? (
            <>
              <ActionButton
                key="cancel" // keys prevent buttons being reused and thus having css colour transitions
                type="text"
                size="small"
                icon={<CloseOutlined />}
                onClick={onCancel}
                aria-label="Cancel"
              />
              <ActionButton
                key="save"
                type="text"
                htmlType="submit"
                size="small"
                icon={<CheckOutlined />}
                onClick={() => form.submit()}
                aria-label="Save"
              />
            </>
          ) : (
            <ActionButton
              key="delete"
              type="text"
              danger
              size="small"
              icon={<CloseOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                onDelete?.();
              }}
              aria-label="Delete"
            />
          )}
        </BandItemControls>
      </Form>
    </StyledListItem>
  );
}
