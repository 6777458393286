import { PlusOutlined } from '@ant-design/icons';
import { ChildConditionType } from '@invenco/common-domain/enums';
import { PropsWithChildren } from 'react';

import { childConditionTypeTitle } from 'shared/title-maps';
import { Button } from 'components/button';

type Props = {
  conditionType?: ChildConditionType;
  onClick: () => void;
};

export function AddButton({ conditionType, onClick, children }: PropsWithChildren<Props>) {
  return (
    <Button type="link" inline icon={<PlusOutlined />} size="small" onClick={onClick}>
      {conditionType ? `${childConditionTypeTitle[conditionType]} Condition` : children}
    </Button>
  );
}
