import { Card } from 'components/card';
import { Header } from 'components/header';
import { formatDate } from 'shared/helpers';
import { Container, DetailContainer, MainContainer } from 'styles/layout';
import { useInsightListPage, InsightRow } from './useInsightListPage';
import { DataTable, Column } from '../../../../components/data-table';

const columns: Column<InsightRow>[] = [
  { title: 'Name', key: 'name' },
  {
    title: 'Created',
    key: 'createdAt',
    render: (createdAt) => formatDate(createdAt),
    align: 'right',
  },
  {
    title: 'Last Updated',
    key: 'updatedAt',
    render: (updatedAt) => formatDate(updatedAt),
    align: 'right',
  },
];

export function InsightList() {
  const {
    models: { isLoading, reports, dashboards },
    operations: { onClickRow },
  } = useInsightListPage();
  return (
    <Container>
      <Header
        title="Insights"
        links={[
          { url: '/analytics', title: 'Analytics' },
          { url: '/analytics', title: 'Insights' },
        ]}
      />
      <DetailContainer>
        <MainContainer>
          <Card title="Reports" count={reports.length}>
            <DataTable
              simple
              columns={columns}
              rows={reports}
              loading={isLoading}
              onClickRow={onClickRow}
            />
          </Card>
          <Card title="Dashboards" count={dashboards.length}>
            <DataTable
              simple
              columns={columns}
              rows={dashboards}
              loading={isLoading}
              onClickRow={onClickRow}
            />
          </Card>
        </MainContainer>
      </DetailContainer>
    </Container>
  );
}
