import React, { ReactNode } from 'react';
import { Button } from 'components/button';
import { Card } from '../card';
import { StyledDivider } from './styles';

type Props = {
  /** Title displayed at the top of the card. Defaults to Details. */
  title?: string;

  /** Optionally help tooltip. */
  tooltip?: String | ReactNode;

  /** Whether to display an edit button on the right. */
  hasEditButton?: boolean;

  /** Label for the edit button. Defaults to Edit. */
  editLabel?: string;

  /** Callback for the edit button. */
  onEdit?: () => void;

  /** The content of the card. Generally a list of DetailsBlock elements. */
  children?: ReactNode;
};

/** Card designed for displaying resource details. Automatically inserts dividers between children. */
export function DetailsCard({
  title = 'Details',
  tooltip,
  hasEditButton,
  onEdit,
  editLabel = 'Edit',
  children,
}: Props) {
  const numChildren = React.Children.count(children);
  return (
    <Card
      title={title}
      tooltip={tooltip}
      spaceChildren={false}
      rightContent={
        hasEditButton && (
          <Button inline type="link" onClick={onEdit}>
            {editLabel}
          </Button>
        )
      }
    >
      {React.Children.map(children, (child, index) =>
        index < numChildren - 1 ? (
          <>
            {child}
            <StyledDivider />
          </>
        ) : (
          child
        ),
      )}
    </Card>
  );
}
