import { useQuery } from '@tanstack/react-query';
import { useGateways } from 'gateways/GatewayProvider';
import { ComponentData } from 'shared/types';
import { QueryProps } from './types';

type Models = {
  isLoading: boolean;
  options: { value: string }[];
};

export function useQuerySelectItemComponent({
  queryKey,
  query,
  valueKey,
}: QueryProps): ComponentData<Models> {
  const gateways = useGateways();
  const { data, isLoading } = useQuery({
    queryKey: [queryKey],
    queryFn: ({ signal }) => {
      if (!query) throw new Error('query undefined');
      return query(gateways, undefined, { signal });
    },
    select: ({ items }) => items.map((option) => ({ value: option[valueKey] })),
  });
  return {
    models: { isLoading, options: data ?? [] },
  };
}
